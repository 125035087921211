<script setup lang="ts">
import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";
import Dialog from "primevue/dialog";
import FeedbackFrom from "@/components/user/FeedbackForm.vue";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import { formatTime, showToast } from "@/utils";
import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { CourseRequest } from "@/types";

const router = useRouter();
const CourseStore = useCourseStore();

const acceptedCourse = ref([]);
const acceptedCurrentPage = ref(1);
const acceptedTotalPages = ref(1);
const declinedCourse = ref([]);
const declinedCurrentPage = ref(1);
const declinedTotalPages = ref(1);
const elementVisible = ref({});
let currCourse = ref({} as CourseRequest);
let feedbackVisible = ref(false);
let tooltipTimeout;

const viewCourseSupport = (type, pageNumber = 1) => {
  PractitionerService.getPractitionerBoard(pageNumber, type).then(
    (response) => {
      if (type === "accepted") {
        console.log(response.data);
        acceptedCourse.value = response.data.courses;
        acceptedTotalPages.value = response.data.totalPages;
        acceptedCurrentPage.value = response.data.currentPage;
      } else {
        declinedCourse.value = response.data.courses;
        declinedTotalPages.value = response.data.totalPages;
        declinedCurrentPage.value = response.data.currentPage;
      }
      response.data.courses.forEach(
        (el) => (elementVisible.value[el.id] = false)
      );
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-prac" });
};

const fetchCourse = () => {
  feedbackVisible.value = false;
  showToast("Your feedback has been successfully saved.");
  viewCourseSupport("accepted", acceptedCurrentPage.value);
};

const acceptedOnClick = () => {
  viewCourseSupport("accepted", acceptedCurrentPage.value);
};
const declinedOnClick = () => {
  viewCourseSupport("declined", declinedCurrentPage.value);
};

viewCourseSupport("accepted");
viewCourseSupport("declined");

const showTooltip = (index) => {
  elementVisible.value[index] = true;
  tooltipTimeout = setTimeout(
    () => (elementVisible.value[index] = false),
    5000
  );
};
const hideTooltip = (index) => {
  elementVisible.value[index] = false;
  clearTimeout(tooltipTimeout);
};
</script>

<style scoped>
.paginator {
  display: grid;
  justify-content: center;
  margin: 0.5rem 1rem 0.5rem 0;
}

.content-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 1.5em;
  padding: 18px;
  margin-right: 2rem;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-radius: 8px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .content-card {
    margin-top: 1rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    flex-direction: column;
  }

  .button-container {
    justify-content: space-evenly;
  }
}
</style>

<template>
  <div class="grid-container">
    <practitioner-sidebar></practitioner-sidebar>
    <div style="min-height: calc(100vh - 10em)">
      <div class="mt-3">
        <div class="flex flex-column lg:flex-row">
          <div>
            <span class="bold-text"> Course-Support Requests Accepted </span>
            <div v-if="acceptedCourse.length > 0">
              <ul>
                <li v-for="course in acceptedCourse" :key="course.id">
                  <div class="content-card">
                    <div>
                      Course-Support Type: {{ course.courseRequestType }}
                      <span
                        v-if="course.status == '60' || course.status == '10'"
                      >
                        &nbsp;
                        <span role="button" class="hint">
                          <span @click="showTooltip(course.id)">
                            <font-awesome-icon
                              icon="fa-solid fa-check"
                              style="color: #0bda51; font-size: x-large"
                            /><span style="font-size: smaller">Accepted</span>
                          </span>
                          <span
                            class="speech_bubble tool_tip_text"
                            id="tooltip"
                            v-show="elementVisible[course.id]"
                            style="display: inline"
                          >
                            <button
                              type="button"
                              class="close-btn-tooltip mr-4"
                            >
                              <font-awesome-icon
                                icon="fa-solid fa-xmark"
                                class="close-btn-icon"
                                @click="hideTooltip(course.id)"
                              />
                            </button>
                            <p
                              style="margin-bottom: 0px"
                              v-if="course.status === '60'"
                            >
                              You accepted to provide this request.
                            </p>
                            <p
                              style="margin-bottom: 0px"
                              v-if="course.status === '10'"
                            >
                              You responded to this request.
                            </p>
                          </span>
                        </span>
                      </span>
                      <br />
                      Course Requested For :
                      <span
                        v-for="(item, index) in JSON.parse(course.date)"
                        :key="index"
                      >
                        {{ item }}@{{
                          formatTime(JSON.parse(course.time)[index])
                        }}
                        <span
                          v-if="index != JSON.parse(course.date).length - 1"
                        >
                          OR
                        </span>
                      </span>
                      <br />
                      Professor :
                      {{
                        course.user.title +
                        " " +
                        course.user.firstName +
                        " " +
                        course.user.lastName
                      }}
                      <br />
                      Institute: {{ course.user.nameOrg }}
                    </div>
                    <div class="button-container flex-shrink-0">
                      <div
                        v-if="course['course_request_feedbacks'].length == 0"
                        class="p-2"
                      >
                        <button
                          class="btn btn-danger bg-red-500 border-none"
                          @click="
                            (currCourse = course), (feedbackVisible = true)
                          "
                          title="Give Feedback"
                        >
                          <img
                            src="@/assets/images/feedback.png"
                            class="sidebar-image"
                          />
                        </button>
                      </div>
                      <div v-else></div>
                      <div class="p-2">
                        <button
                          class="btn btn-primary bg-green-400 border-none"
                          @click="viewCourseRequest(course)"
                          title="View Details"
                        >
                          <img
                            src="@/assets/images/info.png"
                            class="sidebar-image"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="paginator">
                <va-pagination
                  v-model="acceptedCurrentPage"
                  :pages="acceptedTotalPages"
                  :visible-pages="3"
                  :hideOnSinglePage="true"
                  @click="acceptedOnClick"
                />
              </div>
            </div>
            <div v-else style="font-style: italic">
              <br />
              No entries under this category at the moment
            </div>
          </div>
          <div class="mt-3 lg:mt-0">
            <span class="bold-text">
              Course-Support Requests Declined/Not Responded
            </span>
            <div v-if="declinedCourse.length > 0">
              <ul>
                <li v-for="course in declinedCourse" :key="course.id">
                  <div class="content-card">
                    <div>
                      Course-Support Type: {{ course.courseRequestType }}
                      <span v-if="course.status == '90'">
                        &nbsp;
                        <span role="button" class="hint">
                          <span @click="showTooltip(course.id)">
                            <font-awesome-icon
                              icon="fa-solid fa-xmark"
                              style="color: #e71921; font-size: x-large"
                            /><span style="font-size: smaller"> Declined</span>
                          </span>
                          <span
                            class="speech_bubble tool_tip_text"
                            id="tooltip"
                            v-show="elementVisible[course.id]"
                            style="display: inline"
                          >
                            <button
                              type="button"
                              class="close-btn-tooltip mr-4"
                            >
                              <font-awesome-icon
                                icon="fa-solid fa-xmark"
                                class="close-btn-icon"
                                @click="hideTooltip(course.id)"
                              />
                            </button>
                            <p style="margin-bottom: 0px">
                              You declined this request.
                            </p>
                          </span>
                        </span>
                      </span>
                      <span v-else class="hint">
                        &nbsp;
                        <span role="button" class="hint">
                          <font-awesome-icon
                            icon="fa-solid fa-circle-exclamation"
                            @click="showTooltip(course.id)"
                          />
                          <span
                            class="speech_bubble tool_tip_text"
                            id="tooltip"
                            v-show="elementVisible[course.id]"
                            style="display: inline"
                          >
                            <button
                              type="button"
                              class="close-btn-tooltip mr-4"
                            >
                              <font-awesome-icon
                                icon="fa-solid fa-xmark"
                                class="close-btn-icon"
                                @click="hideTooltip(course.id)"
                              />
                            </button>
                            <p style="margin-bottom: 0px">
                              You did not respond to this request.
                            </p>
                          </span>
                        </span>
                      </span>
                      <br />
                      Course Requested For :
                      <span
                        v-for="(item, index) in JSON.parse(course.date)"
                        :key="index"
                      >
                        {{ item }}@{{
                          formatTime(JSON.parse(course.time)[index])
                        }}
                        <span
                          v-if="index != JSON.parse(course.date).length - 1"
                        >
                          OR
                        </span>
                      </span>
                      <br />
                      Professor :
                      {{
                        course.user.title +
                        " " +
                        course.user.firstName +
                        " " +
                        course.user.lastName
                      }}
                      <br />
                      Institute: {{ course.user.nameOrg }}
                    </div>
                    <div class="button-container flex-shrink-0">
                      <div class="p-2">
                        <button
                          class="btn btn-primary bg-green-400 border-none"
                          @click="viewCourseRequest(course)"
                          title="View Details"
                        >
                          <img
                            src="@/assets/images/info.png"
                            class="sidebar-image"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="paginator">
                <va-pagination
                  v-model="declinedCurrentPage"
                  :pages="declinedTotalPages"
                  :visible-pages="3"
                  :hideOnSinglePage="true"
                  @click="declinedOnClick"
                />
              </div>
            </div>
            <div v-else style="font-style: italic">
              <br />
              No entries under this category at the moment
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="feedbackVisible"
    modal
    header="Course-Support Feedback"
    :style="{ width: '50vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <feedback-from :curr-course-id="currCourse.id" @fetchCourse="fetchCourse" />
  </Dialog>
</template>
