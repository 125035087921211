import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/uploads/general_resource.jpg'


const _hoisted_1 = { class: "gallery" }
const _hoisted_2 = { class: "image-container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = {
  key: 1,
  class: "h-inherit"
}
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = {
  key: 1,
  controls: ""
}
const _hoisted_8 = ["src", "type"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["alt"]
const _hoisted_12 = { class: "desc" }
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  class: "flex justify-content-center",
  style: {"height":"70vh"}
}
const _hoisted_15 = ["src"]

import { Upload } from "@/types";
import { websiteURL } from "@/utils";
import { ref } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import Dialog from "primevue/dialog";
import heic2any from "heic2any";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourcesGalleryItem',
  props: {
    item: {}
  },
  setup(__props: any) {

const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;
const showCustomContent = ref(false);
const imgSrc = ref("");
const imgDesc = ref("");
const downloadSrc = ref("");

const formatLink = (link) => {
  if (!link.startsWith("http://") && !link.startsWith("https://")) {
    return `https://${link}`; // Assuming `websiteURL` is the base URL
  }
  return link;
};

// eslint-disable-next-line no-undef
const props = __props;

const showImage = (src, desc, downloadPath) => {
  imgSrc.value = src;
  imgDesc.value = desc;
  downloadSrc.value = downloadPath;
  showCustomContent.value = true;
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.item.linkResources)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: formatLink(_ctx.item.linkResources),
              target: "_blank"
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: _ctx.item.linkResources
              }, null, 8, _hoisted_4)
            ], 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.item.type === 'Project Pictures' && _ctx.item.filePath)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _unref(websiteURL) + _ctx.item.filePath.substring(9),
                    alt: _ctx.item.description,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (
            showImage(
              _unref(websiteURL) + _ctx.item.filePath.substring(9),
              _ctx.item.description,
              _unref(websiteURL) + _ctx.item.origFilePath.substring(9)
            )
          ))
                  }, null, 8, _hoisted_6))
                : _createCommentVNode("", true),
              (_ctx.item.type === 'Video' && _ctx.item.filePath)
                ? (_openBlock(), _createElementBlock("video", _hoisted_7, [
                    _createElementVNode("source", {
                      src: _unref(websiteURL) + _ctx.item.filePath.substring(9),
                      type: _ctx.item['mimetype']
                    }, null, 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.item.type === 'Document')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("a", {
                      href: _unref(websiteURL) + _ctx.item.filePath.substring(9),
                      target: "_blank"
                    }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: _ctx.item.description,
                        style: {"width":"100%","height":"100%","overflow":"hidden"}
                      }, null, 8, _hoisted_11)
                    ], 8, _hoisted_10)
                  ]))
                : _createCommentVNode("", true)
            ]))
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", null, _toDisplayString(_ctx.item.description.slice(0, 25) +
          (_ctx.item.description.length > 25 ? "..." : "")), 1),
        (_ctx.item.filePath)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _unref(websiteURL) + _ctx.item.origFilePath.substring(9),
              download: "",
              target: "_blank",
              title: "Download",
              style: {"color":"var(--bs-body-color)"}
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-download" })
            ], 8, _hoisted_13))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: showCustomContent.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showCustomContent).value = $event)),
      modal: "",
      header: imgDesc.value,
      style: { width: '75vw', padding: '20px', backgroundColor: '#fff' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_14, [
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("img", {
            src: imgSrc.value,
            class: "model-img"
          }, null, 8, _hoisted_15)
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})