import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "search-wrapper" }
const _hoisted_3 = {
  type: "submit",
  class: "search-button button",
  title: "Search"
}

import { ref } from "vue";
import { useRouter } from "vue-router";
import { Field, useForm } from "vee-validate";
import { showErrorToast } from "@/utils";

const MIN_TEXT_MAX_LENGTH = 200;

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSearchBar',
  setup(__props) {

const router = useRouter();
const { handleSubmit } = useForm();

const searchParameter = ref("");

const onInputWithNotification = (maxLength: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    searchParameter.value = target.value;
    showErrorToast(`Maximum character limit reached`);
  } else {
    searchParameter.value = value;
    if (value.length === maxLength) {
      showErrorToast(`Maximum character limit reached`);
    }
  }
};

const search = handleSubmit(() => {
  if (searchParameter.value.length > 0) {
    router.push({ name: "search", params: { keyword: searchParameter.value } });
  }
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(search) && _unref(search)(...args))),
      class: "search-container"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Field), {
          name: "searchParameter",
          modelValue: searchParameter.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParameter).value = $event)),
          type: "text",
          placeholder: "Search ConPEC",
          class: "search-bar",
          maxlength: MIN_TEXT_MAX_LENGTH,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (onInputWithNotification(MIN_TEXT_MAX_LENGTH, $event)))
        }, null, 8, ["modelValue"]),
        _createElementVNode("button", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-magnifying-glass" })
        ])
      ])
    ], 32),
    _createElementVNode("div", null, _toDisplayString(searchParameter.value.length) + " / " + _toDisplayString(MIN_TEXT_MAX_LENGTH) + " characters ", 1)
  ]))
}
}

})