import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-column" }
const _hoisted_2 = {
  key: 0,
  class: "form-group"
}
const _hoisted_3 = {
  key: 1,
  class: "form-group"
}
const _hoisted_4 = {
  key: 0,
  class: "form-group"
}
const _hoisted_5 = {
  key: 1,
  class: "form-group"
}
const _hoisted_6 = {
  key: 2,
  class: "form-group"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 3,
  class: "form-group"
}
const _hoisted_9 = {
  key: 4,
  class: "form-group"
}
const _hoisted_10 = {
  key: 5,
  class: "form-column"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "form-group"
}
const _hoisted_13 = { class: "form-group text-align-center" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "spinner-border spinner-border-sm" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }

import { ref } from "vue";
import * as yup from "yup";
import { useConfigStore } from "@/stores/ConfigStore";
import InstructorService from "@/services/instructor.service.js";
import Multiselect from "@vueform/multiselect";
import { Form, Field, ErrorMessage } from "vee-validate";
import {
  deptOptions,
  academicLevelOptions,
  displayLabels,
  showErrorToast,
} from "@/utils";
import { Course } from "@/types";

// Initialize Config Store
const TEXT_MAX_LENGTH_10 = 10;
const TEXT_MAX_LENGTH_100 = 100;
const TEXT_MAX_LENGTH_250 = 250;
const TEXT_MAX_LENGTH_2000 = 2000;

// Ensure "Others, please specify" is included only once

export default /*@__PURE__*/_defineComponent({
  __name: 'ClassForm',
  props: {
    successAction: { type: Function },
    action: {},
    course: {}
  },
  setup(__props: any) {

const ConfigStore = useConfigStore();

// Define Maximum Length Constants
const deptOptionsAddedBase = [
  { label: "No preference", value: "No preference" },
  ...deptOptions,
  { label: "Others, please specify", value: "Others" },
];

// Input Handler with Notification
const onInputWithNotification = (maxLength: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  }
};

// Reactive Variables
let message = ref("");
let loading = ref(false);
let years: number[] = [];

// Define Props
const props = __props;

// Fetch Configured Fields for 'course' Table
const classFields = ConfigStore.config.filter((el) => el.table === "course");

// Populate Years Array (Current Year + 15 Years)
let currentYear = new Date().getFullYear();
for (let i = 0; i < 16; i++) {
  years.push(currentYear + i);
}

// Initialize Form State
let initialState = {
  courseCode: "",
  courseTitle: "",
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
  classSize: "",
  semester: "",
  year: "",
  additionalInformation: "",
  location: "",
};

// If Updating, Populate Initial State with Existing Course Data
if (props.action === "update" && props.course) {
  initialState.academicLevel = JSON.parse(props.course.academicLevel);
  initialState.studentDept = JSON.parse(props.course.studentDept);

  // Filter out custom items not in deptOptions
  const customItems = initialState.studentDept.filter(
    (item) =>
      !deptOptionsAddedBase.some((option) => option.value === item) &&
      item !== "Others"
  );

  // Assign the first custom item to studentDept_text, and remove it from studentDept
  if (customItems.length > 0) {
    initialState.studentDept_text = customItems[0]; // Use the first custom value
    initialState.studentDept = initialState.studentDept.filter(
      (item) => item !== customItems[0]
    );
  }
}

if (props.action === "update") {
  initialState.academicLevel = JSON.parse(props.course.academicLevel);
  initialState.studentDept = JSON.parse(props.course.studentDept);
  if (
    !deptOptions.some((el) => el.value === initialState.studentDept.at(-1)!)
  ) {
    initialState.studentDept_text = initialState.studentDept.at(-1)!;
    initialState.studentDept.pop();
  }
}

// Define Initial Form Values
const formValues = {
  courseCode: props.course?.courseCode || "",
  courseTitle: props.course?.courseTitle || "",
  academicLevel: initialState.academicLevel,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text, // Additional field for "Others"
  classSize: props.course?.classSize || "",
  semester: props.course?.semester || "",
  year: props.course?.year || "",
  additionalInformation: props.course?.additionalInformation || "",
  location: props.course?.location || "",
};

// Define Validation Schema
const schema = yup.object().shape({
  fields: yup.array().default(classFields),
  courseCode: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "courseCode")?.disabled,
    then: (schema) =>
      schema
        .required("Course Code is required!")
        .max(10, "Course Code cannot be greater than 10 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  courseTitle: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "courseTitle")?.disabled,
    then: (schema) =>
      schema
        .required("Course Title is required!")
        .max(250, "Course Title cannot be greater than 250 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) =>
        !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' academic level is required!")
          .min(1, "Students' academic level is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .required("Students' department or Program of study is required!")
    .min(1, "Please select at least one department or program of study."),
  studentDept_text: yup.string().when("studentDept", {
    is: (studentDept: any) =>
      Array.isArray(studentDept) && studentDept.includes("Others"),
    then: (schema) =>
      schema
        .trim()
        .min(1, "Please specify other department or program of study.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().default(""),
  }),

  classSize: yup
    .number()
    .typeError("Class Size must be a number!")
    .positive("Class Size must be a positive number!")
    .integer("Class Size must be an integer!")
    .when("fields", {
      is: (val: any[]) => !val.find((el) => el.name === "classSize")?.disabled,
      then: (schema) => schema.required("Class Size is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  semester: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "semester")?.disabled,
    then: (schema) => schema.required("Semester is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  year: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "year")?.disabled,
    then: (schema) => schema.required("Year is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  additionalInformation: yup.string().optional().nullable(),
  location: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "location")?.disabled,
    then: (schema) =>
      schema
        .required("Location of Classroom is required!")
        .max(
          250,
          "Location of Classroom cannot be greater than 250 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

// Save Course Function
const saveCourse = async (values: any) => {
  loading.value = true;
  console.log("Attempting to save course with values:", values);

  if (props.action === "update") {
    values.id = props.course.id;
    console.log(values.id);
  }
  if (values?.studentDept?.includes("Others") && values.studentDept_text) {
    values.studentDept.push(values.studentDept_text);
    console.log("Updated studentDept with 'Others':", values.studentDept);
  }

  try {
    const response = await InstructorService.saveCourse(values, props.action);
    console.log("Save Course Response:", response);
    props.successAction();
  } catch (error: any) {
    console.error("Error saving course:", error.message || error);
    showErrorToast(error.message || "An error occurred");
  } finally {
    loading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: saveCourse,
    "validation-schema": _unref(schema),
    "initial-values": formValues
  }, {
    default: _withCtx(({ values }) => [
      _createElementVNode("div", _hoisted_1, [
        (!_unref(classFields).find((el) => el.name === 'courseCode')?.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[6] || (_cache[6] = _createElementVNode("label", { for: "courseCode" }, [
                _createTextVNode(" Course Code "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "courseCode" }, {
                default: _withCtx(({ field }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    maxlength: TEXT_MAX_LENGTH_10,
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_10, $event)))
                  }), null, 16),
                  _createTextVNode(" " + _toDisplayString(field.value ? field.value.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_10) + " characters ", 1),
                  _createVNode(_unref(ErrorMessage), {
                    name: "courseCode",
                    class: "error-feedback"
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_unref(classFields).find((el) => el.name === 'courseTitle')?.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[7] || (_cache[7] = _createElementVNode("label", { for: "courseTitle" }, [
                _createTextVNode(" Course Title "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "courseTitle" }, {
                default: _withCtx(({ field }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    maxlength: TEXT_MAX_LENGTH_250,
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_250, $event)))
                  }), null, 16),
                  _createTextVNode(" " + _toDisplayString(field.value ? field.value.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_250) + " characters ", 1),
                  _createVNode(_unref(ErrorMessage), {
                    name: "courseTitle",
                    class: "error-feedback"
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (
        !_unref(classFields).find((el) => el.name === 'additionalInformation')?.disabled
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "additionalInformation" }, " Link to Additional Course Information ", -1)),
            _createVNode(_unref(Field), { name: "additionalInformation" }, {
              default: _withCtx(({ field }) => [
                _createElementVNode("input", _mergeProps(field, {
                  class: "form-control",
                  type: "text",
                  placeholder: "https://",
                  maxlength: TEXT_MAX_LENGTH_2000,
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_2000, $event)))
                }), null, 16),
                _createTextVNode(" " + _toDisplayString(field.value ? field.value.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_2000) + " characters ", 1),
                _createVNode(_unref(ErrorMessage), {
                  name: "additionalInformation",
                  class: "error-feedback"
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(classFields).find((el) => el.name === 'academicLevel')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[9] || (_cache[9] = _createElementVNode("label", { for: "academicLevel" }, [
              _createTextVNode(" Students' Academic Level"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "academicLevel",
              as: "div"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.academicLevel,
                  "onUpdate:modelValue": ($event: any) => ((values.academicLevel) = $event),
                  options: _unref(academicLevelOptions),
                  mode: "tags",
                  searchable: true,
                  "multiple-label": _unref(displayLabels),
                  "hide-selected": false,
                  "close-on-select": false,
                  "close-on-deselect": false
                }), null, 16, ["modelValue", "onUpdate:modelValue", "options", "multiple-label"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_unref(ErrorMessage), {
              name: "academicLevel",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(classFields).find((el) => el.name === 'studentDept')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { for: "studentDept" }, [
              _createTextVNode(" Students' Department or Program of Study"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "studentDept",
              as: "div"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.studentDept,
                  "onUpdate:modelValue": ($event: any) => ((values.studentDept) = $event),
                  options: deptOptionsAddedBase,
                  mode: "tags",
                  searchable: true,
                  "multiple-label": _unref(displayLabels),
                  "hide-selected": false,
                  "close-on-select": false,
                  "close-on-deselect": false
                }), null, 16, ["modelValue", "onUpdate:modelValue", "multiple-label"])
              ]),
              _: 2
            }, 1024),
            (values.studentDept && values.studentDept.includes('Others'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(Field), { name: "studentDept_text" }, {
                    default: _withCtx(({ field }) => [
                      _createElementVNode("input", _mergeProps(field, {
                        type: "text",
                        placeholder: "Please Specify",
                        class: "form-control bg-others",
                        maxlength: TEXT_MAX_LENGTH_100,
                        onInput: _cache[3] || (_cache[3] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_100, $event)))
                      }), null, 16),
                      _createTextVNode(" " + _toDisplayString(field.value ? field.value.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_100) + " characters ", 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_unref(ErrorMessage), {
              name: "studentDept",
              class: "error-feedback"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "studentDept_text",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(classFields).find((el) => el.name === 'location')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { for: "location" }, [
              _createTextVNode(" Location of Classroom "),
              _createElementVNode("span", { class: "super font-color-red" }, "*"),
              _createElementVNode("span", { class: "font-color-yellow" }, " (Actual Address Including Room Number) ")
            ], -1)),
            _createVNode(_unref(Field), { name: "location" }, {
              default: _withCtx(({ field }) => [
                _createElementVNode("input", _mergeProps(field, {
                  class: "form-control",
                  type: "text",
                  maxlength: TEXT_MAX_LENGTH_250,
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_250, $event)))
                }), null, 16),
                _createTextVNode(" " + _toDisplayString(field.value ? field.value.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_250) + " characters ", 1),
                _createVNode(_unref(ErrorMessage), {
                  name: "location",
                  class: "error-feedback"
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(classFields).find((el) => el.name === 'classSize')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { for: "classSize" }, [
              _createTextVNode(" Class Size"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), { name: "classSize" }, {
              default: _withCtx(({ field }) => [
                _createElementVNode("input", _mergeProps(field, {
                  class: "form-control",
                  type: "text",
                  maxlength: TEXT_MAX_LENGTH_10,
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_10, $event)))
                }), null, 16),
                _createTextVNode(" " + _toDisplayString(field.value ? field.value.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_10) + " characters ", 1),
                _createVNode(_unref(ErrorMessage), {
                  name: "classSize",
                  class: "error-feedback"
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(classFields).find((el) => el.name === 'semester')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[13] || (_cache[13] = _createElementVNode("label", { for: "semester" }, [
                _createTextVNode(" Semester "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "semester",
                type: "text",
                class: "dropdown"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: values.semester,
                    "onUpdate:modelValue": ($event: any) => ((values.semester) = $event),
                    options: ['Fall', 'Spring', 'Summer', 'Winter'],
                    mode: "single",
                    searchable: true
                  }), null, 16, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(ErrorMessage), {
                name: "semester",
                class: "error-feedback"
              })
            ]),
            (!_unref(classFields).find((el) => el.name === 'year')?.disabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", { for: "year" }, [
                    _createTextVNode(" Year "),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "year",
                    type: "text",
                    class: "dropdown"
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Multiselect), _mergeProps(field, {
                        modelValue: values.year,
                        "onUpdate:modelValue": ($event: any) => ((values.year) = $event),
                        options: _unref(years),
                        mode: "single",
                        searchable: true
                      }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_unref(ErrorMessage), {
                    name: "year",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading)
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_15, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          (props.action === 'update')
            ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Update Class"))
            : (_openBlock(), _createElementBlock("span", _hoisted_17, "Add Class"))
        ], 8, _hoisted_14)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})