import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "col-md-12 mt-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "flex flex-column lg:flex-row align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "course-item mt-5"
}
const _hoisted_6 = { style: {"display":"flex","flex-wrap":"wrap","justify-content":"space-between","align-items":"flex-start"} }
const _hoisted_7 = { style: {"white-space":"normal","overflow-wrap":"break-word","word-break":"break-word","margin-right":"1rem"} }
const _hoisted_8 = { style: {"display":"flex","align-items":"center","gap":"0.75rem","margin-top":"0.5rem"} }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "text-align-center mb-3"
}
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  key: 0,
  class: "form-group"
}
const _hoisted_14 = { class: "form-group text-align-center" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import { showErrorToast, showToast, valid } from "@/utils";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import CourseSelectionForm from "@/components/practitioner/CourseSelectionForm.vue";
import CourseDetailsForm from "@/components/practitioner/CourseDetailsForm.vue";
import AvailabilitySelector from "@/components/practitioner/AvailabilitySelector";
import SiteVisitForm from "@/components/practitioner/SiteVisitForm.vue";
import Dialog from "primevue/dialog";
import PractitionerService from "@/services/practitioner.service.js";
import { Site } from "@/types";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { useRouter } from "vue-router";
import { useModal } from "vuestic-ui";


export default /*@__PURE__*/_defineComponent({
  __name: 'AvailabilityView',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const AvailabilityStore = useAvailabilityStore();
const router = useRouter();
const { confirm } = useModal();

const step = ref(0);
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);
const currentUser = AuthStore.auth;
let isVisible = ref(false);
let showAddSiteModel = ref(false);
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let siteAction = ref("");
let emptySite = ref({} as Site);
let currSite = ref({} as Site);
let dataToSave = AvailabilityStore.availability;

const steps = ref([
  { label: "Specify Preferences" },
  { label: "General Details" },
  { label: "Date & Time " },
  { label: "Site Details" },
]);

const stepsChanger = (isSiteVisitSelected) => {
  if (isSiteVisitSelected && steps.value.length === 3) {
    steps.value.splice(3, 0, {
      label: "Site Details",
    });
  } else if (!isSiteVisitSelected && steps.value.length === 4) {
    steps.value.splice(3, 1);
  }
};

const getMaxSiteId = () =>
  dataToSave ? Math.max(...dataToSave?.siteList?.map((el) => el.id)) : -1;

const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),
  courseRequestType: yup
    .array()
    .of(yup.string())
    .required("Please select one of available Course Support Type!")
    .min(1, "Please select one of available Course Support Type!"),
  subjectSpeak: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "subjectSpeak")?.disabled,
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  subjectSpeakOthers: yup
    .array()
    .of(yup.string())
    .when("subjectSpeak", {
      is: (val) => valid(val) && val.includes("Others"),
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().min(0),
    }),
  insDeliveryType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insState: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "insState")?.disabled,
      then: (schema) =>
        schema
          .required("Location of institution is required!")
          .min(1, "Location of institution is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  insName: yup.array().of(yup.string()).optional().min(0),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Academic Level is required!")
          .min(1, "Students' Academic Level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Department or Program of Study is required!")
          .min(1, "Students' Department or Program of Study is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  others: yup.string().optional().nullable(),
  availabilityTime: yup
    .array()
    .of(yup.object())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "availabilityTime")?.disabled,
      then: (schema) => schema.optional().nullable().min(0),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  siteList: yup
    .array()
    .of(yup.object())
    .when("courseRequestType", {
      is: (val) => valid(val) && val.includes("Site Visit"),
      then: (schema) =>
        schema
          .required(
            "Site Details are required. In order to proceed, please provide site details or go to the first step to deselect site visit if you do not want to provide site details!"
          )
          .min(
            1,
            "Site Details are required. In order to proceed, please provide site details or go to the first step to deselect site visit if you do not want to provide site details!"
          ),
      otherwise: (schema) => schema.optional().min(0),
    }),
});

const nextStep = (currStepData) => {
  Object.keys(currStepData).forEach((key) => {
    dataToSave[key] = currStepData[key];
  });
  step.value += 1;
};

const { handleSubmit, validate, values, setValues } = useForm({
  validationSchema: schema,
});

const showPopup = async () => {
  setValues({
    courseRequestType: dataToSave.courseRequestType,
    subjectSpeak: dataToSave.subjectSpeak,
    subjectSpeakOthers: dataToSave.subjectSpeakOthers,
    insDeliveryType: dataToSave.insDeliveryType,
    insState: dataToSave.insState,
    insName: dataToSave.insName,
    academicLevel: dataToSave.academicLevel,
    studentDept: dataToSave.studentDept,
    others: dataToSave.others,
    siteList: dataToSave.siteList,
    availabilityTime: dataToSave.availabilityTime,
    Email: "True",
    phone: currentUser.phone,
    phoneNumber: currentUser.phoneNumber,
  });
  const { valid, errors } = await validate();
  if (valid) isVisible.value = true;
  else Object.values(errors).forEach((error) => showErrorToast(error));
};

const saveAvailability = handleSubmit((data) => {
  // console.log(data);
  // console.log(AvailabilityStore.availability);
  loading.value = true;
  PractitionerService.saveAvailability(data).then(
    () => {
      AuthStore.getProfile().then(
        () => {
          showToast("Your availability has been saved.");
          loading.value = false;
          router.push({ name: "industry-practitioner-feed" });
        },
        (err) => {
          loading.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
});

const saveSite = (action, siteData) => {
  if (action === "create") {
    siteData.id = getMaxSiteId() + 1;
  } else siteData.id = currSite.value.id;
  const index = dataToSave.siteList.findIndex((el) => el.id === siteData.id);
  if (index < 0) dataToSave.siteList.push(siteData);
  else dataToSave.siteList[index] = siteData;
  showAddSiteModel.value = false;
};

const saveAvailabilityTime = (availabilityTime) => {
  if (dataToSave.availabilityTime) dataToSave.availabilityTime.length = 0;
  dataToSave.availabilityTime = availabilityTime;
  if (steps.value.length === 3) showPopup();
  else step.value += 1;
};

const deleteSite = async (site) => {
  const answer = await confirm({
    message: "Do you want to proceed without deleting the selected site?",
    okText: "Confirm",
    cancelText: "Cancel",
  });
  if (answer) {
    const index = dataToSave.siteList.findIndex((el) => el.id === site.id);
    dataToSave.siteList.splice(index, 1); // 2nd parameter means remove one item only
  }
};

if (AvailabilityStore.status) {
  stepsChanger(
    valid(AvailabilityStore.availability.courseRequestType) &&
      AvailabilityStore.availability.courseRequestType.includes("Site Visit")
  );
} else {
  stepsChanger(false);
}

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_stepper = _resolveComponent("va-stepper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(PractitionerSidebar),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(BackButton),
            _cache[9] || (_cache[9] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Type of Course-Support Willing to Provide ", -1))
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_va_stepper, {
            modelValue: step.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((step).value = $event)),
            steps: steps.value,
            controlsHidden: "",
            nextDisabled: ""
          }, {
            "step-content-0": _withCtx(() => [
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "font-color-red ml-2 lg:ml-5" }, "* required field", -1)),
              _createVNode(CourseSelectionForm, {
                availability: _unref(dataToSave),
                onSiteVisitSelected: stepsChanger,
                onNextStep: nextStep
              }, null, 8, ["availability"])
            ]),
            "step-content-1": _withCtx(() => [
              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "font-color-red ml-2 lg:ml-5" }, "* required field", -1)),
              _createVNode(CourseDetailsForm, {
                availability: _unref(dataToSave),
                onNextStep: nextStep,
                onPreviousStep: _cache[0] || (_cache[0] = ($event: any) => (step.value -= 1))
              }, null, 8, ["availability"])
            ]),
            "step-content-2": _withCtx(() => [
              _createVNode(_unref(AvailabilitySelector), {
                availability: _unref(dataToSave),
                lastStep: steps.value.length === 3,
                onNextStep: saveAvailabilityTime,
                onPreviousStep: _cache[1] || (_cache[1] = ($event: any) => (step.value -= 1))
              }, null, 8, ["availability", "lastStep"])
            ]),
            "step-content-3": _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "bold-text" }, "Site Details", -1)),
                _createElementVNode("div", {
                  class: "py-1 px-2 lg:p-3 card cursor-pointer inline-block flex-shrink-1 flex-grow-0 m-0 mt-2 lg:mt-0",
                  style: {"width":"max-content"},
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (
                  (_isRef(currSite) //@ts-ignore
 ? currSite.value = _unref(emptySite) : currSite = _unref(emptySite)),
                    (_isRef(siteAction) //@ts-ignore
 ? siteAction.value = 'create' : siteAction = 'create'),
                    (_isRef(showAddSiteModel) //@ts-ignore
 ? showAddSiteModel.value = true : showAddSiteModel = true)
                ))
                }, [
                  _cache[12] || (_cache[12] = _createElementVNode("b", null, " Add Site ", -1)),
                  _createVNode(_component_font_awesome_icon, {
                    class: "ml-2",
                    title: "Add Site",
                    icon: "fa-solid fa-plus"
                  })
                ])
              ]),
              (_unref(AvailabilityStore).availability.siteList.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AvailabilityStore).availability.siteList, (site) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "m-1 card",
                        key: site.city
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("b", null, _toDisplayString(site.projectDescr), 1),
                            _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(site.city) + ", " + _toDisplayString(site.state), 1)
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-danger",
                              onClick: _withModifiers(($event: any) => (deleteSite(site)), ["prevent"])
                            }, _cache[15] || (_cache[15] = [
                              _createTextVNode(" Delete "),
                              _createElementVNode("span", { class: "d-none d-lg-inline" }, "Site", -1)
                            ]), 8, _hoisted_9),
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-primary",
                              onClick: _withModifiers(($event: any) => (
                        (_isRef(currSite) //@ts-ignore
 ? currSite.value = site : currSite = site),
                          (_isRef(siteAction) //@ts-ignore
 ? siteAction.value = 'update' : siteAction = 'update'),
                          (_isRef(showAddSiteModel) //@ts-ignore
 ? showAddSiteModel.value = true : showAddSiteModel = true)
                      ), ["prevent"])
                            }, _cache[16] || (_cache[16] = [
                              _createTextVNode(" View/Edit "),
                              _createElementVNode("span", { class: "d-none d-lg-inline" }, "Site Details", -1)
                            ]), 8, _hoisted_10)
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "steps"]),
          (step.value === 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "btn btn-secondary ml-2",
                  type: "button",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (step.value -= 1))
                }, " Previous "),
                _createElementVNode("button", {
                  class: "btn btn-primary ml-2",
                  type: "button",
                  onClick: showPopup
                }, " Save Availability ")
              ]))
            : _createCommentVNode("", true),
          (_unref(message))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_unref(successful) ? 'alert-success' : 'alert-danger')
              }, _toDisplayString(_unref(message)), 3))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(showAddSiteModel),
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(showAddSiteModel) ? (showAddSiteModel).value = $event : showAddSiteModel = $event)),
      modal: "",
      header: "Site Details",
      style: {"padding":"20px","background-color":"#fff","border-radius":"8px","box-shadow":"0 4px 6px rgba(0, 0, 0, 0.1)","max-width":"50vw","min-width":"50vw","margin":"0 auto"},
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(SiteVisitForm, {
          action: _unref(siteAction),
          site: _unref(currSite),
          onSaveSite: saveSite
        }, null, 8, ["action", "site"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "Contact Preference",
      style: { width: '30vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_unref(saveAvailability) && _unref(saveAvailability)(...args)), ["prevent"])),
          onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => ($event.preventDefault()), ["enter"]))
        }, [
          _createElementVNode("div", _hoisted_12, [
            _cache[18] || (_cache[18] = _createElementVNode("label", { for: "contactPref" }, "Please select how you would like to be contacted?", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
            _createVNode(_unref(Field), {
              name: "Email",
              value: "True",
              type: "checkbox",
              disabled: ""
            }),
            _cache[20] || (_cache[20] = _createTextVNode("Email ")),
            _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
            _createVNode(_unref(Field), {
              value: "True",
              "unchecked-value": "False",
              name: "phone",
              type: "checkbox"
            }),
            _cache[22] || (_cache[22] = _createTextVNode(" Phone ")),
            _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
            _createVNode(_unref(ErrorMessage), {
              name: "contactPref",
              class: "error-feedback"
            })
          ]),
          (_unref(values).phone === 'True')
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _cache[24] || (_cache[24] = _createElementVNode("label", { for: "phoneNumber" }, "Phone Number", -1)),
                _createVNode(_unref(Field), {
                  name: "phoneNumber",
                  type: "text",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "phoneNumber",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-block",
              disabled: _unref(loading)
            }, [
              _withDirectives(_createElementVNode("span", _hoisted_16, null, 512), [
                [_vShow, _unref(loading)]
              ]),
              _cache[25] || (_cache[25] = _createElementVNode("span", null, "Proceed", -1))
            ], 8, _hoisted_15)
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})