import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/delete.png'
import _imports_1 from '@/assets/images/file.png'


const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "mt-3 max-width" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "flex flex-column lg:flex-row xl:align-items-center" }
const _hoisted_5 = { class: "flex-shrink-1 flex-grow-0 mr-3 layout-button text-align-center" }
const _hoisted_6 = { class: "mt-3 mx-3" }
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { id: "context-menu" }
const _hoisted_10 = {
  class: "flex justify-content-center",
  style: {"height":"70vh"}
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  controls: ""
}
const _hoisted_13 = ["src", "type"]
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "form-group text-align-center" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "spinner-border spinner-border-sm" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "form-group text-align-center" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { class: "spinner-border spinner-border-sm" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = ["value"]
const _hoisted_24 = { class: "form-group text-align-center" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { useAuthStore } from "@/stores/AuthStore";
import UserService from "@/services/user.service.js";
import { showToast, showErrorToast, websiteURL } from "@/utils";
import { Folder, Upload } from "@/types";
import heic2any from "heic2any";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyUploadsView',
  setup(__props) {

const columns = [
  { key: "fileName", label: "Name", sortable: true },
  { key: "updatedAt", label: "Last Modified", sortable: true },
  { key: "size" },
];
const AuthStore = useAuthStore();
let uploads = [] as any[];
let directories = ref([] as any[]);
let files = [] as any[];
let items = ref([] as any[]);
let showCustomContent = ref(false);
let deleteMenuVisible = ref(false);
let newFolderMenuVisible = ref(false);
let moveFileMenuVisible = ref(false);
let resource = ref({} as Upload);
let path = ref(["My Drive"]);
let loading = ref(false);
let message = ref("");
let selectedItem = ref([] as any[]);

const currentUser = AuthStore.auth;

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const handleSubmission = (data) => {
  loading.value = true;
  // console.log(selectedItem.value[0].id);
  data.contentId = selectedItem.value[0].id;
  UserService.deleteContent(data).then(
    () => {
      window.location.reload();
      showToast("Content deleted successfully.");
      deleteMenuVisible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const formatFileSize = (fileSizeInBytes) => {
  if (fileSizeInBytes < 1024 * 1024) {
    const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
    return fileSizeInKB + " KB";
  } else {
    const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2);
    return fileSizeInMB + " MB";
  }
};

const populateDirectoryStructure = (data) => {
  files.length = 0;
  items.value.length = 0;
  files.push(...data.filter((e) => e.folderId === null));
  items.value.push(...data.filter((e) => e.folderId === null));
  directories.value.forEach((el) => {
    let temp = {} as Folder;
    temp.folderId = el.id;
    temp.fileName = el.name;
    temp.files = data.filter((e) => e.folderId === el.id);
    temp.updatedAt = temp.files?.reduce(
      (prev, curr) => (prev.createdOn < curr.createdOn ? prev : curr),
      el
    ).updatedAt;
    files.push(temp);
    items.value.push(temp);
  });
  // console.log(directories.value);
  // items.value = files;
  console.log(items.value);
};

const newFolderSchema = yup.object().shape({
  folderName: yup.string().trim().required("Folder Name is required!"),
});

const moveFileSchema = yup.object().shape({
  folderId: yup.number().required("Folder Name is required!"),
});

const getFolders = () => {
  loading.value = true;
  UserService.getFolders().then(
    (response) => {
      console.log(response.data);
      uploads = response.data.uploads;
      uploads.map(
        (el) => (el.updatedAt = new Date(el.updatedAt).toDateString())
      );
      uploads.map((el) => (el.size = formatFileSize(el.size)));
      directories.value = response.data.folders;
      directories.value.map(
        (el) => (el.updatedAt = new Date(el.updatedAt).toDateString())
      );
      populateDirectoryStructure(uploads);
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const createNewFolder = (data) => {
  if (directories.value.map((el) => el.name).includes(data.folderName)) {
    showErrorToast("Folder Already Exists");
  } else {
    loading.value = true;
    let folder = {
      name: data.folderName,
      status: "01",
      userId: currentUser.id,
    };
    UserService.createFolder(folder).then(
      (response) => {
        showToast("New Folder created successfully.");
        getFolders();
        newFolderMenuVisible.value = false;
        loading.value = false;
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const moveFile = (data) => {
  console.log(data);
  const index = uploads.findIndex((el) => el.id === selectedItem.value[0].id);
  uploads[index].folderId = data.folderId;
  loading.value = true;
  UserService.moveFile(selectedItem.value[0].id, data).then(
    (response) => {
      if (path.value.length === 1) {
        const selectedItemItemsIndex = items.value.findIndex(
          (el) => el.id === selectedItem.value[0].id
        );
        items.value.splice(selectedItemItemsIndex, 1);
        const newFolderItemsIndex = items.value.findIndex(
          (el) => el.folderId === data.folderId
        );
        items.value[newFolderItemsIndex].files.push(selectedItem.value[0]);
      } else {
        getFolders();
        path.value = ["My Drive"];
      }
      moveFileMenuVisible.value = false;
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const selectItem = (e) => {
  selectedItem.value.length = 0;
  selectedItem.value.push(e.item);
};

const handleClick = (e) => {
  // console.log(e.item);
  selectedItem.value.length = 0;
  if ("files" in e.item) {
    path.value.push(e.item.fileName);
    items.value = e.item.files;
  } else {
    resource.value = e.item;
    console.log(resource.value);
    if (
      resource.value.mimetype &&
      resource.value.mimetype.startsWith("application")
    ) {
      convertImages(resource.value);
    }
    if (resource.value.type === "Document") {
      // console.log(resource);
      const a = document.createElement("a");
      a.href = websiteURL + resource.value.filePath.substring(9);
      a.download = resource.value.fileName;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      showCustomContent.value = true;
    }
  }
};

const handleRightClick = (e) => {
  let event = e.event;
  selectedItem.value.length = 0;
  selectedItem.value.push(e.item);
  event.preventDefault();
  if (!("files" in selectedItem.value[0])) {
    let contextMenu = document.getElementById("context-menu");
    let mouseX = event.clientX || event.touches[0].clientX;
    let mouseY = event.clientY || event.touches[0].clientY;
    let menuHeight = contextMenu?.getBoundingClientRect().height || 0;
    let menuWidth = contextMenu?.getBoundingClientRect().width || 0;
    let width = window.innerWidth;
    let height = window.innerHeight;
    //If user clicks/touches near right corner
    if (width - mouseX <= 200) {
      contextMenu!.style.borderRadius = "5px 0 5px 5px";
      contextMenu!.style.left = width - menuWidth + "px";
      contextMenu!.style.top = mouseY + "px";
      //right bottom
      if (height - mouseY <= 200) {
        contextMenu!.style.top = mouseY - menuHeight + "px";
        contextMenu!.style.borderRadius = "5px 5px 0 5px";
      }
    }
    //left
    else {
      contextMenu!.style.borderRadius = "0 5px 5px 5px";
      contextMenu!.style.left = mouseX + "px";
      contextMenu!.style.top = mouseY + "px";
      //left bottom
      if (height - mouseY <= 200) {
        contextMenu!.style.top = mouseY - menuHeight + "px";
        contextMenu!.style.borderRadius = "5px 5px 5px 0";
      }
    }
    //display the menu
    contextMenu!.style.visibility = "visible";
    document.addEventListener("click", function (ev) {
      if (!contextMenu!.contains(ev.target as Node)) {
        contextMenu!.style.visibility = "hidden";
      }
    });
  }
};

const changeDirectory = (e) => {
  // console.log(e.target.textContent);
  if (e.target.textContent === "My Drive") {
    path.value.pop();
    items.value = files;
  }
};

const getMyUploads = () => {
  loading.value = true;
  UserService.getMyUploads().then(
    (response) => {
      uploads = response.data;
      uploads.map(
        (el) => (el.updatedAt = new Date(el.updatedAt).toDateString())
      );
      uploads.map((el) => (el.size = formatFileSize(el.size)));
      populateDirectoryStructure(uploads);
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const convertImg = async (url, el) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => heic2any({ blob }))
    .then((conversionResult) => {
      // conversionResult is a BLOB of the PNG formatted image
      el.convertBlob = URL.createObjectURL(conversionResult as Blob);
      el.imgConversion = true;
    })
    .catch((e) => {
      // see error handling section
    });
};

const convertImages = async (el) => {
  // const promises = [];
  if (el.mimetype && el.mimetype.startsWith("application")) {
    el.imgConversion = false;
    await convertImg(websiteURL + el.filePath.substring(9), el);
  }
};

getFolders();
// getMyUploads();

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_data_table = _resolveComponent("va-data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _createElementVNode("div", _hoisted_4, [
            _cache[9] || (_cache[9] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, "My Uploads", -1)),
            _createElementVNode("div", _hoisted_5, [
              (_unref(path).length === 1)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "m-0 py-1 px-2 lg:p-3 card cursor-pointer inline-block",
                    style: {"width":"max-content"},
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(newFolderMenuVisible) //@ts-ignore
 ? newFolderMenuVisible.value = true : newFolderMenuVisible = true))
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "ml-2",
                      title: "Create New Course-Support Request",
                      icon: "fa-solid fa-plus"
                    }),
                    _cache[8] || (_cache[8] = _createElementVNode("b", null, " New Folder", -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(path), (directory, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, [
                _createElementVNode("span", {
                  class: "px-2 py-2 rounded-2 pointer hover:bg-black-alpha-10",
                  onClick: changeDirectory
                }, _toDisplayString(directory), 1),
                (index != _unref(path).length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " > "))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_va_data_table, {
              items: _unref(items),
              columns: columns,
              loading: _unref(loading),
              clickable: true,
              "onRow:click": selectItem,
              "onRow:dblclick": handleClick,
              "onRow:contextmenu": handleRightClick,
              "sticky-header": "",
              hoverable: "",
              selectable: "",
              "select-mode": "single",
              modelValue: _unref(selectedItem),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(selectedItem) ? (selectedItem).value = $event : selectedItem = $event))
            }, null, 8, ["items", "loading", "modelValue"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: "item",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(deleteMenuVisible) //@ts-ignore
 ? deleteMenuVisible.value = true : deleteMenuVisible = true))
      }, _cache[10] || (_cache[10] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "mr-2 content-menu-img"
        }, null, -1),
        _createTextVNode(" Delete ")
      ])),
      _createElementVNode("div", {
        class: "item",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_isRef(moveFileMenuVisible) //@ts-ignore
 ? moveFileMenuVisible.value = true : moveFileMenuVisible = true))
      }, _cache[11] || (_cache[11] = [
        _createElementVNode("img", {
          src: _imports_1,
          class: "mr-2 content-menu-img"
        }, null, -1),
        _createTextVNode(" Move ")
      ]))
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(showCustomContent),
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_isRef(showCustomContent) ? (showCustomContent).value = $event : showCustomContent = $event)),
      modal: "",
      header: _unref(resource).description,
      style: { width: '50vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          (_unref(resource).type === 'Project Pictures' && _unref(resource).filePath)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _unref(websiteURL) + _unref(resource).filePath.substring(9),
                class: "model-img",
                autofocus: ""
              }, null, 8, _hoisted_11))
            : _createCommentVNode("", true),
          (_unref(resource).type === 'Video' && _unref(resource).filePath)
            ? (_openBlock(), _createElementBlock("video", _hoisted_12, [
                _createElementVNode("source", {
                  src: _unref(websiteURL) + _unref(resource).filePath.substring(9),
                  type: _unref(resource)['mimetype']
                }, null, 8, _hoisted_13)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(deleteMenuVisible),
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(deleteMenuVisible) ? (deleteMenuVisible).value = $event : deleteMenuVisible = $event)),
      modal: "",
      header: " ",
      style: { width: '50vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmission,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_14, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { for: "reasonDelete" }, "Please provide a reason for deleting the uploaded content", -1)),
              _createVNode(_unref(Field), {
                name: "reasonDelete",
                type: "text",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "reasonDelete",
                class: "error-feedback"
              })
            ]),
            _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_17, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[13] || (_cache[13] = _createElementVNode("span", null, "Delete", -1))
              ], 8, _hoisted_16)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(newFolderMenuVisible),
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => (_isRef(newFolderMenuVisible) ? (newFolderMenuVisible).value = $event : newFolderMenuVisible = $event)),
      modal: "",
      header: "Folder Name",
      style: { width: '30vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: createNewFolder,
          "validation-schema": _unref(newFolderSchema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_unref(Field), {
                name: "folderName",
                type: "text",
                class: "form-control",
                placeholder: "Folder Name"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "folderName",
                class: "error-feedback"
              })
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_21, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("span", null, "Create", -1))
              ], 8, _hoisted_20)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(moveFileMenuVisible),
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (_isRef(moveFileMenuVisible) ? (moveFileMenuVisible).value = $event : moveFileMenuVisible = $event)),
      modal: "",
      header: "Select Folder",
      style: { width: '40vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: moveFile,
          "validation-schema": _unref(moveFileSchema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_unref(Field), {
                name: "folderId",
                as: "select",
                class: "dropdown"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(directories), (item) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: item,
                      value: item.id
                    }, _toDisplayString(item.name), 9, _hoisted_23))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "folderId",
                class: "error-feedback"
              })
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_26, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[17] || (_cache[17] = _createElementVNode("span", null, "Move", -1))
              ], 8, _hoisted_25)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})