<template>
  <div class="flex flex-direction-column lg:flex-row">
    <div class="mr-6">
      <input
        name="searchParameter"
        v-model="searchParameter"
        type="text"
        placeholder="Search Resources"
        class="search-bar"
        :maxlength="MIN_TEXT_MAX_LENGTH"
        @input="onInputWithNotification(MIN_TEXT_MAX_LENGTH, $event)"
      />
      {{ searchParameter ? searchParameter.length : 0 }}
      / {{ MIN_TEXT_MAX_LENGTH }} characters
    </div>
    <div>
      <Field
        name="size"
        as="select"
        class="dropdown w-auto ml-auto mr-3"
        v-model="size"
        @change="changePageSize"
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        <option value="50">50</option>
      </Field>
    </div>
  </div>

  <div class="my-3 d-flex align-items-center">
    <label class="ml-3"
      ><input type="checkbox" v-model="showPictures" />
      Pictures
    </label>
    <label class="ml-3"
      ><input type="checkbox" v-model="showVideos" />
      Videos
    </label>
    <label class="ml-3"
      ><input type="checkbox" v-model="showDocuments" />
      Documents
    </label>
  </div>

  <!-- Display filtered results -->
  <div v-if="paginatedData.length === 0">
    <h5 style="margin: 1em" class="text-align-center">No Resources Found</h5>
  </div>
  <div v-else>
    <div class="min-h">
      <!-- Note: Use paginatedData here instead of filteredData -->
      <div class="responsive" v-for="item in paginatedData" :key="item.id">
        <div class="gallery">
          <!-- Pictures -->
          <div v-if="item.type === 'Picture'" class="image-container">
            <img
              v-if="item.filePath"
              :src="websiteURL + item.filePath.substring(9)"
              :alt="item.file"
              @click="
                showImage(
                  websiteURL + item.filePath.substring(9),
                  item.description,
                  websiteURL + item.origFilePath.substring(9)
                )
              "
            />
            <a v-else :href="formatLink(item.linkResources)" target="_blank">
              <img
                src="@/assets/images/uploads/general_resource.jpg"
                :alt="item.linkResources"
              />
            </a>
          </div>

          <!-- Videos -->
          <div v-else-if="item.type === 'Video'" class="video-container">
            <video v-if="item.filePath" controls>
              <source
                :src="websiteURL + item.filePath.substring(9)"
                :type="item['mimetype']"
              />
            </video>
            <a v-else :href="formatLink(item.linkResources)" target="_blank">
              <img
                src="@/assets/images/uploads/general_resource.jpg"
                :alt="item.linkResources"
              />
            </a>
          </div>

          <!-- Documents -->
          <div v-else-if="item.type === 'Document'" class="document-container">
            <div v-if="item.filePath" class="doc-image-container">
              <img
                v-if="item.mimetype === 'application/pdf'"
                src="@/assets/images/uploads/pdf.png"
                class="doc-image"
              />
              <img
                v-else-if="
                  [
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/mspowerpoint',
                  ].includes(item.mimetype)
                "
                src="@/assets/images/uploads/ppt.png"
                class="doc-image"
              />
              <img
                v-else-if="
                  [
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/msword',
                  ].includes(item.mimetype)
                "
                src="@/assets/images/uploads/docx.png"
                class="doc-image"
              />
              <img
                v-else
                src="@/assets/images/uploads/txt.png"
                class="doc-image"
              />
            </div>
            <div v-else class="doc-image-container overflow-hidden">
              <a :href="formatLink(item.linkResources)" target="_blank">
                <img
                  src="@/assets/images/uploads/general_resource.jpg"
                  :alt="item.linkResources"
                  style="width: 100%; height: 100%; overflow: hidden"
                />
              </a>
            </div>
          </div>

          <div class="desc">
            <p>
              {{
                item.description.slice(0, 25) +
                (item.description.length > 25 ? "..." : "")
              }}
            </p>
            <font-awesome-icon
              icon="fa-solid fa-lock"
              class="ml-1 mr-2"
              v-if="!JSON.parse(item.visibility).includes('All')"
              title="This content is restricted."
            />
            <a
              :href="websiteURL + item.origFilePath.substring(9)"
              download
              title="Download"
              style="color: var(--bs-body-color)"
              v-if="props.type === 'public' && item.filePath"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </a>
            <button
              @click="(visible = true), (contentId = item.id)"
              style="color: var(--bs-body-color); border: none"
              class="bg-transparent"
              v-if="props.type === 'private'"
            >
              <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
            <button
              @click="(reportDialogVisible = true), (contentId = item.id)"
              style="color: var(--bs-body-color); border: none"
              class="ml-2 bg-transparent"
              v-if="props.type === 'public'"
              title="Flag as Inappropriate"
            >
              <font-awesome-icon icon="fa-solid fa-flag" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="paginator">
    <va-pagination
      v-model="currentPage"
      :pages="totalPages"
      :visible-pages="3"
      :hideOnSinglePage="true"
    />
  </div>

  <Dialog
    v-model:visible="showCustomContent"
    modal
    :header="imgDesc"
    :style="{ width: '50vw', padding: '20px', backgroundColor: '#fff' }"
  >
    <div style="display: grid; grid-template-columns: 3fr 1fr">
      <div></div>
      <div style="text-align: right">
        <a
          :href="downloadSrc"
          download
          title="Download"
          style="color: var(--bs-body-color)"
          v-if="currentUser.roles === 'INSTRUCTOR'"
        >
          <font-awesome-icon icon="fa-solid fa-download" />
        </a>
      </div>
    </div>
    <div class="flex justify-content-center" style="height: 70vh">
      <img :src="imgSrc" class="model-img" autofocus />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="visible"
    modal
    header=" "
    :style="{ width: '30vw', padding: '20px', backgroundColor: '#fff' }"
  >
    <Form
      @submit="handleSubmission"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonDelete"
          >Please provide a reason for deleting the uploaded content</label
        >
        <Field name="reasonDelete" type="text" class="form-control" />
        <ErrorMessage name="reasonDelete" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Delete</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="reportDialogVisible"
    modal
    header=" "
    :style="{ width: '41vw', padding: '20px', backgroundColor: '#fff' }"
  >
    <Form
      @submit="handleReportDialogSubmission"
      :validation-schema="reportDialogSchema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonReport"
          >Please provide a reason for reporting the uploaded content</label
        >
        <Field name="reasonReport" type="text" class="form-control" />
        <ErrorMessage name="reasonReport" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Report</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { Upload } from "@/types";
import UserService from "@/services/user.service.js";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { showToast, websiteURL, showErrorToast } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const AuthStore = useAuthStore();

const showCustomContent = ref(false);
const downloadSrc = ref("");
const imgSrc = ref("");
const imgDesc = ref("");

const currentUser = AuthStore.auth;
const contentId = ref(-1);
const visible = ref(false);
const reportDialogVisible = ref(false);
const searchParameter = ref("");
const loading = ref(false);
const message = ref("");
const size = ref(10);

const picturesData = ref<Upload[]>([]);
const videosData = ref<Upload[]>([]);
const documentsData = ref<Upload[]>([]);

const showPictures = ref(true);
const showVideos = ref(true);
const showDocuments = ref(true);

const currentPage = ref(1);
const totalPages = ref(1);

const MIN_TEXT_MAX_LENGTH = 100;

const formatLink = (link: string) => {
  if (!link.startsWith("http://") && !link.startsWith("https://")) {
    return `https://${link}`;
  }
  return link;
};

const onInputWithNotification = (maxLength: number, event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

const props = defineProps<{
  type: string;
}>();

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const reportDialogSchema = yup.object().shape({
  reasonReport: yup
    .string()
    .required("Reason for reporting content is required!"),
});

onMounted(async () => {
  loading.value = true;
  try {
    const [picsRes, vidsRes, docsRes] = await Promise.all([
      UserService.getPublicResources(1, "Project Pictures", size.value),
      UserService.getPublicResources(1, "Video", size.value),
      UserService.getPublicResources(1, "Document", size.value),
    ]);

    picturesData.value = picsRes.data.uploads.map((u) => ({
      ...u,
      type: "Picture",
    }));
    videosData.value = vidsRes.data.uploads.map((u) => ({
      ...u,
      type: "Video",
    }));
    documentsData.value = docsRes.data.uploads.map((u) => ({
      ...u,
      type: "Document",
    }));

    // totalPages derived from local pagination now
    // We'll compute it dynamically below.
  } catch (err: any) {
    message.value = err.message || err.toString();
  } finally {
    loading.value = false;
  }
});

// Combine and filter data
const filteredData = computed(() => {
  let allData: Upload[] = [];
  if (showPictures.value) allData = allData.concat(picturesData.value);
  if (showVideos.value) allData = allData.concat(videosData.value);
  if (showDocuments.value) allData = allData.concat(documentsData.value);

  if (searchParameter.value.trim().length > 0) {
    const query = searchParameter.value.toLowerCase();
    allData = allData.filter((item) =>
      item.description.toLowerCase().includes(query)
    );
  }

  return allData;
});

// Apply local pagination
const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * size.value;
  const end = start + size.value;
  return filteredData.value.slice(start, end);
});

// Watch changes to filteredData or size to update totalPages
watch(
  () => [filteredData.value.length, size.value],
  () => {
    totalPages.value = Math.ceil(filteredData.value.length / size.value) || 1;
    // Ensure currentPage is not out of range
    if (currentPage.value > totalPages.value) {
      currentPage.value = totalPages.value;
    }
  },
  { immediate: true }
);

const handleSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.deleteContent(data).then(
    () => {
      showToast("Content deleted successfully.");
      visible.value = false;
      loading.value = false;
      // Remove deleted item locally
      picturesData.value = picturesData.value.filter(
        (i) => i.id !== contentId.value
      );
      videosData.value = videosData.value.filter(
        (i) => i.id !== contentId.value
      );
      documentsData.value = documentsData.value.filter(
        (i) => i.id !== contentId.value
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleReportDialogSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.reportContent(data).then(
    () => {
      showToast("Content Reported successfully.");
      reportDialogVisible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const changePageSize = () => {
  // Reset to page 1 or just let watchers handle it
  currentPage.value = 1;
};

const showImage = (src: string, desc: string, downloadPath: string) => {
  imgSrc.value = src;
  imgDesc.value = desc;
  downloadSrc.value = downloadPath;
  showCustomContent.value = true;
};
</script>

<style scoped>
.image-container,
.video-container,
.document-container {
  width: 275px;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img,
.video-container video,
.doc-image-container img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.video-container {
  background: #fff; /* optional background */
}

.document-container {
  background: #fff;
}

.doc-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #deeaf7;
}

.doc-image {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

div.gallery {
  border: 1px solid #ccc;
}
div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  aspect-ratio: 3/2;
}

div.desc {
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.desc p {
  flex: 15;
}

div.desc a {
  flex: 1;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  margin: 4px 0;
  float: left;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.min-h {
  min-height: 34rem;
}

.paginator {
  display: grid;
  justify-content: center;
  margin: 0.5rem 1rem 1rem 0;
}

.model-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
</style>
