<template>
  <Form
    @submit="saveCourseFeedback"
    :validation-schema="courseFeedbackSchema"
    v-slot="{ values, meta, setFieldValue }"
  >
    <div class="form-group">
      <label for="message">
        Comments about the session
        <span class="super font-color-red">*</span>
      </label>
      <Field name="message" type="text" class="form-control" />
      <ErrorMessage name="message" class="error-feedback" />
    </div>

    <div class="form-group">
      <label for="rating">
        Rating of the Course-Support Session<span class="super font-color-red"
          >*</span
        >
      </label>
      <!-- Custom Star Rating -->
      <div class="rating-stars">
        <span
          v-for="star in 5"
          :key="star"
          class="star"
          :class="{ selected: star <= values.rating }"
          @click="setFieldValue('rating', star)"
        >
          ★
        </span>
      </div>
      <ErrorMessage name="rating" class="error-feedback" />
    </div>

    <div class="form-group">
      <label for="concern">Any feedback, comments, or concern to Admin.</label>
      <Field name="concern" type="text" class="form-control" />
      <ErrorMessage name="concern" class="error-feedback" />
    </div>

    <div class="form-group text-align-center mt-4">
      <button
        class="btn btn-primary btn-block"
        :disabled="loading || !meta.valid"
      >
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        Save Feedback
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "@/services/user.service.js";

// Define props and emits
const props = defineProps<{
  currCourseId: number;
}>();
const emits = defineEmits(["fetchCourse"]);

// State variables
let loading = ref(false);

// Validation schema
const courseFeedbackSchema = yup.object().shape({
  message: yup.string().required("Comments about the session is required!"),
  rating: yup
    .number()
    .required("Rating is required!")
    .min(1, "Please select a rating."),
  concern: yup.string().optional().nullable(),
});

// Save feedback
const saveCourseFeedback = (data) => {
  loading.value = true;
  data.courseRequestId = props.currCourseId;
  UserService.saveCourseFeedback(data).then(
    (response) => {
      if (response.status == "200") emits("fetchCourse");
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      console.error(error);
    }
  );
};
</script>

<style scoped>
/* Custom Star Rating Styles */
.rating-stars {
  display: flex;
  gap: 5px;
}

.star {
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s ease;
}

.star.selected {
  color: #f39c12;
}

.star:hover,
.star:hover ~ .star {
  color: #f1c40f;
}
</style>
