import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "content min-height m-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "m-3 lg:m-5" }
const _hoisted_5 = { class: "justify-content-center lg:justify-content-between course-item" }
const _hoisted_6 = {
  key: 0,
  class: "course-item justify-content-center lg:justify-content-start mt-3 lg:mt-0"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "align-self-end" }
const _hoisted_9 = {
  key: 1,
  class: "mt-3 text-align-center lg:font-bold"
}
const _hoisted_10 = {
  key: 0,
  class: "course-item"
}
const _hoisted_11 = ["onClick"]

import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ClassForm from "@/components/instructor/ClassForm.vue";
import Dialog from "primevue/dialog";
import { useModal } from "vuestic-ui";
import InstructorService from "@/services/instructor.service.js";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";
import { convertCourseToCourseRequest, showToast } from "@/utils";
import { Course } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'SavedCourseRequestView',
  setup(__props) {

const CourseStore = useCourseStore();
const router = useRouter();
const { confirm } = useModal();
let pendingCourse = ref([]);
let message = ref("");
let loading = ref(false);
let myClasses = ref([] as Course[]);
let isDialogVisible = ref(false);
let isAddClassVisible = ref(false);

const getTempCourseRequest = (pageNumber = 1) => {
  InstructorService.getTempCourseRequest(pageNumber).then(
    (response) => {
      console.log(response);
      pendingCourse.value = response.data.courses;
      console.log(pendingCourse);
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const deleteCourseRequest = async (data) => {
  const cancelDeleteRequest = await confirm({
    message: "Are you sure you want to delete this course support request?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDeleteRequest) {
    loading.value = true;
    InstructorService.deleteTempCourseRequest(data).then(
      (_) => {
        getTempCourseRequest();
        showToast("The course support request has been successfully deleted.");
        loading.value = false;
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const getCourse = (pageNumber = 1) => {
  InstructorService.getCourse(pageNumber).then(
    (response) => {
      myClasses.value = response.data;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const editCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "edit-course-request" });
};

const createCourseRequest = (course: Course | Course) => {
  CourseStore.removeCourse();
  let courseRequest = convertCourseToCourseRequest(course);
  CourseStore.addCourse(courseRequest);
  router.push({ name: "create-class-course-request" });
};

const saveCourseSuccess = (values) => {
  showToast("Class has been saved.");
  getCourse();
  isAddClassVisible.value = false;
};

getTempCourseRequest();
getCourse();

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(InstructorSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[4] || (_cache[4] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Course-Support Request Information ", -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Saved Course-Support Request", -1)),
            _createElementVNode("div", {
              class: "mt-3 m-0 lg:mt-0 py-1 px-2 lg:p-3 card cursor-pointer inline-block",
              style: {"width":"max-content"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(isDialogVisible) //@ts-ignore
 ? isDialogVisible.value = true : isDialogVisible = true))
            }, [
              _cache[5] || (_cache[5] = _createElementVNode("b", null, " Create New ", -1)),
              _createVNode(_component_font_awesome_icon, {
                class: "ml-2",
                title: "Create New Course-Support Request",
                icon: "fa-solid fa-plus"
              })
            ])
          ]),
          (_unref(pendingCourse).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pendingCourse), (course) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "m-0 ml-1 mt-2 py-1 px-2 lg:p-3 card cursor-pointer",
                    key: course
                  }, [
                    _createElementVNode("span", {
                      onClick: ($event: any) => (editCourseRequest(course))
                    }, [
                      _createElementVNode("b", null, _toDisplayString(course.courseRequestType), 1),
                      _cache[7] || (_cache[7] = _createTextVNode()),
                      _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(" " + _toDisplayString(new Date(course.updatedAt).toDateString()), 1)
                    ], 8, _hoisted_7),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "fa-solid fa-trash",
                        onClick: ($event: any) => (deleteCourseRequest(course))
                      }, null, 8, ["onClick"])
                    ])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, " You don't have any saved course-support requests. "))
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isDialogVisible),
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(isDialogVisible) ? (isDialogVisible).value = $event : isDialogVisible = $event)),
      modal: "",
      header: "Select Class",
      style: { width: '50vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        (_unref(myClasses).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(myClasses), (currClass) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "m-0 ml-1 mt-2 py-1 px-2 lg:p-3 card cursor-pointer",
                  style: {"width":"max-content"},
                  key: currClass.id
                }, [
                  _createElementVNode("span", {
                    onClick: ($event: any) => (createCourseRequest(currClass))
                  }, [
                    _createElementVNode("b", null, _toDisplayString(currClass.courseCode), 1),
                    _cache[9] || (_cache[9] = _createTextVNode()),
                    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(currClass.courseTitle), 1)
                  ], 8, _hoisted_11)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "m-0 mt-2 py-1 px-2 lg:p-3 card inline-block cursor-pointer course-item-margin",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ((_isRef(isDialogVisible) //@ts-ignore
 ? isDialogVisible.value = false : isDialogVisible = false), (_isRef(isAddClassVisible) //@ts-ignore
 ? isAddClassVisible.value = true : isAddClassVisible = true)))
        }, [
          _cache[11] || (_cache[11] = _createElementVNode("b", null, " Add Class ", -1)),
          _createVNode(_component_font_awesome_icon, {
            class: "ml-2",
            title: "Add Class",
            icon: "fa-solid fa-plus"
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isAddClassVisible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(isAddClassVisible) ? (isAddClassVisible).value = $event : isAddClassVisible = $event)),
      modal: "",
      header: "Add Class",
      style: {"padding":"20px","background-color":"#fff","border-radius":"8px","box-shadow":"0 4px 6px rgba(0, 0, 0, 0.1)","min-width":"70vw","max-width":"70vw","margin":"0 auto"},
      breakpoints: { '768px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(ClassForm, {
          successAction: saveCourseSuccess,
          action: "create",
          course: _ctx.course
        }, null, 8, ["course"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})