<template>
  <div class="card card-container">
    <div>
      <div class="flex flex-direction-row justify-space-between">
        <span class="font-color-red">* required field</span>
        <span class="flex-grow-0 flex-shrink-1">
          {{ values.semester }} {{ values.year }}</span
        >
      </div>
      <form
        @submit="saveCourseRequest"
        @keydown.enter="$event.preventDefault()"
        enctype="multipart/form-data"
      >
        <div class="align-center bold-text flex flex-direction-column">
          General Information
          <span style="font-style: italic; font-size: small"
            >This information will be shared with industry practitioner</span
          >
        </div>
        <Field
          v-if="
            !courseRequestFields.find((el) => el.name === 'semester')?.disabled
          "
          name="semester"
          hidden
        />
        <Field
          v-if="!courseRequestFields.find((el) => el.name === 'year')?.disabled"
          name="year"
          hidden
        />

        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'courseRequestType')
              ?.disabled
          "
        >
          <label for="courseRequestType"
            >Course-Support Type<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.courseRequestType"
            name="courseRequestType"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.courseRequestType"
              :options="[
                { label: 'Seminar', value: 'Seminar' },
                { label: 'Site Visit', value: 'Site Visit' },
                { label: 'Workshop', value: 'Workshop' },
                { label: 'Guest Lecture', value: 'Guest Lecture' },
                { label: 'Judge for Project', value: 'Judge for Project' },
                { label: 'Mentor for Project', value: 'Mentor for Project' },
                {
                  label: 'Sponsor for Project (Context Provider)',
                  value: 'Sponsor for Project',
                },
                { label: 'Labs', value: 'Labs' },
                { label: 'Others, please specify', value: 'Others' },
              ]"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <div v-if="values.courseRequestType === 'Others'">
            <Field
              name="courseRequestType-text"
              type="text"
              placeholder="Please Specify"
              class="form-control bg-others"
            />
          </div>
          <ErrorMessage name="courseRequestType" class="error-feedback" />
          <span v-if="values.courseRequestType === 'Site Visit'"
            ><b>Site visit</b> is a means to expose students to the workplace
            and work practices on construction job sites.</span
          >
          <span v-if="values.courseRequestType === 'Guest Lecture'"
            ><b>Guest lecture</b> entails bringing practitioners into the
            classroom to discuss technical topics from their experience.</span
          >
          <span v-if="values.courseRequestType === 'Seminar'"
            ><b>Seminar</b> is for sharing knowledge, and facilitating
            discussions on a specific topic or subject. It can also be an avenue
            to present their ideas and experiences to students.</span
          >
          <span v-if="values.courseRequestType === 'Workshop'"
            ><b>Workshop</b> is an interactive learning and skill-building
            session to provide students with hands-on experience and practical
            training in a specific area or to develop certain skills.</span
          >
          <span v-if="values.courseRequestType === 'Labs'"
            ><b>Laboratory session</b> is a practical, hands-on learning
            experience that takes place in a laboratory where students get the
            opportunity to apply theoretical concepts to real-world construction
            tasks.</span
          >
          <span v-if="values.courseRequestType === 'Judge for Project'"
            ><b>Judge for project</b> involves assessing and appraising
            students’ projects based on established guidelines, client
            expectations, and project outcomes.</span
          >
          <span v-if="values.courseRequestType === 'Mentor for Project'"
            ><b>Mentor for project</b>: This entails providing professional
            guidance to students throughout the delivery of their project.</span
          >
          <span v-if="values.courseRequestType === 'Sponsor for Project'"
            ><b>Sponsor for project</b>: This entails providing context for
            students’ project and acting as client.</span
          >
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'insDeliveryType')
              ?.disabled
          "
        >
          <label for="insDeliveryType"
            >Instruction Delivery Mode<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.insDeliveryType"
            name="insDeliveryType"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field, meta }"
          >
            <multiselect
              v-if="values.courseRequestType === 'Site Visit'"
              v-bind="field"
              v-model="values.insDeliveryType"
              :options="insDeliveryTypeSiteVisitOptions"
              :searchable="true"
              mode="single"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
            <multiselect
              v-else
              v-bind="field"
              v-model="values.insDeliveryType"
              :options="insDeliveryTypeOptions"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="insDeliveryType" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'typeProject')
              ?.disabled && isCapstoneTermProject(values.courseRequestType)
          "
        >
          <label for="typeProject">
            Type of Project<span
              v-if="values.courseRequestType === 'Sponsor for Project'"
            >
              Required</span
            ><span class="super font-color-red">*</span></label
          >
          <Field
            v-model="values.typeProject"
            name="typeProject"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.typeProject"
              :options="typeOfProjectOptions"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
            />
          </Field>
          <ErrorMessage name="typeProject" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'projectDescr')
              ?.disabled && isCapstoneTermProject(values.courseRequestType)
          "
        >
          <label for="projectDescr">
            Description of Project<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field name="projectDescr" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              placeholder="Please Specify"
              :maxlength="LOT_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(LOT_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.projectDescr ? values.projectDescr.length : 0 }}
            / {{ LOT_TEXT_MAX_LENGTH }} characters
          </Field>

          <ErrorMessage
            v-if="meta.dirty && !meta.valid"
            name="projectDescr"
            class="error-feedback"
          />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'academicLevel')
              ?.disabled
          "
        >
          <label for="academicLevel"
            >Students' Academic Level<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.academicLevel"
            name="academicLevel"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.academicLevel"
              :options="academicLevelOptions"
              mode="tags"
              :multipleLabel="displayLabels"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              :disabled="true"
              :searchable="true"
            />
          </Field>
          <ErrorMessage name="academicLevel" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'studentDept')
              ?.disabled
          "
        >
          <label for="studentDept">
            Students' Department or Program of Study<span
              class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.studentDept"
            name="studentDept"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.studentDept"
              :options="deptOptions"
              mode="tags"
              :multipleLabel="displayLabels"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              :disabled="true"
              :searchable="true"
            />
          </Field>
          <div
            v-if="values.studentDept && values.studentDept.includes('Others')"
          >
            <Field
              name="studentDept_text"
              type="text"
              placeholder="Please Specify"
              class="form-control bg-others"
              :readonly="props.action === 'view'"
            />
          </div>
          <ErrorMessage name="studentDept" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'classSize')?.disabled
          "
        >
          <span v-if="values.courseRequestType !== 'Mentor for Project'">
            <label for="classSize"
              >Class Size<span class="super font-color-red">*</span></label
            >
          </span>
          <span v-else>
            <label for="classSize"
              >Size of student group to mentor<span class="super font-color-red"
                >*</span
              ></label
            >
          </span>
          <Field name="classSize" v-slot="{ field, meta }">
            <input
              v-bind="field"
              :class="{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }"
              class="form-control"
              type="text"
              readonly="true"
            />
            <ErrorMessage
              v-if="meta.dirty && !meta.valid"
              name="classSize"
              class="error-feedback"
            />
          </Field>
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'physicalDis')
              ?.disabled &&
            values.courseRequestType === 'Site Visit' &&
            values.insDeliveryType !== 'Virtual site visit'
          "
        >
          <label for="physicalDis">
            Physical Disabilities of Students
            <span class="super font-color-red">*</span>
          </label>
          <Field
            v-model="values.physicalDis"
            name="physicalDis"
            type="text"
            class="form-control"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.physicalDis"
              :options="physicalDisOptions"
              mode="tags"
              :searchable="true"
              :multipleLabel="displayLabels"
              :disabled="props.action === 'view'"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
            >
              <template v-slot:option="{ option }">
                {{ option.label }}
              </template>
              <template v-slot:singleLabel="{ option }">
                {{ option.label }}
              </template>
            </multiselect>
          </Field>
          <div
            v-if="values.physicalDis && values.physicalDis.includes('Others')"
          >
            <Field
              name="physicalDis_text"
              type="text"
              placeholder="Please Specify"
              class="form-control bg-others"
              v-model="values.physicalDis_text"
              :maxlength="MED_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(MED_TEXT_MAX_LENGTH, $event)"
              :class="{
                'input-error':
                  values.physicalDis_text &&
                  values.physicalDis_text.length > MED_TEXT_MAX_LENGTH,
              }"
            />
            <span
              :class="{
                'text-danger':
                  values.physicalDis_text &&
                  values.physicalDis_text.length > MED_TEXT_MAX_LENGTH,
              }"
            >
              {{ values.physicalDis_text ? values.physicalDis_text.length : 0 }}
              / {{ MED_TEXT_MAX_LENGTH }} characters
            </span>
          </div>
          <ErrorMessage name="physicalDis" class="error-feedback" />
        </div>

        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'courseCode')
              ?.disabled
          "
        >
          <label for="courseCode"
            >Course Code<span class="super font-color-red">*</span></label
          >
          <Field name="courseCode" v-slot="{ field, meta }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :readonly="true"
            />
            <ErrorMessage
              v-if="meta.dirty && !meta.valid"
              name="courseCode"
              class="error-feedback"
            />
          </Field>
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'courseTitle')
              ?.disabled
          "
        >
          <label for="courseTitle"
            >Course Title<span class="super font-color-red">*</span></label
          >
          <Field name="courseTitle" v-slot="{ field, meta }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :readonly="true"
            />
            <ErrorMessage
              v-if="meta.dirty && !meta.valid"
              name="courseTitle"
              class="error-feedback"
            />
          </Field>
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'file')?.disabled &&
            values.courseRequestType === 'Judge for Project'
          "
        >
          <label for="file">RFP (Request for proposal)</label>
          <input
            type="file"
            name="file"
            v-on:change="uploadFile"
            accept="application/pdf"
            class="form-control"
          />
          <a
            :href="websiteURL + '/course_support/' + values.fileName"
            download
            style="color: var(--bs-body-color)"
            target="_blank"
          >
            <img
              v-if="values.fileName"
              src="@/assets/images/uploads/pdf.png"
              class="doc-image"
            />
          </a>

          <ErrorMessage
            v-if="meta.dirty && !meta.valid"
            name="file"
            class="error-feedback"
          />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find(
              (el) => el.name === 'additionalInformation'
            )?.disabled
          "
        >
          <label for="additionalInformation"
            >Link to Additional Course Information</label
          >
          <Field name="additionalInformation" v-slot="{ field, meta }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              placeholder="https://"
              :readonly="true"
            />
            <ErrorMessage
              v-if="meta.dirty && !meta.valid"
              name="additionalInformation"
              class="error-feedback"
            />
          </Field>
        </div>

        <div class="form-group">
          <label for="date"
            >When the Course-Support is Needed<span class="super font-color-red"
              >*</span
            >
            <span
              class="font-color-yellow"
              v-if="values.courseRequestType !== 'Mentor for Project'"
            >
              (Multiple Date & Time are allowed)
            </span></label
          >
          <div
            class="flex flex-direction-column lg:flex-row"
            style="column-gap: 4px"
          >
            <div class="flex-grow-1" style="flex-basis: 0">
              <!-- Mentor for Project Date Fields -->
              <div v-if="values.courseRequestType === 'Mentor for Project'">
                <!-- Start Date Field -->
                <Field name="startDate" v-slot="{ field, meta }">
                  <input
                    v-bind="field"
                    class="form-control"
                    type="date"
                    placeholder="Start YYYY-MM-DD"
                    :value="field.value"
                    @input="setFieldValue('startDate', $event.target.value)"
                    :class="{
                      'field-bg-color': !meta.dirty,
                      'field-border-error': meta.dirty && !meta.valid,
                    }"
                  />
                </Field>
                <ErrorMessage name="startDate" class="error-feedback" />

                <!-- End Date Field -->
                <Field name="endDate" v-slot="{ field, meta }">
                  <input
                    v-bind="field"
                    class="form-control"
                    type="date"
                    placeholder="End YYYY-MM-DD"
                    :value="field.value"
                    @input="setFieldValue('endDate', $event.target.value)"
                    :class="{
                      'field-bg-color': !meta.dirty,
                      'field-border-error': meta.dirty && !meta.valid,
                    }"
                  />
                </Field>
                <ErrorMessage name="endDate" class="error-feedback" />
              </div>

              <!-- Date and Time Field for Other Course Types -->
              <div
                v-else-if="
                  !courseRequestFields.find((el) => el.name === 'date')
                    ?.disabled
                "
              >
                <Field
                  name="date_text"
                  type="date"
                  class="form-control"
                  v-model="date_text"
                  :min="today"
                />
                <ErrorMessage name="date_text" class="error-feedback" />
                <div
                  style="display: flex; flex-wrap: wrap"
                  v-if="values.date != null"
                  :class="{ 'pointer-events-none': props.action === 'view' }"
                >
                  <span
                    v-for="(item, index) in values.date"
                    :key="item"
                    class="list-item"
                  >
                    {{ item + " at " + values.time[index] }}
                    <span class="list-item-remove">
                      <span
                        class="list-item-remove-icon"
                        @click="removeTime(item)"
                      ></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="flex-grow-1" style="flex-basis: 0">
              <div
                v-if="
                  !courseRequestFields.find((el) => el.name === 'time')
                    ?.disabled &&
                  values.courseRequestType === 'Mentor for Project'
                "
              >
                <Field name="time_text" v-slot="{ field }">
                  <input v-bind="field" type="time" class="form-control" />
                </Field>
                <ErrorMessage name="time" class="error-feedback" />
                <ErrorMessage name="time" class="error-feedback" />
              </div>
              <div
                v-else-if="
                  !courseRequestFields.find((el) => el.name === 'time')
                    ?.disabled
                "
              >
                <Field
                  name="time_text"
                  type="time"
                  class="form-control"
                  v-model="time_text"
                />
                <ErrorMessage name="time_text" class="error-feedback" />
              </div>
              <span class="font-italic" style="font-size: small">
                {{ getTimezoneName() }}
                <span role="button" class="hint">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-question"
                    @click="showTooltip"
                  />
                  <span
                    class="speech_bubble tool_tip_text"
                    id="tooltip"
                    v-show="elementVisible"
                    style="display: inline"
                  >
                    <button type="button" class="close-btn-tooltip mr-4">
                      <font-awesome-icon
                        icon="fa-solid fa-xmark"
                        class="close-btn-icon"
                        @click="hideTooltip"
                      />
                    </button>
                    <p style="margin-bottom: 0px">
                      This is your local timezone.
                    </p>
                  </span>
                </span>
              </span>
            </div>
            <div
              v-if="values.courseRequestType !== 'Mentor for Project'"
              class="ml-2 cursor-pointer d-inline flex-shrink-1 flex-grow-0 btn btn-primary w-6rem"
              style="height: 2.25rem"
              @click="addTime"
            >
              <font-awesome-icon
                style="margin: auto"
                icon="fa-solid fa-plus"
                title="Add"
              />&nbsp;
              <span>Add</span>
            </div>
          </div>
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'duration')?.disabled
          "
        >
          <label
            v-if="values.courseRequestType === 'Mentor for Project'"
            for="duration"
            >Duration of Meeting with Students<span class="super font-color-red"
              >*</span
            ><span class="font-color-yellow"> (in minutes)</span></label
          >
          <label v-else for="duration"
            >Duration of Course-Support<span class="super font-color-red"
              >*</span
            ><span class="font-color-yellow"> (in minutes)</span></label
          >
          <Field name="duration" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="DUR_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(DUR_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.duration ? values.duration.length : 0 }}
            / {{ DUR_TEXT_MAX_LENGTH }} characters
          </Field>
          <ErrorMessage
            v-if="meta.dirty && !meta.valid"
            name="duration"
            class="error-feedback"
          />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'location')
              ?.disabled && showLocation(values.courseRequestType)
          "
        >
          <label for="location"
            >Location of Classroom <span class="super font-color-red">*</span
            ><span class="font-color-yellow">
              (Actual Address Including Room Number)</span
            ></label
          >
          <Field name="location" v-slot="{ field, meta }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :readonly="true"
            />
            <ErrorMessage
              v-if="meta.dirty && !meta.valid"
              name="location"
              class="error-feedback"
            />
          </Field>
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'noOfFreq')?.disabled
          "
        >
          <label
            for="noOfFreq"
            v-if="values.courseRequestType === 'Mentor for Project'"
            >Frequency of Meeting with Students<span
              class="super font-color-red"
              >*</span
            ></label
          >
          <label for="noOfFreq" v-else
            >Frequency of Course-Support<span class="super font-color-red"
              >*</span
            ><span class="font-color-yellow"> (How Often?)</span></label
          >
          <Field
            v-model="values.noOfFreq"
            name="noOfFreq"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field, meta }"
          >
            <multiselect
              v-bind="field"
              v-model="values.noOfFreq"
              v-if="values.courseRequestType === 'Mentor for Project'"
              :options="['Once a week', 'Once in two weeks', 'Once a month']"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
            <multiselect
              v-else
              v-bind="field"
              v-model="values.noOfFreq"
              :options="[
                'One-off',
                'Once in a month',
                'Once in two months',
                'Once in three months',
                'Throughout the semester',
              ]"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="noOfFreq" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'daysOfMeeting')
              ?.disabled && values.courseRequestType === 'Mentor for Project'
          "
        >
          <label for="daysOfMeeting"
            >Days of Meeting with Students<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.daysOfMeeting"
            name="daysOfMeeting"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field, meta }"
          >
            <multiselect
              v-bind="field"
              v-model="values.daysOfMeeting"
              :options="[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ]"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
            <ErrorMessage
              v-if="meta.dirty && !meta.valid"
              name="daysOfMeeting"
              class="error-feedback"
            />
          </Field>
        </div>

        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'topic')?.disabled
          "
        >
          <label for="topic"
            >Topic Requiring Course-Support<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field name="topic" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="TOP_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(TOP_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.topic ? values.topic.length : 0 }}
            / {{ TOP_TEXT_MAX_LENGTH }} characters
          </Field>
          <ErrorMessage
            v-if="meta.dirty && !meta.valid"
            name="topic"
            class="error-feedback"
          />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'priorKnowledge')
              ?.disabled && showPriorKnowledge(values.courseRequestType)
          "
        >
          <label for="priorKnowledge"
            >Students' Prior Knowledge About Topic Requiring Course-Support
            <span class="super font-color-red">*</span></label
          >
          <Field name="priorKnowledge" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="LOT_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(LOT_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.priorKnowledge ? values.priorKnowledge.length : 0 }}
            / {{ LOT_TEXT_MAX_LENGTH }} characters
          </Field>
          <ErrorMessage
            v-if="meta.dirty && !meta.valid"
            name="priorKnowledge"
            class="error-feedback"
          />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'learning')?.disabled
          "
        >
          <label for="learning"
            >Learning Objective/Expected Student Outcomes<span
              class="super font-color-red"
              >*</span
            ><span class="font-color-yellow">
              (You can add multiple)</span
            ></label
          >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <Field
              name="learning_text"
              v-slot="{ field }"
              v-model="learning_text"
              :readonly="props.action === 'view'"
              v-on:keyup.enter.prevent="addLearningObjectives"
            >
              <input
                v-bind="field"
                class="form-control"
                type="text"
                :maxlength="LER_TEXT_MAX_LENGTH"
                @input="onInputWithNotification(LER_TEXT_MAX_LENGTH, $event)"
              />
            </Field>

            <button
              type="button"
              class="ml-2 cursor-pointer btn btn-primary w-6rem"
              style="display: inline; justify-content: center"
              @click="addLearningObjectives"
              :disabled="
                !learning_text.trim() ||
                learning_text.length > LER_TEXT_MAX_LENGTH
              "
            >
              <font-awesome-icon
                style="margin: auto"
                icon="fa-solid fa-plus"
                title="Add Learning Objective/Expected Student Outcomes"
              />
              &nbsp;
              <span>Add</span>
            </button>
          </div>
          {{ learning_text ? learning_text.length : 0 }}
          / {{ LER_TEXT_MAX_LENGTH }} characters
          <ErrorMessage name="learning" class="error-feedback" />
          <div
            style="display: flex; flex-wrap: wrap"
            v-if="values.learning != null"
            :class="{ 'pointer-events-none': props.action === 'view' }"
          >
            <span v-for="item in values.learning" :key="item" class="list-item">
              {{ item }}
              <span class="list-item-remove"
                ><span
                  class="list-item-remove-icon"
                  @click="removeLearningObjectives(item)"
                ></span
              ></span>
            </span>
          </div>
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'needForPreVisit')
              ?.disabled && values.courseRequestType === 'Site Visit'
          "
        >
          <label for="needForPreVisit">
            Need for Pre-Visit by the Instructor<span
              class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.needForPreVisit"
            name="needForPreVisit"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.needForPreVisit"
              :options="['Yes', 'No']"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="needForPreVisit" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'visualRep')
              ?.disabled && showVisualRep(values.courseRequestType)
          "
        >
          <label for="visualRep"
            >Describe Specific Demonstrations/Visual Representation Required
            <span class="super font-color-red">*</span> &nbsp;
            <span
              title="Kindly give a description of any demonstration or visual representation you want the practitioner to provide to students in the delivery of this course-support"
            >
              <font-awesome-icon icon="fa-solid fa-circle-question" />
            </span>
          </label>
          <Field name="visualRep" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="LOT_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(LOT_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.visualRep ? values.visualRep.length : 0 }}
            / {{ LOT_TEXT_MAX_LENGTH }} characters
          </Field>
          <ErrorMessage name="visualRep" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'siteOffice')
              ?.disabled && values.courseRequestType === 'Site Visit'
          "
        >
          <label for="siteOffice"
            >Need to Visit the Office Trailer/Site Office for any Demonstration
            <span class="super font-color-red">*</span></label
          >
          <Field
            v-model="values.siteOffice"
            name="siteOffice"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.siteOffice"
              :options="['Yes', 'No']"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="siteOffice" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'classroomItems')
              ?.disabled && showClassroomItems(values.courseRequestType)
          "
        >
          <label for="classroomItems">
            Available items in the classroom e.g., projector, HDMI, whiteboard
          </label>
          <Field name="classroomItems" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="LOT_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(LOT_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.classroomItems ? values.classroomItems.length : 0 }}
            / {{ LOT_TEXT_MAX_LENGTH }} characters
          </Field>
          <ErrorMessage name="classroomItems" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'assigmentConnected')
              ?.disabled && showAssignmentConnected(values.courseRequestType)
          "
        >
          <label for="assigmentConnected">
            Is there any Student Assignment Connected to this Course-Support? If
            yes, Please Provide Details.
          </label>
          <Field name="assigmentConnected" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="LOT_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(LOT_TEXT_MAX_LENGTH, $event)"
            />
            {{
              values.assigmentConnected ? values.assigmentConnected.length : 0
            }}
            / {{ LOT_TEXT_MAX_LENGTH }} characters
          </Field>
          <ErrorMessage name="assigmentConnected" class="error-feedback" />
        </div>

        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'generalInfo')
              ?.disabled
          "
        >
          <label for="generalInfo"
            >Detailed Description of Course-Support Needed<span
              class="super font-color-red"
              >*
            </span>
            <span class="font-color-yellow"
              >(Please describe the format of the class and/or the form of
              engagement you desire from the practitioner)</span
            ></label
          >
          <Field name="generalInfo" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              :maxlength="DET_TEXT_MAX_LENGTH"
              @input="onInputWithNotification(DET_TEXT_MAX_LENGTH, $event)"
            />
            {{ values.generalInfo ? values.generalInfo.length : 0 }}
            / {{ DET_TEXT_MAX_LENGTH }} characters
          </Field>

          <ErrorMessage name="generalInfo" class="error-feedback" />
        </div>
        <br />
        <div class="align-center bold-text flex flex-direction-column">
          Preference Definition (Industry Practitioner Related)
          <span style="font-style: italic; font-size: small"
            >This information will not be shared with industry
            practitioners</span
          >
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'gender')?.disabled
          "
        >
          <label for="gender"
            >Gender Identity<span class="super font-color-red">*</span></label
          >
          <Field
            v-model="values.gender"
            name="gender"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.gender"
              :options="[
                { label: 'No preference', value: 'No preference' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Non-binary', value: 'Non-binary' },
                { label: 'Others, please specify', value: 'Others' },
              ]"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <div v-if="values.gender === 'Others'">
            <Field
              name="gender-text"
              type="text"
              placeholder="Please Specify"
              class="form-control bg-others"
              :readonly="props.action === 'view'"
            />
          </div>
          <ErrorMessage name="gender" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'ethnicity')?.disabled
          "
        >
          <label for="ethnicity"
            >Ethnicity<span class="super font-color-red">*</span></label
          >
          <Field
            v-model="values.ethnicity"
            name="ethnicity"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.ethnicity"
              :options="ethnicityOptionsAdded"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <div v-if="values.ethnicity === 'Others'">
            <Field
              name="ethnicity-text"
              type="text"
              placeholder="Please Specify"
              class="form-control bg-others"
              :readonly="props.action === 'view'"
            />
          </div>
          <ErrorMessage name="ethnicity" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'yearsOfExp')
              ?.disabled
          "
        >
          <label for="yearsOfExp"
            >Years of Experience<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.yearsOfExp"
            name="yearsOfExp"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.yearsOfExp"
              :options="yearsOfExpOptionsAdded"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="yearsOfExp" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'areaOfExpertise')
              ?.disabled
          "
        >
          <label for="areaOfExpertise"
            >Area of Expertise/Specialization<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.areaOfExpertise"
            name="areaOfExpertise"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.areaOfExpertise"
              :options="areaOfExpertiseOptionsAdded"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <div v-if="values.areaOfExpertise === 'Others'">
            <Field name="areaOfExpertise-text" v-slot="{ field }">
              <input
                v-bind="field"
                placeholder="Please Specify"
                class="form-control bg-others"
                type="text"
                :readonly="props.action === 'view'"
              />
            </Field>
          </div>
          <ErrorMessage name="areaOfExpertise" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'levelEducation')
              ?.disabled
          "
        >
          <label for="levelEducation"
            >Minimum Level of Education<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.levelEducation"
            name="levelEducation"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.levelEducation"
              :options="[
                { label: 'No preference', value: 'No preference' },
                { label: 'Diploma', value: 'Diploma' },
                { label: 'Associate Degree', value: 'Associate Degree' },
                { label: 'Bachelor’s degree', value: 'Bachelor’s degree' },
                { label: 'Master’s degree', value: 'Master’s degree' },
                { label: 'Doctoral Degree', value: 'Doctoral Degree' },
              ]"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="levelEducation" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'typeFirm')?.disabled
          "
        >
          <label for="typeFirm"
            >Type of Firm<span class="super font-color-red">*</span></label
          >
          <Field
            v-model="values.typeFirm"
            name="typeFirm"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.typeFirm"
              :options="typeOfFirmOptionsAdded"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <div v-if="values.typeFirm === 'Others'">
            <Field name="typeFirm_text" v-slot="{ field, meta }">
              <input
                v-bind="field"
                :class="{
                  'field-bg-color': !meta.dirty,
                  'field-border-error': meta.dirty && !meta.valid,
                }"
                placeholder="Please Specify"
                class="form-control bg-others"
                type="text"
                :readonly="props.action === 'view'"
              />
            </Field>
          </div>
          <ErrorMessage name="typeFirm" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !courseRequestFields.find((el) => el.name === 'sizeFirm')?.disabled
          "
        >
          <label for="sizeFirm"
            >Size of Firm<span class="super font-color-red">*</span></label
          >
          <Field
            v-model="values.sizeFirm"
            name="sizeFirm"
            type="text"
            class="dropdown"
            :class="{ 'non-editable-select': props.action === 'view' }"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.sizeFirm"
              :options="sizeOfFirmOptionsAdded"
              mode="single"
              :searchable="true"
              :disabled="props.action === 'view'"
              :class="{ 'field-bg-color': !meta.dirty && !meta.valid }"
            />
          </Field>
          <ErrorMessage name="sizeFirm" class="error-feedback" />
        </div>

        <br />
        <div v-if="props.action !== 'view'">
          <div class="form-group flex flex-direction-row align-content-between">
            <button
              type="button"
              @click="saveTempCourseRequest"
              class="btn btn-secondary btn-block"
            >
              <span>Save & Continue Later</span>
            </button>
            <div></div>
            <button
              type="button"
              class="btn btn-primary btn-block"
              @click="showPopup"
            >
              <span v-if="action === 'update'">Update Course Request</span>
              <span v-if="action === 'create' || action === 'resubmit'"
                >Request Course-Support</span
              >
            </button>
          </div>
          <PopupModal :visible="isVisible" @close="hidePopup">
            <template v-slot:title> Contact Preference</template>
            <template v-slot:content>
              <div class="form-group">
                <label for="contactPref"
                  >Please select how you would like to be contacted?</label
                >
                <Field
                  name="Email"
                  value="True"
                  type="checkbox"
                  disabled
                />Email
                <br />
                <Field
                  value="True"
                  unchecked-value="False"
                  name="phone"
                  type="checkbox"
                />
                Phone
                <br />
                <ErrorMessage name="contactPref" class="error-feedback" />
              </div>

              <div class="form-group" v-if="values.phone === 'True'">
                <label for="phoneNumber">Phone Number</label>
                <Field name="phoneNumber" type="text" class="form-control" />
                <ErrorMessage name="phoneNumber" class="error-feedback" />
              </div>
              <br />
              <div class="form-group text-align-center">
                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  @click="
                    (isVisible = false), (isRecommendationsVisible = true)
                  "
                >
                  <span>Proceed</span>
                </button>
              </div>
            </template>
          </PopupModal>
          <PopupModal
            :visible="isRecommendationsVisible"
            @close="isRecommendationsVisible = false"
          >
            <template v-slot:title></template>
            <template v-slot:content>
              <div>
                To complete the submission of your request, kindly make a
                maximum of three (3) selections from the recommended
                practitioners on the next page. Your request would be sent to
                practitioner(s) that you select.
              </div>
              <br />
              <br />
              <div
                class="form-group flex flex-direction-row align-content-between"
              >
                <button
                  type="button"
                  class="btn btn-secondary btn-block"
                  @click="isRecommendationsVisible = false"
                  :disabled="loading"
                >
                  <span>Back</span>
                </button>
                <div></div>
                <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Accept</span>
                </button>
              </div>
            </template>
          </PopupModal>
        </div>
      </form>
    </div>
    <div v-if="message" :class="successful ? 'alert-success' : 'alert-danger'">
      {{ message }}
    </div>
    <!--    <va-modal v-model="showRecommendationsModal" hide-default-actions>-->
    <!--      <h3 class="va-h3"></h3>-->
    <!--      <p>-->
    <!--        Your course-support request will be posted to all practitioners on this-->
    <!--        platform. However, you can make a maximum of three selections from the-->
    <!--        recommended practitioners. Once you make the selection(s), your-->
    <!--        course-support request will now only be visible to the practitioners-->
    <!--        that you selected.-->
    <!--      </p>-->
    <!--      <br />-->
    <!--      <div style="text-align: center">-->
    <!--        <va-button @click="recommendationsModalAccept"> Accept </va-button>-->
    <!--      </div>-->
    <!--    </va-modal>-->
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import InstructorService from "@/services/instructor.service.js";
import Multiselect from "@vueform/multiselect";
import PopupModal from "../user/PopupModal.vue";
import {
  displayLabels,
  physicalDisOptions,
  insDeliveryTypeOptions,
  areaOfExpertiseOptions,
  yearsOfExpOptions,
  deptOptions,
  academicLevelOptions,
  showVisualRep,
  showToast,
  showPriorKnowledge,
  insDeliveryTypeSiteVisitOptions,
  isCapstoneTermProject,
  typeOfProjectOptions,
  typeOfFirmOptions,
  sizeOfFirmOptions,
  ethnicityOptions,
  showLocation,
  showClassroomItems,
  showErrorToast,
  getTimezoneName,
  showAssignmentConnected,
  websiteURL,
} from "@/utils";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useConfigStore } from "@/stores/ConfigStore";
import { useModal } from "vuestic-ui";

import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useCourseStore } from "@/stores/CourseStore";

const RecommendationsStore = useRecommendationsStore();
const CourseStore = useCourseStore();

const MED_TEXT_MAX_LENGTH = 100;
const MAX_TEXT_MAX_LENGTH = 2000;

const DUR_TEXT_MAX_LENGTH = 10;
const TOP_TEXT_MAX_LENGTH = 250;
const LER_TEXT_MAX_LENGTH = 300;
const LOT_TEXT_MAX_LENGTH = 500;
const DET_TEXT_MAX_LENGTH = 1000;

// Define `disabledFields` to track which fields to disable
const disabledFields = ref(false);

const ConfigStore = useConfigStore();
const router = useRouter();
const { confirm } = useModal();

const courseRequestFields = ConfigStore.config.filter(
  (el) => el.table === "course_request"
);

// eslint-disable-next-line no-undef
const props = defineProps<{
  formValues: Record<string, unknown>;
  action: string;
}>();

const yearsOfExpOptionsAdded = ["No preference", ...yearsOfExpOptions];
const typeOfFirmOptionsAdded = [
  { label: "No preference", value: "No preference" },
  ...typeOfFirmOptions,
];
const sizeOfFirmOptionsAdded = [
  { label: "No preference", value: "No preference" },
  ...sizeOfFirmOptions,
];
const areaOfExpertiseOptionsAdded = [
  "No preference",
  ...areaOfExpertiseOptions,
];
const ethnicityOptionsAdded = ["No preference", ...ethnicityOptions];

function processFieldWithOthers(fieldData, options) {
  let parsedField = [];
  try {
    parsedField = JSON.parse(fieldData || "[]");
  } catch (e) {
    console.error("Error parsing fieldData:", fieldData, e);
    parsedField = [];
  }

  const predefined = [];
  const customInputs = [];

  const normalizedOptionValues = new Set(
    options.map((opt) =>
      (typeof opt === "object" ? opt.value : opt).toLowerCase()
    )
  );

  parsedField.forEach((value) => {
    const normalizedValue = value.toLowerCase();
    if (normalizedOptionValues.has(normalizedValue)) {
      predefined.push(value);
    } else {
      customInputs.push(value);
    }
  });

  // Add all unknown items as custom inputs, and mark "Others"
  if (customInputs.length > 0 && !predefined.includes("Others")) {
    predefined.push("Others");
  }

  return {
    field: predefined, // Includes known options + "Others"
    text: customInputs.join(", "), // Stores unknown items in text input
  };
}

const formatFn = (dateString) => {
  if (!dateString) return ""; // Return an empty string for invalid dates
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ""; // Ensure it's a valid date
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Month is 0-indexed
  const day = date.getDate();
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
};

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length >= maxLength) {
    event.target.value = value.substring(0, maxLength); // Prevent further input
    showErrorToast(`Maximum character limit of ${maxLength} reached.`);
  }
};

const parseFn = (text) => {
  if (!text) return null; // Return null for empty values
  const [year, month, day] = text.split("-");
  const parsedDate = new Date(year, month - 1, day);
  return isNaN(parsedDate.getTime()) ? null : parsedDate; // Validate parsed date
};

const validateDate = (field, meta) => {
  const value = field.value;

  // Ensure the date is valid and not in the past
  const parsedDate = new Date(value);
  if (isNaN(parsedDate.getTime()) || parsedDate < new Date()) {
    meta.valid = false;
    meta.errorMessage = "Invalid or past date provided.";
  } else {
    meta.valid = true;
    meta.errorMessage = "";
  }
};

const schema = yup.object().shape({
  fields: yup.array().default(courseRequestFields),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' academic level is required!")
          .min(1, "Students' academic level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  areaOfExpertise: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "areaOfExpertise")?.disabled,
    then: (schema) =>
      schema.required("Area of Expertise/Specialization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  classSize: yup
    .number()
    .label("Class Size")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "classSize")?.disabled,
      then: (schema) =>
        schema
          .typeError("Class Size must be a number!")
          .positive()
          .integer()
          .required("Class Size is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  courseRequestType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseRequestType")?.disabled,
    then: (schema) => schema.required("Course-Support Type is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  duration: yup
    .number()
    .label("Duration of Course-Support")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "duration")?.disabled,
      then: (schema) =>
        schema
          .typeError("Duration of Course-Support must be a number!")
          .positive()
          .integer()
          .required("Duration of Course-Support is required!")
          .test(
            "maxDigits",
            `Duration cannot have more than ${DUR_TEXT_MAX_LENGTH} digits!`,
            (value) => value && value.toString().length <= DUR_TEXT_MAX_LENGTH
          ),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  ethnicity: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicity")?.disabled,
    then: (schema) => schema.required("Ethnicity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "gender")?.disabled,
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insDeliveryType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  learning: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "learning")?.disabled,
      then: (schema) =>
        schema
          .required("Learning objective/Expected student Outcome is required!")
          .min(1, "Learning Objective/Expected Student Outcome is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  levelEducation: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "levelEducation")?.disabled,
    then: (schema) => schema.required("Level of Education is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  needForPreVisit: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "levelEducation")?.disabled &&
      courseRequestType == "Site Visit",
    then: (schema) =>
      schema.required("Need for Pre-Visit by the instructor is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  noOfFreq: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "noOfFreq")?.disabled,
    then: (schema) =>
      schema.required("Frequency of Course-Support is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  physicalDis: yup
    .array()
    .of(yup.string())
    .when(["fields", "courseRequestType", "insDeliveryType"], {
      is: (fields, courseRequestType, insDeliveryType) =>
        !fields.find((el) => el.name === "physicalDis")?.disabled &&
        courseRequestType === "Site Visit" &&
        insDeliveryType !== "Virtual site visit",
      then: (schema) =>
        schema
          .required("Physical disabilities of students is required!")
          .min(1, "At least one disability must be selected!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  physicalDis_text: yup.string().when("physicalDis", {
    is: (val) => val?.includes("Others"),
    then: (schema) =>
      schema
        .required("Custom input for 'Others' is required!")
        .max(
          MED_TEXT_MAX_LENGTH,
          `Custom input cannot exceed ${MED_TEXT_MAX_LENGTH} characters`
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  siteOffice: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "siteOffice")?.disabled &&
      courseRequestType == "Site Visit",
    then: (schema) =>
      schema.required(
        "Need to visit the office trailer/site office is required!"
      ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' department or Program of study is required!")
          .min(1, "Students' department or Program of study is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  topic: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "topic")?.disabled,
    then: (schema) =>
      schema
        .required("Topic requiring Course-Support is required!")
        .max(
          TOP_TEXT_MAX_LENGTH,
          `Topic requiring Course-Support cannot be greater than ${TOP_TEXT_MAX_LENGTH} characters`
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  visualRep: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "visualRep")?.disabled &&
      showVisualRep(courseRequestType),
    then: (schema) =>
      schema
        .required(
          "Describe Specific demonstrations/ Visual Representation Required is required!"
        )
        .max(
          LOT_TEXT_MAX_LENGTH,
          `Describe Specific demonstrations/ Visual Representation Required cannot be greater than ${LOT_TEXT_MAX_LENGTH} characters`
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  priorKnowledge: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "priorKnowledge")?.disabled &&
      showPriorKnowledge(courseRequestType),
    then: (schema) =>
      schema
        .required(
          "Students' Prior Knowledge About Topic Requiring Course-Support is required!"
        )
        .max(
          LOT_TEXT_MAX_LENGTH,
          `Students' Prior Knowledge About Topic Requiring Course-Support cannot be greater than ${LOT_TEXT_MAX_LENGTH} characters`
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  yearsOfExp: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "yearsOfExp")?.disabled,
    then: (schema) => schema.required("Years of Experience is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  date: yup
    .array()
    .of(
      yup
        .date()
        .min(new Date(), "Date for Course-Support cannot be in the past!")
    )
    .when(["fields", "courseRequestType"], {
      is: (fields, courseRequestType) =>
        !fields.find((el) => el.name === "date")?.disabled &&
        courseRequestType !== "Mentor for Project",
      then: (schema) =>
        schema
          .required("Date for Course-Support is required!")
          .min(1, "Date for Course-Support is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  time: yup
    .array()
    .of(yup.string())
    .when(["fields", "courseRequestType"], {
      is: (fields, courseRequestType) =>
        !fields.find((el) => el.name === "time")?.disabled &&
        courseRequestType !== "Mentor for Project",
      then: (schema) =>
        schema
          .required("Time for Course-Support is required!")
          .min(1, "Time for Course-Support is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  generalInfo: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "generalInfo")?.disabled,
    then: (schema) =>
      schema
        .required("Detailed Description of Course-Support Needed is required!")
        .max(
          DET_TEXT_MAX_LENGTH,
          `Detailed Description of Course-Support Needed cannot be greater than ${DET_TEXT_MAX_LENGTH} characters`
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  courseCode: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseCode")?.disabled,
    then: (schema) => schema.required("Course Code is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  courseTitle: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseTitle")?.disabled,
    then: (schema) => schema.required("Course Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  typeProject: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "typeProject")?.disabled &&
      isCapstoneTermProject(courseRequestType),
    then: (schema) => schema.required("Type of Project is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  location: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "location")?.disabled &&
      showLocation(courseRequestType),
    then: (schema) =>
      schema.required("Location of Course-Support is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  projectDescr: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "projectDescr")?.disabled &&
      isCapstoneTermProject(courseRequestType),
    then: (schema) =>
      schema
        .required("Description of Project is required!")
        .max(
          LOT_TEXT_MAX_LENGTH,
          `Description of Project cannot be greater than ${LOT_TEXT_MAX_LENGTH} characters`
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  daysOfMeeting: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "daysOfMeeting")?.disabled &&
      courseRequestType === "Mentor for Project",
    then: (schema) =>
      schema.required("Days of Meeting with Students is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  startDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .when(["fields", "courseRequestType"], {
      is: (fields, courseRequestType) =>
        !fields.find((el) => el.name === "startDate")?.disabled &&
        courseRequestType === "Mentor for Project",
      then: (schema) =>
        schema
          .required("Start Date for Course-Support is required!")
          .min(new Date(), "Start Date cannot be in the past."),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  endDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .when(["fields", "courseRequestType"], {
      is: (fields, courseRequestType) =>
        !fields.find((el) => el.name === "endDate")?.disabled &&
        courseRequestType === "Mentor for Project",
      then: (schema) =>
        schema
          .required("End Date for Course-Support is required!")
          .min(yup.ref("startDate"), "End Date cannot be before Start Date."),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  classroomItems: yup
    .string()
    .optional()
    .nullable()
    .max(
      LOT_TEXT_MAX_LENGTH,
      `Available items in the classroom cannot be greater than ${LOT_TEXT_MAX_LENGTH} characters`
    ),
  assigmentConnected: yup
    .string()
    .optional()
    .nullable()
    .max(
      LOT_TEXT_MAX_LENGTH,
      `Is there any Student Assignment Connected to this Course-Support cannot be greater than ${LOT_TEXT_MAX_LENGTH} characters`
    ),
  additionalInformation: yup.string().optional().nullable(),
  typeFirm: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "typeFirm")?.disabled,
    then: (schema) => schema.required("Type of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  sizeFirm: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "sizeFirm")?.disabled,
    then: (schema) => schema.required("Size of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  semester: yup.string(),
  year: yup.string(),
});

const initializePhysicalDisabilities = () => {
  const predefinedOptions = new Set(
    physicalDisOptions.map((opt) => (typeof opt === "object" ? opt.value : opt))
  );

  const parsedField = values.physicalDis || [];
  const predefined = [];
  const customInputs = [];

  parsedField.forEach((value) => {
    if (predefinedOptions.has(value)) {
      predefined.push(value);
    } else {
      customInputs.push(value);
    }
  });

  // Add "Others" if custom inputs exist
  if (customInputs.length > 0 && !predefined.includes("Others")) {
    predefined.push("Others");
  }

  // Update values
  setFieldValue("physicalDis", predefined);
  setFieldValue("physicalDis_text", customInputs.join(", "));
};

const { handleSubmit, errors, values, meta, resetForm, setFieldValue } =
  useForm({
    validationSchema: schema,
  });

resetForm({
  values: {
    ...props.formValues,
    physicalDis: props.formValues.physicalDis || [],
    startDate: props.formValues.startDate
      ? formatFn(props.formValues.startDate)
      : "",
    endDate: props.formValues.endDate ? formatFn(props.formValues.endDate) : "",
  },
});

// Ensure the function is defined before calling it
initializePhysicalDisabilities();

onBeforeRouteLeave(async (to, from) => {
  if (meta.value.dirty && !loading.value && !successful.value) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    if (!answer) return false;
  }
});

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let isVisible = ref(false);
let isRecommendationsVisible = ref(false);
let showRecommendationsModal = ref(false);
let date_text = ref("");
let time_text = ref("");
let learning_text = ref("");
let elementVisible = ref(false);
let tooltipTimeout;

const today = new Date(new Date().setDate(new Date().getDate() + 1))
  .toISOString()
  .substring(0, 10);

const showTooltip = () => {
  elementVisible.value = true;
  tooltipTimeout = setTimeout(() => (elementVisible.value = false), 5000);
};
const hideTooltip = () => {
  elementVisible.value = false;
  clearTimeout(tooltipTimeout);
};
const showPopup = () => {
  isVisible.value = true;
};
const hidePopup = () => {
  isVisible.value = false;
};

const uploadFile = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  setFieldValue("files", files);
};

const saveTempCourseRequest = () => {
  loading.value = true;

  // Ensure values.physicalDis_text is properly processed
  if (values.physicalDis.includes("Others")) {
    // Ensure custom inputs are split and trimmed
    const customInputs = values.physicalDis_text
      ? values.physicalDis_text.split(",").map((item) => item.trim())
      : [];

    // Retain "Others" while appending custom inputs
    const updatedPhysicalDis = [
      ...values.physicalDis, // Retain all existing options including "Others"
      ...customInputs, // Add custom inputs
    ];

    // Remove duplicate entries (in case custom inputs overlap with existing ones)
    const uniquePhysicalDis = [...new Set(updatedPhysicalDis)];

    // Reassign the new array to values.physicalDis
    setFieldValue("physicalDis", uniquePhysicalDis); // Use setFieldValue to update reactive state
  }

  if (values.courseRequestType === "Others") {
    setFieldValue("courseRequestType", values["courseRequestType-text"]);
  }
  if (values?.studentDept?.includes("Others")) {
    const newStudentDept = [...values.studentDept];
    newStudentDept[newStudentDept.indexOf("Others")] =
      values["studentDept_text"];
    setFieldValue("studentDept", newStudentDept);
  }
  if (values.gender === "Others") {
    setFieldValue("gender", values["gender-text"]);
  }
  if (values.ethnicity === "Others") {
    setFieldValue("ethnicity", values["ethnicity-text"]);
  }
  if (values.areaOfExpertise === "Others") {
    setFieldValue("areaOfExpertise", values["areaOfExpertise-text"]);
  }
  if (values.typeFirm === "Others") {
    setFieldValue("typeFirm", values["typeFirm_text"]);
  }

  // Handle date and time for "Mentor for Project"
  if (values.courseRequestType === "Mentor for Project") {
    const formattedStartDate = formatFn(values["startDate"]);
    const formattedEndDate = formatFn(values["endDate"]);

    setFieldValue("date", [formattedStartDate]);
    setFieldValue("time", [values["time_text"]]);
    setFieldValue("endDate", formattedEndDate);
  }

  InstructorService.saveTempCourseRequest(values).then(
    (response) => {
      loading.value = false;
      successful.value = true;
      showToast("Your course-support request has been saved.");
      router.push({ name: "instructor-feed" });
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const saveCourseRequest = handleSubmit(
  (data) => {
    console.log("validated while submitting");
    loading.value = true;

    // Process "physicalDis" field to include items from "physicalDis_text"
    // Make sure data.physicalDis is an array before calling .includes()
    if (
      Array.isArray(data.physicalDis) &&
      data.physicalDis.includes("Others")
    ) {
      const customInputs = data.physicalDis_text
        ? data.physicalDis_text.split(",").map((item) => item.trim())
        : [];
      const updatedPhysicalDis = [
        ...data.physicalDis, // Include all existing items, including "Others"
        ...customInputs, // Add custom inputs
      ];
      // Remove duplicates
      data.physicalDis = [...new Set(updatedPhysicalDis)];
    }

    // For fields that are strings, ensure they exist and are strings before comparing
    if (
      typeof data.courseRequestType === "string" &&
      data.courseRequestType === "Others"
    ) {
      data.courseRequestType = data["courseRequestType-text"];
    }

    // studentDept should be an array of strings
    if (
      Array.isArray(data.studentDept) &&
      data.studentDept.includes("Others")
    ) {
      const newStudentDept = [...data.studentDept];
      const idx = newStudentDept.indexOf("Others");
      if (idx !== -1) {
        newStudentDept[idx] = data["studentDept_text"];
      }
      data.studentDept = newStudentDept;
    }

    if (typeof data.gender === "string" && data.gender === "Others") {
      data.gender = data["gender-text"];
    }
    if (typeof data.ethnicity === "string" && data.ethnicity === "Others") {
      data.ethnicity = data["ethnicity-text"];
    }
    if (
      typeof data.areaOfExpertise === "string" &&
      data.areaOfExpertise === "Others"
    ) {
      data.areaOfExpertise = data["areaOfExpertise-text"];
    }
    if (typeof data.typeFirm === "string" && data.typeFirm === "Others") {
      data.typeFirm = data["typeFirm_text"];
    }

    // Handle date and time for "Mentor for Project"
    if (data.courseRequestType === "Mentor for Project") {
      const formattedStartDate = formatFn(data["startDate"]);
      const formattedEndDate = formatFn(data["endDate"]);
      data.date = [formattedStartDate];
      data.time = [data["time_text"]];
      data["endDate"] = formattedEndDate;
    }

    InstructorService.saveCourseRequest(data, props.action).then(
      (response) => {
        loading.value = false;
        successful.value = true;
        CourseStore.addCourse(response.data.course);
        router.push({ name: "view-recommendations" });
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  (error) => {
    console.log("error while submitting");
    isRecommendationsVisible.value = false;
    Object.values(error.errors).forEach((error) => showErrorToast(error));
  }
);

const recommendationsModalAccept = () => {
  showRecommendationsModal.value = false;
  router.push({ name: "instructor-feed" });
};

const removeTime = (val) => {
  const index = values.date.indexOf(val);
  if (index !== -1) {
    const newDateArray = [...values.date];
    const newTimeArray = [...values.time];

    newDateArray.splice(index, 1);
    newTimeArray.splice(index, 1);

    setFieldValue("date", newDateArray);
    setFieldValue("time", newTimeArray);
  }
};

const addTime = () => {
  // Check if the user has already added 5 entries
  if (values.date && values.date.length >= 5) {
    showErrorToast("You can add a maximum of 5 date-time entries.");
    return;
  }

  if (date_text.value && time_text.value) {
    // Check if the date-time pair already exists
    const exists = values.date.some((date, index) => {
      return date === date_text.value && values.time[index] === time_text.value;
    });

    if (!exists) {
      const newDateArray = values.date ? [...values.date] : [];
      const newTimeArray = values.time ? [...values.time] : [];

      newDateArray.push(date_text.value);
      newTimeArray.push(time_text.value);

      setFieldValue("date", newDateArray);
      setFieldValue("time", newTimeArray);

      date_text.value = "";
      time_text.value = "";
    } else {
      showErrorToast("This date and time pair has already been added.");
    }
  } else {
    showErrorToast("Please enter both date and time.");
  }
};

const addLearningObjectives = () => {
  if (!learning_text.value || learning_text.value.trim().length === 0) {
    showErrorToast("Learning objective cannot be empty.");
    return;
  }

  // Check if the character limit is exceeded
  if (learning_text.value.length > LER_TEXT_MAX_LENGTH) {
    learning_text.value = learning_text.value.substring(0, LER_TEXT_MAX_LENGTH);
    showErrorToast(
      `Learning objective cannot exceed ${LER_TEXT_MAX_LENGTH} characters.`
    );
    return;
  }

  // Check if the maximum number of objectives is reached
  if (values.learning && values.learning.length >= 5) {
    showErrorToast("You can add a maximum of 5 learning objectives.");
    return;
  }

  // Add the learning objective if it's not a duplicate
  if (!values.learning?.includes(learning_text.value)) {
    const newLearningArray = values.learning
      ? [...values.learning, learning_text.value]
      : [learning_text.value];
    setFieldValue("learning", newLearningArray);
  }
  learning_text.value = ""; // Clear the input field after adding
};

const removeLearningObjectives = (val) => {
  const newLearningArray = values.learning.filter((item) => item !== val);
  setFieldValue("learning", newLearningArray);
};

let isProgrammaticChange = false; // Flag to avoid recursive updates

watch(
  () => values.physicalDis,
  (newVal) => {
    if (!newVal) {
      disabledFields.value = false; // Reset or set default behavior
      return;
    }

    if (isProgrammaticChange) {
      isProgrammaticChange = false; // Reset flag after programmatic change
      return;
    }

    if (newVal.includes("None")) {
      // If "None" is selected, disable other fields and update physicalDis
      disabledFields.value = true;

      // Avoid infinite recursion by setting the flag
      isProgrammaticChange = true;
      setFieldValue("physicalDis", ["None"]); // Reset to only "None"
    } else {
      // If "None" is removed, enable other fields
      disabledFields.value = false;
    }
  }
);
</script>

<style scoped>
.non-editable-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.card-container.card {
  max-width: 900px !important;
  padding: 40px 40px;
}

.doc-image {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}

input:read-only {
  background-color: #f3f4f6;
}

@media screen and (max-width: 768px) {
  .card-container.card {
    max-width: 100% !important;
    padding: 10px 10px;
  }
}
</style>
