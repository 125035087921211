import axios from "axios";
import authHeader from "./auth-header";
import { websiteURL } from "@/utils";

const API_URL = websiteURL + "api/";

class UserService {
  getConfig() {
    // console.log(API_URL + "user/config");
    return axios.get(API_URL + "user/config/");
  }

  updateConfig(id, value) {
    // console.log(API_URL + "user/config");
    return axios.patch(
      API_URL + "user/config/" + id,
      { disabled: value },
      { headers: authHeader() }
    );
  }

  getNotifications() {
    // console.log(API_URL + "user/notification");
    return axios.get(API_URL + "user/notification", { headers: authHeader() });
  }

  getCities(state) {
    console.log(API_URL + "user/cities/" + state);
    return axios.get(API_URL + "user/cities/" + state, {});
  }

  markNotificationAsRead(notificationId) {
    // console.log(API_URL + "user/notification/" + notificationId);
    return axios.patch(
      API_URL + "user/notification/" + notificationId,
      {},
      { headers: authHeader() }
    );
  }

  getNotification(notificationId) {
    // console.log(API_URL + "user/notification" + notificationId);
    return axios.get(API_URL + "user/notification/" + notificationId, {
      headers: authHeader(),
    });
  }

  getResourcesRequest(page, size = 5) {
    // console.log(API_URL + "user/course");
    return axios.get(API_URL + "user/resource", {
      params: { page: page, size: size },
      headers: authHeader(),
    });
  }

  getMyUploads(page, type, size) {
    // console.log(API_URL + "user/upload");
    return axios.get(API_URL + "user/upload", {
      params: { page: page, size: size, type: type },
      headers: authHeader(),
    });
  }

  getFolders() {
    // console.log(API_URL + "getFolders");
    return axios.get(API_URL + "user/upload/folder", {
      headers: authHeader(),
    });
  }

  createFolder(data) {
    // console.log(API_URL + "user/upload/" + itemId);
    return axios.post(API_URL + "user/upload/folder", data, {
      headers: authHeader(),
    });
  }

  moveFile(itemId, data) {
    // console.log(API_URL + "user/upload/" + itemId);
    return axios.patch(API_URL + "user/upload/" + itemId, data, {
      headers: authHeader(),
    });
  }

  getPublicResources(page, type, size) {
    // console.log(API_URL + "user/resources");
    return axios.get(API_URL + "user/resources", {
      params: { page: page, size: size, type: type },
      headers: authHeader(),
    });
  }

  searchResources(searchParameter, searchType, page, type, size) {
    // console.log(API_URL + "user/resources/" + searchParameter);
    return axios.get(API_URL + "user/resources/" + searchParameter, {
      params: { page: page, size: size, type: type, searchType: searchType },
      headers: authHeader(),
    });
  }

  saveFeedback(data) {
    return axios.post(
      API_URL + "user/feedback",
      {
        message: data.message,
        rating: data.rating,
      },
      { headers: authHeader() }
    );
  }

  getFeedback() {
    // console.log(API_URL + "user/feedback");
    return axios.get(API_URL + "user/feedback");
  }

  getFAQ() {
    console.log(API_URL + "user/faq");
    return axios.get(API_URL + "user/faq");
  }

  getUserFeedback(userId) {
    // console.log(API_URL + "user/" + userId + "/feedback");
    return axios.get(API_URL + "user/" + userId + "/feedback");
  }

  postFeedbackComment(comment, feedbackId) {
    // console.log(API_URL + "user/feedback/" + feedbackId + "/comment");
    return axios.post(
      API_URL + "user/feedback/" + feedbackId + "/comment",
      comment,
      {
        headers: authHeader(),
      }
    );
  }

  getFeedbackComments(feedbackId) {
    // console.log(API_URL + "user/feedback/" + feedbackId + "/comment");
    return axios.get(API_URL + "user/feedback/" + feedbackId + "/comment", {
      headers: authHeader(),
    });
  }

  getOrgList(roleType) {
    // console.log(API_URL + "user/org");
    return axios.get(API_URL + "user/org/" + roleType);
  }

  saveFAQ(data) {
    return axios.post(API_URL + "user/faq", {
      userQuestion: data.userQuestion,
    });
  }

  saveContactUsInfo(data) {
    return axios.post(API_URL + "user/contact", {
      firstName: data.firstName,
      lastName: data.lastName,
      nameOrg: data.nameOrg,
      email: data.email,
      message: data.message,
    });
  }

  sendInvite(data) {
    return axios.post(
      API_URL + "user/invite",
      {
        firstName: data.firstName,
        lastName: data.lastName,
        title: data.title,
        role: data.role,
        email: data.email,
      },
      { headers: authHeader() }
    );
  }

  getSearchResults(keyword) {
    // console.log(API_URL + "user/search");
    return axios.post(
      API_URL + "user/search",
      { searchParameter: keyword },
      { headers: authHeader() }
    );
  }

  getUserProfile(userId) {
    // console.log(API_URL + "user/profile/new/");
    return axios.get(API_URL + "user/profile/new/" + userId, {
      headers: authHeader(),
    });
  }

  getMyUserProfile(userId) {
    // console.log(API_URL + "user/profile/new/");
    return axios.get(API_URL + "myuser/profile/new/" + userId, {
      headers: authHeader(),
    });
  }

  deleteContent(data) {
    return axios.post(
      API_URL + "user/upload/delete",
      {
        contentId: data.contentId,
        reasonDelete: data.reasonDelete,
      },
      { headers: authHeader() }
    );
  }

  reportContent(data) {
    return axios.post(
      API_URL + "user/upload/report",
      {
        contentId: data.contentId,
        reasonReport: data.reasonReport,
      },
      { headers: authHeader() }
    );
  }

  saveCourseFeedback(data) {
    // console.log("Trying To Save Feedback " + API_URL + "user/course/feedback");
    return axios.post(
      API_URL + "user/course/feedback",
      {
        courseRequestId: data.courseRequestId,
        message: data.message,
        rating: data.rating,
        concern: data.concern,
      },
      { headers: authHeader() }
    );
  }

  checkFeedbackExists(courseId) {
    // console.log(
    //   "Trying To Check Feedback Exists " +
    //     API_URL +
    //     "user/course/" +
    //     courseId +
    //     "/feedback"
    // );
    return axios.get(API_URL + "user/course/" + courseId + "/feedback", {
      headers: authHeader(),
    });
  }

  getUsers(role) {
    // console.log(API_URL + "user/users");
    return axios.get(API_URL + "user/users/" + role, { headers: authHeader() });
  }

  getUpload(fileName) {
    console.log(API_URL + "uploads/" + fileName);
    return axios.get(API_URL + "uploads/" + fileName, {
      headers: { ...authHeader(), responseType: "blob" },
    });
  }

  requestDashboardAccess() {
    return axios.post(
      API_URL + "user/dashboard/access",
      {},
      { headers: authHeader() }
    );
  }

  checkDashboardAccessRequestExists(userId) {
    // console.log(
    //   "Trying To Check DashboardAccessRequest Exists " +
    //     API_URL +
    //     "user/dashboard/access/" +
    //     userId
    // );
    return axios.get(API_URL + "user/dashboard/access/" + userId, {
      headers: authHeader(),
    });
  }

  getDashboardFeedback(userId) {
    // console.log(API_URL + "user/dashboard/" + userId + "/feedback");
    return axios.get(API_URL + "user/dashboard/" + userId + "/feedback", {
      headers: authHeader(),
    });
  }

  saveDashboardFeedback(data) {
    return axios.post(
      API_URL + "user/dashboard/feedback",
      {
        message: data.message,
      },
      { headers: authHeader() }
    );
  }

  postDashboardFeedbackComment(comment, feedbackId) {
    // console.log(API_URL + "user/feedback/" + feedbackId + "/comment");
    return axios.post(
      API_URL + "user/feedback/" + feedbackId + "/comment",
      comment,
      {
        headers: authHeader(),
      }
    );
  }

  getDashboardFeedbackComments(feedbackId) {
    // console.log(API_URL + "user/feedback/" + feedbackId + "/comment");
    return axios.get(API_URL + "user/feedback/" + feedbackId + "/comment", {
      headers: authHeader(),
    });
  }
}

export default new UserService();
