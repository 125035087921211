<script setup lang="ts">
import { ref } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import { showErrorToast, showToast, valid } from "@/utils";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import CourseSelectionForm from "@/components/practitioner/CourseSelectionForm.vue";
import CourseDetailsForm from "@/components/practitioner/CourseDetailsForm.vue";
import AvailabilitySelector from "@/components/practitioner/AvailabilitySelector";
import SiteVisitForm from "@/components/practitioner/SiteVisitForm.vue";
import Dialog from "primevue/dialog";
import PractitionerService from "@/services/practitioner.service.js";
import { Site } from "@/types";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { useRouter } from "vue-router";
import { useModal } from "vuestic-ui";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const AvailabilityStore = useAvailabilityStore();
const router = useRouter();
const { confirm } = useModal();

const step = ref(0);
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);
const currentUser = AuthStore.auth;
let isVisible = ref(false);
let showAddSiteModel = ref(false);
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let siteAction = ref("");
let emptySite = ref({} as Site);
let currSite = ref({} as Site);
let dataToSave = AvailabilityStore.availability;

const steps = ref([
  { label: "Specify Preferences" },
  { label: "General Details" },
  { label: "Date & Time " },
  { label: "Site Details" },
]);

const stepsChanger = (isSiteVisitSelected) => {
  if (isSiteVisitSelected && steps.value.length === 3) {
    steps.value.splice(3, 0, {
      label: "Site Details",
    });
  } else if (!isSiteVisitSelected && steps.value.length === 4) {
    steps.value.splice(3, 1);
  }
};

const getMaxSiteId = () =>
  dataToSave ? Math.max(...dataToSave?.siteList?.map((el) => el.id)) : -1;

const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),
  courseRequestType: yup
    .array()
    .of(yup.string())
    .required("Please select one of available Course Support Type!")
    .min(1, "Please select one of available Course Support Type!"),
  subjectSpeak: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "subjectSpeak")?.disabled,
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  subjectSpeakOthers: yup
    .array()
    .of(yup.string())
    .when("subjectSpeak", {
      is: (val) => valid(val) && val.includes("Others"),
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().min(0),
    }),
  insDeliveryType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insState: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "insState")?.disabled,
      then: (schema) =>
        schema
          .required("Location of institution is required!")
          .min(1, "Location of institution is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  insName: yup.array().of(yup.string()).optional().min(0),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Academic Level is required!")
          .min(1, "Students' Academic Level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Department or Program of Study is required!")
          .min(1, "Students' Department or Program of Study is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  others: yup.string().optional().nullable(),
  availabilityTime: yup
    .array()
    .of(yup.object())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "availabilityTime")?.disabled,
      then: (schema) => schema.optional().nullable().min(0),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  siteList: yup
    .array()
    .of(yup.object())
    .when("courseRequestType", {
      is: (val) => valid(val) && val.includes("Site Visit"),
      then: (schema) =>
        schema
          .required(
            "Site Details are required. In order to proceed, please provide site details or go to the first step to deselect site visit if you do not want to provide site details!"
          )
          .min(
            1,
            "Site Details are required. In order to proceed, please provide site details or go to the first step to deselect site visit if you do not want to provide site details!"
          ),
      otherwise: (schema) => schema.optional().min(0),
    }),
});

const nextStep = (currStepData) => {
  Object.keys(currStepData).forEach((key) => {
    dataToSave[key] = currStepData[key];
  });
  step.value += 1;
};

const { handleSubmit, validate, values, setValues } = useForm({
  validationSchema: schema,
});

const showPopup = async () => {
  setValues({
    courseRequestType: dataToSave.courseRequestType,
    subjectSpeak: dataToSave.subjectSpeak,
    subjectSpeakOthers: dataToSave.subjectSpeakOthers,
    insDeliveryType: dataToSave.insDeliveryType,
    insState: dataToSave.insState,
    insName: dataToSave.insName,
    academicLevel: dataToSave.academicLevel,
    studentDept: dataToSave.studentDept,
    others: dataToSave.others,
    siteList: dataToSave.siteList,
    availabilityTime: dataToSave.availabilityTime,
    Email: "True",
    phone: currentUser.phone,
    phoneNumber: currentUser.phoneNumber,
  });
  const { valid, errors } = await validate();
  if (valid) isVisible.value = true;
  else Object.values(errors).forEach((error) => showErrorToast(error));
};

const saveAvailability = handleSubmit((data) => {
  // console.log(data);
  // console.log(AvailabilityStore.availability);
  loading.value = true;
  PractitionerService.saveAvailability(data).then(
    () => {
      AuthStore.getProfile().then(
        () => {
          showToast("Your availability has been saved.");
          loading.value = false;
          router.push({ name: "industry-practitioner-feed" });
        },
        (err) => {
          loading.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
});

const saveSite = (action, siteData) => {
  if (action === "create") {
    siteData.id = getMaxSiteId() + 1;
  } else siteData.id = currSite.value.id;
  const index = dataToSave.siteList.findIndex((el) => el.id === siteData.id);
  if (index < 0) dataToSave.siteList.push(siteData);
  else dataToSave.siteList[index] = siteData;
  showAddSiteModel.value = false;
};

const saveAvailabilityTime = (availabilityTime) => {
  if (dataToSave.availabilityTime) dataToSave.availabilityTime.length = 0;
  dataToSave.availabilityTime = availabilityTime;
  if (steps.value.length === 3) showPopup();
  else step.value += 1;
};

const deleteSite = async (site) => {
  const answer = await confirm({
    message: "Do you want to proceed without deleting the selected site?",
    okText: "Confirm",
    cancelText: "Cancel",
  });
  if (answer) {
    const index = dataToSave.siteList.findIndex((el) => el.id === site.id);
    dataToSave.siteList.splice(index, 1); // 2nd parameter means remove one item only
  }
};

if (AvailabilityStore.status) {
  stepsChanger(
    valid(AvailabilityStore.availability.courseRequestType) &&
      AvailabilityStore.availability.courseRequestType.includes("Site Visit")
  );
} else {
  stepsChanger(false);
}
</script>

<style scoped>
.card {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .card {
    padding: 10px 10px;
  }
}
</style>

<template>
  <div class="grid-container">
    <practitioner-sidebar></practitioner-sidebar>
    <div>
      <div class="col-md-12 mt-3">
        <div class="back-grid">
          <back-button></back-button>
          <h2 style="text-align: center; font-weight: bold">
            Type of Course-Support Willing to Provide
          </h2>
        </div>
        <br />
        <va-stepper v-model="step" :steps="steps" controlsHidden nextDisabled>
          <template #step-content-0>
            <span class="font-color-red ml-2 lg:ml-5">* required field</span>
            <course-selection-form
              :availability="dataToSave"
              @site-visit-selected="stepsChanger"
              @next-step="nextStep"
            />
          </template>
          <template #step-content-1>
            <span class="font-color-red ml-2 lg:ml-5">* required field</span>
            <course-details-form
              :availability="dataToSave"
              @next-step="nextStep"
              @previous-step="step -= 1"
            />
          </template>
          <template #step-content-2>
            <availability-selector
              :availability="dataToSave"
              :lastStep="steps.length === 3"
              @next-step="saveAvailabilityTime"
              @previous-step="step -= 1"
            />
          </template>
          <template #step-content-3>
            <div class="flex flex-column lg:flex-row align-items-center">
              <h3 class="bold-text">Site Details</h3>
              <div
                class="py-1 px-2 lg:p-3 card cursor-pointer inline-block flex-shrink-1 flex-grow-0 m-0 mt-2 lg:mt-0"
                style="width: max-content"
                @click="
                  (currSite = emptySite),
                    (siteAction = 'create'),
                    (showAddSiteModel = true)
                "
              >
                <b> Add Site </b>
                <font-awesome-icon
                  class="ml-2"
                  title="Add Site"
                  icon="fa-solid fa-plus"
                />
              </div>
            </div>
            <div
              v-if="AvailabilityStore.availability.siteList.length > 0"
              class="course-item mt-5"
            >
              <div
                class="m-1 card"
                v-for="site in AvailabilityStore.availability.siteList"
                :key="site.city"
              >
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-start;
                  "
                >
                  <div
                    style="
                      white-space: normal;
                      overflow-wrap: break-word;
                      word-break: break-word;
                      margin-right: 1rem;
                    "
                  >
                    <b>{{ site.projectDescr }}</b
                    ><br />
                    {{ site.city }}, {{ site.state }}
                  </div>

                  <!-- Buttons container -->
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      gap: 0.75rem;
                      margin-top: 0.5rem;
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click.prevent="deleteSite(site)"
                    >
                      Delete <span class="d-none d-lg-inline">Site</span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="
                        (currSite = site),
                          (siteAction = 'update'),
                          (showAddSiteModel = true)
                      "
                    >
                      View/Edit
                      <span class="d-none d-lg-inline">Site Details</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </va-stepper>
        <div class="text-align-center mb-3" v-if="step === 3">
          <button
            class="btn btn-secondary ml-2"
            type="button"
            @click="step -= 1"
          >
            Previous
          </button>
          <button class="btn btn-primary ml-2" type="button" @click="showPopup">
            Save Availability
          </button>
        </div>
        <div
          v-if="message"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="showAddSiteModel"
    modal
    header="Site Details"
    style="
      padding: 20px; /* Adjust the padding as needed */
      background-color: #fff; /* Background color for the dialog box */
      border-radius: 8px; /* Optional: rounded corners */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
      max-width: 50vw; /* Optional: set a max width */
      min-width: 50vw; /* Optional: set a max width */
      margin: 0 auto; /* Center horizontally if needed */
    "
    :breakpoints="{ '768px': '90vw' }"
  >
    <site-visit-form
      :action="siteAction"
      :site="currSite"
      @save-site="saveSite"
    />
  </Dialog>
  <Dialog
    v-model:visible="isVisible"
    modal
    header="Contact Preference"
    :style="{ width: '30vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '75vw' }"
  >
    <form
      @submit.prevent="saveAvailability"
      @keydown.enter="$event.preventDefault()"
    >
      <div class="form-group">
        <label for="contactPref"
          >Please select how you would like to be contacted?</label
        >
        <br />
        <Field name="Email" value="True" type="checkbox" disabled />Email
        <br />
        <Field
          value="True"
          unchecked-value="False"
          name="phone"
          type="checkbox"
        />
        Phone
        <br />
        <ErrorMessage name="contactPref" class="error-feedback" />
      </div>

      <div class="form-group" v-if="values.phone === 'True'">
        <label for="phoneNumber">Phone Number</label>
        <Field name="phoneNumber" type="text" class="form-control" />
        <ErrorMessage name="phoneNumber" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button class="btn btn-primary btn-block" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Proceed</span>
        </button>
      </div>
    </form>
  </Dialog>
</template>
