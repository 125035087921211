import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mt-3 min-height" }
const _hoisted_5 = { class: "back-grid" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "form-column" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  class: "form-group",
  style: {"width":"100%","text-align":"center"}
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "spinner-border spinner-border-sm" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "form-group text-align-center" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import AuthService from "@/services/auth.service.js";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import { showErrorToast, showToast } from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsView',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const settingsFields = ConfigStore.config.filter(
  (el) => el.table === "settings"
);
const currentUser = AuthStore.auth;
let loading = ref(false);
let message = ref("");
let visible = ref(false);
let deleteVisible = ref(false);
let deleteFormVisible = ref(false);

const schema = yup.object().shape({
  fields: yup.array().default(settingsFields),
  email: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "email")?.disabled,
    then: (schema) =>
      schema
        .email("Please Enter a valid email address")
        .required("Email is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const deleteSchema = yup.object().shape({
  fields: yup.array().default(settingsFields),
  reasonDelete: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "reasonDelete")?.disabled,
    then: (schema) => schema.required("Email is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const changeEmail = () => {
  visible.value = true;
};

const deleteAccount = () => {
  deleteVisible.value = true;
};

const handleSubmit = (data) => {
  loading.value = true;
  data.roles = currentUser.roles;

  AuthService.changeEmail(data).then(
    (_) => {
      showToast(
        "Please confirm your new email by clicking on the link sent to you"
      );
      if (AuthStore.role === "INSTRUCTOR")
        router.push({ name: "instructor-feed" });
      else if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "industry-practitioner-feed" });
      else router.push("/home");
    },
    (error) => {
      loading.value = false;
      visible.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};

const handleDeleteSubmit = (data) => {
  loading.value = true;
  AuthService.deleteAccount(data).then(
    (_) => {
      showToast("Your account has been successfully deleted");
      AuthStore.logout();
      router.push("/");
    },
    (error) => {
      loading.value = false;
      visible.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(currentUser).roles === 'INDUSTRYPRACTITIONER')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(PractitionerSidebar)
          ]))
        : (_unref(currentUser).roles === 'INSTRUCTOR')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(InstructorSidebar)
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(BackButton),
          _cache[4] || (_cache[4] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Your Account Settings ", -1))
        ]),
        (!_unref(settingsFields).find((el) => el.name === 'email')?.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "mt-5" }, "Change email address", -1)),
              _cache[6] || (_cache[6] = _createElementVNode("label", { for: "username" }, "Current Email", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(Field), {
                    name: "email",
                    disabled: "",
                    type: "text",
                    class: "form-control px-4",
                    value: _unref(currentUser).email
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("button", {
                  class: "btn btn-primary btn-block",
                  style: {"width":"40%","min-width":"max-content"},
                  onClick: changeEmail
                }, " Update email address ")
              ])
            ]))
          : _createCommentVNode("", true),
        (
          !_unref(settingsFields).find((el) => el.name === 'reasonDelete')?.disabled
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "mt-5" }, "Delete Account", -1)),
              _createElementVNode("button", {
                class: "btn btn-danger btn-block mt-3",
                style: {"width":"max-content"},
                onClick: deleteAccount
              }, " Delete Account ")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      modal: "",
      draggable: false,
      header: "Change email",
      style: { width: '60vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmit,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            _cache[9] || (_cache[9] = _createTextVNode(" Your current email is ")),
            _createElementVNode("i", null, _toDisplayString(_unref(currentUser).email), 1),
            _cache[10] || (_cache[10] = _createTextVNode(". What would you like to update it to? ")),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(Field), {
                name: "email",
                type: "email",
                class: "form-control"
              })
            ]),
            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_13, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("span", null, "Change email address", -1))
              ], 8, _hoisted_12)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(deleteVisible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(deleteVisible) ? (deleteVisible).value = $event : deleteVisible = $event)),
      modal: "",
      header: "Delete Account",
      style: { width: '60vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _cache[15] || (_cache[15] = _createTextVNode(" Are you sure you want to delete your account? Deleting your account will remove all your information from this platform including your course-support requests. The action is irreversible. If you delete your account, to use this platform again you would have to create a new account. ")),
        _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
        _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
        _cache[19] || (_cache[19] = _createTextVNode(" Do you want to proceed to delete your account?   ")),
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(deleteVisible) //@ts-ignore
 ? deleteVisible.value = false : deleteVisible = false))
        }, "No"),
        _cache[20] || (_cache[20] = _createTextVNode("   ")),
        _createElementVNode("button", {
          class: "btn btn-secondary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(deleteFormVisible) //@ts-ignore
 ? deleteFormVisible.value = true : deleteFormVisible = true))
        }, " Yes "),
        (_unref(deleteFormVisible))
          ? (_openBlock(), _createBlock(_unref(Form), {
              key: 0,
              onSubmit: handleDeleteSubmit,
              "validation-schema": _unref(deleteSchema)
            }, {
              default: _withCtx(({ meta }) => [
                _createElementVNode("div", _hoisted_14, [
                  _cache[12] || (_cache[12] = _createElementVNode("label", { for: "reasonDelete" }, "Please provide a reason for deleting the account", -1)),
                  _createVNode(_unref(Field), {
                    name: "reasonDelete",
                    type: "text",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "reasonDelete",
                    class: "error-feedback"
                  })
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-block",
                    disabled: _unref(loading) || !meta.valid
                  }, [
                    _withDirectives(_createElementVNode("span", _hoisted_17, null, 512), [
                      [_vShow, _unref(loading)]
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("span", null, "Delete", -1))
                  ], 8, _hoisted_16)
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})