<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Field, useForm } from "vee-validate";
import { showErrorToast } from "@/utils";

const router = useRouter();
const { handleSubmit } = useForm();

const MIN_TEXT_MAX_LENGTH = 200;
const searchParameter = ref("");

const onInputWithNotification = (maxLength: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    searchParameter.value = target.value;
    showErrorToast(`Maximum character limit reached`);
  } else {
    searchParameter.value = value;
    if (value.length === maxLength) {
      showErrorToast(`Maximum character limit reached`);
    }
  }
};

const search = handleSubmit(() => {
  if (searchParameter.value.length > 0) {
    router.push({ name: "search", params: { keyword: searchParameter.value } });
  }
});
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5em;
  padding: 1em;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-wrapper {
  position: relative;
  display: inline-block;
}

.search-bar {
  margin: 0.5em 0 0.5em 0.5em;
  width: 20em;
  border: none;
  background-color: var(--primary-background-color);
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  /* Add padding to the right so text doesn't overlap the button */
  padding-right: 2.5em;
}

.search-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  color: black;
  background-color: var(--primary-background-color);
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  cursor: pointer;
}

.search-button:hover {
  color: black;
  background: rgba(15, 20, 25, 0.1);
}

@media (max-width: 768px) {
  .search-bar {
    width: 10em;
  }
}
</style>

<template>
  <div class="container">
    <form @submit="search" class="search-container">
      <div class="search-wrapper">
        <Field
          name="searchParameter"
          v-model="searchParameter"
          type="text"
          placeholder="Search ConPEC"
          class="search-bar"
          :maxlength="MIN_TEXT_MAX_LENGTH"
          @input="onInputWithNotification(MIN_TEXT_MAX_LENGTH, $event)"
        />
        <button type="submit" class="search-button button" title="Search">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </button>
      </div>
    </form>
    <div>
      {{ searchParameter.length }} / {{ MIN_TEXT_MAX_LENGTH }} characters
    </div>
  </div>
</template>
