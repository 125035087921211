import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow } from "vue"
import _imports_0 from '@/assets/images/uploads/general_resource.jpg'
import _imports_1 from '@/assets/images/uploads/pdf.png'
import _imports_2 from '@/assets/images/uploads/ppt.png'
import _imports_3 from '@/assets/images/uploads/docx.png'
import _imports_4 from '@/assets/images/uploads/txt.png'


const _hoisted_1 = { class: "flex flex-direction-column lg:flex-row" }
const _hoisted_2 = { class: "mr-6" }
const _hoisted_3 = { class: "my-3 d-flex align-items-center" }
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = { class: "ml-3" }
const _hoisted_6 = { class: "ml-3" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "min-h" }
const _hoisted_10 = { class: "gallery" }
const _hoisted_11 = {
  key: 0,
  class: "image-container"
}
const _hoisted_12 = ["src", "alt", "onClick"]
const _hoisted_13 = ["href"]
const _hoisted_14 = ["alt"]
const _hoisted_15 = {
  key: 1,
  class: "video-container"
}
const _hoisted_16 = {
  key: 0,
  controls: ""
}
const _hoisted_17 = ["src", "type"]
const _hoisted_18 = ["href"]
const _hoisted_19 = ["alt"]
const _hoisted_20 = {
  key: 2,
  class: "document-container"
}
const _hoisted_21 = {
  key: 0,
  class: "doc-image-container"
}
const _hoisted_22 = {
  key: 0,
  src: _imports_1,
  class: "doc-image"
}
const _hoisted_23 = {
  key: 1,
  src: _imports_2,
  class: "doc-image"
}
const _hoisted_24 = {
  key: 2,
  src: _imports_3,
  class: "doc-image"
}
const _hoisted_25 = {
  key: 3,
  src: _imports_4,
  class: "doc-image"
}
const _hoisted_26 = {
  key: 1,
  class: "doc-image-container overflow-hidden"
}
const _hoisted_27 = ["href"]
const _hoisted_28 = ["alt"]
const _hoisted_29 = { class: "desc" }
const _hoisted_30 = ["href"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "paginator" }
const _hoisted_34 = { style: {"display":"grid","grid-template-columns":"3fr 1fr"} }
const _hoisted_35 = { style: {"text-align":"right"} }
const _hoisted_36 = ["href"]
const _hoisted_37 = {
  class: "flex justify-content-center",
  style: {"height":"70vh"}
}
const _hoisted_38 = ["src"]
const _hoisted_39 = { class: "form-group" }
const _hoisted_40 = { class: "form-group text-align-center" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { class: "spinner-border spinner-border-sm" }
const _hoisted_43 = { class: "form-group" }
const _hoisted_44 = { class: "form-group text-align-center" }
const _hoisted_45 = ["disabled"]
const _hoisted_46 = { class: "spinner-border spinner-border-sm" }

import { ref, watch, onMounted, computed } from "vue";
import { Upload } from "@/types";
import UserService from "@/services/user.service.js";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { showToast, websiteURL, showErrorToast } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const MIN_TEXT_MAX_LENGTH = 100;


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourcesListTabs',
  props: {
    type: {}
  },
  setup(__props: any) {

const AuthStore = useAuthStore();

const showCustomContent = ref(false);
const downloadSrc = ref("");
const imgSrc = ref("");
const imgDesc = ref("");

const currentUser = AuthStore.auth;
const contentId = ref(-1);
const visible = ref(false);
const reportDialogVisible = ref(false);
const searchParameter = ref("");
const loading = ref(false);
const message = ref("");
const size = ref(10);

const picturesData = ref<Upload[]>([]);
const videosData = ref<Upload[]>([]);
const documentsData = ref<Upload[]>([]);

const showPictures = ref(true);
const showVideos = ref(true);
const showDocuments = ref(true);

const currentPage = ref(1);
const totalPages = ref(1);

const formatLink = (link: string) => {
  if (!link.startsWith("http://") && !link.startsWith("https://")) {
    return `https://${link}`;
  }
  return link;
};

const onInputWithNotification = (maxLength: number, event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

const props = __props;

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const reportDialogSchema = yup.object().shape({
  reasonReport: yup
    .string()
    .required("Reason for reporting content is required!"),
});

onMounted(async () => {
  loading.value = true;
  try {
    const [picsRes, vidsRes, docsRes] = await Promise.all([
      UserService.getPublicResources(1, "Project Pictures", size.value),
      UserService.getPublicResources(1, "Video", size.value),
      UserService.getPublicResources(1, "Document", size.value),
    ]);

    picturesData.value = picsRes.data.uploads.map((u) => ({
      ...u,
      type: "Picture",
    }));
    videosData.value = vidsRes.data.uploads.map((u) => ({
      ...u,
      type: "Video",
    }));
    documentsData.value = docsRes.data.uploads.map((u) => ({
      ...u,
      type: "Document",
    }));

    // totalPages derived from local pagination now
    // We'll compute it dynamically below.
  } catch (err: any) {
    message.value = err.message || err.toString();
  } finally {
    loading.value = false;
  }
});

// Combine and filter data
const filteredData = computed(() => {
  let allData: Upload[] = [];
  if (showPictures.value) allData = allData.concat(picturesData.value);
  if (showVideos.value) allData = allData.concat(videosData.value);
  if (showDocuments.value) allData = allData.concat(documentsData.value);

  if (searchParameter.value.trim().length > 0) {
    const query = searchParameter.value.toLowerCase();
    allData = allData.filter((item) =>
      item.description.toLowerCase().includes(query)
    );
  }

  return allData;
});

// Apply local pagination
const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * size.value;
  const end = start + size.value;
  return filteredData.value.slice(start, end);
});

// Watch changes to filteredData or size to update totalPages
watch(
  () => [filteredData.value.length, size.value],
  () => {
    totalPages.value = Math.ceil(filteredData.value.length / size.value) || 1;
    // Ensure currentPage is not out of range
    if (currentPage.value > totalPages.value) {
      currentPage.value = totalPages.value;
    }
  },
  { immediate: true }
);

const handleSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.deleteContent(data).then(
    () => {
      showToast("Content deleted successfully.");
      visible.value = false;
      loading.value = false;
      // Remove deleted item locally
      picturesData.value = picturesData.value.filter(
        (i) => i.id !== contentId.value
      );
      videosData.value = videosData.value.filter(
        (i) => i.id !== contentId.value
      );
      documentsData.value = documentsData.value.filter(
        (i) => i.id !== contentId.value
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleReportDialogSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.reportContent(data).then(
    () => {
      showToast("Content Reported successfully.");
      reportDialogVisible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const changePageSize = () => {
  // Reset to page 1 or just let watchers handle it
  currentPage.value = 1;
};

const showImage = (src: string, desc: string, downloadPath: string) => {
  imgSrc.value = src;
  imgDesc.value = desc;
  downloadSrc.value = downloadPath;
  showCustomContent.value = true;
};

return (_ctx: any,_cache: any) => {
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          name: "searchParameter",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParameter).value = $event)),
          type: "text",
          placeholder: "Search Resources",
          class: "search-bar",
          maxlength: MIN_TEXT_MAX_LENGTH,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (onInputWithNotification(MIN_TEXT_MAX_LENGTH, $event)))
        }, null, 544), [
          [_vModelText, searchParameter.value]
        ]),
        _createTextVNode(" " + _toDisplayString(searchParameter.value ? searchParameter.value.length : 0) + " / " + _toDisplayString(MIN_TEXT_MAX_LENGTH) + " characters ", 1)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_unref(Field), {
          name: "size",
          as: "select",
          class: "dropdown w-auto ml-auto mr-3",
          modelValue: size.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((size).value = $event)),
          onChange: changePageSize
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("option", { value: "10" }, "10", -1),
            _createElementVNode("option", { value: "20" }, "20", -1),
            _createElementVNode("option", { value: "30" }, "30", -1),
            _createElementVNode("option", { value: "40" }, "40", -1),
            _createElementVNode("option", { value: "50" }, "50", -1)
          ])),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showPictures).value = $event))
        }, null, 512), [
          [_vModelCheckbox, showPictures.value]
        ]),
        _cache[11] || (_cache[11] = _createTextVNode(" Pictures "))
      ]),
      _createElementVNode("label", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showVideos).value = $event))
        }, null, 512), [
          [_vModelCheckbox, showVideos.value]
        ]),
        _cache[12] || (_cache[12] = _createTextVNode(" Videos "))
      ]),
      _createElementVNode("label", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showDocuments).value = $event))
        }, null, 512), [
          [_vModelCheckbox, showDocuments.value]
        ]),
        _cache[13] || (_cache[13] = _createTextVNode(" Documents "))
      ])
    ]),
    (paginatedData.value.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[14] || (_cache[14] = [
          _createElementVNode("h5", {
            style: {"margin":"1em"},
            class: "text-align-center"
          }, "No Resources Found", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedData.value, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "responsive",
                key: item.id
              }, [
                _createElementVNode("div", _hoisted_10, [
                  (item.type === 'Picture')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        (item.filePath)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: _unref(websiteURL) + item.filePath.substring(9),
                              alt: item.file,
                              onClick: ($event: any) => (
                showImage(
                  _unref(websiteURL) + item.filePath.substring(9),
                  item.description,
                  _unref(websiteURL) + item.origFilePath.substring(9)
                )
              )
                            }, null, 8, _hoisted_12))
                          : (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              href: formatLink(item.linkResources),
                              target: "_blank"
                            }, [
                              _createElementVNode("img", {
                                src: _imports_0,
                                alt: item.linkResources
                              }, null, 8, _hoisted_14)
                            ], 8, _hoisted_13))
                      ]))
                    : (item.type === 'Video')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (item.filePath)
                            ? (_openBlock(), _createElementBlock("video", _hoisted_16, [
                                _createElementVNode("source", {
                                  src: _unref(websiteURL) + item.filePath.substring(9),
                                  type: item['mimetype']
                                }, null, 8, _hoisted_17)
                              ]))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: formatLink(item.linkResources),
                                target: "_blank"
                              }, [
                                _createElementVNode("img", {
                                  src: _imports_0,
                                  alt: item.linkResources
                                }, null, 8, _hoisted_19)
                              ], 8, _hoisted_18))
                        ]))
                      : (item.type === 'Document')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            (item.filePath)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                  (item.mimetype === 'application/pdf')
                                    ? (_openBlock(), _createElementBlock("img", _hoisted_22))
                                    : (
                  [
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/mspowerpoint',
                  ].includes(item.mimetype)
                )
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_23))
                                      : (
                  [
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/msword',
                  ].includes(item.mimetype)
                )
                                        ? (_openBlock(), _createElementBlock("img", _hoisted_24))
                                        : (_openBlock(), _createElementBlock("img", _hoisted_25))
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                  _createElementVNode("a", {
                                    href: formatLink(item.linkResources),
                                    target: "_blank"
                                  }, [
                                    _createElementVNode("img", {
                                      src: _imports_0,
                                      alt: item.linkResources,
                                      style: {"width":"100%","height":"100%","overflow":"hidden"}
                                    }, null, 8, _hoisted_28)
                                  ], 8, _hoisted_27)
                                ]))
                          ]))
                        : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("p", null, _toDisplayString(item.description.slice(0, 25) +
                (item.description.length > 25 ? "..." : "")), 1),
                    (!JSON.parse(item.visibility).includes('All'))
                      ? (_openBlock(), _createBlock(_unref(FontAwesomeIcon), {
                          key: 0,
                          icon: "fa-solid fa-lock",
                          class: "ml-1 mr-2",
                          title: "This content is restricted."
                        }))
                      : _createCommentVNode("", true),
                    (props.type === 'public' && item.filePath)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          href: _unref(websiteURL) + item.origFilePath.substring(9),
                          download: "",
                          title: "Download",
                          style: {"color":"var(--bs-body-color)"}
                        }, [
                          _createVNode(_unref(FontAwesomeIcon), { icon: "fa-solid fa-download" })
                        ], 8, _hoisted_30))
                      : _createCommentVNode("", true),
                    (props.type === 'private')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 2,
                          onClick: ($event: any) => ((visible.value = true), (contentId.value = item.id)),
                          style: {"color":"var(--bs-body-color)","border":"none"},
                          class: "bg-transparent"
                        }, [
                          _createVNode(_unref(FontAwesomeIcon), { icon: "fa-solid fa-trash" })
                        ], 8, _hoisted_31))
                      : _createCommentVNode("", true),
                    (props.type === 'public')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          onClick: ($event: any) => ((reportDialogVisible.value = true), (contentId.value = item.id)),
                          style: {"color":"var(--bs-body-color)","border":"none"},
                          class: "ml-2 bg-transparent",
                          title: "Flag as Inappropriate"
                        }, [
                          _createVNode(_unref(FontAwesomeIcon), { icon: "fa-solid fa-flag" })
                        ], 8, _hoisted_32))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ]),
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "clearfix" }, null, -1))
        ])),
    _createElementVNode("div", _hoisted_33, [
      _createVNode(_component_va_pagination, {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((currentPage).value = $event)),
        pages: totalPages.value,
        "visible-pages": 3,
        hideOnSinglePage: true
      }, null, 8, ["modelValue", "pages"])
    ]),
    _createVNode(_unref(Dialog), {
      visible: showCustomContent.value,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((showCustomContent).value = $event)),
      modal: "",
      header: imgDesc.value,
      style: { width: '50vw', padding: '20px', backgroundColor: '#fff' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_34, [
          _cache[16] || (_cache[16] = _createElementVNode("div", null, null, -1)),
          _createElementVNode("div", _hoisted_35, [
            (_unref(currentUser).roles === 'INSTRUCTOR')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: downloadSrc.value,
                  download: "",
                  title: "Download",
                  style: {"color":"var(--bs-body-color)"}
                }, [
                  _createVNode(_unref(FontAwesomeIcon), { icon: "fa-solid fa-download" })
                ], 8, _hoisted_36))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("img", {
            src: imgSrc.value,
            class: "model-img",
            autofocus: ""
          }, null, 8, _hoisted_38)
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_unref(Dialog), {
      visible: visible.value,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((visible).value = $event)),
      modal: "",
      header: " ",
      style: { width: '30vw', padding: '20px', backgroundColor: '#fff' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmission,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_39, [
              _cache[17] || (_cache[17] = _createElementVNode("label", { for: "reasonDelete" }, "Please provide a reason for deleting the uploaded content", -1)),
              _createVNode(_unref(Field), {
                name: "reasonDelete",
                type: "text",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "reasonDelete",
                class: "error-feedback"
              })
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: loading.value || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_42, null, 512), [
                  [_vShow, loading.value]
                ]),
                _cache[18] || (_cache[18] = _createElementVNode("span", null, "Delete", -1))
              ], 8, _hoisted_41)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: reportDialogVisible.value,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((reportDialogVisible).value = $event)),
      modal: "",
      header: " ",
      style: { width: '41vw', padding: '20px', backgroundColor: '#fff' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleReportDialogSubmission,
          "validation-schema": _unref(reportDialogSchema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_43, [
              _cache[20] || (_cache[20] = _createElementVNode("label", { for: "reasonReport" }, "Please provide a reason for reporting the uploaded content", -1)),
              _createVNode(_unref(Field), {
                name: "reasonReport",
                type: "text",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "reasonReport",
                class: "error-feedback"
              })
            ]),
            _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: loading.value || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_46, null, 512), [
                  [_vShow, loading.value]
                ]),
                _cache[21] || (_cache[21] = _createElementVNode("span", null, "Report", -1))
              ], 8, _hoisted_45)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})