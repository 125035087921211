import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "card card-container" }
const _hoisted_2 = {
  key: 0,
  class: "form-group"
}
const _hoisted_3 = {
  key: 1,
  class: "form-group"
}
const _hoisted_4 = { class: "char-count" }
const _hoisted_5 = {
  key: 2,
  class: "text-align-center bold-text"
}
const _hoisted_6 = {
  key: 3,
  class: "form-group"
}
const _hoisted_7 = ["checked"]
const _hoisted_8 = {
  key: 4,
  class: "form-group"
}
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  key: 5,
  class: "text-align-center bold-text"
}
const _hoisted_11 = {
  key: 6,
  class: "form-group"
}
const _hoisted_12 = ["checked"]
const _hoisted_13 = {
  key: 7,
  class: "form-group"
}
const _hoisted_14 = ["checked"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "char-count" }
const _hoisted_17 = { class: "text-align-center mb-3" }

import { ref, watch } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { onBeforeRouteLeave } from "vue-router";
import { useModal } from "vuestic-ui";
import {
  insDeliveryTypeOptions,
  academicLevelOptions,
  stateOptions,
  deptOptions,
  univOptions,
  displayLabels,
  validateOptAll,
  validateOptNoPref,
  showErrorToast,
} from "@/utils";
import { Availability } from "@/types";

// Define Props
const TEXT_MAX_LENGTH_500 = 500;
const TEXT_MAX_LENGTH_100 = 100;

// Input Handler with Notification

export default /*@__PURE__*/_defineComponent({
  __name: 'CourseDetailsForm',
  props: {
    availability: {}
  },
  emits: ["previousStep", "nextStep"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// Define Emits
const emits = __emit;

// Modal for confirmation
const { confirm } = useModal();

// Access Stores
const ConfigStore = useConfigStore();
const AvailabilityStore = useAvailabilityStore();

// Define Options
const academicLevelOptionsAdded = [
  { label: "No preference", value: "No preference" },
  ...academicLevelOptions,
];

// Ensure "Others, please specify" is included only once
const deptOptionsAddedBase = [
  { label: "No preference", value: "No preference" },
  ...deptOptions,
  { label: "Others, please specify", value: "Others, please specify" },
];

// Function to merge preselected departments and handle custom departments
const getDeptOptionsAdded = () => {
  const preSelectedDepts = AvailabilityStore.status
    ? props.availability.studentDept || []
    : [];
  const predefinedDepts = preSelectedDepts.filter((dept) =>
    deptOptionsAddedBase.some((option) => option.value === dept)
  );
  const customDepts = preSelectedDepts.filter(
    (dept) => !deptOptionsAddedBase.some((option) => option.value === dept)
  );

  let deptOptionsAdded = [...deptOptionsAddedBase];

  return deptOptionsAdded;
};

const deptOptionsAdded = getDeptOptionsAdded();

// Define Additional Options
const stateOptionsAdded = [{ label: "All", value: "All" }, ...stateOptions];

const univOptionsAdded = [{ label: "All", value: "All" }, ...univOptions];

// Filter availability fields from config
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);

// Define Constants
const onInputWithNotification = (maxLength: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  let value = target.value;
  // Prevent input from being "Others, please specify"
  if (value.trim().toLowerCase() === "others, please specify") {
    showErrorToast(
      `'Others, please specify' is a reserved option and cannot be used here.`
    );
    target.value = "";
    return;
  }
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  }
};

// Define Validation Schema
const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),

  insDeliveryType: yup.string().when("fields", {
    is: (val: any[]) =>
      !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  others: yup.string().optional().nullable(),

  insState: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) => !val.find((el) => el.name === "insState")?.disabled,
      then: (schema) =>
        schema
          .required("Location of institution is required!")
          .min(1, "Location of institution is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  insName: yup.array().of(yup.string()).optional().min(0),

  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) =>
        !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Academic Level is required!")
          .min(1, "Students' Academic Level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) =>
        !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Department or Program of Study is required!")
          .min(1, "Students' Department or Program of Study is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  studentDept_text: yup.string().when("studentDept", {
    is: (studentDept: string[]) =>
      studentDept.includes("Others, please specify"),
    then: (schema) =>
      schema
        .required("Please specify your department or program of study.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

// Setup Initial Values
let initialState = {
  insDeliveryType: "",
  others: "",
  insState: [] as string[],
  insName: [] as string[],
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
};

// If the store is populated, load initial values
if (AvailabilityStore.status) {
  const preSelectedDepts = props.availability.studentDept || [];
  const predefinedDepts = preSelectedDepts.filter((dept) =>
    deptOptionsAdded.some((option) => option.value === dept)
  );
  const customDepts = preSelectedDepts.filter(
    (dept) => !deptOptionsAdded.some((option) => option.value === dept)
  );

  // Initialize studentDept with predefined departments
  initialState.studentDept = [...predefinedDepts];

  // If there are custom departments, add "Others, please specify" and set the text field
  if (customDepts.length > 0) {
    if (!initialState.studentDept.includes("Others, please specify")) {
      initialState.studentDept.push("Others, please specify");
    }
    initialState.studentDept_text = customDepts.join(", ");
  }

  initialState.insDeliveryType = props.availability.insDeliveryType || "";
  initialState.others = props.availability.others || "";
  initialState.insState = props.availability.insState || [];
  initialState.insName = props.availability.insName || [];
  initialState.academicLevel = props.availability.academicLevel || [];
  // studentDept and studentDept_text are already set above
}

// Initialize form values
const formValues = {
  insDeliveryType: initialState.insDeliveryType,
  others: initialState.others,
  insState: initialState.insState,
  insName: initialState.insName,
  academicLevel: initialState.academicLevel,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
};

// Initialize Form
const { handleSubmit, errors, values, meta, validate, resetForm } = useForm({
  validationSchema: schema,
  initialValues: formValues,
});

// Function to handle studentDept change
const handleStudentDeptChange = (selected) => {
  // No additional logic needed as "Others, please specify" is managed by the watcher below
};

// Watch for Changes in studentDept to Manage studentDept_text
watch(
  () => values.studentDept,
  (newVal) => {
    if (newVal.includes("Others, please specify")) {
      // Ensure studentDept_text is present if "Others, please specify" is selected
      if (!values.studentDept_text) {
        values.studentDept_text = "";
      }
    } else {
      // Clear studentDept_text if "Others, please specify" is not selected
      values.studentDept_text = "";
    }
  },
  { immediate: true }
);

// Function to Check if Form is Dirty and Confirm Navigation
const checkDirty = async () => {
  if (meta.value.dirty) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    return answer;
  }
  return true;
};

// Handle Form Submission
const saveCourseDetails = handleSubmit(
  (data) => {
    if (
      data.studentDept &&
      data.studentDept.includes("Others, please specify")
    ) {
      // Replace "Others, please specify" with the specified text
      const othersIndex = data.studentDept.indexOf("Others, please specify");
      if (othersIndex !== -1) {
        // Split the text field by comma and trim spaces
        const customDepts = data.studentDept_text
          .split(",")
          .map((dept) => dept.trim())
          .filter((dept) => dept.length > 0);

        // Replace "Others, please specify" with custom departments
        data.studentDept.splice(othersIndex, 1, ...customDepts);
      }
      delete data.studentDept_text;
    }
    emits("nextStep", data);
  },
  (formErrors) => {
    Object.values(formErrors.errors).forEach((error) => showErrorToast(error));
  }
);

// Handle Navigation to Previous Step
const goToPreviousStep = async () => {
  const answer = await checkDirty();
  if (!answer) return false;
  emits("previousStep");
};

// Prevent Navigation Away if Form is Dirty
onBeforeRouteLeave(async (to, from) => {
  const answer = await checkDirty();
  if (!answer) return false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onKeydown: _cache[8] || (_cache[8] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
    class: "mt-3 lg:mx-3"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "bold-text ml-3 text-align-center" }, [
        _createTextVNode(" Please provide further details based on your earlier selections "),
        _createElementVNode("br"),
        _createElementVNode("br")
      ], -1)),
      _createElementVNode("div", null, [
        (
            !_unref(availabilityFields).find((el) => el.name === 'insDeliveryType')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[9] || (_cache[9] = _createElementVNode("label", { for: "insDeliveryType" }, [
                _createTextVNode(" Instruction Delivery Mode "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "insDeliveryType",
                type: "text",
                class: "dropdown"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).insDeliveryType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(values).insDeliveryType) = $event)),
                    options: _unref(insDeliveryTypeOptions),
                    mode: "single",
                    searchable: true,
                    placeholder: "Select Delivery Mode"
                  }), null, 16, ["modelValue", "options"])
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "insDeliveryType",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(availabilityFields).find((el) => el.name === 'insDeliveryType')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "others" }, "Other Comment/Information", -1)),
              _createVNode(_unref(Field), {
                name: "others",
                type: "text",
                class: "form-control"
              }, {
                default: _withCtx(({ field }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    maxlength: TEXT_MAX_LENGTH_500,
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_500, $event))),
                    placeholder: "Enter additional comments or information"
                  }), null, 16)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, _toDisplayString((_unref(values).others || "").length) + " / " + _toDisplayString(TEXT_MAX_LENGTH_500) + " characters ", 1),
              _createVNode(_unref(ErrorMessage), {
                name: "others",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
        (
            !_unref(availabilityFields).find((el) => el.name === 'insState')
              ?.disabled &&
            !_unref(availabilityFields).find((el) => el.name === 'insName')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Preference Definition (Institution-related) "))
          : _createCommentVNode("", true),
        (
            !_unref(availabilityFields).find((el) => el.name === 'insState')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[11] || (_cache[11] = _createElementVNode("label", { for: "insState" }, [
                _createTextVNode(" Location of Institution "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "insState",
                type: "text",
                class: "form-control"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).insState,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(values).insState) = $event)),
                    options: stateOptionsAdded,
                    mode: "tags",
                    placeholder: "Select State(s)",
                    multipleLabel: _unref(displayLabels),
                    searchable: true,
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false,
                    onChange: _unref(validateOptAll)
                  }), {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("input", {
                        type: "checkbox",
                        class: "input-pointer",
                        checked: _unref(values).insState?.includes(option.value)
                      }, null, 8, _hoisted_7),
                      _createTextVNode("   " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1040, ["modelValue", "multipleLabel", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "insState",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(availabilityFields).find((el) => el.name === 'insName')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { for: "insName" }, "Name of Institution", -1)),
              _createVNode(_unref(Field), {
                name: "insName",
                type: "text",
                class: "form-control"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).insName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(values).insName) = $event)),
                    options: univOptionsAdded,
                    mode: "tags",
                    placeholder: "Select Institution(s)",
                    multipleLabel: _unref(displayLabels),
                    searchable: true,
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false,
                    onChange: _unref(validateOptAll)
                  }), {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("input", {
                        type: "checkbox",
                        class: "input-pointer",
                        checked: _unref(values).insName?.includes(option.value)
                      }, null, 8, _hoisted_9),
                      _createTextVNode("   " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1040, ["modelValue", "multipleLabel", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "insName",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
        (
            !_unref(availabilityFields).find((el) => el.name === 'academicLevel')
              ?.disabled &&
            !_unref(availabilityFields).find((el) => el.name === 'studentDept')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Preference Definition (Students-related) "))
          : _createCommentVNode("", true),
        (
            !_unref(availabilityFields).find((el) => el.name === 'academicLevel')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[13] || (_cache[13] = _createElementVNode("label", { for: "academicLevel" }, [
                _createTextVNode(" Students' Academic Level "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "academicLevel",
                type: "text",
                class: "form-control"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).academicLevel,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(values).academicLevel) = $event)),
                    options: academicLevelOptionsAdded,
                    mode: "tags",
                    multipleLabel: _unref(displayLabels),
                    searchable: true,
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false,
                    onChange: _unref(validateOptNoPref)
                  }), {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("input", {
                        type: "checkbox",
                        class: "input-pointer",
                        checked: _unref(values).academicLevel?.includes(option.value)
                      }, null, 8, _hoisted_12),
                      _createTextVNode("   " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1040, ["modelValue", "multipleLabel", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "academicLevel",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(availabilityFields).find((el) => el.name === 'studentDept')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _cache[15] || (_cache[15] = _createElementVNode("label", { for: "studentDept" }, [
                _createTextVNode(" Students' Department or Program of Study "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "studentDept",
                type: "text",
                class: "form-control"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).studentDept,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(values).studentDept) = $event)),
                    options: _unref(deptOptionsAdded),
                    mode: "tags",
                    placeholder: "Select Department(s)",
                    multipleLabel: _unref(displayLabels),
                    searchable: true,
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false,
                    allowCreate: false,
                    onChange: handleStudentDeptChange
                  }), {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("input", {
                        type: "checkbox",
                        class: "input-pointer",
                        checked: _unref(values).studentDept.includes(option.value)
                      }, null, 8, _hoisted_14),
                      _createTextVNode("   " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1040, ["modelValue", "options", "multipleLabel"])
                ]),
                _: 1
              }),
              (_unref(values).studentDept.includes('Others, please specify'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", {
                      for: "studentDept_text",
                      class: "mt-2"
                    }, " Others, please specify ", -1)),
                    _createVNode(_unref(Field), {
                      name: "studentDept_text",
                      type: "text",
                      placeholder: "Please specify your department or program of study",
                      class: "form-control"
                    }, {
                      default: _withCtx(({ field }) => [
                        _createElementVNode("input", _mergeProps(field, {
                          class: "form-control",
                          maxlength: TEXT_MAX_LENGTH_100,
                          onInput: _cache[6] || (_cache[6] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_100, $event)))
                        }), null, 16)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_16, _toDisplayString((_unref(values).studentDept_text || "").length) + " / " + _toDisplayString(TEXT_MAX_LENGTH_100) + " characters ", 1),
                    _createVNode(_unref(ErrorMessage), {
                      name: "studentDept_text",
                      class: "error-feedback"
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "studentDept",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("button", {
        class: "btn btn-secondary ml-2",
        type: "button",
        onClick: goToPreviousStep
      }, " Previous "),
      _createElementVNode("button", {
        class: "btn btn-primary ml-2",
        type: "button",
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_unref(saveCourseDetails) && _unref(saveCourseDetails)(...args)))
      }, " Next ")
    ])
  ], 32))
}
}

})