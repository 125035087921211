<template>
  <Form
    @submit="saveCourse"
    :validation-schema="schema"
    v-slot="{ values }"
    :initial-values="formValues"
  >
    <div class="form-column">
      <!-- Course Code Field -->
      <div
        class="form-group"
        v-if="!classFields.find((el) => el.name === 'courseCode')?.disabled"
      >
        <label for="courseCode">
          Course Code <span class="super font-color-red">*</span>
        </label>
        <Field name="courseCode" v-slot="{ field }">
          <input
            v-bind="field"
            class="form-control"
            type="text"
            :maxlength="TEXT_MAX_LENGTH_10"
            @input="onInputWithNotification(TEXT_MAX_LENGTH_10, $event)"
          />
          <!-- Character Count -->
          {{ field.value ? field.value.length : 0 }}
          / {{ TEXT_MAX_LENGTH_10 }} characters
          <ErrorMessage name="courseCode" class="error-feedback" />
        </Field>
      </div>

      <!-- Course Title Field -->
      <div
        class="form-group"
        v-if="!classFields.find((el) => el.name === 'courseTitle')?.disabled"
      >
        <label for="courseTitle">
          Course Title <span class="super font-color-red">*</span>
        </label>
        <Field name="courseTitle" v-slot="{ field }">
          <input
            v-bind="field"
            class="form-control"
            type="text"
            :maxlength="TEXT_MAX_LENGTH_250"
            @input="onInputWithNotification(TEXT_MAX_LENGTH_250, $event)"
          />
          <!-- Character Count -->
          {{ field.value ? field.value.length : 0 }}
          / {{ TEXT_MAX_LENGTH_250 }} characters
          <ErrorMessage name="courseTitle" class="error-feedback" />
        </Field>
      </div>
    </div>

    <!-- Additional Information Field -->
    <div
      class="form-group"
      v-if="
        !classFields.find((el) => el.name === 'additionalInformation')?.disabled
      "
    >
      <label for="additionalInformation">
        Link to Additional Course Information
      </label>
      <Field name="additionalInformation" v-slot="{ field }">
        <input
          v-bind="field"
          class="form-control"
          type="text"
          placeholder="https://"
          :maxlength="TEXT_MAX_LENGTH_2000"
          @input="onInputWithNotification(TEXT_MAX_LENGTH_2000, $event)"
        />
        <!-- Character Count -->
        {{ field.value ? field.value.length : 0 }}
        / {{ TEXT_MAX_LENGTH_2000 }} characters
        <ErrorMessage name="additionalInformation" class="error-feedback" />
      </Field>
    </div>

    <!-- Academic Level Field -->
    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'academicLevel')?.disabled"
    >
      <label for="academicLevel">
        Students' Academic Level<span class="super font-color-red">*</span>
      </label>
      <Field name="academicLevel" as="div" v-slot="{ field }">
        <Multiselect
          v-bind="field"
          v-model="values.academicLevel"
          :options="academicLevelOptions"
          mode="tags"
          :searchable="true"
          :multiple-label="displayLabels"
          :hide-selected="false"
          :close-on-select="false"
          :close-on-deselect="false"
        />
      </Field>
      <ErrorMessage name="academicLevel" class="error-feedback" />
    </div>

    <!-- Students' Department or Program of Study Field -->
    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'studentDept')?.disabled"
    >
      <label for="studentDept">
        Students' Department or Program of Study<span
          class="super font-color-red"
          >*</span
        >
      </label>
      <Field name="studentDept" as="div" v-slot="{ field }">
        <Multiselect
          v-bind="field"
          v-model="values.studentDept"
          :options="deptOptionsAddedBase"
          mode="tags"
          :searchable="true"
          :multiple-label="displayLabels"
          :hide-selected="false"
          :close-on-select="false"
          :close-on-deselect="false"
        />
      </Field>
      <!-- Conditional Additional Text Field for "Others" -->
      <div v-if="values.studentDept && values.studentDept.includes('Others')">
        <Field name="studentDept_text" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            placeholder="Please Specify"
            class="form-control bg-others"
            :maxlength="TEXT_MAX_LENGTH_100"
            @input="onInputWithNotification(TEXT_MAX_LENGTH_100, $event)"
          />
          <!-- Character Count -->
          {{ field.value ? field.value.length : 0 }}
          / {{ TEXT_MAX_LENGTH_100 }} characters
        </Field>
      </div>
      <ErrorMessage name="studentDept" class="error-feedback" />
      <ErrorMessage name="studentDept_text" class="error-feedback" />
    </div>

    <!-- Location of Classroom Field -->
    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'location')?.disabled"
    >
      <label for="location">
        Location of Classroom
        <span class="super font-color-red">*</span>
        <span class="font-color-yellow">
          (Actual Address Including Room Number)
        </span>
      </label>
      <Field name="location" v-slot="{ field }">
        <input
          v-bind="field"
          class="form-control"
          type="text"
          :maxlength="TEXT_MAX_LENGTH_250"
          @input="onInputWithNotification(TEXT_MAX_LENGTH_250, $event)"
        />
        <!-- Character Count -->
        {{ field.value ? field.value.length : 0 }}
        / {{ TEXT_MAX_LENGTH_250 }} characters
        <ErrorMessage name="location" class="error-feedback" />
      </Field>
    </div>

    <!-- Class Size Field -->
    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'classSize')?.disabled"
    >
      <label for="classSize">
        Class Size<span class="super font-color-red">*</span>
      </label>
      <Field name="classSize" v-slot="{ field }">
        <input
          v-bind="field"
          class="form-control"
          type="text"
          :maxlength="TEXT_MAX_LENGTH_10"
          @input="onInputWithNotification(TEXT_MAX_LENGTH_10, $event)"
        />
        <!-- Character Count -->
        {{ field.value ? field.value.length : 0 }}
        / {{ TEXT_MAX_LENGTH_10 }} characters
        <ErrorMessage name="classSize" class="error-feedback" />
      </Field>
    </div>
    <!-- Semester and Year Fields -->
    <div
      class="form-column"
      v-if="!classFields.find((el) => el.name === 'semester')?.disabled"
    >
      <!-- Semester Field -->
      <div class="form-group">
        <label for="semester">
          Semester <span class="super font-color-red">*</span>
        </label>
        <Field name="semester" type="text" class="dropdown" v-slot="{ field }">
          <Multiselect
            v-bind="field"
            v-model="values.semester"
            :options="['Fall', 'Spring', 'Summer', 'Winter']"
            mode="single"
            :searchable="true"
          />
        </Field>
        <ErrorMessage name="semester" class="error-feedback" />
      </div>

      <!-- Year Field -->
      <div
        class="form-group"
        v-if="!classFields.find((el) => el.name === 'year')?.disabled"
      >
        <label for="year">
          Year <span class="super font-color-red">*</span>
        </label>
        <Field name="year" type="text" class="dropdown" v-slot="{ field }">
          <Multiselect
            v-bind="field"
            v-model="values.year"
            :options="years"
            mode="single"
            :searchable="true"
          />
        </Field>
        <ErrorMessage name="year" class="error-feedback" />
      </div>
    </div>

    <!-- Submit Button -->
    <br />
    <div class="form-group text-align-center">
      <button class="btn btn-primary btn-block" :disabled="loading">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span v-if="props.action === 'update'">Update Class</span>
        <span v-else>Add Class</span>
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";
import * as yup from "yup";
import { useConfigStore } from "@/stores/ConfigStore";
import InstructorService from "@/services/instructor.service.js";
import Multiselect from "@vueform/multiselect";
import { Form, Field, ErrorMessage } from "vee-validate";
import {
  deptOptions,
  academicLevelOptions,
  displayLabels,
  showErrorToast,
} from "@/utils";
import { Course } from "@/types";

// Initialize Config Store
const ConfigStore = useConfigStore();

// Define Maximum Length Constants
const TEXT_MAX_LENGTH_10 = 10;
const TEXT_MAX_LENGTH_100 = 100;
const TEXT_MAX_LENGTH_250 = 250;
const TEXT_MAX_LENGTH_2000 = 2000;

// Ensure "Others, please specify" is included only once
const deptOptionsAddedBase = [
  { label: "No preference", value: "No preference" },
  ...deptOptions,
  { label: "Others, please specify", value: "Others" },
];

// Input Handler with Notification
const onInputWithNotification = (maxLength: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  }
};

// Reactive Variables
let message = ref("");
let loading = ref(false);
let years: number[] = [];

// Define Props
const props = defineProps<{
  successAction: () => void;
  action: string;
  course?: Course;
}>();

// Fetch Configured Fields for 'course' Table
const classFields = ConfigStore.config.filter((el) => el.table === "course");

// Populate Years Array (Current Year + 15 Years)
let currentYear = new Date().getFullYear();
for (let i = 0; i < 16; i++) {
  years.push(currentYear + i);
}

// Initialize Form State
let initialState = {
  courseCode: "",
  courseTitle: "",
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
  classSize: "",
  semester: "",
  year: "",
  additionalInformation: "",
  location: "",
};

// If Updating, Populate Initial State with Existing Course Data
if (props.action === "update" && props.course) {
  initialState.academicLevel = JSON.parse(props.course.academicLevel);
  initialState.studentDept = JSON.parse(props.course.studentDept);

  // Filter out custom items not in deptOptions
  const customItems = initialState.studentDept.filter(
    (item) =>
      !deptOptionsAddedBase.some((option) => option.value === item) &&
      item !== "Others"
  );

  // Assign the first custom item to studentDept_text, and remove it from studentDept
  if (customItems.length > 0) {
    initialState.studentDept_text = customItems[0]; // Use the first custom value
    initialState.studentDept = initialState.studentDept.filter(
      (item) => item !== customItems[0]
    );
  }
}

if (props.action === "update") {
  initialState.academicLevel = JSON.parse(props.course.academicLevel);
  initialState.studentDept = JSON.parse(props.course.studentDept);
  if (
    !deptOptions.some((el) => el.value === initialState.studentDept.at(-1)!)
  ) {
    initialState.studentDept_text = initialState.studentDept.at(-1)!;
    initialState.studentDept.pop();
  }
}

// Define Initial Form Values
const formValues = {
  courseCode: props.course?.courseCode || "",
  courseTitle: props.course?.courseTitle || "",
  academicLevel: initialState.academicLevel,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text, // Additional field for "Others"
  classSize: props.course?.classSize || "",
  semester: props.course?.semester || "",
  year: props.course?.year || "",
  additionalInformation: props.course?.additionalInformation || "",
  location: props.course?.location || "",
};

// Define Validation Schema
const schema = yup.object().shape({
  fields: yup.array().default(classFields),
  courseCode: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "courseCode")?.disabled,
    then: (schema) =>
      schema
        .required("Course Code is required!")
        .max(10, "Course Code cannot be greater than 10 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  courseTitle: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "courseTitle")?.disabled,
    then: (schema) =>
      schema
        .required("Course Title is required!")
        .max(250, "Course Title cannot be greater than 250 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) =>
        !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' academic level is required!")
          .min(1, "Students' academic level is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .required("Students' department or Program of study is required!")
    .min(1, "Please select at least one department or program of study."),
  studentDept_text: yup.string().when("studentDept", {
    is: (studentDept: any) =>
      Array.isArray(studentDept) && studentDept.includes("Others"),
    then: (schema) =>
      schema
        .trim()
        .min(1, "Please specify other department or program of study.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().default(""),
  }),

  classSize: yup
    .number()
    .typeError("Class Size must be a number!")
    .positive("Class Size must be a positive number!")
    .integer("Class Size must be an integer!")
    .when("fields", {
      is: (val: any[]) => !val.find((el) => el.name === "classSize")?.disabled,
      then: (schema) => schema.required("Class Size is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  semester: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "semester")?.disabled,
    then: (schema) => schema.required("Semester is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  year: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "year")?.disabled,
    then: (schema) => schema.required("Year is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  additionalInformation: yup.string().optional().nullable(),
  location: yup.string().when("fields", {
    is: (val: any[]) => !val.find((el) => el.name === "location")?.disabled,
    then: (schema) =>
      schema
        .required("Location of Classroom is required!")
        .max(
          250,
          "Location of Classroom cannot be greater than 250 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

// Save Course Function
const saveCourse = async (values: any) => {
  loading.value = true;
  console.log("Attempting to save course with values:", values);

  if (props.action === "update") {
    values.id = props.course.id;
    console.log(values.id);
  }
  if (values?.studentDept?.includes("Others") && values.studentDept_text) {
    values.studentDept.push(values.studentDept_text);
    console.log("Updated studentDept with 'Others':", values.studentDept);
  }

  try {
    const response = await InstructorService.saveCourse(values, props.action);
    console.log("Save Course Response:", response);
    props.successAction();
  } catch (error: any) {
    console.error("Error saving course:", error.message || error);
    showErrorToast(error.message || "An error occurred");
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped></style>
