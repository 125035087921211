import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-column" }
const _hoisted_2 = {
  key: 0,
  class: "form-group"
}
const _hoisted_3 = {
  key: 1,
  class: "form-group"
}
const _hoisted_4 = {
  key: 0,
  class: "form-group"
}
const _hoisted_5 = {
  key: 1,
  class: "form-group"
}
const _hoisted_6 = {
  key: 2,
  class: "form-group"
}
const _hoisted_7 = ["checked"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 3,
  class: "form-group"
}
const _hoisted_10 = {
  key: 4,
  class: "form-column"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "form-group"
}
const _hoisted_13 = {
  key: 5,
  class: "form-group"
}
const _hoisted_14 = {
  key: 6,
  class: "form-group"
}
const _hoisted_15 = ["checked"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-project-overview-column" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  key: 7,
  class: "form-group"
}
const _hoisted_23 = {
  key: 8,
  class: "form-group"
}
const _hoisted_24 = ["checked"]
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 9,
  class: "form-group"
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 10,
  class: "form-group"
}
const _hoisted_29 = {
  key: 11,
  class: "form-group"
}
const _hoisted_30 = { class: "form-group text-align-center mt-3" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = { class: "spinner-border spinner-border-sm" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }

import { ref } from "vue";
import { Site } from "@/types";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import {
  physicalDisOptions,
  safetyReqOptions,
  stateOptions,
  projectStageOptions,
  displayLabels,
  validateOptNone,
  validateOptions,
  showErrorToast,
} from "@/utils";
import Multiselect from "@vueform/multiselect";
import { useConfigStore } from "@/stores/ConfigStore";
import UserService from "@/services/user.service.js";

const TEXT_MAX_LENGTH_10 = 10;
const TEXT_MAX_LENGTH_20 = 20;
const TEXT_MAX_LENGTH_50 = 50;
const TEXT_MAX_LENGTH_100 = 100;
const TEXT_MAX_LENGTH_250 = 250;
const TEXT_MAX_LENGTH_300 = 300;
const TEXT_MAX_LENGTH_500 = 500;


export default /*@__PURE__*/_defineComponent({
  __name: 'SiteVisitForm',
  props: {
    action: {},
    site: {}
  },
  emits: ["saveSite"],
  setup(__props: any, { emit: __emit }) {

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length > maxLength) {
    event.target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

// eslint-disable-next-line no-undef
const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;
const ConfigStore = useConfigStore();
const siteVisitFields = ConfigStore.config.filter(
  (el) => el.table === "site_visit"
);

let loading = ref(false);
let message = ref("");

const schema = yup.object().shape({
  fields: yup.array().default(siteVisitFields),
  city: yup
    .string()
    .label("Location of institution")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "city")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  state: yup
    .string()
    .label("Location of institution")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "state")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  noOfStudent: yup
    .string()
    .label("Number of students that can be accommodated")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "noOfStudent")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  limitAccess: yup
    .string()
    .label("Limit/Extent of access to site allow")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "limitAccess")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectDescr: yup
    .string()
    .label("Title and Description of Project")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "projectDescr")?.disabled,
      then: (schema) =>
        schema.required("Title and Description of Project is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectStage: yup
    .array()
    .of(yup.string())
    .label("Project Stage")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "projectStage")?.disabled,
      then: (schema) => schema.required().min(1, "Project Stage is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectOverviewDuration: yup.string().optional().nullable(),
  projectOverviewCost: yup.string().optional().nullable(),
  projectOverviewClient: yup.string().optional().nullable(),
  safetyRequirement: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "safetyRequirement")?.disabled,
      then: (schema) =>
        schema.required().min(1, "Safety Requirement is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  availSeniorPersonnel: yup
    .string()
    .label("Availability of senior personnel during site visit")
    .when("fields", {
      is: (val) =>
        !val.find((el) => el.name === "availSeniorPersonnel")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  address: yup
    .string()
    .label("Specific Address of Job Site")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "address")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  physicalDisReason: yup
    .string()
    .optional()
    .nullable()
    .label("Why can't you accommodate students with disabilities"),
  durationSiteVisit: yup
    .string()
    .label("Duration of Site Visit that can be Allowed")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "durationSiteVisit")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectCompletionDate: yup
    .string()
    .label("Probable Project Completion Date")
    .when("fields", {
      is: (val) =>
        !val.find((el) => el.name === "projectCompletionDate")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  safetyReqAvailability: yup
    .string()
    .label("Will you provide the required PPE")
    .when("fields", {
      is: (val) =>
        !val.find((el) => el.name === "safetyReqAvailability")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  physicalDis: yup
    .array()
    .of(yup.string().required("Each item must be a string"))
    .required(
      "Type of Physical Disabilities that can be Accommodated is required!"
    )
    .min(
      1,
      "Please select at least one type of physical disability that can be accommodated"
    )
    .label("Type of Physical Disabilities that can be Accommodated"),
  siteSpecificRequirements: yup
    .string()
    .optional()
    .nullable()
    .label("Site Specific Requirements"),

  physicalDis_text: yup.string().when("physicalDis", {
    is: (physicalDis) =>
      Array.isArray(physicalDis) && physicalDis.includes("Others"),
    then: (schema) =>
      schema
        .trim()
        .min(1, "Please specify the type of physical disabilities.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  projectStage_text: yup.string().when("projectStage", {
    is: (projectStage) =>
      Array.isArray(projectStage) && projectStage.includes("Others"),
    then: (schema) =>
      schema
        .trim()
        .min(1, "Please specify the project stage.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  safetyRequirement_text: yup.string().when("safetyRequirement", {
    is: (safetyRequirement) =>
      Array.isArray(safetyRequirement) && safetyRequirement.includes("Others"),
    then: (schema) =>
      schema
        .trim()
        .min(1, "Please specify the safety requirement.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  safetyReqAvailability_text: yup
    .string()
    .strict(true)
    .when("safetyReqAvailability", {
      is: "some",
      then: (schema) =>
        schema
          .required("Please specify the required PPE.")
          .trim()
          .min(1, "Please specify the safety requirement.")
          .max(250, "Cannot exceed 250 characters."),
      otherwise: (schema) => schema.optional().nullable(),
    }),
});

const validateOptSafetyReq = (e, s) => {
  validateOptions(e, s, "All");
  validateOptions(e, s, "Not Required");
};

let initialState = {
  availSeniorPersonnel: "",
  city: "",
  state: "",
  limitAccess: "",
  noOfStudent: "",
  physicalDis: [] as string[],
  physicalDis_text: "",
  physicalDisReason: "",
  projectStage: [] as string[],
  projectStage_text: "",
  safetyRequirement: [] as string[],
  safetyRequirement_text: "",
  safetyReqAvailability: "",
  safetyReqAvailability_text: "",
  projectOverviewDuration: "",
  projectOverviewCost: "",
  projectOverviewClient: "",
  durationSiteVisit: "",
  projectCompletionDate: "",
  siteSpecificRequirements: "",
  address: "",
  projectDescr: "",
};

if (props.action === "update") {
  if (props.site.physicalDis.includes("Others")) {
    initialState["physicalDis_text"] = props.site.physicalDis.at(-1) || "";
    initialState.physicalDis = props.site.physicalDis.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.physicalDis = props.site.physicalDis;
  }
  if (props.site.projectStage.includes("Others")) {
    initialState["projectStage_text"] = props.site.projectStage.at(-1) || "";
    initialState.projectStage = props.site.projectStage.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.projectStage = props.site.projectStage;
  }
  if (props.site.safetyRequirement.includes("Others")) {
    initialState["safetyRequirement_text"] =
      props.site.safetyRequirement.at(-1) || "";
    initialState.safetyRequirement = props.site.safetyRequirement.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.safetyRequirement = props.site.safetyRequirement;
  }
}

const formValues = {
  availSeniorPersonnel: props.site?.availSeniorPersonnel,
  city: props.site?.city,
  state: props.site?.state,
  limitAccess: props.site?.limitAccess,
  noOfStudent: props.site?.noOfStudent,
  physicalDis: initialState.physicalDis,
  physicalDis_text: initialState.physicalDis_text,
  projectStage: initialState.projectStage,
  projectStage_text: initialState.projectStage_text,
  safetyRequirement: initialState.safetyRequirement,
  safetyRequirement_text: initialState.safetyRequirement_text,
  projectOverviewDuration: props.site?.projectOverviewDuration,
  projectOverviewCost: props.site?.projectOverviewCost,
  projectOverviewClient: props.site?.projectOverviewClient,
  address: props.site?.address,
  projectDescr: props.site?.projectDescr,
  physicalDisReason: props.site?.physicalDisReason,
  durationSiteVisit: props.site?.durationSiteVisit,
  projectCompletionDate: props.site?.projectCompletionDate,
  safetyReqAvailability: props.site?.safetyReqAvailability,
  safetyReqAvailability_text: props.site?.safetyReqAvailability_text,
  siteSpecificRequirements: props.site?.siteSpecificRequirements,
};

const saveSite = (data) => {
  // console.log(data);
  if (data.projectStage && data.projectStage === "Others")
    data.projectStage = data["projectStage_text"];
  if (data.safetyRequirement && data.safetyRequirement.includes("Others"))
    data.safetyRequirement.push(data["safetyRequirement_text"]);
  if (data.physicalDis && data.physicalDis.includes("Others"))
    data.physicalDis.push(data["physicalDis_text"]);
  emits("saveSite", props.action, data);
};

const cityOptions = ref([] as any[]);

const getCityOptions = (state) => {
  console.log(state);
  UserService.getCities(state).then(
    (response) => {
      console.log(response.data);
      cityOptions.value = response.data;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCityOptions(props.site?.state);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: saveSite,
    "validation-schema": _unref(schema),
    "initial-values": formValues
  }, {
    default: _withCtx(({ values }) => [
      _createElementVNode("div", null, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { for: "city" }, [
          _createTextVNode("Location of Job Site"),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          (!_unref(siteVisitFields).find((el) => el.name === 'state')?.disabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(Field), {
                  modelValue: values.state,
                  "onUpdate:modelValue": ($event: any) => ((values.state) = $event),
                  name: "state",
                  type: "text",
                  class: "form-control"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: values.state,
                      "onUpdate:modelValue": ($event: any) => ((values.state) = $event),
                      options: _unref(stateOptions),
                      mode: "single",
                      searchable: true,
                      placeholder: "State",
                      onChange: ($event: any) => (getCityOptions(values.state), (values.city = ''))
                    }), null, 16, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "state",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          (!_unref(siteVisitFields).find((el) => el.name === 'city')?.disabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(Field), {
                  modelValue: values.city,
                  "onUpdate:modelValue": ($event: any) => ((values.city) = $event),
                  name: "city",
                  type: "text",
                  class: "dropdown"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: values.city,
                      "onUpdate:modelValue": ($event: any) => ((values.city) = $event),
                      options: cityOptions.value,
                      mode: "single",
                      searchable: true,
                      placeholder: "City"
                    }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "city",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_unref(siteVisitFields).find((el) => el.name === 'address')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { for: "address" }, [
              _createTextVNode("Specific Address of Job Site"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "address",
              type: "text",
              class: "form-control",
              modelValue: values.address,
              "onUpdate:modelValue": ($event: any) => ((values.address) = $event),
              maxlength: TEXT_MAX_LENGTH_300,
              onInput: _cache[0] || (_cache[0] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_300, $event)))
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createTextVNode(" " + _toDisplayString(values.address ? values.address.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_300) + " characters ", 1),
            _createVNode(_unref(ErrorMessage), {
              name: "address",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(siteVisitFields).find((el) => el.name === 'noOfStudent')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[13] || (_cache[13] = _createElementVNode("label", { for: "noOfStudent" }, [
              _createTextVNode("Number of Students that can be Accommodated"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "noOfStudent",
              type: "text",
              class: "form-control"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "noOfStudent",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(siteVisitFields).find((el) => el.name === 'physicalDis')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { for: "physicalDis" }, [
              _createTextVNode("Type of Physical Disabilities that can be Accommodated"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              modelValue: values.physicalDis,
              "onUpdate:modelValue": ($event: any) => ((values.physicalDis) = $event),
              name: "physicalDis",
              type: "text",
              class: "form-control"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.physicalDis,
                  "onUpdate:modelValue": ($event: any) => ((values.physicalDis) = $event),
                  options: _unref(physicalDisOptions),
                  mode: "tags",
                  searchable: true,
                  multipleLabel: _unref(displayLabels),
                  hideSelected: false,
                  closeOnSelect: false,
                  closeOnDeselect: false,
                  onChange: _unref(validateOptNone)
                }), {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("input", {
                      type: "checkbox",
                      class: "input-pointer",
                      checked: values.physicalDis.includes(option.value)
                    }, null, 8, _hoisted_7),
                    _createTextVNode("   " + _toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1040, ["modelValue", "onUpdate:modelValue", "options", "multipleLabel", "onChange"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"]),
            (values.physicalDis && values.physicalDis.includes('Others'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_unref(Field), {
                    name: "physicalDis_text",
                    type: "text",
                    placeholder: "Please Specify",
                    class: "form-control",
                    modelValue: values.physicalDis_text,
                    "onUpdate:modelValue": ($event: any) => ((values.physicalDis_text) = $event),
                    maxlength: TEXT_MAX_LENGTH_100,
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_100, $event)))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createTextVNode(" " + _toDisplayString(values.physicalDis_text ? values.physicalDis_text.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_100) + " characters ", 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_unref(ErrorMessage), {
              name: "physicalDis",
              class: "error-feedback"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "physicalDis_text",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (values.physicalDis.includes('None'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "physicalDisReason" }, "Why can't you accommodate students with disabilities?", -1)),
            _createVNode(_unref(Field), {
              name: "physicalDisReason",
              type: "text",
              class: "form-control"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "physicalDisReason",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(siteVisitFields).find((el) => el.name === 'limitAccess')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[16] || (_cache[16] = _createElementVNode("label", { for: "limitAccess" }, [
                _createTextVNode("Limit/Extent of Access to Site Allow"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: values.limitAccess,
                "onUpdate:modelValue": ($event: any) => ((values.limitAccess) = $event),
                name: "limitAccess",
                type: "text",
                class: "dropdown"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: values.limitAccess,
                    "onUpdate:modelValue": ($event: any) => ((values.limitAccess) = $event),
                    options: ['Full Access', 'Limited Access'],
                    searchable: true,
                    mode: "single"
                  }), null, 16, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"]),
              _createVNode(_unref(ErrorMessage), {
                name: "limitAccess",
                class: "error-feedback"
              })
            ]),
            (
          !_unref(siteVisitFields).find((el) => el.name === 'durationSiteVisit')
            ?.disabled
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", { for: "durationSiteVisit" }, [
                    _createTextVNode("Duration of Site Visit that can be Allowed (in minutes)"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "durationSiteVisit",
                    class: "form-control",
                    type: "text",
                    modelValue: values.durationSiteVisit,
                    "onUpdate:modelValue": ($event: any) => ((values.durationSiteVisit) = $event),
                    maxlength: TEXT_MAX_LENGTH_10,
                    onInput: _cache[2] || (_cache[2] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_10, $event)))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createTextVNode(" " + _toDisplayString(values.durationSiteVisit ? values.durationSiteVisit.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_10) + " characters ", 1),
                  _createVNode(_unref(ErrorMessage), {
                    name: "durationSiteVisit",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_unref(siteVisitFields).find((el) => el.name === 'projectDescr')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _cache[18] || (_cache[18] = _createElementVNode("label", { for: "projectDescr" }, [
              _createTextVNode("Title and Description of Project"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "projectDescr",
              type: "text",
              class: "form-control",
              modelValue: values.projectDescr,
              "onUpdate:modelValue": ($event: any) => ((values.projectDescr) = $event),
              maxlength: TEXT_MAX_LENGTH_500,
              onInput: _cache[3] || (_cache[3] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_500, $event)))
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createTextVNode(" " + _toDisplayString(values.projectDescr ? values.projectDescr.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_500) + " characters ", 1),
            _createVNode(_unref(ErrorMessage), {
              name: "projectDescr",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(siteVisitFields).find((el) => el.name === 'projectStage')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _cache[19] || (_cache[19] = _createElementVNode("label", { for: "projectStage" }, [
              _createTextVNode("Project Stage"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              modelValue: values.projectStage,
              "onUpdate:modelValue": ($event: any) => ((values.projectStage) = $event),
              name: "projectStage",
              type: "text",
              class: "form-control"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.projectStage,
                  "onUpdate:modelValue": ($event: any) => ((values.projectStage) = $event),
                  options: _unref(projectStageOptions),
                  mode: "tags",
                  searchable: true,
                  multipleLabel: _unref(displayLabels),
                  hideSelected: false,
                  closeOnSelect: false,
                  closeOnDeselect: false
                }), {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("input", {
                      type: "checkbox",
                      class: "input-pointer",
                      checked: values.projectStage.includes(option.value)
                    }, null, 8, _hoisted_15),
                    _createTextVNode("   " + _toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1040, ["modelValue", "onUpdate:modelValue", "options", "multipleLabel"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"]),
            (values.projectStage && values.projectStage.includes('Others'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_unref(Field), {
                    name: "projectStage_text",
                    type: "text",
                    placeholder: "Please Specify",
                    class: "form-control",
                    modelValue: values.projectStage_text,
                    "onUpdate:modelValue": ($event: any) => ((values.projectStage_text) = $event),
                    maxlength: TEXT_MAX_LENGTH_100,
                    onInput: _cache[4] || (_cache[4] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_100, $event)))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createTextVNode(" " + _toDisplayString(values.projectStage_text ? values.projectStage_text.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_100) + " characters ", 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_unref(ErrorMessage), {
              name: "projectStage",
              class: "error-feedback"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "projectStage_text",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { for: "projectOverviewDuration" }, "General Project Overview", -1)),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", null, [
            (
              !_unref(siteVisitFields).find(
                (el) => el.name === 'projectOverviewDuration'
              )?.disabled
            )
              ? (_openBlock(), _createBlock(_unref(Field), {
                  key: 0,
                  name: "projectOverviewDuration",
                  type: "text",
                  class: "form-control",
                  placeholder: "Duration (in months)",
                  modelValue: values.projectOverviewDuration,
                  "onUpdate:modelValue": ($event: any) => ((values.projectOverviewDuration) = $event),
                  maxlength: TEXT_MAX_LENGTH_10,
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_10, $event)))
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            (
              !_unref(siteVisitFields).find(
                (el) => el.name === 'projectOverviewDuration'
              )?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(values.projectOverviewDuration
                ? values.projectOverviewDuration.length
                : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_10) + " characters ", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            (
              !_unref(siteVisitFields).find((el) => el.name === 'projectOverviewCost')
                ?.disabled
            )
              ? (_openBlock(), _createBlock(_unref(Field), {
                  key: 0,
                  name: "projectOverviewCost",
                  type: "text",
                  class: "form-control",
                  placeholder: "Cost (in dollars)",
                  modelValue: values.projectOverviewCost,
                  "onUpdate:modelValue": ($event: any) => ((values.projectOverviewCost) = $event),
                  maxlength: TEXT_MAX_LENGTH_20,
                  onInput: _cache[6] || (_cache[6] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_20, $event)))
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            (
              !_unref(siteVisitFields).find((el) => el.name === 'projectOverviewCost')
                ?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(values.projectOverviewCost ? values.projectOverviewCost.length : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_20) + " characters ", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            (
              !_unref(siteVisitFields).find((el) => el.name === 'projectOverviewClient')
                ?.disabled
            )
              ? (_openBlock(), _createBlock(_unref(Field), {
                  key: 0,
                  name: "projectOverviewClient",
                  type: "text",
                  class: "form-control",
                  placeholder: "Owner",
                  modelValue: values.projectOverviewClient,
                  "onUpdate:modelValue": ($event: any) => ((values.projectOverviewClient) = $event),
                  maxlength: TEXT_MAX_LENGTH_100,
                  onInput: _cache[7] || (_cache[7] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_100, $event)))
                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            (
              !_unref(siteVisitFields).find((el) => el.name === 'projectOverviewClient')
                ?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(values.projectOverviewClient
                ? values.projectOverviewClient.length
                : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_100) + " characters ", 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_unref(ErrorMessage), {
          name: "projectOverviewDuration",
          class: "error-feedback"
        }),
        _createVNode(_unref(ErrorMessage), {
          name: "projectOverviewCost",
          class: "error-feedback"
        }),
        _createVNode(_unref(ErrorMessage), {
          name: "projectOverviewClient",
          class: "error-feedback"
        })
      ]),
      (
        !_unref(siteVisitFields).find((el) => el.name === 'projectCompletionDate')
          ?.disabled
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { for: "projectCompletionDate" }, [
              _createTextVNode("Probable Project Completion Date"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "projectCompletionDate",
              type: "date",
              class: "form-control",
              modelValue: values.projectCompletionDate,
              "onUpdate:modelValue": ($event: any) => ((values.projectCompletionDate) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_unref(ErrorMessage), {
              name: "projectCompletionDate",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (
        !_unref(siteVisitFields).find((el) => el.name === 'safetyRequirement')?.disabled
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { for: "safetyRequirement" }, [
              _createTextVNode("Safety Requirement"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              modelValue: values.safetyRequirement,
              "onUpdate:modelValue": ($event: any) => ((values.safetyRequirement) = $event),
              name: "safetyRequirement",
              type: "text",
              class: "form-control"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.safetyRequirement,
                  "onUpdate:modelValue": ($event: any) => ((values.safetyRequirement) = $event),
                  options: _unref(safetyReqOptions),
                  mode: "tags",
                  searchable: true,
                  multipleLabel: _unref(displayLabels),
                  hideSelected: false,
                  closeOnSelect: false,
                  closeOnDeselect: false,
                  onChange: validateOptSafetyReq
                }), {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("input", {
                      type: "checkbox",
                      class: "input-pointer",
                      checked: values.safetyRequirement.includes(option.value)
                    }, null, 8, _hoisted_24),
                    _createTextVNode("   " + _toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1040, ["modelValue", "onUpdate:modelValue", "options", "multipleLabel"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"]),
            (
          values.safetyRequirement &&
          values.safetyRequirement.includes('Others')
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createVNode(_unref(Field), {
                    name: "safetyRequirement_text",
                    type: "text",
                    placeholder: "Please Specify",
                    class: "form-control",
                    modelValue: values.safetyRequirement_text,
                    "onUpdate:modelValue": ($event: any) => ((values.safetyRequirement_text) = $event),
                    maxlength: TEXT_MAX_LENGTH_100,
                    onInput: _cache[8] || (_cache[8] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_100, $event)))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createTextVNode(" " + _toDisplayString(values.safetyRequirement_text
            ? values.safetyRequirement_text.length
            : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_100) + " characters ", 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_unref(ErrorMessage), {
              name: "safetyRequirement",
              class: "error-feedback"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "safetyRequirement_text",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (
        !_unref(siteVisitFields).find((el) => el.name === 'safetyReqAvailability')
          ?.disabled
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _cache[23] || (_cache[23] = _createElementVNode("label", { for: "safetyReqAvailability" }, [
              _createTextVNode("Will you provide the required PPE?"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              modelValue: values.safetyReqAvailability,
              "onUpdate:modelValue": ($event: any) => ((values.safetyReqAvailability) = $event),
              name: "safetyReqAvailability",
              type: "text",
              class: "dropdown"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.safetyReqAvailability,
                  "onUpdate:modelValue": ($event: any) => ((values.safetyReqAvailability) = $event),
                  options: [
            { label: 'Yes, we would', value: 'Yes' },
            { label: 'No, students would have to come with them', value: 'No' },
            {
              label: 'We would provide only the ones listed below',
              value: 'some',
            },
          ],
                  searchable: true,
                  mode: "single"
                }), null, 16, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_unref(ErrorMessage), {
              name: "safetyReqAvailability",
              class: "error-feedback"
            }),
            (
          !_unref(siteVisitFields).find(
            (el) => el.name === 'safetyReqAvailability_text'
          )?.disabled &&
          values.safetyReqAvailability &&
          values.safetyReqAvailability === 'some'
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createVNode(_unref(Field), {
                    name: "safetyReqAvailability_text",
                    type: "text",
                    placeholder: "Please Specify",
                    class: "form-control",
                    modelValue: values.safetyReqAvailability_text,
                    "onUpdate:modelValue": ($event: any) => ((values.safetyReqAvailability_text) = $event),
                    maxlength: TEXT_MAX_LENGTH_250,
                    onInput: _cache[9] || (_cache[9] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_250, $event)))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createTextVNode(" " + _toDisplayString(values.safetyReqAvailability_text
            ? values.safetyReqAvailability_text.length
            : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_250) + " characters ", 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_unref(ErrorMessage), {
              name: "safetyReqAvailability_text",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (
        !_unref(siteVisitFields).find((el) => el.name === 'availSeniorPersonnel')
          ?.disabled
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _cache[24] || (_cache[24] = _createElementVNode("label", { for: "availSeniorPersonnel" }, [
              _createTextVNode("Availability of Senior Personnel During Site Visit"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              modelValue: values.availSeniorPersonnel,
              "onUpdate:modelValue": ($event: any) => ((values.availSeniorPersonnel) = $event),
              name: "availSeniorPersonnel",
              type: "text",
              class: "dropdown"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: values.availSeniorPersonnel,
                  "onUpdate:modelValue": ($event: any) => ((values.availSeniorPersonnel) = $event),
                  options: ['Yes', 'No', 'Not Sure'],
                  searchable: true,
                  mode: "single"
                }), null, 16, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_unref(ErrorMessage), {
              name: "availSeniorPersonnel",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (
        !_unref(siteVisitFields).find((el) => el.name === 'siteSpecificRequirements')
          ?.disabled
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _cache[25] || (_cache[25] = _createElementVNode("label", { for: "siteSpecificRequirements" }, "Site Specific Requirements", -1)),
            _createVNode(_unref(Field), {
              name: "siteSpecificRequirements",
              type: "text",
              class: "form-control",
              modelValue: values.siteSpecificRequirements,
              "onUpdate:modelValue": ($event: any) => ((values.siteSpecificRequirements) = $event),
              maxlength: TEXT_MAX_LENGTH_500,
              onInput: _cache[10] || (_cache[10] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH_500, $event)))
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createTextVNode(" " + _toDisplayString(values.siteSpecificRequirements
          ? values.siteSpecificRequirements.length
          : 0) + " / " + _toDisplayString(TEXT_MAX_LENGTH_500) + " characters ", 1),
            _createVNode(_unref(ErrorMessage), {
              name: "siteSpecificRequirements",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading)
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_32, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          (props.action === 'update')
            ? (_openBlock(), _createElementBlock("span", _hoisted_33, "Update Site"))
            : (_openBlock(), _createElementBlock("span", _hoisted_34, "Add Site"))
        ], 8, _hoisted_31)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})