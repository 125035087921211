import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/process.png'


const _hoisted_1 = { class: "sidebar sidebar-max min-height text-align-center" }
const _hoisted_2 = { style: {"position":"relative"} }
const _hoisted_3 = { class: "notification-count" }
const _hoisted_4 = { class: "sidebar sidebar-min min-height" }
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { style: {"position":"relative"} }
const _hoisted_7 = { class: "notification-count" }

import { useAuthStore } from "@/stores/AuthStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useRoute } from "vue-router";
import ProfileIcon from "@/components/user/ProfileIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminSidebar',
  setup(__props) {

const AuthStore = useAuthStore();
const NotificationStore = useNotificationStore();
const route = useRoute();

const currentUser = AuthStore.auth;

NotificationStore.getAdminNotification();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        id: "profile-img",
        src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
        class: "profile-img-card"
      }, null, -1)),
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_unref(currentUser).firstName + " " + _unref(currentUser).lastName), 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_router_link, { to: { name: 'admin-feed' } }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
          _unref(route).path === '/admin/home'
            ? 'selected-sidebar-button'
            : 'unselected-sidebar-button',
        ]]),
            title: "Home"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "sidebar-image-large"
            }, null, -1),
            _createElementVNode("span", { class: "ml-2" }, "Home", -1)
          ]), 2)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'view-notifications-admin' } }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
          _unref(route).path === '/admin/notifications'
            ? 'selected-sidebar-button'
            : 'unselected-sidebar-button',
        ]]),
            title: "Your notifications will appear here"
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: "sidebar-icon",
              icon: "fa-regular fa-bell",
              style: {"color":"#fcc62a"}
            }),
            _createElementVNode("span", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(NotificationStore).count), 1)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "ml-3" }, "View Notifications", -1))
          ], 2)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(ProfileIcon),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, { to: { name: 'view-notifications-admin' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/admin/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Your notifications will appear here"
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "sidebar-icon",
                  icon: "fa-regular fa-bell",
                  style: {"color":"#fcc62a"}
                }),
                _createElementVNode("span", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(NotificationStore).count), 1)
                ])
              ], 2)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 64))
}
}

})