<template>
  <form @keydown.enter.prevent class="mt-3 lg:mx-3">
    <div class="card card-container">
      <!-- Header -->
      <div class="bold-text ml-3 text-align-center">
        Please provide further details based on your earlier selections
        <br /><br />
      </div>

      <!-- Instruction Delivery Mode -->
      <div>
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insDeliveryType')
              ?.disabled
          "
        >
          <label for="insDeliveryType">
            Instruction Delivery Mode
            <span class="super font-color-red">*</span>
          </label>
          <Field
            name="insDeliveryType"
            type="text"
            class="dropdown"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.insDeliveryType"
              :options="insDeliveryTypeOptions"
              mode="single"
              :searchable="true"
              placeholder="Select Delivery Mode"
            />
          </Field>
          <ErrorMessage name="insDeliveryType" class="error-feedback" />
        </div>

        <!-- Other Comment/Information -->
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insDeliveryType')
              ?.disabled
          "
        >
          <label for="others">Other Comment/Information</label>
          <Field
            name="others"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <input
              v-bind="field"
              class="form-control"
              :maxlength="TEXT_MAX_LENGTH_500"
              @input="onInputWithNotification(TEXT_MAX_LENGTH_500, $event)"
              placeholder="Enter additional comments or information"
            />
          </Field>
          <div class="char-count">
            {{ (values.others || "").length }} /
            {{ TEXT_MAX_LENGTH_500 }} characters
          </div>
          <ErrorMessage name="others" class="error-feedback" />
        </div>

        <br />

        <!-- Institution-related Preferences -->
        <div
          class="text-align-center bold-text"
          v-if="
            !availabilityFields.find((el) => el.name === 'insState')
              ?.disabled &&
            !availabilityFields.find((el) => el.name === 'insName')?.disabled
          "
        >
          Preference Definition (Institution-related)
        </div>

        <!-- Location of Institution -->
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insState')?.disabled
          "
        >
          <label for="insState">
            Location of Institution
            <span class="super font-color-red">*</span>
          </label>
          <Field
            name="insState"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.insState"
              :options="stateOptionsAdded"
              mode="tags"
              placeholder="Select State(s)"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptAll"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.insState?.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <ErrorMessage name="insState" class="error-feedback" />
        </div>

        <!-- Name of Institution -->
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insName')?.disabled
          "
        >
          <label for="insName">Name of Institution</label>
          <Field
            name="insName"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.insName"
              :options="univOptionsAdded"
              mode="tags"
              placeholder="Select Institution(s)"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptAll"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.insName?.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <ErrorMessage name="insName" class="error-feedback" />
        </div>

        <br />

        <!-- Students-related Preferences -->
        <div
          class="text-align-center bold-text"
          v-if="
            !availabilityFields.find((el) => el.name === 'academicLevel')
              ?.disabled &&
            !availabilityFields.find((el) => el.name === 'studentDept')
              ?.disabled
          "
        >
          Preference Definition (Students-related)
        </div>

        <!-- Students' Academic Level -->
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'academicLevel')
              ?.disabled
          "
        >
          <label for="academicLevel">
            Students' Academic Level
            <span class="super font-color-red">*</span>
          </label>
          <Field
            name="academicLevel"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.academicLevel"
              :options="academicLevelOptionsAdded"
              mode="tags"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptNoPref"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.academicLevel?.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <ErrorMessage name="academicLevel" class="error-feedback" />
        </div>

        <!-- Students' Department or Program of Study -->
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'studentDept')
              ?.disabled
          "
        >
          <label for="studentDept">
            Students' Department or Program of Study
            <span class="super font-color-red">*</span>
          </label>
          <Field
            name="studentDept"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.studentDept"
              :options="deptOptionsAdded"
              mode="tags"
              placeholder="Select Department(s)"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              :allowCreate="false"
              @change="handleStudentDeptChange"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.studentDept.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>

          <!-- Show the text field only if "Others, please specify" is selected -->
          <div v-if="values.studentDept.includes('Others, please specify')">
            <label for="studentDept_text" class="mt-2">
              Others, please specify
            </label>
            <Field
              name="studentDept_text"
              type="text"
              placeholder="Please specify your department or program of study"
              class="form-control"
              v-slot="{ field }"
            >
              <input
                v-bind="field"
                class="form-control"
                :maxlength="TEXT_MAX_LENGTH_100"
                @input="onInputWithNotification(TEXT_MAX_LENGTH_100, $event)"
              />
            </Field>
            <div class="char-count">
              {{ (values.studentDept_text || "").length }} /
              {{ TEXT_MAX_LENGTH_100 }} characters
            </div>
            <ErrorMessage name="studentDept_text" class="error-feedback" />
          </div>

          <ErrorMessage name="studentDept" class="error-feedback" />
        </div>
      </div>
    </div>

    <!-- Navigation Buttons -->
    <div class="text-align-center mb-3">
      <button
        class="btn btn-secondary ml-2"
        type="button"
        @click="goToPreviousStep"
      >
        Previous
      </button>
      <button
        class="btn btn-primary ml-2"
        type="button"
        @click="saveCourseDetails"
      >
        Next
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { onBeforeRouteLeave } from "vue-router";
import { useModal } from "vuestic-ui";
import {
  insDeliveryTypeOptions,
  academicLevelOptions,
  stateOptions,
  deptOptions,
  univOptions,
  displayLabels,
  validateOptAll,
  validateOptNoPref,
  showErrorToast,
} from "@/utils";
import { Availability } from "@/types";

// Define Props
const props = defineProps<{
  availability: Availability;
}>();

// Define Emits
const emits = defineEmits(["previousStep", "nextStep"]);

// Modal for confirmation
const { confirm } = useModal();

// Access Stores
const ConfigStore = useConfigStore();
const AvailabilityStore = useAvailabilityStore();

// Define Options
const academicLevelOptionsAdded = [
  { label: "No preference", value: "No preference" },
  ...academicLevelOptions,
];

// Ensure "Others, please specify" is included only once
const deptOptionsAddedBase = [
  { label: "No preference", value: "No preference" },
  ...deptOptions,
  { label: "Others, please specify", value: "Others, please specify" },
];

// Function to merge preselected departments and handle custom departments
const getDeptOptionsAdded = () => {
  const preSelectedDepts = AvailabilityStore.status
    ? props.availability.studentDept || []
    : [];
  const predefinedDepts = preSelectedDepts.filter((dept) =>
    deptOptionsAddedBase.some((option) => option.value === dept)
  );
  const customDepts = preSelectedDepts.filter(
    (dept) => !deptOptionsAddedBase.some((option) => option.value === dept)
  );

  let deptOptionsAdded = [...deptOptionsAddedBase];

  return deptOptionsAdded;
};

const deptOptionsAdded = getDeptOptionsAdded();

// Define Additional Options
const stateOptionsAdded = [{ label: "All", value: "All" }, ...stateOptions];

const univOptionsAdded = [{ label: "All", value: "All" }, ...univOptions];

// Filter availability fields from config
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);

// Define Constants
const TEXT_MAX_LENGTH_500 = 500;
const TEXT_MAX_LENGTH_100 = 100;

// Input Handler with Notification
const onInputWithNotification = (maxLength: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  let value = target.value;
  // Prevent input from being "Others, please specify"
  if (value.trim().toLowerCase() === "others, please specify") {
    showErrorToast(
      `'Others, please specify' is a reserved option and cannot be used here.`
    );
    target.value = "";
    return;
  }
  if (value.length > maxLength) {
    target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit of ${maxLength} reached`);
  }
};

// Define Validation Schema
const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),

  insDeliveryType: yup.string().when("fields", {
    is: (val: any[]) =>
      !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  others: yup.string().optional().nullable(),

  insState: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) => !val.find((el) => el.name === "insState")?.disabled,
      then: (schema) =>
        schema
          .required("Location of institution is required!")
          .min(1, "Location of institution is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  insName: yup.array().of(yup.string()).optional().min(0),

  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) =>
        !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Academic Level is required!")
          .min(1, "Students' Academic Level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val: any[]) =>
        !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Department or Program of Study is required!")
          .min(1, "Students' Department or Program of Study is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  studentDept_text: yup.string().when("studentDept", {
    is: (studentDept: string[]) =>
      studentDept.includes("Others, please specify"),
    then: (schema) =>
      schema
        .required("Please specify your department or program of study.")
        .max(100, "Cannot exceed 100 characters."),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

// Setup Initial Values
let initialState = {
  insDeliveryType: "",
  others: "",
  insState: [] as string[],
  insName: [] as string[],
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
};

// If the store is populated, load initial values
if (AvailabilityStore.status) {
  const preSelectedDepts = props.availability.studentDept || [];
  const predefinedDepts = preSelectedDepts.filter((dept) =>
    deptOptionsAdded.some((option) => option.value === dept)
  );
  const customDepts = preSelectedDepts.filter(
    (dept) => !deptOptionsAdded.some((option) => option.value === dept)
  );

  // Initialize studentDept with predefined departments
  initialState.studentDept = [...predefinedDepts];

  // If there are custom departments, add "Others, please specify" and set the text field
  if (customDepts.length > 0) {
    if (!initialState.studentDept.includes("Others, please specify")) {
      initialState.studentDept.push("Others, please specify");
    }
    initialState.studentDept_text = customDepts.join(", ");
  }

  initialState.insDeliveryType = props.availability.insDeliveryType || "";
  initialState.others = props.availability.others || "";
  initialState.insState = props.availability.insState || [];
  initialState.insName = props.availability.insName || [];
  initialState.academicLevel = props.availability.academicLevel || [];
  // studentDept and studentDept_text are already set above
}

// Initialize form values
const formValues = {
  insDeliveryType: initialState.insDeliveryType,
  others: initialState.others,
  insState: initialState.insState,
  insName: initialState.insName,
  academicLevel: initialState.academicLevel,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
};

// Initialize Form
const { handleSubmit, errors, values, meta, validate, resetForm } = useForm({
  validationSchema: schema,
  initialValues: formValues,
});

// Function to handle studentDept change
const handleStudentDeptChange = (selected) => {
  // No additional logic needed as "Others, please specify" is managed by the watcher below
};

// Watch for Changes in studentDept to Manage studentDept_text
watch(
  () => values.studentDept,
  (newVal) => {
    if (newVal.includes("Others, please specify")) {
      // Ensure studentDept_text is present if "Others, please specify" is selected
      if (!values.studentDept_text) {
        values.studentDept_text = "";
      }
    } else {
      // Clear studentDept_text if "Others, please specify" is not selected
      values.studentDept_text = "";
    }
  },
  { immediate: true }
);

// Function to Check if Form is Dirty and Confirm Navigation
const checkDirty = async () => {
  if (meta.value.dirty) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    return answer;
  }
  return true;
};

// Handle Form Submission
const saveCourseDetails = handleSubmit(
  (data) => {
    if (
      data.studentDept &&
      data.studentDept.includes("Others, please specify")
    ) {
      // Replace "Others, please specify" with the specified text
      const othersIndex = data.studentDept.indexOf("Others, please specify");
      if (othersIndex !== -1) {
        // Split the text field by comma and trim spaces
        const customDepts = data.studentDept_text
          .split(",")
          .map((dept) => dept.trim())
          .filter((dept) => dept.length > 0);

        // Replace "Others, please specify" with custom departments
        data.studentDept.splice(othersIndex, 1, ...customDepts);
      }
      delete data.studentDept_text;
    }
    emits("nextStep", data);
  },
  (formErrors) => {
    Object.values(formErrors.errors).forEach((error) => showErrorToast(error));
  }
);

// Handle Navigation to Previous Step
const goToPreviousStep = async () => {
  const answer = await checkDirty();
  if (!answer) return false;
  emits("previousStep");
};

// Prevent Navigation Away if Form is Dirty
onBeforeRouteLeave(async (to, from) => {
  const answer = await checkDirty();
  if (!answer) return false;
});
</script>

<style scoped>
.card {
  margin-top: 0;
}
.card-container.card {
  max-width: 70vw !important;
  padding: 40px 40px;
}

@media only screen and (max-width: 1440px) {
  .card-container.card {
    max-width: 75vw !important;
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 1366px) {
  .card-container.card {
    max-width: 85vw !important;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 1024px) {
  .card-container.card {
    max-width: 85vw !important;
    padding: 10px 10px;
  }
}

.char-count {
  font-size: 0.9em;
  color: #6c757d;
  margin-top: 4px;
}
</style>
