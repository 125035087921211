import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "container min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "m-2 lg:m-3 lg:mt-5 heading-container" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-img-card"
}
const _hoisted_9 = { class: "details" }
const _hoisted_10 = { class: "name" }
const _hoisted_11 = { class: "position" }
const _hoisted_12 = {
  key: 2,
  class: "button-container flex flex-direction-row xl:flex-column"
}
const _hoisted_13 = { class: "va-text-center" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "va-text-center" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = {
  class: "btn btn-grey sm:ml-1",
  style: {"display":"inline-flex","align-items":"center","white-space":"nowrap"}
}
const _hoisted_20 = { class: "mt-3" }
const _hoisted_21 = { class: "table" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { key: 2 }
const _hoisted_25 = { key: 3 }
const _hoisted_26 = { key: 4 }
const _hoisted_27 = { class: "table" }
const _hoisted_28 = ["href"]
const _hoisted_29 = ["href"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "mt-5 heading-container" }
const _hoisted_35 = ["src"]
const _hoisted_36 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-img-card"
}
const _hoisted_37 = { class: "details" }
const _hoisted_38 = { class: "name" }
const _hoisted_39 = { class: "position" }
const _hoisted_40 = {
  key: 2,
  class: "button-container flex flex-direction-row xl:flex-column"
}
const _hoisted_41 = { class: "va-text-center" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { key: 1 }
const _hoisted_44 = { class: "va-text-center" }
const _hoisted_45 = { key: 0 }
const _hoisted_46 = { key: 1 }
const _hoisted_47 = {
  class: "btn btn-grey sm:ml-1",
  style: {"display":"inline-flex","align-items":"center","white-space":"nowrap"}
}
const _hoisted_48 = { class: "table" }
const _hoisted_49 = { key: 0 }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { key: 2 }
const _hoisted_52 = { key: 3 }
const _hoisted_53 = { key: 4 }
const _hoisted_54 = { key: 5 }

import { ref, watch } from "vue";
import { websiteURL, valid, formatSubjectSpeak } from "@/utils";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import UserBadge from "@/components/user/UserBadge.vue";
import UserService from "@/services/user.service.js";
import { useAuthStore } from "@/stores/AuthStore";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfileView',
  setup(__props) {

const AuthStore = useAuthStore();
const route = useRoute();
const currentUser = AuthStore.auth;
let userProfile = ref({});
let privateProfile = ref(false);
let loading = ref(false);
let successful = ref(false);
let message = ref("");

const getUserProfile = (data) => {
  loading.value = true;
  UserService.getMyUserProfile(data).then(
    (response) => {
      console.log(response.data);
      userProfile.value = response.data;
      loading.value = false;
      successful.value = true;
    },
    (error) => {
      loading.value = false;
      successful.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

watch(
  () => route.params.userId,
  (userId) => {
    getUserProfile(userId as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_switch = _resolveComponent("va-switch")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1))
      ]),
      (_unref(successful))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_unref(userProfile).hasOwnProperty('industry_practitioner'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_unref(userProfile).fileName)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          id: "profile-img",
                          src: _unref(websiteURL) + 'profile_picture/' + _unref(userProfile).fileName,
                          class: "profile-img-card"
                        }, null, 8, _hoisted_7))
                      : (_openBlock(), _createElementBlock("img", _hoisted_8)),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(userProfile).firstName) + " " + _toDisplayString(_unref(userProfile).lastName), 1),
                      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(userProfile).industry_practitioner.position) + " at " + _toDisplayString(_unref(userProfile).nameOrg), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                      _createVNode(UserBadge)
                    ]),
                    (_unref(userProfile).id === _unref(currentUser).id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_va_switch, {
                            modelValue: _unref(privateProfile),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(privateProfile) ? (privateProfile).value = $event : privateProfile = $event)),
                            color: "#5123a1",
                            "off-color": "#ffd300",
                            size: "small",
                            class: "block lg:hidden",
                            style: {"--va-switch-checker-background-color":"#252723"}
                          }, {
                            innerLabel: _withCtx(() => [
                              _createElementVNode("div", _hoisted_13, [
                                (_unref(privateProfile))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock" }),
                                      _cache[7] || (_cache[7] = _createElementVNode("span", null, " Private ", -1))
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock-open" }),
                                      _cache[8] || (_cache[8] = _createElementVNode("span", null, " Public ", -1))
                                    ]))
                              ])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_va_switch, {
                            class: "d-none lg:block",
                            modelValue: _unref(privateProfile),
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(privateProfile) ? (privateProfile).value = $event : privateProfile = $event)),
                            color: "#5123a1",
                            "off-color": "#ffd300",
                            style: {"--va-switch-checker-background-color":"#252723"}
                          }, {
                            innerLabel: _withCtx(() => [
                              _createElementVNode("div", _hoisted_16, [
                                (_unref(privateProfile))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock" }),
                                      _cache[9] || (_cache[9] = _createElementVNode("span", null, " Private ", -1))
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock-open" }),
                                      _cache[10] || (_cache[10] = _createElementVNode("span", null, " Public ", -1))
                                    ]))
                              ])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_router_link, {
                            to: {
                  name: 'industry-practitioner-profile',
                },
                            class: "mt-1 lg:mt-3"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("button", _hoisted_19, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "fa-solid fa-pencil",
                                  style: {"display":"inline-block","align-self":"center"}
                                }),
                                _cache[11] || (_cache[11] = _createElementVNode("span", { style: {"line-height":"2.5rem","margin-left":"0.5rem"} }, "Edit Profile", -1))
                              ])
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _cache[34] || (_cache[34] = _createElementVNode("div", { class: "divider" }, null, -1)),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[32] || (_cache[32] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Personal Information ", -1)),
                    _createElementVNode("table", _hoisted_21, [
                      _createElementVNode("tr", null, [
                        _cache[12] || (_cache[12] = _createElementVNode("td", null, "Title", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).title), 1)
                      ]),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_22, [
                            _cache[13] || (_cache[13] = _createElementVNode("td", null, "Gender Identity", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).gender), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_23, [
                            _cache[14] || (_cache[14] = _createElementVNode("td", null, "Racial Identity", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).ethnicity), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(valid)(_unref(userProfile).industry_practitioner.pronouns))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_24, [
                            _cache[15] || (_cache[15] = _createElementVNode("td", null, "Pronouns", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.pronouns), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_25, [
                            _cache[16] || (_cache[16] = _createElementVNode("td", null, "Age Range", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.ageRange), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("tr", null, [
                        _cache[17] || (_cache[17] = _createElementVNode("td", null, "Years of Experience", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.yearsOfExp), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[18] || (_cache[18] = _createElementVNode("td", null, "Highest Level of Education", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.levelEducation), 1)
                      ]),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_26, [
                            _cache[19] || (_cache[19] = _createElementVNode("td", null, "Physical Disabilities", -1)),
                            _createElementVNode("td", null, _toDisplayString(JSON.parse(
                      _unref(userProfile).industry_practitioner?.physicalDis
                    ).join(", ")), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("tr", null, [
                        _cache[20] || (_cache[20] = _createElementVNode("td", null, "Area of Specialization", -1)),
                        _createElementVNode("td", null, _toDisplayString(JSON.parse(
                      _unref(userProfile).industry_practitioner?.expertise || '[""]'
                    ).join(", ")), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[21] || (_cache[21] = _createElementVNode("td", null, "Professional License", -1)),
                        _createElementVNode("td", null, _toDisplayString(JSON.parse(
                      _unref(userProfile).industry_practitioner?.profLicense || '[""]'
                    ).join(", ")), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[22] || (_cache[22] = _createElementVNode("td", null, "Subject willing to speak on", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(formatSubjectSpeak)(
                      _unref(userProfile).industry_practitioner_availability
                        ?.subjectSpeak,
                      _unref(userProfile).industry_practitioner_availability
                        ?.subjectSpeakOthers
                    )), 1)
                      ])
                    ]),
                    _cache[33] || (_cache[33] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Company Information ", -1)),
                    _createElementVNode("table", _hoisted_27, [
                      _createElementVNode("tr", null, [
                        _cache[23] || (_cache[23] = _createElementVNode("td", null, "Location", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.city +
                    " " +
                    _unref(userProfile).industry_practitioner.state), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[24] || (_cache[24] = _createElementVNode("td", null, "Type of Organization", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.type), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[25] || (_cache[25] = _createElementVNode("td", null, "Size of Organization", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.size), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[26] || (_cache[26] = _createElementVNode("td", null, "Gender Diversity of Organization", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.genderDiv), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[27] || (_cache[27] = _createElementVNode("td", null, "Racial Diversity of Organization", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.ethnicDiv), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[28] || (_cache[28] = _createElementVNode("td", null, "Website of Organization", -1)),
                        _createElementVNode("td", null, [
                          (
                      !_unref(userProfile).industry_practitioner.website.startsWith(
                        'http'
                      )
                    )
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: 
                      'https://' + _unref(userProfile).industry_practitioner.website
                    ,
                                target: "_blank"
                              }, _toDisplayString(_unref(userProfile).industry_practitioner.website), 9, _hoisted_28))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: _unref(userProfile).industry_practitioner.website,
                                target: "_blank"
                              }, _toDisplayString(_unref(userProfile).industry_practitioner.website), 9, _hoisted_29))
                        ])
                      ]),
                      (_unref(valid)(_unref(userProfile).industry_practitioner.typeProject))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_30, [
                            _cache[29] || (_cache[29] = _createElementVNode("td", null, "Types of Projects Executed", -1)),
                            _createElementVNode("td", null, _toDisplayString(JSON.parse(
                      _unref(userProfile).industry_practitioner.typeProject || '[""]'
                    ).join(", ")), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(valid)(_unref(userProfile).industry_practitioner.typeTechnology))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_31, [
                            _cache[30] || (_cache[30] = _createElementVNode("td", null, " Types of Technologies Adopted by Practitioner's Organization ", -1)),
                            _createElementVNode("td", null, _toDisplayString(JSON.parse(
                      _unref(userProfile).industry_practitioner.typeTechnology || '[""]'
                    ).join(", ")), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(valid)(_unref(userProfile).industry_practitioner.organPolicy))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_32, [
                            _cache[31] || (_cache[31] = _createElementVNode("td", null, "Company's Effort to Support Student Development", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).industry_practitioner.organPolicy), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : (_unref(userProfile).hasOwnProperty('department'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                      (_unref(userProfile).user.fileName)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            id: "profile-img",
                            src: _unref(websiteURL) + 'profile_picture/' + _unref(userProfile).user.fileName,
                            class: "profile-img-card"
                          }, null, 8, _hoisted_35))
                        : (_openBlock(), _createElementBlock("img", _hoisted_36)),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("span", _hoisted_38, _toDisplayString(_unref(userProfile).user.firstName) + " " + _toDisplayString(_unref(userProfile).user.lastName), 1),
                        _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_unref(userProfile).user.title) + " at " + _toDisplayString(_unref(userProfile).user.nameOrg), 1)
                      ]),
                      (_unref(userProfile).user.id === _unref(currentUser).id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                            _createVNode(_component_va_switch, {
                              modelValue: _unref(privateProfile),
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(privateProfile) ? (privateProfile).value = $event : privateProfile = $event)),
                              color: "#5123a1",
                              "off-color": "#ffd300",
                              style: {"--va-switch-checker-background-color":"#252723"},
                              class: "block lg:hidden",
                              size: "small"
                            }, {
                              innerLabel: _withCtx(() => [
                                _createElementVNode("div", _hoisted_41, [
                                  (_unref(privateProfile))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock" }),
                                        _cache[36] || (_cache[36] = _createElementVNode("span", null, " Private ", -1))
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock-open" }),
                                        _cache[37] || (_cache[37] = _createElementVNode("span", null, " Public ", -1))
                                      ]))
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue"]),
                            _createVNode(_component_va_switch, {
                              modelValue: _unref(privateProfile),
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(privateProfile) ? (privateProfile).value = $event : privateProfile = $event)),
                              color: "#5123a1",
                              "off-color": "#ffd300",
                              class: "hidden lg:block",
                              style: {"--va-switch-checker-background-color":"#252723"}
                            }, {
                              innerLabel: _withCtx(() => [
                                _createElementVNode("div", _hoisted_44, [
                                  (_unref(privateProfile))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock" }),
                                        _cache[38] || (_cache[38] = _createElementVNode("span", null, " Private ", -1))
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_46, [
                                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-lock-open" }),
                                        _cache[39] || (_cache[39] = _createElementVNode("span", null, " Public ", -1))
                                      ]))
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue"]),
                            _createVNode(_component_router_link, {
                              to: {
                  name: 'instructor-profile',
                },
                              class: "mt-3"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("button", _hoisted_47, [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: "fa-solid fa-pencil",
                                    style: {"display":"inline-block","align-self":"center"}
                                  }),
                                  _cache[40] || (_cache[40] = _createElementVNode("span", { style: {"line-height":"2.5rem","margin-left":"0.5rem"} }, "Edit Profile", -1))
                                ])
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _cache[52] || (_cache[52] = _createElementVNode("div", { class: "divider" }, null, -1)),
                    _createElementVNode("table", _hoisted_48, [
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_49, [
                            _cache[41] || (_cache[41] = _createElementVNode("td", null, "Gender Identity", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).user.gender), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_50, [
                            _cache[42] || (_cache[42] = _createElementVNode("td", null, "Racial Identity", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).user.ethnicity), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_51, [
                            _cache[43] || (_cache[43] = _createElementVNode("td", null, "Age Range", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).ageRange), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_52, [
                            _cache[44] || (_cache[44] = _createElementVNode("td", null, "Years of Faculty Experience", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).yearsOfExp), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(valid)(_unref(userProfile).pronouns))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_53, [
                            _cache[45] || (_cache[45] = _createElementVNode("td", null, "Pronouns", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).pronouns), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("tr", null, [
                        _cache[46] || (_cache[46] = _createElementVNode("td", null, "Office Address", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).officeAddress), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[47] || (_cache[47] = _createElementVNode("td", null, "Department/Program", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).department), 1)
                      ]),
                      (_unref(privateProfile))
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_54, [
                            _cache[48] || (_cache[48] = _createElementVNode("td", null, "Type of Institution", -1)),
                            _createElementVNode("td", null, _toDisplayString(_unref(userProfile).insType), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("tr", null, [
                        _cache[50] || (_cache[50] = _createElementVNode("td", null, "Location of Institution", -1)),
                        _createElementVNode("td", null, [
                          _createTextVNode(_toDisplayString(_unref(userProfile).parkingAddress) + " ", 1),
                          _cache[49] || (_cache[49] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_unref(userProfile).city + " " + _unref(userProfile).state), 1)
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[51] || (_cache[51] = _createElementVNode("td", null, "Parking Arrangement for Practitioners", -1)),
                        _createElementVNode("td", null, _toDisplayString(_unref(userProfile).parkingArrangement), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})