<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="content min-height m-3">
      <div class="back-grid">
        <back-button></back-button>
        <div class="flex flex-column lg:flex-row align-items-center">
          <h2 style="text-align: center; font-weight: bold">My Classes</h2>
          <div
            class="py-1 px-2 card cursor-pointer inline-block flex-shrink-1 flex-grow-0 m-0 mt-1 lg:mt-0 lg:p-3"
            style="width: max-content"
            @click="isVisible = true"
          >
            <b> Add Class </b>
            <font-awesome-icon
              class="ml-2"
              title="Add Class"
              icon="fa-solid fa-plus"
            />
          </div>
        </div>
      </div>
      <div class="m-3 ml-0 mt-5">
        <div v-if="myClasses.length > 0">
          <div
            class="m-0 mt-2 cursor-pointer"
            v-for="(classes, semYear) in classesGrouped"
            v-bind:key="semYear"
          >
            <va-collapse
              class="w-96 mt-2 pb-2"
              color="#fff"
              :header="semYear.split('|')[0] + ' ' + semYear.split('|')[1]"
            >
              <div v-for="myClass in classes" :key="myClass.courseCode">
                <div
                  class="m-1 p-3 pl-4 card flex flex-row"
                  @click="(isViewClassVisible = true), (course = myClass)"
                >
                  <div class="flex-grow-1">
                    <b>{{ myClass.courseCode }} </b> <br />
                    {{ myClass.courseTitle }}
                  </div>
                  <div class="flex-grow-0 flex-shrink-1 mr-3">
                    <button
                      class="btn btn-primary bg-green-400 border-none"
                      title="View Details"
                    >
                      <img
                        src="@/assets/images/info.png"
                        class="sidebar-image"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </va-collapse>
          </div>
        </div>
        <div v-else>You don't have any saved classes.</div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isVisible"
    modal
    header="Add Class"
    style="
      padding: 20px; /* Adjust the padding as needed */
      background-color: #fff; /* Background color for the dialog box */
      border-radius: 8px; /* Optional: rounded corners */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
      min-width: 70vw; /* Optional: set a max width */
      max-width: 70vw; /* Optional: set a max width */
      margin: 0 auto; /* Center horizontally if needed */
    "
    :breakpoints="{ '768px': '90vw' }"
  >
    <class-form
      :successAction="saveCourseSuccess"
      action="create"
      :course="course"
    />
  </Dialog>
  <Dialog
    v-model:visible="isViewClassVisible"
    modal
    header="View Class Details"
    style="
      padding: 20px; /* Adjust the padding as needed */
      background-color: #fff; /* Background color for the dialog box */
      border-radius: 8px; /* Optional: rounded corners */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
      min-width: 70vw; /* Optional: set a max width */
      max-width: 70vw; /* Optional: set a max width */
      margin: 0 auto; /* Center horizontally if needed */
    "
    :breakpoints="{ '768px': '90vw' }"
  >
    <div v-if="isEditClassVisible">
      <class-form
        :success-action="updateCourseSuccess"
        action="update"
        :course="course"
      />
    </div>
    <div v-else>
      <table class="table">
        <tr>
          <td>Semester & Year</td>
          <td>{{ course.semester + " " + course.year }}</td>
        </tr>
        <tr>
          <td>Course Code</td>
          <td>{{ course.courseCode }}</td>
        </tr>
        <tr>
          <td>Course Title</td>
          <td>{{ course.courseTitle }}</td>
        </tr>
        <tr v-if="course.additionalInformation?.length > 0">
          <td>Link to Additional Course Information</td>
          <td>{{ course.additionalInformation }}</td>
        </tr>
        <tr>
          <td>Location of Classroom</td>
          <td>{{ course.location }}</td>
        </tr>
        <tr>
          <td>Class Size</td>
          <td>{{ course.classSize }}</td>
        </tr>
        <tr>
          <td>Students' Academic Level</td>
          <td>{{ JSON.parse(course.academicLevel).join(", ") }}</td>
        </tr>
        <tr>
          <td>Students' Department or Program of Study</td>
          <td>{{ JSON.parse(course.studentDept).join(", ") }}</td>
        </tr>
      </table>
      <div
        class="flex flex-row align-content-center justify-content-between gap-2"
      >
        <button
          class="btn btn-primary bg-cyan-600 border-none"
          @click="isEditClassVisible = true"
        >
          <font-awesome-icon
            icon="fa-solid fa-pencil"
            style="display: inline-block; align-self: center"
          />&nbsp;Edit Class
        </button>
        <button class="btn btn-secondary" @click="getCourseRequest(course)">
          View Course-Support Request
        </button>
        <button class="btn btn-primary" @click="createCourseRequest(course)">
          Request Course-Support
        </button>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ClassForm from "@/components/instructor/ClassForm.vue";
import Dialog from "primevue/dialog";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";
import InstructorService from "@/services/instructor.service.js";
import {
  showToast,
  groupBy,
  convertCourseToCourseRequest,
  showErrorToast,
} from "@/utils";
import { Course } from "@/types";

const CourseStore = useCourseStore();
const router = useRouter();
let myClasses = ref([] as Course[]);
let classesGrouped = ref([]);
let message = ref("");
let loading = ref(false);
let isVisible = ref(false);
let isViewClassVisible = ref(false);
let isEditClassVisible = ref(false);
let course = {} as Course;

const saveCourseSuccess = (values) => {
  showToast("Class has been saved.");
  getCourse();
  loading.value = false;
  isVisible.value = false;
};

const updateCourseSuccess = (values) => {
  showToast("Class has been updated.");
  getCourse();
  loading.value = false;
  isEditClassVisible.value = false;
  isViewClassVisible.value = false;
};

const getCourse = (pageNumber = 1) => {
  InstructorService.getCourse(pageNumber).then(
    (response) => {
      myClasses.value = response.data;
      myClasses.value.forEach((el) => {
        el.semYear = el.semester + "|" + el.year;
      });
      classesGrouped.value = groupBy(myClasses.value, "semYear");
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
getCourse();

const getCourseRequest = (course: Course) => {
  console.log(course.courseCode);
  router.push({
    name: "class-course-request",
    params: { courseCode: course.courseCode },
  });
};

const createCourseRequest = (course: Course | Course) => {
  CourseStore.removeCourse();
  let courseRequest = convertCourseToCourseRequest(course);
  CourseStore.addCourse(courseRequest);
  router.push({ name: "create-class-course-request" });
};
</script>

<style scoped>
.card {
  padding: 0 10px 10px 0;
}

table {
  min-width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 1em;
  font-size: larger;
}

@media only screen and (max-width: 600px) {
  .course-item {
    flex-direction: column;
  }

  td {
    padding: 0.2em 0.25em;
    font-size: small;
    overflow-wrap: anywhere;
  }
}
</style>
