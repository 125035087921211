import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-1 mt-3 lg:m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "card card-container" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "form-group"
}
const _hoisted_7 = { class: "size-counter" }
const _hoisted_8 = { style: {"width":"100%"} }
const _hoisted_9 = {
  class: "form-group",
  style: {"text-align":"center"}
}
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "spinner-border spinner-border-sm" }
const _hoisted_12 = {
  key: 0,
  class: "mt-8"
}
const _hoisted_13 = { class: "course-item mt-5" }
const _hoisted_14 = { class: "flex flex-direction-column lg:flex-row xl:flex-row justify-content-between align-content-center flex-wrap" }
const _hoisted_15 = { style: {"word-wrap":"break-word","word-break":"break-word","white-space":"pre-wrap","max-width":"100%","overflow-wrap":"anywhere"} }
const _hoisted_16 = {
  key: 0,
  title: "Unhappy"
}
const _hoisted_17 = {
  key: 1,
  title: "Neutral"
}
const _hoisted_18 = {
  key: 2,
  title: "Happy"
}
const _hoisted_19 = {
  class: "flex flex-grow-0 align-content-center lg:flex-wrap mr-3 leave-comment",
  style: {"cursor":"default"}
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "flex flex-direction-row" }
const _hoisted_22 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_23 = { class: "ml-3 mt-2 bold-text dark-text-color" }
const _hoisted_24 = {
  class: "mt-2",
  style: {"font-size":"larger"},
  autofocus: ""
}
const _hoisted_25 = { class: "flex flex-direction-row ml-2 overflow-scroll" }
const _hoisted_26 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_27 = ["src"]
const _hoisted_28 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-icon-card"
}
const _hoisted_29 = { class: "mb-2" }
const _hoisted_30 = { class: "ml-3 pl-3 mt-1 card comment-card" }
const _hoisted_31 = { class: "bold-text dark-text-color" }
const _hoisted_32 = { class: "ml-3 pl-1 small" }
const _hoisted_33 = { class: "flex flex-direction-row" }
const _hoisted_34 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_35 = {
  key: 1,
  class: "ml-3 mt-2"
}
const _hoisted_36 = { class: "flex flex-direction-row" }

import { ref } from "vue";
import { useRouter } from "vue-router";
import { useForm, useField, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import UserService from "../services/user.service.js";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { websiteURL, getTimeDiff, showToast, showErrorToast } from "@/utils";
import { VaDivider } from "vuestic-ui";
import { Feedback, FeedbackComment } from "@/types";

const TEXT_MAX_LENGTH = 5000;


export default /*@__PURE__*/_defineComponent({
  __name: 'FeedbackView',
  setup(__props) {

const router = useRouter();
const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();

const feedbackFields = ConfigStore.config.filter(
  (el) => el.table === "feedback"
);
const currentUser = AuthStore.auth;

let loading = ref(false);
let successful = ref(false);
let msg = ref("");
let userFeedback = ref([] as Feedback[]);
let currFeedback = ref({} as Feedback);
let feedbackComments = ref([] as FeedbackComment[]);
let isVisible = ref(false);
let editable = ref(false);

// Define your schema with custom tests
const schema = yup.object().shape({
  rating: yup
    .number()
    .required("Rating is required!")
    .min(0, "Rating must be at least 0!"),
  message: yup
    .string()
    .required("Message is required!")
    .min(10, "Message must be at least 10 characters!")
    .max(
      TEXT_MAX_LENGTH,
      `Message cannot exceed ${TEXT_MAX_LENGTH} characters!`
    ),
});

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length >= maxLength) {
    event.target.value = value.substring(0, maxLength); // Prevent further input
    showErrorToast(`Maximum character limit of ${maxLength} reached.`);
  }
};

// Initialize the form
const { handleSubmit, setFieldValue, values, meta, errors } = useForm({
  initialValues: {
    rating: null,
    message: "",
  },
});

// Initialize fields
const { value: rating, errorMessage: ratingError } = useField("rating");
const { value: message, errorMessage: messageError } = useField("message");

// Function to update rating
const updateRating = (value) => {
  rating.value = value;
  setFieldValue("rating", value);
};

// Handle form submission
const handleSubmission = handleSubmit(async (data, { setErrors }) => {
  loading.value = true;

  try {
    // Validate fields manually
    await schema.validate(data, { abortEarly: false });

    // Proceed with submission if validation passes
    UserService.saveFeedback(data).then(
      (response) => {
        msg.value = response.message;
        successful.value = true;
        loading.value = false;
        showToast("Your Feedback has been successfully submitted.");
        router.push({ name: "home" });
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        msg.value = errorMessage;
        successful.value = false;
        loading.value = false;
        showErrorToast(`Failed to submit feedback: ${errorMessage}`);
      }
    );
  } catch (validationError) {
    // Handle validation errors
    const fieldErrors = {};
    validationError.inner.forEach((error) => {
      fieldErrors[error.path] = error.message;
      showErrorToast(error.message); // Toast notifications will now show as intended
    });

    setErrors(fieldErrors);
    loading.value = false;
  }
});

// Fetch user feedback
const getUserFeedback = (userId) => {
  UserService.getUserFeedback(userId).then(
    (response) => {
      console.log(response);
      userFeedback.value = response.data;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

// Fetch feedback comments
const getFeedbackComments = (feedbackId) => {
  UserService.getFeedbackComments(feedbackId).then(
    (response) => {
      console.log(response);
      feedbackComments.value = response.data;
      isVisible.value = true;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

// Post a comment
const postComment = handleSubmit((data) => {
  loading.value = true;
  data["linkedResourceType"] = "feedback";
  UserService.postFeedbackComment(data, currFeedback.value.id).then(
    (response) => {
      msg.value = response.message;
      successful.value = true;
      showToast("Comment posted successfully.");
      isVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
});

// Fetch feedback on component mount
getUserFeedback(currentUser.id);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[9] || (_cache[9] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, "Feedback", -1))
        ]),
        _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
        _cache[23] || (_cache[23] = _createElementVNode("h5", { class: "text-align-center pl-1 pr-1 lg:pl-8 lg:pr-8" }, " Your feedback is important to us for improving the platform. Please provide feedback based on your experience. We are happy to hear from you. ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[18] || (_cache[18] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
          _createElementVNode("form", {
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_unref(handleSubmission) && _unref(handleSubmission)(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, [
                _cache[13] || (_cache[13] = _createElementVNode("label", {
                  for: "rating",
                  style: {"margin-right":"1rem"}
                }, [
                  _createTextVNode(" Rating"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "hidden",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(rating) ? (rating).value = $event : null))
                }, null, 512), [
                  [_vModelText, _unref(rating)]
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["emoji", { selected: _unref(rating) === 0 }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (updateRating(0)))
                }, _cache[10] || (_cache[10] = [
                  _createTextVNode(" 😞 "),
                  _createElementVNode("span", null, [
                    _createElementVNode("br"),
                    _createTextVNode("Unhappy")
                  ], -1)
                ]), 2),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["emoji ml-3", { selected: _unref(rating) === 1 }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (updateRating(1)))
                }, _cache[11] || (_cache[11] = [
                  _createTextVNode(" 😐 "),
                  _createElementVNode("span", null, [
                    _createElementVNode("br"),
                    _createTextVNode("Neutral")
                  ], -1)
                ]), 2),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["emoji ml-3", { selected: _unref(rating) === 2 }]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (updateRating(2)))
                }, _cache[12] || (_cache[12] = [
                  _createTextVNode(" 😃 "),
                  _createElementVNode("span", null, [
                    _createElementVNode("br"),
                    _createTextVNode("Happy")
                  ], -1)
                ]), 2),
                _createVNode(_unref(ErrorMessage), {
                  name: "rating",
                  class: "error-feedback"
                })
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
              (
                !_unref(feedbackFields).find((el) => el.name === 'message')?.disabled
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", { for: "message" }, [
                      _createTextVNode(" Message"),
                      _createElementVNode("span", { class: "super font-color-red" }, "*")
                    ], -1)),
                    _createVNode(_unref(Field), {
                      name: "message",
                      type: "text",
                      as: "textarea",
                      class: "form-control",
                      maxlength: TEXT_MAX_LENGTH,
                      onInput: _cache[4] || (_cache[4] = ($event: any) => (onInputWithNotification(TEXT_MAX_LENGTH, $event))),
                      style: {"height":"100px"}
                    }),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(_unref(message) ? _unref(message).length : 0) + " / 5000", 1)
                    ]),
                    _createVNode(_unref(ErrorMessage), {
                      name: "message",
                      class: "error-feedback"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  style: {"text-align":"center"},
                  disabled: _unref(loading)
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_11, null, 512), [
                    [_vShow, _unref(loading)]
                  ]),
                  _cache[16] || (_cache[16] = _createTextVNode(" Submit "))
                ], 8, _hoisted_10)
              ])
            ])
          ], 32),
          (_unref(msg))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["alert", _unref(successful) ? 'alert-success' : 'alert-danger'])
              }, _toDisplayString(_unref(msg)), 3))
            : _createCommentVNode("", true)
        ]),
        (_unref(userFeedback).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[21] || (_cache[21] = _createElementVNode("h3", { class: "text-align-center" }, "Your Feedback", -1)),
              _createElementVNode("div", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userFeedback), (feedback) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "lg:m-1 card",
                    key: feedback.id
                  }, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("b", null, _toDisplayString(feedback.message), 1),
                        _cache[19] || (_cache[19] = _createTextVNode("   ")),
                        (feedback.rating === '0')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, " 😞 "))
                          : (feedback.rating === '1')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17, " 😐 "))
                            : (feedback.rating === '2')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_18, " 😃 "))
                              : _createCommentVNode("", true),
                        _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(new Date(feedback.createdAt).toDateString()), 1)
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("button", {
                          class: "btn btn-primary",
                          onClick: ($event: any) => (
                    (_isRef(currFeedback) //@ts-ignore
 ? currFeedback.value = feedback : currFeedback = feedback), getFeedbackComments(feedback.id)
                  )
                        }, " Leave a comment ", 8, _hoisted_20)
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "User Feedback",
      style: { width: '60vw', padding: '20px', backgroundColor: '#fff' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(ProfileIcon)
          ]),
          _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(currentUser).firstName) + " " + _toDisplayString(_unref(currentUser).lastName), 1)
        ]),
        _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(currFeedback).message), 1),
        _createVNode(_unref(VaDivider), {
          vertical: "",
          color: "red"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedbackComments), (feedbackComment) => {
          return (_openBlock(), _createElementBlock("div", {
            key: feedbackComment.id
          }, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                (feedbackComment.user.fileName)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: "profile-img",
                      src: 
              _unref(websiteURL) + 'profile_picture/' + feedbackComment.user.fileName
            ,
                      class: "profile-icon-card"
                    }, null, 8, _hoisted_27))
                  : (_openBlock(), _createElementBlock("img", _hoisted_28))
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, _toDisplayString(feedbackComment.user.firstName) + " " + _toDisplayString(feedbackComment.user.lastName), 1),
                  _createTextVNode(" " + _toDisplayString(feedbackComment.message), 1)
                ]),
                _createElementVNode("span", _hoisted_32, _toDisplayString(_unref(getTimeDiff)(feedbackComment.createdAt)), 1)
              ])
            ])
          ]))
        }), 128)),
        _createVNode(_unref(VaDivider)),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createVNode(ProfileIcon)
          ]),
          (!_unref(editable))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "ml-3 mt-2",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(editable) //@ts-ignore
 ? editable.value = true : editable = true))
              }, " Add Comment "))
            : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                _createElementVNode("form", {
                  onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_unref(postComment) && _unref(postComment)(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_36, [
                    _createVNode(_unref(Field), { name: "message" }, {
                      default: _withCtx(({ field }) => [
                        _createElementVNode("textarea", _mergeProps({ type: "text" }, field, {
                          class: "form-control border-2",
                          placeholder: "Add a comment..."
                        }), null, 16),
                        _createVNode(_unref(ErrorMessage), {
                          name: "message",
                          class: "error-feedback"
                        })
                      ]),
                      _: 1
                    }),
                    _cache[24] || (_cache[24] = _createElementVNode("button", { class: "btn btn-primary mx-4 my-3" }, "Reply", -1))
                  ])
                ], 32)
              ]))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})