<template>
  <div class="m-3 lg:m-5 min-height header-space">
    <div class="text-align-center">
      <img
        src="@/assets/images/site/contact-us.jpg"
        class="site-image mx-auto"
        alt="About Us"
      />
    </div>
    <div class="mb-1 lg:mb-3">
      <div class="text-align-center">
        <p class="page-heading">Contact Us</p>
        <div class="heading-line">
          <img src="@/assets/images/site/line.svg" alt="" />
        </div>
      </div>
      <div class="card card-container">
        <span class="font-color-red">* required field</span>
        <Form @submit="handleSubmission" :validation-schema="schema">
          <div>
            <div class="form-group">
              <label for="firstName"
                >First Name<span class="super font-color-red">*</span></label
              >
              <Field
                name="firstName"
                v-model="firstName"
                type="text"
                class="form-control"
                :maxlength="TEXT_MAX_LENGTH_100"
                @input="onInputWithNotification(TEXT_MAX_LENGTH_100, $event)"
              />
              {{ firstName ? firstName.length : 0 }}
              / {{ TEXT_MAX_LENGTH_100 }} characters
              <ErrorMessage name="firstName" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="lastName"
                >Last Name<span class="super font-color-red">*</span></label
              >
              <Field
                name="lastName"
                type="text"
                v-model="lastName"
                class="form-control"
                :maxlength="TEXT_MAX_LENGTH_100"
                @input="onInputWithNotification(TEXT_MAX_LENGTH_100, $event)"
              />
              {{ lastName ? lastName.length : 0 }}
              / {{ TEXT_MAX_LENGTH_100 }} characters
              <ErrorMessage name="lastName" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="nameOrg"
                >Institution/Company Name<span class="super font-color-red"
                  >*</span
                ></label
              >
              <Field
                name="nameOrg"
                type="text"
                v-model="nameOrg"
                class="form-control"
                :maxlength="TEXT_MAX_LENGTH_100"
                @input="onInputWithNotification(TEXT_MAX_LENGTH_100, $event)"
              />
              {{ nameOrg ? nameOrg.length : 0 }}
              / {{ TEXT_MAX_LENGTH_100 }} characters
              <ErrorMessage name="nameOrg" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="email"
                >Email<span class="super font-color-red">*</span></label
              >
              <Field
                name="email"
                type="email"
                v-model="email"
                class="form-control"
                :maxlength="TEXT_MAX_LENGTH_100"
                @input="onInputWithNotification(TEXT_MAX_LENGTH_100, $event)"
              />
              {{ email ? email.length : 0 }}
              / {{ TEXT_MAX_LENGTH_100 }} characters
              <ErrorMessage name="email" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="message"
                >Message<span class="super font-color-red">*</span></label
              >
              <Field
                name="message"
                v-model="messageField"
                type="text"
                as="textarea"
                class="form-control"
                :maxlength="TEXT_MAX_LENGTH_5000"
                @input="onInputWithNotification(TEXT_MAX_LENGTH_5000, $event)"
              />
              {{ messageField ? messageField.length : 0 }}
              / {{ TEXT_MAX_LENGTH_5000 }} characters
              <ErrorMessage name="message" class="error-feedback" />
            </div>

            <br />
            <div class="form-group text-align-center">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                Contact Us
              </button>
            </div>
          </div>
        </Form>

        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service.js";
import { showToast, showErrorToast } from "@/utils";

let loading = false;
let successful = false;
let message = "";

const TEXT_MAX_LENGTH_100 = 100;
const TEXT_MAX_LENGTH_5000 = 5000;

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length > maxLength) {
    event.target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  nameOrg: yup.string().required("Institution/Company Name is required!"),
  email: yup.string().required("Email is required!").email("Email is invalid!"),
  message: yup
    .string()
    .required("Message is required!")
    .min(10, "Must be at least 10 characters!")
    .max(
      TEXT_MAX_LENGTH_5000,
      `Must be maximum {TEXT_MAX_LENGTH_5000} characters!`
    ),
});

const handleSubmission = (data, { resetForm }) => {
  loading = true;

  UserService.saveContactUsInfo(data).then(
    (data) => {
      message = data.message;
      successful = true;
      loading = false;
      showToast(
        "Query raised successfully. Our team will contact you within 2 days"
      );

      resetForm();
    },
    (error) => {
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful = false;
      loading = false;
    }
  );
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .card-container.card {
    max-width: 90% !important;
    padding: 10px 10px;
  }

  .card {
    margin-top: 20px;
  }
}
</style>
