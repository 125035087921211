<script setup lang="ts">
import { ref } from "vue";
import { showToast } from "@/utils";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import ResourcesRequestForm from "@/components/ResourcesRequestForm.vue";
import BackButton from "@/components/user/BackButton.vue";
import ResourcesGalleryItem from "@/components/ResourcesGalleryItem.vue";
import Dialog from "primevue/dialog";
import UserService from "@/services/user.service.js";
import InstructorService from "@/services/instructor.service.js";
import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";

const router = useRouter();
const CourseStore = useCourseStore();
const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

let resourceRequests = ref([]);
let visible = ref(false);
let currResourceRequest = ref({});
const currentPage = ref(1);
const totalPages = ref(1);

const viewResourceRequest = (pageNumber = 1) => {
  UserService.getResourcesRequest(pageNumber).then(
    (response) => {
      resourceRequests.value = response.data.data;
      totalPages.value = response.data.totalPages;
      currentPage.value = response.data.currentPage;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const updateResourceRequest = (id, status) => {
  InstructorService.updateResourcesRequest(id, status).then(
    (response) => {
      viewResourceRequest();
      showToast("Resource Request updated successfully");
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const onClick = (val) => {
  viewResourceRequest(currentPage.value);
};

viewResourceRequest();
</script>

<style
  src="../../../../node_modules/@vueform/multiselect/themes/default.css"
></style>
<style scoped>
.card-container.card {
  max-width: 900px !important;
  padding: 40px 40px;
}

.responsive {
  padding: 0 6px;
  margin: 4px 0;
  float: left;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.paginator {
  display: grid;
  justify-content: center;
  margin: 1rem 2rem;
}

@media screen and (max-width: 768px) {
  .card-container.card {
    max-width: 90% !important;
    padding: 10px 10px;
  }

  .card {
    padding: 10px 5px 10px;
    margin: 10px auto 25px;
  }

  .btn {
    padding: 0.25rem;
  }
}
</style>

<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Resources Request Information
        </h2>
      </div>
      <div class="card card-container">
        <resources-request-form
          action="create"
          @reload-resource-request="viewResourceRequest"
        ></resources-request-form>
      </div>
      <div class="mt-8">
        <h3 class="text-align-center font-bold">Your Resources Request</h3>
        <div v-if="resourceRequests.length > 0" class="course-item mt-5">
          <div
            class="m-1 card"
            v-for="resourceRequest in resourceRequests"
            :key="resourceRequest.id"
          >
            <div
              class="flex flex-direction-column lg:flex-row xl:flex-row justify-content-between flex-wrap mx-2 lg:mx-0"
            >
              <div class="flex flex-row">
                <div>
                  <b>{{ resourceRequest.type }} </b>
                  <br />
                  {{ resourceRequest.description }}
                </div>
                <div
                  v-if="resourceRequest.status === '90'"
                  class="text-align-center align-self-center font-bold d-inline d-lg-none flex-shrink-1 flex-grow-0 mr-3"
                >
                  Closed
                </div>
              </div>
              <div
                class="flex flex-grow-0 align-content-center mr-3 lg:flex-wrap"
              >
                <span v-if="resourceRequest.status === '90'" class="mr-3">
                  <div class="d-inline">
                    <span class="text-align-center font-bold d-none d-lg-inline"
                      >Closed</span
                    >
                    <span>
                      <button
                        @click="updateResourceRequest(resourceRequest.id, '01')"
                        class="btn btn-secondary mx-1 lg:mx-3"
                      >
                        Re-Open Request
                      </button>
                    </span>
                  </div>
                </span>
                <span v-else>
                  <button
                    @click="updateResourceRequest(resourceRequest.id, '90')"
                    class="btn btn-danger mr-3"
                  >
                    Close Request
                  </button>
                </span>
                <span v-if="resourceRequest?.resource_responses?.length > 0">
                  <button
                    @click="
                      (visible = true), (currResourceRequest = resourceRequest)
                    "
                    class="btn btn-primary ml-3"
                  >
                    View
                    {{ resourceRequest.resource_responses.length }}
                    Response(s)
                  </button>
                </span>
                <span v-else> 0 Response </span>
              </div>
            </div>
          </div>
          <div class="paginator">
            <va-pagination
              v-model="currentPage"
              :pages="totalPages"
              :visible-pages="3"
              :hideOnSinglePage="true"
              @click="onClick"
            />
          </div>
        </div>
        <div v-else class="mt-3 text-align-center">
          You don't have any saved resource requests.
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    :header="currResourceRequest.type"
    :style="{ width: '75vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '80vw' }"
  >
    <i> {{ currResourceRequest.description }} </i>
    <div class="min-h">
      <div
        class="responsive"
        v-for="item in currResourceRequest.resource_responses"
        :key="item.id"
      >
        <resources-gallery-item :item="item" />
      </div>
    </div>
    <div class="clearfix"></div>
  </Dialog>
</template>
