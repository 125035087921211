<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="mt-3 max-width">
      <div class="back-grid">
        <back-button></back-button>
        <div class="flex flex-column lg:flex-row xl:align-items-center">
          <h2 style="text-align: center; font-weight: bold">My Uploads</h2>
          <div
            class="flex-shrink-1 flex-grow-0 mr-3 layout-button text-align-center"
          >
            <div
              v-if="path.length === 1"
              class="m-0 py-1 px-2 lg:p-3 card cursor-pointer inline-block"
              style="width: max-content"
              @click="newFolderMenuVisible = true"
            >
              <font-awesome-icon
                class="ml-2"
                title="Create New Course-Support Request"
                icon="fa-solid fa-plus"
              />
              <b> New Folder</b>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 mx-3">
        <div class="my-2">
          <span v-for="(directory, index) in path" :key="index">
            <span
              class="px-2 py-2 rounded-2 pointer hover:bg-black-alpha-10"
              @click="changeDirectory"
            >
              {{ directory }} </span
            ><span v-if="index != path.length - 1"> > </span>
          </span>
        </div>
        <div>
          <va-data-table
            :items="items"
            :columns="columns"
            :loading="loading"
            :clickable="true"
            @row:click="selectItem"
            @row:dblclick="handleClick"
            @row:contextmenu="handleRightClick"
            sticky-header
            hoverable
            selectable
            select-mode="single"
            v-model="selectedItem"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="context-menu">
    <div class="item" @click="deleteMenuVisible = true">
      <img src="@/assets/images/delete.png" class="mr-2 content-menu-img" />
      Delete
    </div>
    <div class="item" @click="moveFileMenuVisible = true">
      <img src="@/assets/images/file.png" class="mr-2 content-menu-img" /> Move
    </div>
  </div>
  <Dialog
    v-model:visible="showCustomContent"
    modal
    :header="resource.description"
    :style="{ width: '50vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <div class="flex justify-content-center" style="height: 70vh">
      <img
        :src="websiteURL + resource.filePath.substring(9)"
        class="model-img"
        v-if="resource.type === 'Project Pictures' && resource.filePath"
        autofocus
      />
      <video v-if="resource.type === 'Video' && resource.filePath" controls>
        <source
          :src="websiteURL + resource.filePath.substring(9)"
          :type="resource['mimetype']"
        />
      </video>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="deleteMenuVisible"
    modal
    header=" "
    :style="{ width: '50vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <Form
      @submit="handleSubmission"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonDelete"
          >Please provide a reason for deleting the uploaded content</label
        >
        <Field name="reasonDelete" type="text" class="form-control" />
        <ErrorMessage name="reasonDelete" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Delete</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="newFolderMenuVisible"
    modal
    header="Folder Name"
    :style="{ width: '30vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <Form
      @submit="createNewFolder"
      :validation-schema="newFolderSchema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <Field
          name="folderName"
          type="text"
          class="form-control"
          placeholder="Folder Name"
        />
        <ErrorMessage name="folderName" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Create</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="moveFileMenuVisible"
    modal
    header="Select Folder"
    :style="{ width: '40vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <Form
      @submit="moveFile"
      :validation-schema="moveFileSchema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <Field name="folderId" as="select" class="dropdown">
          <option
            v-for="item in directories"
            v-bind:key="item"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </Field>
        <ErrorMessage name="folderId" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Move</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { useAuthStore } from "@/stores/AuthStore";
import UserService from "@/services/user.service.js";
import { showToast, showErrorToast, websiteURL } from "@/utils";
import { Folder, Upload } from "@/types";
import heic2any from "heic2any";

const columns = [
  { key: "fileName", label: "Name", sortable: true },
  { key: "updatedAt", label: "Last Modified", sortable: true },
  { key: "size" },
];
const AuthStore = useAuthStore();
let uploads = [] as any[];
let directories = ref([] as any[]);
let files = [] as any[];
let items = ref([] as any[]);
let showCustomContent = ref(false);
let deleteMenuVisible = ref(false);
let newFolderMenuVisible = ref(false);
let moveFileMenuVisible = ref(false);
let resource = ref({} as Upload);
let path = ref(["My Drive"]);
let loading = ref(false);
let message = ref("");
let selectedItem = ref([] as any[]);

const currentUser = AuthStore.auth;

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const handleSubmission = (data) => {
  loading.value = true;
  // console.log(selectedItem.value[0].id);
  data.contentId = selectedItem.value[0].id;
  UserService.deleteContent(data).then(
    () => {
      window.location.reload();
      showToast("Content deleted successfully.");
      deleteMenuVisible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const formatFileSize = (fileSizeInBytes) => {
  if (fileSizeInBytes < 1024 * 1024) {
    const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
    return fileSizeInKB + " KB";
  } else {
    const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2);
    return fileSizeInMB + " MB";
  }
};

const populateDirectoryStructure = (data) => {
  files.length = 0;
  items.value.length = 0;
  files.push(...data.filter((e) => e.folderId === null));
  items.value.push(...data.filter((e) => e.folderId === null));
  directories.value.forEach((el) => {
    let temp = {} as Folder;
    temp.folderId = el.id;
    temp.fileName = el.name;
    temp.files = data.filter((e) => e.folderId === el.id);
    temp.updatedAt = temp.files?.reduce(
      (prev, curr) => (prev.createdOn < curr.createdOn ? prev : curr),
      el
    ).updatedAt;
    files.push(temp);
    items.value.push(temp);
  });
  // console.log(directories.value);
  // items.value = files;
  console.log(items.value);
};

const newFolderSchema = yup.object().shape({
  folderName: yup.string().trim().required("Folder Name is required!"),
});

const moveFileSchema = yup.object().shape({
  folderId: yup.number().required("Folder Name is required!"),
});

const getFolders = () => {
  loading.value = true;
  UserService.getFolders().then(
    (response) => {
      console.log(response.data);
      uploads = response.data.uploads;
      uploads.map(
        (el) => (el.updatedAt = new Date(el.updatedAt).toDateString())
      );
      uploads.map((el) => (el.size = formatFileSize(el.size)));
      directories.value = response.data.folders;
      directories.value.map(
        (el) => (el.updatedAt = new Date(el.updatedAt).toDateString())
      );
      populateDirectoryStructure(uploads);
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const createNewFolder = (data) => {
  if (directories.value.map((el) => el.name).includes(data.folderName)) {
    showErrorToast("Folder Already Exists");
  } else {
    loading.value = true;
    let folder = {
      name: data.folderName,
      status: "01",
      userId: currentUser.id,
    };
    UserService.createFolder(folder).then(
      (response) => {
        showToast("New Folder created successfully.");
        getFolders();
        newFolderMenuVisible.value = false;
        loading.value = false;
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const moveFile = (data) => {
  console.log(data);
  const index = uploads.findIndex((el) => el.id === selectedItem.value[0].id);
  uploads[index].folderId = data.folderId;
  loading.value = true;
  UserService.moveFile(selectedItem.value[0].id, data).then(
    (response) => {
      if (path.value.length === 1) {
        const selectedItemItemsIndex = items.value.findIndex(
          (el) => el.id === selectedItem.value[0].id
        );
        items.value.splice(selectedItemItemsIndex, 1);
        const newFolderItemsIndex = items.value.findIndex(
          (el) => el.folderId === data.folderId
        );
        items.value[newFolderItemsIndex].files.push(selectedItem.value[0]);
      } else {
        getFolders();
        path.value = ["My Drive"];
      }
      moveFileMenuVisible.value = false;
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const selectItem = (e) => {
  selectedItem.value.length = 0;
  selectedItem.value.push(e.item);
};

const handleClick = (e) => {
  // console.log(e.item);
  selectedItem.value.length = 0;
  if ("files" in e.item) {
    path.value.push(e.item.fileName);
    items.value = e.item.files;
  } else {
    resource.value = e.item;
    console.log(resource.value);
    if (
      resource.value.mimetype &&
      resource.value.mimetype.startsWith("application")
    ) {
      convertImages(resource.value);
    }
    if (resource.value.type === "Document") {
      // console.log(resource);
      const a = document.createElement("a");
      a.href = websiteURL + resource.value.filePath.substring(9);
      a.download = resource.value.fileName;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      showCustomContent.value = true;
    }
  }
};

const handleRightClick = (e) => {
  let event = e.event;
  selectedItem.value.length = 0;
  selectedItem.value.push(e.item);
  event.preventDefault();
  if (!("files" in selectedItem.value[0])) {
    let contextMenu = document.getElementById("context-menu");
    let mouseX = event.clientX || event.touches[0].clientX;
    let mouseY = event.clientY || event.touches[0].clientY;
    let menuHeight = contextMenu?.getBoundingClientRect().height || 0;
    let menuWidth = contextMenu?.getBoundingClientRect().width || 0;
    let width = window.innerWidth;
    let height = window.innerHeight;
    //If user clicks/touches near right corner
    if (width - mouseX <= 200) {
      contextMenu!.style.borderRadius = "5px 0 5px 5px";
      contextMenu!.style.left = width - menuWidth + "px";
      contextMenu!.style.top = mouseY + "px";
      //right bottom
      if (height - mouseY <= 200) {
        contextMenu!.style.top = mouseY - menuHeight + "px";
        contextMenu!.style.borderRadius = "5px 5px 0 5px";
      }
    }
    //left
    else {
      contextMenu!.style.borderRadius = "0 5px 5px 5px";
      contextMenu!.style.left = mouseX + "px";
      contextMenu!.style.top = mouseY + "px";
      //left bottom
      if (height - mouseY <= 200) {
        contextMenu!.style.top = mouseY - menuHeight + "px";
        contextMenu!.style.borderRadius = "5px 5px 5px 0";
      }
    }
    //display the menu
    contextMenu!.style.visibility = "visible";
    document.addEventListener("click", function (ev) {
      if (!contextMenu!.contains(ev.target as Node)) {
        contextMenu!.style.visibility = "hidden";
      }
    });
  }
};

const changeDirectory = (e) => {
  // console.log(e.target.textContent);
  if (e.target.textContent === "My Drive") {
    path.value.pop();
    items.value = files;
  }
};

const getMyUploads = () => {
  loading.value = true;
  UserService.getMyUploads().then(
    (response) => {
      uploads = response.data;
      uploads.map(
        (el) => (el.updatedAt = new Date(el.updatedAt).toDateString())
      );
      uploads.map((el) => (el.size = formatFileSize(el.size)));
      populateDirectoryStructure(uploads);
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const convertImg = async (url, el) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => heic2any({ blob }))
    .then((conversionResult) => {
      // conversionResult is a BLOB of the PNG formatted image
      el.convertBlob = URL.createObjectURL(conversionResult as Blob);
      el.imgConversion = true;
    })
    .catch((e) => {
      // see error handling section
    });
};

const convertImages = async (el) => {
  // const promises = [];
  if (el.mimetype && el.mimetype.startsWith("application")) {
    el.imgConversion = false;
    await convertImg(websiteURL + el.filePath.substring(9), el);
  }
};

getFolders();
// getMyUploads();
</script>

<style scoped>
ul > li {
  display: inline-block;
  margin: 1em;
}

#context-menu {
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(37, 40, 42, 0.22);
  color: #1f194c;
  width: 8rem;
  padding: 0rem;
  position: fixed;
  visibility: hidden;
}

.item {
  padding: 0.5rem 0.75rem;
}

.item:hover {
  background-color: rgba(44, 141, 247, 0.2);
  cursor: pointer;
}

.model-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

video {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
</style>
