<template>
  <div class="sidebar sidebar-max min-height text-align-center">
    <img
      id="profile-img"
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      class="profile-img-card"
    />
    <span>
      {{ currentUser.firstName + " " + currentUser.lastName }}<br />
    </span>
    <br /><br />
    <router-link :to="{ name: 'admin-feed' }">
      <div
        class="sidebar-button pl-3"
        :class="[
          route.path === '/admin/home'
            ? 'selected-sidebar-button'
            : 'unselected-sidebar-button',
        ]"
        title="Home"
      >
        <img src="@/assets/images/process.png" class="sidebar-image-large" />
        <span class="ml-2">Home</span>
      </div>
    </router-link>
    <router-link :to="{ name: 'view-notifications-admin' }">
      <div
        class="sidebar-button pl-3"
        :class="[
          route.path === '/admin/notifications'
            ? 'selected-sidebar-button'
            : 'unselected-sidebar-button',
        ]"
        title="Your notifications will appear here"
      >
        <font-awesome-icon
          class="sidebar-icon"
          icon="fa-regular fa-bell"
          style="color: #fcc62a"
        />
        <span style="position: relative">
          <span class="notification-count">{{ NotificationStore.count }}</span>
        </span>
        <span class="ml-3">View Notifications</span>
      </div>
    </router-link>
  </div>
  <div class="sidebar sidebar-min min-height">
    <profile-icon></profile-icon>
    <div>
      <div class="mt-3">
        <router-link :to="{ name: 'view-notifications-admin' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/admin/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Your notifications will appear here"
          >
            <font-awesome-icon
              class="sidebar-icon"
              icon="fa-regular fa-bell"
              style="color: #fcc62a"
            />
            <span style="position: relative">
              <span class="notification-count">{{
                NotificationStore.count
              }}</span>
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/AuthStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useRoute } from "vue-router";
import ProfileIcon from "@/components/user/ProfileIcon.vue";

const AuthStore = useAuthStore();
const NotificationStore = useNotificationStore();
const route = useRoute();

const currentUser = AuthStore.auth;

NotificationStore.getAdminNotification();
</script>

<style scoped></style>
