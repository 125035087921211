import { useToast } from "vue-toast-notification";
import { Course, CourseRequest } from "@/types";
const toast = useToast();

// export const websiteURL = "http://localhost:8080/";
// export const websiteURL = "http://conpec.mlsoc.vt.edu:8085/";
export const websiteURL = "https://conpec.mlsoc.vt.edu/";

export const longMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function checkIfFilesAreTooBig(value, contest, files?: File[]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 25) {
        valid = false;
      }
    });
  }
  return valid;
}

export const isJSONObject = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const formatSubjectSpeak = (s, o) => {
  const subjectSpeak = JSON.parse(s || "[]");
  const subjectSpeakOthers = JSON.parse(o || "[]");
  if (subjectSpeak.length == 0 && subjectSpeakOthers.length == 0) return "N/A";
  const index = subjectSpeak.indexOf("Others");
  if (index > -1) {
    subjectSpeak.splice(index, 1);
    return subjectSpeak.concat(subjectSpeakOthers).join(", ");
  } else return subjectSpeak.join(", ");
};

export const sameDate = (s, d, t) => {
  if (s && d && t)
    return new Date(s).getTime() == new Date(d + "T" + t).getTime();
  else return false;
};

export const formatTime = (t) => {
  if (t == null || t == undefined) return;
  const hours = t.split(":")[0];
  return hours > 12 ? hours - 12 + ":" + t.split(":")[1] + " PM" : t + " AM";
};

export const formatDate = (d) => {
  const [year, month, day] = d.split("-");
  return new Date(+year, +month - 1, +day).toLocaleDateString();
};

export const displayLabels = (values) => {
  const value = JSON.parse(JSON.stringify(values)).map((x) => x["label"]);
  return value.join(", ");
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const mulGroupBy = function (xs, f) {
  // return xs.reduce(function (rv, x) {
  //   console.log(rv);
  //   console.log(x);
  //   const key = x[key1] + "-" + x[key2];
  //   console.log(key);
  //   (rv[x[key]] = rv[x[key]] || []).push(x);
  //   return rv;
  // }, {});
  const groups = {};
  xs.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return groups;
};

export const getTimeDiff = (date) => {
  const millisecondsDifference: number = +new Date() - +new Date(date);
  // Calculate the various time units
  const millisecondsInMinute = 1000 * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;
  const millisecondsInWeek = millisecondsInDay * 7;
  const millisecondsInYear = millisecondsInDay * 365.25;

  if (millisecondsDifference >= millisecondsInYear) {
    const years = Math.floor(millisecondsDifference / millisecondsInYear);
    return `${years}y`;
  } else if (millisecondsDifference >= millisecondsInWeek) {
    const weeks = Math.floor(millisecondsDifference / millisecondsInWeek);
    return `${weeks}w`;
  } else if (millisecondsDifference >= millisecondsInDay) {
    const days = Math.floor(millisecondsDifference / millisecondsInDay);
    return `${days}d`;
  } else if (millisecondsDifference >= millisecondsInHour) {
    const hours = Math.floor(millisecondsDifference / millisecondsInHour);
    return `${hours}h`;
  } else {
    const minutes = Math.floor(millisecondsDifference / millisecondsInMinute);
    return `${minutes}m`;
  }
};

export const validateOptions = (e, s, k) => {
  if (e[0] === k && e.length > 1) {
    s.remove(k);
  } else if (e[e.length - 1] === k && e.length > 1) {
    s.clear();
    s.select(k);
  }
};

export const validateOptNone = (e, s) => {
  validateOptions(e, s, "None");
};
export const validateOptAll = (e, s) => {
  validateOptions(e, s, "All");
};
export const validateOptNoPref = (e, s) => {
  validateOptions(e, s, "No preference");
};

export const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};

export const getTimezoneName = () => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: "long" });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

export const responseParser = (availability, response, availabilityObjects) => {
  for (const key in response) {
    if (availabilityObjects.includes(key)) {
      availability[key] = JSON.parse(response[key]) || '[""]';
    } else {
      availability[key] = response[key];
    }
  }
};

export const convertCourseToCourseRequest = (course: Course) => {
  const courseRequest = {} as CourseRequest;
  courseRequest.courseCode = course.courseCode;
  courseRequest.courseTitle = course.courseTitle;
  courseRequest.academicLevel = course.academicLevel;
  courseRequest.studentDept = course.studentDept;
  courseRequest.classSize = course.classSize;
  courseRequest.additionalInformation = course.additionalInformation;
  courseRequest.location = course.location;
  courseRequest.semester = course.semester;
  courseRequest.year = course.year;
  return courseRequest;
};

export const showVisualRep = (value) => {
  if (value === "Labs" || value === "Site Visit" || value === "Workshop") {
    return true;
  }
  return false;
};

export const showPriorKnowledge = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Workshop"
  ) {
    return true;
  }
  return false;
};

export const showLocation = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Workshop" ||
    value === "Judge for Project" ||
    value === "Sponsor for Project" ||
    value === "Labs"
  ) {
    return true;
  }
  return false;
};

export const isCapstoneTermProject = (value) => {
  if (
    value === "Judge for Project" ||
    value === "Mentor for Project" ||
    value === "Sponsor for Project"
  ) {
    return true;
  }
  return false;
};

export const showClassroomItems = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Workshop" ||
    value === "Labs"
  ) {
    return true;
  }
  return false;
};

export const showAssignmentConnected = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Site Visit" ||
    value === "Workshop" ||
    value === "Labs"
  ) {
    return true;
  }
  return false;
};

export const showInfoToast = (msg) => {
  toast.info(msg, {
    position: "top-right",
    duration: 5000,
  });
};

export const showToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    duration: 5000,
  });
};

export const showErrorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    duration: 5000,
    type: "error",
  });
};

export const valid = (e) => {
  return e && e.length > 0;
};

export const courseReqTypeOptions = [
  "Site Visit",
  "Seminar",
  "Workshop",
  "Guest Lecture",
  "Judge for Project",
  "Mentor for Project",
  "Sponsor for Project",
  "Labs",
];

export const physicalDisOptions = [
  "None",
  "Mobility impairment",
  "Hearing impairment",
  "Vision impairment",
  "Speech impairment",
  "Autism",
  "Attention-deficit/hyperactivity disorder (ADHD)",
  "Others",
];
export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "Non-binary" },
  { label: "Others, please specify", value: "Others" },
];

export const ethnicityOptions = [
  {
    label: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native",
  },
  { label: "Asian", value: "Asian" },
  { label: "Black or African American", value: "Black or African American" },
  { label: "Hispanic or Latinx", value: "Hispanic or Latinx" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  {
    label: "Native Hawaiian or other Pacific Islander",
    value: "Native Hawaiian or other Pacific Islander",
  },
  { label: "White/Caucasian", value: "White/Caucasian" },
  { label: "Others, please specify", value: "Others" },
];

export const safetyReqOptions = [
  { label: "All", value: "All" },
  { label: "Not Required", value: "Not Required" },
  { label: "Face mask", value: "Face mask" },
  { label: "Face shield", value: "Face shield" },
  { label: "Glove", value: "Glove" },
  { label: "Hardhat", value: "Hardhat" },
  { label: "Life vest", value: "Life vest" },
  { label: "Safety boot", value: "Safety boot" },
  { label: "Safety glasses", value: "Safety glasses" },
  { label: "Safety vest", value: "Safety vest" },
  { label: "Others, please specify", value: "Others" },
];

export const projectStageOptions = [
  { label: "Site layout", value: "Site layout" },
  { label: "Excavation", value: "Excavation" },
  { label: "Foundation", value: "Foundation" },
  { label: "Casting of beams and slabs", value: "Casting of beams and slabs" },
  {
    label: "Concreting and Brick masonry",
    value: "Concreting and Brick masonry",
  },
  { label: "Flooring", value: "Flooring" },
  { label: "Rough framing", value: "Rough framing" },
  {
    label: "Mechanical Electrical and Plumbing",
    value: "Mechanical Electrical and Plumbing",
  },
  { label: "Drywall Installation", value: "Drywall Installation" },
  { label: "Roofing", value: "Roofing" },
  { label: "Finishing", value: "Finishing" },
  { label: "Demolition", value: "Demolition" },
  { label: "Others, please specify", value: "Others" },
];

export const countryOptions = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo {Democratic Rep}",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland {Republic}",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, {Burma}",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const stateOptions = [
  { label: "Alabama", value: "AL", region: "Southeast" },
  { label: "Alaska", value: "AK", region: "West" },
  { label: "Arizona", value: "AZ", region: "Southwest" },
  { label: "Arkansas", value: "AR", region: "Southeast" },
  { label: "California", value: "CA", region: "West" },
  { label: "Colorado", value: "CO", region: "West" },
  { label: "Connecticut", value: "CT", region: "Northeast" },
  { label: "Delaware", value: "DE", region: "Northeast" },
  { label: "District Of Columbia", value: "DC", region: "Southeast" },
  { label: "Florida", value: "FL", region: "Southeast" },
  { label: "Georgia", value: "GA", region: "Southeast" },
  { label: "Hawaii", value: "HI", region: "West" },
  { label: "Idaho", value: "ID", region: "West" },
  { label: "Illinois", value: "IL", region: "Midwest" },
  { label: "Indiana", value: "IN", region: "Midwest" },
  { label: "Iowa", value: "IA", region: "Midwest" },
  { label: "Kansas", value: "KS", region: "Midwest" },
  { label: "Kentucky", value: "KY", region: "Southeast" },
  { label: "Louisiana", value: "LA", region: "Southeast" },
  { label: "Maine", value: "ME", region: "Northeast" },
  { label: "Maryland", value: "MD", region: "Northeast" },
  { label: "Massachusetts", value: "MA", region: "Northeast" },
  { label: "Michigan", value: "MI", region: "Midwest" },
  { label: "Minnesota", value: "MN", region: "Midwest" },
  { label: "Mississippi", value: "MS", region: "Southeast" },
  { label: "Missouri", value: "MO", region: "Midwest" },
  { label: "Montana", value: "MT", region: "West" },
  { label: "Nebraska", value: "NE", region: "Midwest" },
  { label: "Nevada", value: "NV", region: "West" },
  { label: "New Hampshire", value: "NH", region: "Northeast" },
  { label: "New Jersey", value: "NJ", region: "Northeast" },
  { label: "New Mexico", value: "NM", region: "Southwest" },
  { label: "New York", value: "NY", region: "Northeast" },
  { label: "North Carolina", value: "NC", region: "Southeast" },
  { label: "North Dakota", value: "ND", region: "Midwest" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH", region: "Midwest" },
  { label: "Oklahoma", value: "OK", region: "Southwest" },
  { label: "Oregon", value: "OR", region: "West" },
  { label: "Pennsylvania", value: "PA", region: "Northeast" },
  { label: "Rhode Island", value: "RI", region: "Northeast" },
  { label: "South Carolina", value: "SC", region: "Southeast" },
  { label: "South Dakota", value: "SD", region: "Midwest" },
  { label: "Tennessee", value: "TN", region: "Southeast" },
  { label: "Texas", value: "TX", region: "Southwest" },
  { label: "Utah", value: "UT", region: "West" },
  { label: "Vermont", value: "VT", region: "Northeast" },
  { label: "Virginia", value: "VA", region: "Southeast" },
  { label: "Washington", value: "WA", region: "West" },
  { label: "West Virginia", value: "WV", region: "Southeast" },
  { label: "Wisconsin", value: "WI", region: "Midwest" },
  { label: "Wyoming", value: "WY", region: "West" },
];
export const insDeliveryTypeOptions = [
  "In-person",
  "Virtual",
  "Virtual or In-person",
];
export const insDeliveryTypeSiteVisitOptions = [
  "On-site",
  "Virtual site visit",
  "Virtual site visit or On-site",
];
export const typeOfProjectOptions = [
  "Capstone Project",
  "Course/Classroom/Term project",
];
export const areaOfExpertiseOptions = [
  "Architectural Engineering",
  "Architecture",
  "Building and Construction Inspection",
  "Building Control",
  "Carpenter",
  "Civil Engineering",
  "Construction Data Analytics",
  "Construction Management",
  "Drone Operator",
  "Dry Wall and Ceiling Tile Installer",
  "Electrical Engineering",
  "Electrician",
  "Estimating",
  "Executive/Legal",
  "Facilities Management & Building Operation",
  "Geotechnical Engineering",
  "Glazier",
  "Health and Safety",
  "HVAC",
  "Landscape Architecture",
  "Mason",
  "Mechanical Engineering",
  "MEP Specialist",
  "MEP works",
  "Operation Management",
  "Owner's Representative",
  "Painter",
  "Plumber",
  "Project Management",
  "Real Estate Development",
  "Roofer",
  "Scheduler",
  "Site Management",
  "Site Superintendent",
  "Smart Building",
  "Structural Engineering",
  "Virtual Design Construction Manager",
];
export const univOptions = [
  "A.T. Still University",
  "Abilene Christian University",
  "Academy of Art University",
  "Acupuncture & Integrative Medicine College",
  "Acupuncture Massage College",
  "Adams State University",
  "Adance Computing Institute",
  "Adelphi University",
  "Adrian College",
  "AdventHealth University",
  "Agnes Scott College",
  "AIB College of Business",
  "Aims Community College",
  "Air Force Institute of Technology",
  "Alabama A&M University",
  "Alabama State University",
  "Alamance Community College",
  "Alamo Colleges",
  "Alaska Christian College",
  "Alaska Pacific University",
  "Albany State University",
  "Albany Technical College",
  "Albertus Magnus College",
  "Albion College",
  "Albizu University, Miami",
  "Albizu University, San Juan",
  "Alcorn State University",
  "Alexandria Technical & Community College",
  "Alfred University",
  "Allan Hancock College",
  "Allegheny College",
  "Allen University",
  "Alliant International University",
  "Allied American University",
  "Alma College",
  "Alpena Community College",
  "Altierus Career College, Bissonnet",
  "Altierus Career College, Tampa",
  "Alvernia University",
  "Alverno College",
  "Alvin Community College",
  "Amarillo College",
  "Amberton University",
  "American Baptist College",
  "American InterContinental University",
  "American International College",
  "American River College",
  "American University",
  "American University of Puerto Rico, Bayamon",
  "American University of Puerto Rico, Manati",
  "Amherst College",
  "Ana G. Mendez University",
  "Anabaptist Mennonite Biblical Seminary",
  "Anaheim University",
  "Anderson University",
  "Andrews University",
  "Angeles College",
  "Angelina College",
  "Angelo State University",
  "Anna Maria College",
  "Anne Arundel Community College",
  "Anoka-Ramsey Community College",
  "Antelope Valley College",
  "Antelope Valley Community College District",
  "Antioch University Los Angeles",
  "Antioch University New England",
  "Antioch University, Santa Barbara",
  "Appalachian State University",
  "Aquinas College",
  "Aquinas Institute of Theology",
  "Arapahoe Community College",
  "Arcadia University",
  "Argosy University",
  "Arizona Christian University",
  "Arizona State University",
  "Arizona Western College",
  "Arkansas Baptist College",
  "Arkansas Northeastern College",
  "Arkansas State University",
  "Arkansas Tech University",
  "Armstrong Atlantic State University",
  "Armstrong University",
  "Art Center College of Design",
  "ASA College",
  "Asbury University",
  "Asheville-Buncombe Technical Community College",
  "Ashford University",
  "Ashland University",
  "Asnuntuck Community College",
  "Assemblies of God Theological Seminary",
  "Assumption College",
  "Assumption University",
  "Atenas College",
  "Athens State University",
  "ATI College",
  "Atlanta Metropolitan State College",
  "Atlanta Technical College",
  "Atlanta’s John Marshall Law School",
  "Atlantic Cape Community College",
  "Atlantic Institute of Oriental Medicine",
  "Atlantic University College",
  "Auburn University",
  "Auburn University at Montgomery",
  "Augsburg College",
  "Augsburg University",
  "Augusta State University",
  "Augusta University",
  "Augustana College",
  "Augustana University",
  "Aultman College of Nursing and Health Sciences",
  "Auraria Higher Education Center",
  "Aurora University",
  "Austin College",
  "Austin Community College District",
  "Austin Graduate School of Theology",
  "Austin Peay State University",
  "Averett University",
  "Avila University",
  "Azusa Pacific University",
  "Babson College",
  "Baker College",
  "Baker University",
  "Bakersfield College",
  "Baldwin Wallace University",
  "Ball State University",
  "Baltimore City Community College",
  "Baptist Bible College & Seminary",
  "Baptist Health System School of Health Professions",
  "Baptist University of the Americas",
  "Bard College",
  "Barnard College",
  "Barry University",
  "Barstow Community College",
  "Bates College",
  "Bay College",
  "Bay Path University",
  "Bayamón Central University",
  "Baylor University",
  "Belhaven University",
  "Bellarmine University",
  "Bellevue College",
  "Bellevue University",
  "Belmont College",
  "Belmont University",
  "Beloit College",
  "Bemidji State University",
  "Benedict College",
  "Benedictine College",
  "Benedictine University",
  "Benjamin Franklin Institute of Technology",
  "Benjamin Franklin University",
  "Bennett College",
  "Bennington College",
  "Bentley University",
  "Berea College",
  "Bergen Community College",
  "Berkeley City College",
  "Berkeley University of California",
  "Berklee College of Music",
  "Berkshire Community College",
  "Berry College",
  "Bethel College Indiana",
  "Bethel University",
  "Bethune-Cookman University",
  "Big Bend Community College",
  "Binghamton University",
  "Biola University",
  "Birmingham-Southern College",
  "Bishop State Community College",
  "Black Hills State University",
  "Blackfeet Community College",
  "Bladen Community College",
  "Blinn College",
  "Bloomfield College",
  "Bloomsburg University",
  "Bloomsburg University of Pennsylvania",
  "Blue Mountain Community College",
  "Bluefield State College",
  "Bob Jones University",
  "Body Therapy Institute",
  "Boise State University",
  "Boricua College",
  "Bossier Parish Community College",
  "Boston Architectural College",
  "Boston College",
  "Boston University",
  "Bowdoin College",
  "Bowie State University",
  "Bowling Green State University",
  "Bradley University",
  "Brandeis University",
  "Brandman University",
  "Brazosport College",
  "Brenau University",
  "Brescia University",
  "Briar Cliff University",
  "Bridgewater State University",
  "Brigham Young University",
  "Brigham Young University Hawaii",
  "Brigham Young University Idaho",
  "Brookdale Community College",
  "Brookhaven College",
  "Broward College",
  "Brown Mackie College",
  "Brown University",
  "Bryan College",
  "Bryan College of Health Sciences",
  "Bryan University",
  "Bryant University",
  "Bryn Mawr College",
  "Bucknell University",
  "Bucks County Community College",
  "Buena Vista University",
  "Buffalo State University",
  "Bunker Hill Community College",
  "Burlington County College",
  "Bushnell University",
  "Butler Community College",
  "Butler County Community College",
  "Butler University",
  "Butte College",
  "Cabrillo College",
  "Cabrini University",
  "Cairn University",
  "Caldwell University",
  "Calhoun Community College",
  "California Baptist University",
  "California Christian College",
  "California College of the Arts",
  "California Community Colleges Chanellor’s Office",
  "California Institute of Integral Studies",
  "California Institute of Technology",
  "California Institute of the Arts",
  "California Lutheran University",
  "California Miramar University",
  "California Polytechnic State University",
  "California State Polytechnic University Pomona",
  "California State Polytechnic University, Humboldt",
  "California State University",
  "California State University - Hayward",
  "California State University Bakersfield",
  "California State University Channel Islands",
  "California State University Dominguez Hills",
  "California State University East Bay",
  "California State University Fullerton",
  "California State University Long Beach",
  "California State University Monterey Bay",
  "California State University Northridge",
  "California State University Sacramento",
  "California State University San Bernardino",
  "California State University San Marcos",
  "California State University Stanislaus",
  "California State University, Bakersfield",
  "California State University, Channel Islands",
  "California State University, Chico",
  "California State University, Fresno",
  "California State University, Los Angeles",
  "California University of Pennsylvania",
  "California Western School of Law San Diego",
  "Calumet College of Saint Joseph",
  "Calvin College",
  "Calvin University",
  "Cambridge College",
  "Camden County College",
  "Cameron University",
  "Campbell University",
  "Campbellsville University",
  "Canada College",
  "Canisius College",
  "Canton State University of New York",
  "Cape Fear Community College",
  "Capital Community College",
  "Capital University",
  "Cardinal Stritch University",
  "Career Point College",
  "Caribbean University, Bayamon",
  "Caribbean University, Carolina",
  "Caribbean University, Ponce",
  "Caribbean University, Vega Baja",
  "Carleton College",
  "Carlos Albizu University",
  "Carlow University",
  "Carnegie Institution for Science",
  "Carnegie Mellon University",
  "Carroll College",
  "Carroll Community College",
  "Carroll University",
  "Carson-Newman University",
  "Carthage College",
  "Carver College",
  "Casa Loma College, Van Nuys",
  "Cascadia Community College",
  "Case Western Reserve University",
  "Casper College",
  "Castleton State College of Vermont",
  "Cazenovia College",
  "Cedar Crest College",
  "Cedar Valley College",
  "Cedarville University",
  "Centenary College of Louisiana",
  "Centenary College of New Jersey",
  "Centenary University",
  "Central Arizona College",
  "Central Baptist College",
  "Central College",
  "Central Connecticut State University",
  "Central Georgia Technical College",
  "Central Lakes College",
  "Central Maine Community College",
  "Central Methodist University",
  "Central Michigan University",
  "Central New Mexico",
  "Central New Mexico Community College",
  "Central Ohio Technical College",
  "Central Oregon Community College",
  "Central Piedmont Community College",
  "Central State University",
  "Central Texas College",
  "Central Washington University",
  "Central Wyoming College",
  "Centralia College",
  "Centre College",
  "Centura College",
  "Century College",
  "Cerritos College",
  "Cerro Coso Community College",
  "Chadron State College",
  "Chaffey College",
  "Chaminade University",
  "Chaminade University of Honolulu",
  "Champlain College",
  "Chandler-Gilbert Community College",
  "Chapman University",
  "Charleston Southern University",
  "Charlotte School of Law",
  "Charter College",
  "Chatham University",
  "Chemeketa Community College",
  "Chesapeake College",
  "Chestnut Hill College",
  "Cheyney University of Pennsylvania",
  "Chicago ORT Technical Institute",
  "Chicago School of Professional Psychology at Chicago",
  "Chicago State University",
  "Child Care Education Institute",
  "Chipola College",
  "Chippewa Valley Technical College",
  "Chowan University",
  "Christendom College",
  "Christian Brothers University",
  "Christopher Newport University",
  "Cincinnati College of Mortuary Science",
  "Cincinnati State Technical and Community College",
  "Cisco College",
  "Citrus College",
  "City College Miami",
  "City College of New York",
  "City College of San Francisco",
  "City Colleges of Chicago",
  "City Colleges of Chicago, Harold Washington College",
  "City Colleges of Chicago, Harry S Truman College",
  "City Colleges of Chicago, Malcolm X College",
  "City Colleges of Chicago, Olive-Harvey College",
  "City Colleges of Chicago, Richard J. Daley College",
  "City Colleges of Chicago, Wilbur Wright College",
  "City University of New York, Bernard Baruch",
  "City University of New York, Bernard M. Baruch College",
  "City University of New York, Bronx Community College",
  "City University of New York, Brooklyn College",
  "City University of New York, City College",
  "City University of New York, College of Staten Island",
  "City University of New York, Hostos Community College",
  "City University of New York, Hunter College",
  "City University of New York, John Jay College of Criminal Justice",
  "City University of New York, LaGuardia Community College",
  "City University of New York, Lehman College",
  "City University of New York, New York City College of Technology",
  "City University of New York, Queens College",
  "City University of New York, Queensborough Community College",
  "City University of New York, Stella and Charles Guttman Community College",
  "City University of New York, York College",
  "City University of Seattle",
  "Claflin University",
  "Claremont Graduate University",
  "Claremont McKenna College",
  "Clarion University",
  "Clarion University of Pennsylvania",
  "Clark Atlanta University",
  "Clark College",
  "Clark University",
  "Clarke University",
  "Clarkson University",
  "Clatsop Community College",
  "Clayton State University",
  "Cleary University",
  "Clemson University",
  "Cleveland State University",
  "Clinton College",
  "Clinton Community College",
  "Clovis Community College",
  "Coahoma Community College",
  "Coastal Bend College",
  "Coastal Carolina University",
  "Coastline Community College",
  "Cobleskill University",
  "Cochise County Community College District",
  "Coe College",
  "Cogswell Polytechnical College",
  "Coker College",
  "Colby College",
  "Colby Sawyer College",
  "Colegio Universitario de San Juan",
  "Colgate University",
  "College for Creative Studies",
  "College of Alameda",
  "College of Central Florida",
  "College of Charleston",
  "College of DuPage",
  "College of Environmental Science and Forestry",
  "College of Lake County",
  "College of Marin",
  "College of Mount Saint Vincent",
  "College of Mount St. Vincent",
  "College of Saint Benedict, Saint John’s University",
  "College of Saint Mary",
  "College of San Mateo",
  "College of Southern Idaho",
  "College of Southern Maryland",
  "College of Southern Nevada",
  "College of the Atlantic",
  "College of the Canyons",
  "College of the Desert",
  "College of the Florida Keys",
  "College of the Holy Cross",
  "College of the Mainland",
  "College of the Redwoods",
  "College of the Sequoias",
  "College of the Siskiyous",
  "College Unbound",
  "Colorado Christian University",
  "Colorado College",
  "Colorado Heights University",
  "Colorado Mesa University",
  "Colorado Mountain College",
  "Colorado School of Mines",
  "Colorado State University",
  "Colorado State University-Pueblo",
  "Colorado Technical University",
  "Columbia Basin College",
  "Columbia College",
  "Columbia College Chicago",
  "Columbia College South Carolina",
  "Columbia International University",
  "Columbia School of English",
  "Columbia State Community College",
  "Columbia University in the City of New York",
  "Columbus State Community College",
  "Columbus State University",
  "Columbus Technical College",
  "Commonwealth University of Pennsylvania",
  "Community College of Allegheny County",
  "Community College of Aurora",
  "Community College of Denver",
  "Community College of Philadelphia",
  "Community College of Rhode Island",
  "Community College System of New Hampshire",
  "Compton College",
  "Concord University",
  "Concordia College",
  "Concordia College New York",
  "Concordia University (Oregon)",
  "Concordia University Nebraska",
  "Concordia University Portland Oregon",
  "Concordia University Saint Paul",
  "Concordia University Texas",
  "Concordia University Wisconsin",
  "Concordia University, Chicago",
  "Concordia University, Irvine",
  "Connecticut College",
  "Connors State College",
  "Conservatory of Music of Puerto Rico",
  "Contra Costa College",
  "Copper Mountain College",
  "Coppin State University",
  "Cornell College",
  "Cornell University",
  "Cornerstone University",
  "Cortland College",
  "Cossatot Community College of the University of Arkansas",
  "Cosumnes River College",
  "County College of Morris",
  "Covenant College",
  "Crafton Hills College",
  "Craven Community College",
  "Creighton University",
  "Crowder College",
  "Crown College",
  "Cuesta College",
  "Culinary Institute LeNotre",
  "Culinary Institute of America",
  "Culver-Stockton College",
  "Cumberland University",
  "Curry College",
  "Cuyahoga Community College",
  "Cuyamaca College",
  "Cypress College",
  "D'Youville College",
  "Dabney S. Lancaster Community College",
  "Dade Medical College",
  "Daemen College",
  "Dakota College at Bottineau",
  "Dakota County Technical College",
  "Dakota State University",
  "Dakota Wesleyan University",
  "Dallas Baptist University",
  "Dallas College",
  "Dallas Nursing Institute",
  "Dalton State College",
  "Danville Area Community College",
  "Dartmouth College",
  "Darton State College",
  "Davenport University",
  "Davidson College",
  "Daytona State College",
  "DeAnza College",
  "Defiance College",
  "Del Mar College",
  "Delaware County Community College",
  "Delaware State University",
  "Delaware Technical Community College",
  "Delaware Valley College",
  "Delaware Valley University",
  "Delhi State University of New York",
  "Delta College",
  "Delta State University",
  "Denison University",
  "Denmark Technical College",
  "DePaul University",
  "DePauw University",
  "Des Moines Area Community College",
  "DeSales University",
  "DeVry University",
  "DeVry University - Addison",
  "DeVry University - Alpharetta",
  "DeVry University - Decatur",
  "DeVry University - Oak Brook Terrace",
  "Dewey University, Carolina",
  "Dewey University, Hato Rey",
  "Dewey University, Juana DÃ­az",
  "Dewey University, Manati",
  "Diablo Valley College",
  "Dickinson College",
  "Dickinson State University",
  "DigiPen Institute of Technology",
  "Dillard University",
  "Divine Word College",
  "Dixie State University",
  "Dominican College",
  "Dominican College of Blauvelt",
  "Dominican School of Philosophy and Theology",
  "Dominican University",
  "Dominican University of California",
  "Dominican University, River Forest, Illinois",
  "Dordt College",
  "Douglas J. Aveda Institute",
  "Dowling College",
  "Drake University",
  "Drew University",
  "Drexel University",
  "Drexel University College of Medicine",
  "Drury University",
  "Duke University",
  "Duquesne University",
  "Durham Technical Community College",
  "D’Youville College",
  "Earlham College",
  "East  Central University",
  "East Carolina University",
  "East Central University",
  "East Georgia State College",
  "East Los Angeles College",
  "East Mississippi Community College",
  "East Stroudsburg University",
  "East Tennessee State University",
  "East Texas Baptist University",
  "Eastern Connecticut State University",
  "Eastern Illinois University",
  "Eastern Iowa Community Colleges",
  "Eastern Kentucky University",
  "Eastern Mennonite University",
  "Eastern Michigan University",
  "Eastern New Mexico University",
  "Eastern New Mexico University, Ruidoso Branch Community College",
  "Eastern Oregon University",
  "Eastern University",
  "Eastern Virginia Medical School",
  "Eastern Washington University",
  "Eastern West Virgina Community and Technical College",
  "Eastwick College",
  "Ecclesia College",
  "Eckerd College",
  "Edgewood College",
  "Edinboro University",
  "Edinboro University of Pennsylvania",
  "Edison State College",
  "Edmonds Community College",
  "EDP University of Puerto Rico, Humacao",
  "EDP University of Puerto Rico, Manati",
  "EDP University of Puerto Rico, San Juan",
  "EDP University of Puerto Rico, San Sebastian",
  "EDP University of Puerto Rico, Villalba",
  "Edward Waters College",
  "El Camino College",
  "El Camino College Compton Center",
  "El Camino Community College District",
  "El Paso Community College",
  "Elim Bible Institute",
  "Elizabeth City State University",
  "Elizabethtown College",
  "Elmhurst College",
  "Elmhurst University",
  "Elmira College",
  "Elms College",
  "Elon University",
  "Embry-Riddle Aeronautical University",
  "Emerson College",
  "Emmanuel College",
  "Emory University",
  "Empire State College",
  "Empire State University",
  "Emporia State University",
  "Endicott College",
  "Enterprise State Community College",
  "Erie Community College State University of New York",
  "Escuela de Artes Plasticas y Diseno de Puerto Rico",
  "Essex County College",
  "Estrella Mountain Community College",
  "Evangel University",
  "Evangelical Seminary",
  "Everett Community College",
  "Everglades University",
  "Evergreen Valley College",
  "Excelsior College",
  "Ex’pression College",
  "Fairfield University",
  "Fairleigh Dickinson University",
  "Fairmont State University",
  "Faith Evangelical College and Seminary",
  "Family of Faith Christian University",
  "Farmingdale State College",
  "Fashion Institute of Design & Merchandising",
  "Fashion Institute of Technology",
  "Faulkner State Community College",
  "Faulkner University",
  "Fayetteville State University",
  "Fayetteville Technical Community College",
  "Felician College",
  "Felician University",
  "Ferris State University",
  "Ferrum College",
  "Fielding Graduate University",
  "Finger Lakes Community College",
  "Finlandia University",
  "Fisk University",
  "Fitchburg State University",
  "Five Towns College",
  "Flagler College",
  "Flathead Valley Community College",
  "Flint Hills Technical College",
  "Florence-Darlington Technical College",
  "Florida Agricultural and Mechanical University",
  "Florida Atlantic University",
  "Florida Gulf Coast University",
  "Florida Institute of Technology",
  "Florida International University",
  "Florida Memorial University",
  "Florida Metropolitan University - Fort Lauderdale",
  "Florida Metropolitan University - Pinellas",
  "Florida National University",
  "Florida Polytechnic University",
  "Florida Southern College",
  "Florida SouthWestern State College",
  "Florida State College at Jacksonville",
  "Florida State University",
  "Fontbonne University",
  "Foothill College",
  "Foothill-De Anza Community College District",
  "Fordham University",
  "Forrest College",
  "Forsyth Technical Community College",
  "Fort Hays State University",
  "Fort Lewis College",
  "Fort Peck Community College",
  "Fort Scott Community College",
  "Fort Valley State University",
  "Fortis Institute",
  "Framingham State University",
  "Francis Marion University",
  "Franciscan University of Steubenville",
  "Frank Phillips College",
  "Franklin & Marshall College",
  "Franklin Pierce University",
  "Franklin University",
  "Frederick Community College",
  "Frederick Taylor University",
  "Fredonia University",
  "Freed-Hardeman University",
  "Fresno City College",
  "Fresno Pacific University",
  "Fresno State",
  "Friends University",
  "Front Range Community College",
  "Frontier Nursing University",
  "Frostburg State University",
  "Fuller Theological Seminary",
  "Fullerton  College",
  "Furman University",
  "Gadsden State  Community College",
  "Galen College of Nursing",
  "Gallaudet University",
  "Galveston College",
  "Gannon University",
  "Garden City Community College",
  "Gardner-Webb University",
  "Garrett College",
  "Gaston College",
  "GateWay Community College",
  "Gateway Technical College",
  "Gather 4 Him Christian College",
  "Gavilan College",
  "Geneseo University",
  "Geneva College",
  "George Fox University",
  "George Mason University",
  "Georgetown College",
  "Georgetown University",
  "Georgia College",
  "Georgia College & State University",
  "Georgia Gwinnett College",
  "Georgia Institute of Technology",
  "Georgia Perimeter College",
  "Georgia South Western State University",
  "Georgia Southern University",
  "Georgia Southwestern State University",
  "Georgia State University",
  "Georgian Court University",
  "Germanna Community College",
  "Gettysburg College",
  "Glendale Community College",
  "Globe Institute of Technology",
  "Globe University",
  "Gnomon School of Visual Effects",
  "Goddard College",
  "Golden Gate Baptist Theological Seminary",
  "Golden Gate University",
  "Golden West College",
  "Goldey-Beacom College",
  "Gonzaga University",
  "Goodwin College",
  "Gordon College",
  "Gordon Conwell Theological Seminary",
  "Goshen College",
  "Goucher College",
  "Governors State",
  "Governors State University",
  "Grace College and Seminary",
  "Grace University",
  "Graceland University",
  "Grambling State University",
  "Grand Canyon University",
  "Grand Rapids Community College",
  "Grand Valley State University",
  "Granite State College",
  "Grayson College",
  "Great Basin College",
  "Green Mountain College",
  "Green River Community College",
  "Greenville College",
  "Greenville University",
  "Grinnell College",
  "Grossmont College",
  "Grove City College",
  "Guilford Technical Community College",
  "Gulf Coast State College",
  "Gustavus Adolphus College",
  "Gwynedd Mercy University",
  "Hagerstown Community  College",
  "Hallmark University",
  "Hamilton College",
  "Hamline University",
  "Hampden-Sydney College",
  "Hampshire College",
  "Hampton University",
  "Hannibal-LaGrange",
  "Hanover College",
  "Hardin-Simmons University",
  "Harding University",
  "Harford Community College",
  "Harper College",
  "Harris-Stowe State University",
  "Harrisburg Area Community College",
  "Harrisburg University of Science and Technology",
  "Harrison College",
  "Hartford Seminary",
  "Hartnell College",
  "Hartwick College",
  "Harvard Business School",
  "Harvard University",
  "Harvey Mudd College",
  "Haverford College",
  "Hawaii Pacific University",
  "Hawkeye Community College",
  "Heartland Community College",
  "Heidelberg University",
  "Henderson State University",
  "Hendrix College",
  "Henley-Putnam University",
  "Henry Ford College",
  "Heritage Bible College",
  "Heritage University",
  "Herkimer College",
  "Herzing University, Kenosha",
  "Herzing University, Madison",
  "Hesston College",
  "High Point University",
  "Highland Community College",
  "Highline College",
  "Hill College",
  "Hillsborough Community College",
  "Hillsdale College",
  "Hiram College",
  "Hiwassee College",
  "Hobart and William Smith Colleges",
  "Hocking College",
  "Hodges University",
  "Hofstra University",
  "Hollins University",
  "Holy Cross College",
  "Holy Family College",
  "Holy Family University",
  "Holy Names University",
  "Holyoke Community College",
  "Hood College",
  "Hope College",
  "Hope International University",
  "Housatonic Community College",
  "Houston Baptist University",
  "Houston Community College",
  "Howard College",
  "Howard Community College",
  "Howard Payne University",
  "Howard University",
  "Hudson County Community College",
  "Hudson Valley Community College",
  "Humacao Community College",
  "Humboldt State University",
  "Humphreys College",
  "Humphreys University, Stockton and Modesto",
  "Huntingdon College",
  "Huntington College of Health Sciences",
  "Huntington University",
  "Husson University",
  "Huston-Tillotson University",
  "Icahn School of Medicine at Mount Sinai",
  "Idaho State University",
  "IIT Chicago-Kent College of Law",
  "Illinois Central College",
  "Illinois College of Optometry",
  "Illinois Institute of Technology",
  "Illinois Mathematics and Science Academy",
  "Illinois State University",
  "Illinois Valley Community College",
  "Illinois Wesleyan University",
  "Immaculata University",
  "Imperial Valley College",
  "Indian River State College",
  "Indiana Institute of Technology",
  "Indiana State University",
  "Indiana Tech",
  "Indiana University",
  "Indiana University - Kokomo",
  "Indiana University Bloomington",
  "Indiana University East",
  "Indiana University Northwest",
  "Indiana University of Pennsylvania",
  "Indiana University South Bend",
  "Indiana University Southeast",
  "Indiana University – Purdue University Fort Wayne",
  "Indiana University – Purdue University Indianapolis",
  "Indiana Wesleyan University",
  "Institute of Design and Construction",
  "Institute of Production and Recording",
  "Instituto Tecnologico de Puerto Rico, Recinto de Manati",
  "Instituto Tecnologico de Puerto Rico, Recinto de Ponce",
  "Instituto Tecnologico de Puerto Rico, Recinto de San Juan",
  "Inter American University of Puerto Rico, Aguadilla",
  "Inter American University of Puerto Rico, Arecibo",
  "Inter American University of Puerto Rico, Barranquitas",
  "Inter American University of Puerto Rico, Bayamon",
  "Inter American University of Puerto Rico, Fajardo",
  "Inter American University of Puerto Rico, Guayama",
  "Inter American University of Puerto Rico, Metro",
  "Inter American University of Puerto Rico, Ponce",
  "Inter American University of Puerto Rico, San German",
  "Interactive College of Technology",
  "International Technological University",
  "Inver Hills Community College",
  "Iona College",
  "Iowa Central Community College",
  "Iowa State University",
  "Iowa State University of Science and Technology",
  "Iowa Wesleyan College",
  "Iowa Western Community College",
  "Irvine Valley College",
  "Isothermal Community College",
  "Itawamba Community College",
  "Ithaca College",
  "Ithica College",
  "Ivy Tech Community College",
  "J. F. Drake State Community and Technical College",
  "Jackson College",
  "Jackson State Community College",
  "Jackson State University",
  "Jacksonville State University",
  "Jacksonville University",
  "James Madison University",
  "James Sprunt Community College",
  "Jamestown Community College",
  "Jarvis Christian University",
  "Jefferson College",
  "John A. Logan College",
  "John Brown University",
  "John Carroll University",
  "John F. Kennedy University",
  "John Paul the Great Catholic University",
  "Johns Hopkins Bloomberg School of Public Health",
  "Johns Hopkins School of Advanced International Studies",
  "Johns Hopkins University",
  "Johnson & Wales University",
  "Johnson and Wales University",
  "Johnson C Smith University",
  "Johnson County Community College",
  "Johnson State College",
  "Johnson University",
  "Johnson University Florida",
  "Joliet Junior College",
  "Jones International University",
  "Joseph’s College",
  "Judson University",
  "Juniata College",
  "Kalamazoo College",
  "Kalamazoo Valley Community College",
  "Kankakee Community College",
  "Kansas City Kansas Community College",
  "Kansas City University of Medicine and Biosciences",
  "Kansas State University",
  "Kansas State University - Salina",
  "Kansas Wesleyan University",
  "Kaplan University",
  "Kaskaskia College",
  "Kean University",
  "Keck Graduate Institute",
  "Keene State College",
  "Keiser University",
  "Kennesaw State University",
  "Kent State University",
  "Kentucky Christian University",
  "Kentucky Community and Technical College System",
  "Kentucky State University",
  "Kenyon College",
  "Kettering College",
  "Kettering University",
  "Keuka College",
  "Keystone College",
  "King University",
  "King's College",
  "King’s College",
  "Kirkwood Community College",
  "Kishwaukee College",
  "Knox College",
  "Kutztown University",
  "Kutztown University of Pennsylvania",
  "La Roche College",
  "La Roche University",
  "La Salle University",
  "La Sierra University",
  "Lackawanna College",
  "Lafayette College",
  "Lake Erie College",
  "Lake Erie College of Osteopathic Medicine",
  "Lake Forest College",
  "Lake Michigan College",
  "Lake Sumter State College",
  "Lake Superior State University",
  "Lake Tahoe Community College",
  "Lakeland Community College",
  "Lakeland University",
  "Lakeview College of Nursing",
  "Lamar Community College",
  "Lamar Institute of Technology",
  "Lamar State College – Orange",
  "Lamar State College – Port Arthur",
  "Lamar University",
  "Lancaster Bible College",
  "Lancaster County Career and Technology Center",
  "Lancaster Theological Seminary",
  "Lander University",
  "Lane College",
  "Lane Community College",
  "Laney  College",
  "Langston University",
  "Lanier Technical College",
  "Lansdale School of Business",
  "Lansing Community College",
  "Laredo College",
  "Las Positas College",
  "Lassen Community College",
  "Laurus College",
  "Lawrence Technological University",
  "Lawrence University",
  "Lawson State Community College",
  "La’ James College",
  "Le Moyne College",
  "Le Moyne-Owen College",
  "Lebanon Valley College",
  "Lee College",
  "Lee University",
  "Leech Lake Tribal College",
  "Lees McRae College",
  "Lehigh Carbon Community College",
  "Lehigh University",
  "Lehman College",
  "Lenoir Community College",
  "Lesley University",
  "LeTourneau University",
  "Lewis and Clark",
  "Lewis and Clark Community College",
  "Lewis University",
  "Liberty University",
  "Life Chiropractic College West",
  "Life Pacific University",
  "Life University",
  "Lim College",
  "Lima Central Catholic High School",
  "Limestone College",
  "Lincoln Land Community College",
  "Lincoln Memorial University",
  "Lincoln University",
  "Lindenwood University",
  "Lindsey Wilson College",
  "Linfield College",
  "Linn State Technical College",
  "Linn-Benton Community College",
  "Lipscomb University",
  "Little Priest Tribal College",
  "Livingstone College",
  "Lock Haven University",
  "Loma Linda University",
  "Lone Star College System",
  "Long Beach City College",
  "Long Island University",
  "Longwood University",
  "Lorain County Community College",
  "Loraines Academy Incorporated",
  "Loras College",
  "Los Angeles City College",
  "Los Angeles Community College District",
  "Los Angeles Harbor College",
  "Los Angeles Mission College",
  "Los Angeles Pacific University",
  "Los Angeles Pierce College",
  "Los Angeles Southwest College",
  "Los Angeles Trade-Tech Community College",
  "Los Angeles Valley College",
  "Los Medanos College",
  "Los Rios Community College District",
  "Louisiana Culinary Institute",
  "Louisiana State University",
  "Louisiana State University System",
  "Louisiana State University – Eunice",
  "Louisiana Tech University",
  "Lourdes University",
  "Loyola Marymount University",
  "Loyola University Chicago",
  "Loyola University Maryland",
  "Loyola University New Orleans",
  "LSU Health Sciences Center – New Orleans",
  "Lubbock Christian University",
  "Luna Community College",
  "Lurleen B. Wallace Community College",
  "Luther College",
  "Luther Seminary",
  "Lycoming College",
  "Lynn University",
  "Lyon College",
  "Macalester College",
  "Macomb Community College",
  "Madison Area Technical College",
  "Madonna University",
  "Maharishi International University",
  "Maharishi University of Management",
  "Maine Maritime Academy",
  "Malone University",
  "Manchester Community College",
  "Manchester University",
  "Manhattan College",
  "Manhattanville College",
  "Manor College",
  "Mansfield University",
  "Mansfield University of Pennsylvania",
  "Marian University Indianapolis",
  "Marian University, Fond du Lac",
  "Maricopa Community Colleges",
  "Marietta College",
  "Marine Biological Laboratory",
  "Marist College",
  "Marquette University",
  "Marshall University",
  "Martin University",
  "Mary Baldwin University",
  "Marygrove College",
  "Maryland Institute College of Art",
  "Maryland University of Integrative Health",
  "Marylhurst University",
  "Marymount California University",
  "Marymount Manhattan College",
  "Marymount University",
  "Maryville University",
  "Marywood University",
  "Massachusetts College of Art and Design",
  "Massachusetts Department of Higher Education",
  "Massachusetts Institute of Technology",
  "Massachusetts Maritime Academy",
  "MassBay Community College",
  "Mayville State University",
  "McDaniel College",
  "McHenry County College",
  "McKendree University",
  "McLennan Community College",
  "McMurry University",
  "McNally Smith College of Music",
  "McNeese State University",
  "McPherson College",
  "Medaille College",
  "Medical College of Wisconsin",
  "Medical University of South Carolina",
  "Meharry Medical College",
  "Mendocino College",
  "Merced College",
  "Merced Community College",
  "Mercer County Community  College",
  "Mercer University",
  "Merchhurst University",
  "Mercy College",
  "Mercyhurst University",
  "Meridian Technology Center",
  "Merrimack College",
  "Merrimack Education Center",
  "Merritt College",
  "Mesa Community College",
  "Mesabi Range College",
  "Messiah College",
  "Messiah University",
  "Methodist University",
  "Metropolitan College of New York",
  "Metropolitan Community College",
  "Metropolitan State University",
  "Metropolitan State University of Denver",
  "MGH Institute of Health Professions",
  "Miami Dade College",
  "Miami International University of Art and Design",
  "Miami University",
  "Michigan State University",
  "Michigan Technological University",
  "Mid-America Christian University",
  "Mid-Pacific Institute",
  "Mid-Plains Community College",
  "Mid-South Community College",
  "MidAmerica Nazarene University",
  "Middle Georgia State College",
  "Middle Tennessee State University",
  "Middlebury College",
  "Middlesex Community College",
  "Middlesex County College",
  "Middlesex School",
  "Midland College",
  "Midland University",
  "Midlands Technical College",
  "Midway College",
  "Midwestern  State University",
  "Midwestern Career College",
  "Midwestern State University",
  "Midwestern University",
  "Miles Community College",
  "Millennia Atlantic University",
  "Millersville University",
  "Millersville University of Pennsylvania",
  "Milligan College",
  "Milligan University",
  "Millikin University",
  "Mills College",
  "Milton Academy",
  "Milwaukee Area Technical College",
  "Milwaukee Institute of Art and Design",
  "Milwaukee School of Engineering",
  "Mineral Area College",
  "Minnesota State University Mankato",
  "Minnesota State University Moorhead",
  "Minot State University",
  "MiraCosta College",
  "Misericordia University",
  "Mission College",
  "Mississippi Gulf Coast Community College",
  "Mississippi State University",
  "Mississippi University for Women",
  "Mississippi Valley State University",
  "Missouri Baptist University",
  "Missouri Southern State  University",
  "Missouri State University",
  "Missouri University of Science and Technology",
  "Missouri Valley College",
  "Missouri Western State University",
  "Mizzou University of Missouri",
  "Modesto Junior College",
  "Mohave Community College",
  "Molloy College",
  "Monmouth College",
  "Monmouth University",
  "Monroe College",
  "Monroe Community College",
  "Monroe County Community College",
  "Montana State University",
  "Montana State University - Bozeman",
  "Montana State University Billings",
  "Montana State University – Northern",
  "Montana Tech of the University of Montana",
  "Montana Technological University",
  "Montana University System",
  "Montclair State University",
  "Monterey Institute of International Studies",
  "Monterey Peninsula College",
  "Montgomery Blair High School",
  "Montgomery College",
  "Montgomery Community College",
  "Montgomery County Community College",
  "Montserrat College of Art",
  "Moorpark College",
  "Moraine Park Technical College",
  "Moraine Valley Community College",
  "Moravian College",
  "Morehead State University",
  "Morehouse College",
  "Morehouse School of Medicine",
  "Moreno Valley College",
  "Morgan Community College",
  "Morgan State University",
  "Morningside University",
  "Morris College",
  "Morrison University",
  "Morrisville State College",
  "Morton College",
  "Mount Aloysius College",
  "Mount Holyoke College",
  "Mount Ida College",
  "Mount Marty College",
  "Mount Marty University",
  "Mount Mary University",
  "Mount Mercy University",
  "Mount Saint Joseph University",
  "Mount Saint Mary College",
  "Mount Saint Mary’s College",
  "Mount Saint Mary’s University",
  "Mount San Antonio College",
  "Mount San Jacinto College",
  "Mount San Jacinto Community College District",
  "Mount St. Mary University",
  "Mount Vernon Nazarene University",
  "Mount Washington College",
  "Mountain State University",
  "Muhlenberg College",
  "Mukogawa Fort Wright Institute",
  "Murray State University",
  "Muskingum University",
  "Myers University",
  "Napa Valley College",
  "Naropa University",
  "Nashua Community College",
  "Nashville State Community College",
  "Nassau Community College",
  "National American University",
  "National College of Natural Medicine",
  "National Louis University",
  "National Outdoor Leadership School",
  "National Park Community College",
  "National University",
  "National University of Health Sciences",
  "Naval Postgraduate School",
  "Nazarene Theological Seminary",
  "Nazareth College",
  "Nebraska Wesleyan University",
  "Nelson University",
  "Neosho County Community College",
  "Neumann University",
  "Nevada State College",
  "New College of Florida",
  "New England College",
  "New England Culinary Institute",
  "New England Institute of Technology",
  "New England Law – Boston",
  "New Hope Christian College, Eugene",
  "New Jersey City University",
  "New Jersey Institute of Technology",
  "New Life Theological Seminary",
  "New Mexico Highlands University",
  "New Mexico Institute of Mining and Technology",
  "New Mexico State University",
  "New Mexico State University Alamogordo",
  "New Mexico State University, Dona Ana",
  "New Mexico State University, Grants",
  "New Mexico State University, Main Campus",
  "New Mexico Tech",
  "New Orleans Baptist Theological Seminary",
  "New River Community College",
  "New York College of Health Professions",
  "New York Film Academy",
  "New York Institute of Technology",
  "New York University",
  "Newberry College",
  "Newman University",
  "NewSchool of Architecture and Design",
  "NHTI Concord’s Community College",
  "Niagara University",
  "Nicholls State University",
  "Norco College",
  "Norfolk State University",
  "Normandale Community College",
  "North American University",
  "North Arkansas College",
  "North Bennet Street School",
  "North Carolina Agricultural and Technical State University",
  "North Carolina Central University",
  "North Carolina School of Science and Mathematics",
  "North Carolina State University",
  "North Carolina Wesleyan College",
  "North Central College",
  "North Central Missouri College",
  "North Central State College",
  "North Central Texas College",
  "North Dakota State University",
  "North Dakota University System",
  "North Florida Community College",
  "North Georgia Technical College",
  "North Greenville University",
  "North Hennepin Community College",
  "North Lake College",
  "North Park University",
  "North Seattle College",
  "North Shore Community College",
  "Northampton Community College",
  "Northampton County Area Community College",
  "Northcentral  Technical College",
  "Northeast Alabama Community College",
  "Northeast Community College",
  "Northeast Iowa Community  College",
  "Northeast Lakeview College",
  "Northeast Mississippi Community College",
  "Northeast Texas Community College",
  "Northeast Wisconsin Technical College",
  "Northeastern Illinois University",
  "Northeastern State University",
  "Northeastern University",
  "Northern Arizona University",
  "Northern Essex Community College",
  "Northern Illinois University",
  "Northern Kentucky University",
  "Northern Maine Community College",
  "Northern Michigan University",
  "Northern New Mexico College",
  "Northern State University",
  "Northern Vermont University",
  "Northern Virginia Community College",
  "Northern Wyoming Community College District",
  "Northland College",
  "Northland Pioneer  College",
  "Northwest Arkansas Community College",
  "Northwest Arkansas University",
  "Northwest College",
  "Northwest Florida State College",
  "Northwest Missouri State University",
  "Northwest Nazarene University",
  "Northwest Technical Institute",
  "Northwest University",
  "Northwest Vista College",
  "Northwest-Shoals Community College",
  "Northwestern Michigan College",
  "Northwestern Oklahoma State University",
  "Northwestern State University",
  "Northwestern University",
  "Northwood University",
  "Norwich  University",
  "Norwich University",
  "Notre Dame College of Ohio",
  "Notre Dame de Namur University",
  "Notre Dame University of Maryland",
  "Nova Southeastern University",
  "Nunez Community College",
  "Nyack College",
  "Oak Point University",
  "Oakland City University",
  "Oakland Community College",
  "Oakland Univeristy",
  "Oakland University",
  "Oakton Community College",
  "Oakwood University",
  "Oberlin College and Conservatory",
  "Occidental College",
  "Ocean County College",
  "Odessa College",
  "Ogle School",
  "Oglethorpe University",
  "Ohio Christian University",
  "Ohio Dominican University",
  "Ohio Northern University",
  "Ohio State University",
  "Ohio University",
  "Ohio Valley University",
  "Ohio Wesleyan University",
  "Ohlone College",
  "Okefenokee Technical College",
  "Oklahoma Baptist University",
  "Oklahoma Christian University",
  "Oklahoma City Community College",
  "Oklahoma City University",
  "Oklahoma Panhandle State University",
  "Oklahoma State University",
  "Oklahoma State University Institue of Technology",
  "Oklahoma Wesleyan University",
  "Old Dominion University",
  "Olin College of Engineering",
  "Olivet Nazarene University",
  "Olympic College",
  "Oral Roberts University",
  "Orange Coast College",
  "Orange County Community College",
  "Orangeburg-Calhoun Technical College",
  "Oregon Health and Science University",
  "Oregon Institute of Technology",
  "Oregon State University",
  "Otero Junior College",
  "Otis College of Art and Design",
  "Ottawa University, in Ottawa Kansas",
  "Ottawa University, Phoenix",
  "Ottawa University, Surprise",
  "Otterbein University",
  "Ouachita Baptist University",
  "Our Lady of the Lake University",
  "Owens Community College",
  "Oxnard College",
  "Ozarks Technical Community College",
  "Pace University",
  "Pacific College of Oriental Medicine",
  "Pacific Lutheran  Theological Seminary",
  "Pacific Lutheran University",
  "Pacific Oaks College",
  "Pacific School of Religion",
  "Pacific States University",
  "Pacific Union College",
  "Pacific University Oregon",
  "Paine College",
  "Palm Beach Atlantic University",
  "Palm Beach State College",
  "Palmer College of Chiropractic",
  "Palo Alto College",
  "Palo Alto University",
  "Palo Verde College",
  "Palomar College",
  "Panola College",
  "Paradise Valley Community College",
  "Park University",
  "Parkland College",
  "Pasadena City College",
  "Pasco-Hernando State College",
  "Passaic County Community College",
  "Patty Hands Shelton School of Nursing",
  "Paul Quinn College",
  "Paul Smith’s College",
  "Pearl River Community College",
  "Pellissippi State Community College",
  "Peninsula College",
  "Penn State University",
  "Pennsylvania Academy of the Fine Arts",
  "Pennsylvania College of Technology",
  "Pennsylvania Gunsmith School",
  "Pennsylvania State University",
  "Pensacola State College",
  "Pepperdine University",
  "Peralta Community College District",
  "Perry Technical Institute",
  "Peru State College",
  "Pfeiffer University",
  "Philadelphia Biblical University",
  "Philadelphia College of Osteopathic Medicine",
  "Philadelphia University",
  "Philander Smith College",
  "Phillips Community College U of A",
  "Phillips Exeter Academy",
  "Phillips University",
  "Phoenix College",
  "Piedmont College",
  "Piedmont Community College",
  "Pierce College",
  "Pierpont Community and Technical College",
  "Pikes Peak Community College",
  "Pillar College",
  "Pima Community College",
  "Pima Medical Institute",
  "Pitt Community College",
  "Pittsburg State University",
  "Pitzer College",
  "Plymouth State University",
  "Point Loma Nazarene University",
  "Point Park University",
  "Polk State College",
  "Polytechnic University of Puerto Rico, Miami",
  "Polytechnic University of Puerto Rico, Orlando",
  "Pomona College",
  "Pontifical Catholic University of Puerto Rico, Arecibo",
  "Pontifical Catholic University of Puerto Rico, Mayaguez",
  "Pontifical Catholic University of Puerto Rico, Ponce",
  "Porterville College",
  "Portland Community College",
  "Portland State University",
  "Post University",
  "Prairie State College",
  "Prairie View A & M University",
  "Prairie View A&M University",
  "Pratt Institute",
  "Presbyterian College",
  "Prescott College",
  "Prince George’s Community College",
  "Princeton Theological Seminary",
  "Princeton University",
  "Principia College",
  "Providence Christian College",
  "Providence College",
  "Pueblo Community College",
  "Pulaski Technical College",
  "Purchase College",
  "Purdue University",
  "Purdue University - Fort Wayne",
  "Purdue University - Northwest",
  "Purdue University Calumet",
  "Purdue University North Central",
  "Queens University of Charlotte",
  "Quincy University",
  "Quinebaug Valley Community College",
  "Quinnipiac University",
  "Radford University",
  "Ramapo College of New Jersey",
  "Randolph College",
  "Randolph Community College",
  "Randolph-Macon College",
  "Ranger College",
  "Rappahannock Community College",
  "Raritan Valley Community College",
  "Rasmussen College",
  "Reading Area Community College",
  "Red Rocks Community College",
  "Redstone College",
  "Reed College",
  "Reedley College",
  "Reformed Theological Seminary",
  "Regent University",
  "Regis University",
  "Reinhardt University",
  "Remington College, Dallas",
  "Remington College, Houston Southeast",
  "Remington College, North Houston",
  "Rensselaer  at Hartford",
  "Rensselaer Polytechnic Institute",
  "Renton Technical College",
  "Resurrection University",
  "Reynolds Community College",
  "Rhode Island College",
  "Rhodes College",
  "Rhose Island School of Design",
  "Rice University",
  "Richland College",
  "Rider University",
  "Ridgewater College",
  "Ringling College of Art and Design",
  "Rio Hondo College",
  "Rio Salado College",
  "Riverside City College",
  "Riverside Community College District",
  "Rivier University",
  "Roane State Community College",
  "Roanoke College",
  "Roanoke-Chowan Community College",
  "Robert Morris University",
  "Robert Morris University Illinois",
  "Roberts Wesleyan College",
  "Rochester Institute of Technology",
  "Rock Valley College",
  "Rockford University",
  "Rockhurst University",
  "Rockland Community College",
  "Rocky Mountain College",
  "Roger Williams University",
  "Rogers State University",
  "Rogue Community College",
  "Rollins College",
  "Roosevelt University",
  "Rosalind Franklin University",
  "Rose State College",
  "Rose-Hulman Institute of Technology",
  "Rosemead College",
  "Rosemont College",
  "Ross University",
  "Rowan University",
  "Russell Sage College",
  "Rust College",
  "Rutgers University",
  "Sacramento City College",
  "Sacred Heart University",
  "Saddleback College",
  "Saginaw Chippewa Tribal College",
  "Saginaw Valley State University",
  "Saint Ambrose University",
  "Saint Andrews University",
  "Saint Anselm College",
  "Saint Anthony College of Nursing",
  "Saint Augustine College",
  "Saint Augustine’s University",
  "Saint Bernard’s School of Theology and Ministry",
  "Saint Bonaventure University",
  "Saint Catharine College",
  "Saint Catherine University",
  "Saint Charles Community College",
  "Saint Cloud State  University",
  "Saint Edward's University",
  "Saint Edward’s University",
  "Saint Elizabeth University",
  "Saint Francis College",
  "Saint Francis Medical Center College of Nursing",
  "Saint Francis University",
  "Saint James School",
  "Saint John Fisher College",
  "Saint John's University",
  "Saint John’s College",
  "Saint John’s University",
  "Saint Joseph University",
  "Saint Joseph's College",
  "Saint Joseph's University",
  "Saint Joseph’s College",
  "Saint Joseph’s University",
  "Saint Lawrence University",
  "Saint Leo University",
  "Saint Louis Community College",
  "Saint Louis University",
  "Saint Martin's University",
  "Saint Martin’s University",
  "Saint Mary's College of California",
  "Saint Mary's University",
  "Saint Mary-of-the-Woods College",
  "Saint Mary’s College",
  "Saint Mary’s College of Maryland",
  "Saint Mary’s University",
  "Saint Mary’s University of Minnesota",
  "Saint Michael’s College",
  "Saint Norbert College",
  "Saint Olaf College",
  "Saint Paul School of Theology and Ministry",
  "Saint Peter's University",
  "Saint Peters University",
  "Saint Petersburg College",
  "Saint Thomas Aquinas College",
  "Saint Thomas University",
  "Saint Vincent College",
  "Saint Vincent de Paul Regional Seminary",
  "Saint Vincent Seminary",
  "Saint Xavier University",
  "Salem College",
  "Salem International University",
  "Salem State University",
  "Salem University",
  "Salisbury University",
  "Salt Lake Community College",
  "Salus University",
  "Salve Regina University",
  "Sam Houston State University",
  "Samford University",
  "Sampson Community College",
  "San Antonio College",
  "San Bernardino Valley College",
  "San Diego Christian College",
  "San Diego City College",
  "San Diego Mesa College",
  "San Diego Miramar College",
  "San Diego State University",
  "San Francisco State University",
  "San Jacinto College",
  "San Jacinto Community College",
  "San Joaquin Delta College",
  "San Jose City College",
  "San Jose State University",
  "San Juan Bautista School of Medicine",
  "San Juan College",
  "San Mateo County Community College",
  "Sandhills Community College",
  "Santa Ana College",
  "Santa Barbara City College",
  "Santa Clara University",
  "Santa Fe College",
  "Santa Fe Community College",
  "Santa Monica College",
  "Santa Rosa Junior College",
  "Santiago Canyon College",
  "Sarah Lawrence College",
  "Savannah College of Art and Design",
  "Savannah State University",
  "Schiller International University",
  "School for International Training",
  "School of Continuing Education",
  "School of the Art Institute of Chicago",
  "Schreiner University",
  "Scripps College",
  "Seattle Central College",
  "Seattle Institute of Oriental Medicine",
  "Seattle Pacific University",
  "Seattle University",
  "Seminary of the Southwest",
  "Seminole State College of Florida",
  "Sentara College of Health Sciences",
  "Seton Hall University",
  "Seton Hill University",
  "Sewanee The University of the South",
  "Seward County Community College",
  "Shasta College",
  "Shaw University",
  "Shawnee Community College",
  "Shawnee State University",
  "Shelton State Community College",
  "Shenandoah University",
  "Shepherd University",
  "Shippensburg University",
  "Shoreline Community College",
  "Shorter College",
  "Shorter University",
  "Si Tanka University",
  "Siena College",
  "Siena Heights University",
  "Sierra College",
  "Sierra Nevada College",
  "Sierra Nevada University",
  "Silver Lake College of the Holy Family",
  "Simmons College",
  "Simmons College of Kentucky",
  "Simmons University",
  "Simpson College",
  "Simpson University",
  "Sinclair Community College",
  "Sinte Gleska University",
  "Skagit Valley College",
  "Skidmore College",
  "Skyline College",
  "Slippery Rock University",
  "Slippery Rock University of Pennsylvania",
  "Smith College",
  "Snead State Community College",
  "Snow College",
  "Sojourner-Douglass College",
  "Soka University of America",
  "Solano Community College",
  "Sonoma State University",
  "South Carolina State University",
  "South Central Career Center",
  "South Central College",
  "South Dakota School of Mines and Technology",
  "South Dakota State University",
  "South Florida State College",
  "South Mountain Community College",
  "South Piedmont Community College",
  "South Plains College",
  "South Seattle College",
  "South Suburban College",
  "South Texas College",
  "South Texas College of Law",
  "South University",
  "Southeast Arkansas College",
  "Southeast Missouri State University",
  "Southeast New Mexico College",
  "Southeastern Baptist Theological Seminary",
  "Southeastern Bible College",
  "Southeastern Community College",
  "Southeastern Illinois College",
  "Southeastern Louisiana University",
  "Southeastern Oklahoma State University",
  "Southeastern Technical College",
  "Southeastern University",
  "Southern Adventist University",
  "Southern Arkansas University Magnolia",
  "Southern Arkansas University Tech",
  "Southern California University of Health Sciences",
  "Southern College of Optometry",
  "Southern Connecticut State University",
  "Southern Illinois University",
  "Southern Illinois University Edwardsville",
  "Southern Maine Community College",
  "Southern Methodist University",
  "Southern Nazarene University",
  "Southern New Hampshire University",
  "Southern Oregon University",
  "Southern Polytechnic State University",
  "Southern University",
  "Southern University and A & M College",
  "Southern University at New Orleans",
  "Southern University at Shreveport",
  "Southern Utah University",
  "Southern Virgina University",
  "Southern Virginia University",
  "Southern Wesleyan University",
  "Southwest Baptist University",
  "Southwest Minnesota State University",
  "Southwest Missouri State University",
  "Southwest Tennessee Community College",
  "Southwest Texas Junior College",
  "Southwest Virgina Community College",
  "Southwestern Adventist University",
  "Southwestern Assemblies of God University",
  "Southwestern Christian College",
  "Southwestern Christian University",
  "Southwestern College",
  "Southwestern Community College",
  "Southwestern Illinois College",
  "Southwestern Oklahoma State Univeristy",
  "Southwestern Oklahoma State University",
  "Southwestern Oregon Community  College",
  "Southwestern University",
  "Spa Tech Institute",
  "Space Coast Health Institute",
  "Spalding University",
  "Spartanburg Methodist College",
  "Spelman College",
  "Spokane Falls Community College",
  "Spoon River College",
  "Spring Arbor University",
  "Springfield College",
  "Springfield Technical Community College",
  "St Philip’s College",
  "St. Cloud State University",
  "St. Gregory's University",
  "St. John's University",
  "St. Mary's College of California",
  "St. Mary's University of San Antonio",
  "Stanford University",
  "Stanly Community College",
  "Starr King School for the Ministry",
  "State College of Florida",
  "State University of New Institute of Technology",
  "State University of New York",
  "State University of New York - Brockport",
  "State University of New York - Fredonia",
  "State University of New York - Platsburgh",
  "State University of New York at Adirondack",
  "State University of New York at Albany",
  "State University of New York at Binghamton",
  "State University of New York at Farmingdale",
  "State University of New York at New Paltz",
  "State University of New York at Oneonta",
  "State University of New York at Oswego",
  "State University of New York at Plattsburgh",
  "State University of New York at Potsdam",
  "State University of New York Buffalo State",
  "State University of New York College - Cortland",
  "State University of New York College of Agriculture and Technology at Cobleskill",
  "State University of New York College of Environmental Science and Forestry",
  "State University of New York Maritime College",
  "State University of New York Polytechnic Institute",
  "State University of New York Polytechnic Institute - Utica/Rome",
  "State University of New York, College at Old Westbury",
  "State University of New York, Morrisville",
  "State University of New York, Purchase College",
  "State University of New York, Syracuse",
  "Stenotech Career Institute",
  "Stephen F. Austin State University",
  "Stephens College",
  "Sterling College",
  "Stetson University",
  "Stevens Institute of Technology",
  "Stevenson University",
  "Stillman College",
  "Stockton College",
  "Stockton University",
  "Stony Brook Medicine",
  "Stony Brook University",
  "Stratford University",
  "Strayer University",
  "Suffolk County Community College",
  "Suffolk University",
  "Sul Ross State University",
  "Sullivan University",
  "Susquehanna University",
  "Sussex County Community College",
  "Swarthmore College",
  "Syracuse University",
  "Taft College",
  "Talladega College",
  "Tarleton State University",
  "Tarrant County College District",
  "Taylor University",
  "Technical Career Institute",
  "Temple College",
  "Temple University",
  "Tennessee State University",
  "Tennessee Tech University",
  "Tennessee Technological University",
  "Tennessee Temple University",
  "Tennessee Wesleyan College",
  "Texas A & M International University",
  "Texas A & M University, Central Texas",
  "Texas A & M University, College Station",
  "Texas A & M University, Corpus Christi",
  "Texas A & M University, Kingsville",
  "Texas A & M University, San Antonio",
  "Texas A&M Health Science Center",
  "Texas A&M International University",
  "Texas A&M University",
  "Texas A&M University - San Antonio",
  "Texas A&M University College Station",
  "Texas A&M University Commerce",
  "Texas A&M University Corpus Christi",
  "Texas A&M University Kingsville",
  "Texas A&M University Texarkana",
  "Texas Chiropractic College",
  "Texas Christian University",
  "Texas College",
  "Texas Lutheran University",
  "Texas Southern University",
  "Texas Southmost College",
  "Texas State Technical College",
  "Texas State University",
  "Texas Tech University",
  "Texas Tech University Health Sciences Center",
  "Texas Wesleyan University",
  "Texas Woman's University",
  "Texas Woman’s University",
  "The  American College of Financial Services",
  "The American Musical and Dramatic Academy",
  "The Art Institute",
  "The Boston Conservatory",
  "The Catholic University of America",
  "The Citadel Military College of South Carolina",
  "The City University of New York",
  "The Claremont Colleges",
  "The College at Brockport",
  "The College of Idaho",
  "The College of New Jersey",
  "The College of New Rochelle",
  "The College of Saint Rose",
  "The College of Saint Scholastica",
  "The College of Westchester",
  "The College of William and Mary",
  "The College of Wooster",
  "The Community College of Baltimore County",
  "The Cooper Union",
  "The Evergreen State College",
  "The General Theological Seminary",
  "The George Washington University",
  "The Hadley School for the Blind",
  "The Institute for the Psychological Sciences",
  "The Institute of Beauty and Wellness",
  "The Institute of Buddhist Studies",
  "The Jewish Theological Seminary",
  "The John Marshall Law School",
  "The Juilliard School",
  "The LeMoyne-Owen College",
  "The Lincoln University",
  "The Master's University and Seminary",
  "The Master’s College",
  "The New School",
  "The New York Conservatory for Dramatic Arts",
  "The Sages Colleges",
  "The Southern Baptist Theological Seminary",
  "The Touro College and University System",
  "The University of Akron",
  "The University of Chicago Booth School of Business",
  "The University of Findlay",
  "The University of Kansas",
  "The University of Louisville",
  "The University of Memphis",
  "The University of Mississippi",
  "The University of Mount Olive",
  "The University of Oklahoma",
  "The University of Oklahoma Health Sciences Center",
  "The University of Rhode Island",
  "The University of Scranton",
  "The University of Tennessee",
  "Thiel College",
  "Thomas College",
  "Thomas Edison State College",
  "Thomas Edison State University",
  "Thomas Jefferson University",
  "Thomas Nelson Community College",
  "Thomas University",
  "Three Rivers Community College",
  "Tidewater Community College",
  "Tiffin University",
  "Tompkins Cortland Community College",
  "Tougaloo College",
  "Touro College Jacob D. Fuchsberg Law Center",
  "Touro University California",
  "Towson University",
  "Transylvania University",
  "Traviss Career Center",
  "Treasure Valley Community College",
  "Trenholm State Technical College",
  "Trevecca Nazarene University",
  "TriCounty Technical College",
  "Trine University",
  "Trinidad State College",
  "Trinity Christian College",
  "Trinity College",
  "Trinity International University, Florida",
  "Trinity University",
  "Trinity Valley Community College",
  "Trinity Washington University",
  "Troy University",
  "Truckee Meadows Community College",
  "Truman State University",
  "Tufts University",
  "Tulane University",
  "Tulsa Community College",
  "Tunxis Community College",
  "Turtle Mountain Community College",
  "Tuskegee University",
  "Tyler Junior College",
  "UCLA School of Law",
  "Union College",
  "Union County College",
  "Union Institute & University",
  "Union University",
  "United States Military Academy West Point",
  "United States Naval Academy",
  "United States University",
  "United Theological Seminary of the Twin Cities",
  "United Tribes Technical College",
  "Unitek College",
  "Unity College",
  "Univeristy of Iowa",
  "Univeristy of New Mexico",
  "Universal Technology College of Puerto Rico",
  "Universidad Adventista de las Antillas",
  "Universidad Ana G. Mendez, Carolina",
  "Universidad Ana G. Mendez, Cupey",
  "Universidad Ana G. Mendez, Gurabo",
  "Universidad Central de Bayamon",
  "Universidad Central Del Caribe",
  "Universidad del Sagrado Corazon",
  "Universidad Pentecostal Mizpa",
  "Universidad Politecnica de Puerto Rico",
  "Universidad Teologica del Caribe",
  "University at Albany",
  "University at Buffalo",
  "University Corporation for Atmospheric Research",
  "University of  Baltimore",
  "University of  Maryland Baltimore County",
  "University of Advancing Technology",
  "University of Alabama",
  "University of Alabama at Birmingham",
  "University of Alabama in Huntsville",
  "University of Alaska",
  "University of Alaska - Anchorage",
  "University of Alaska - Southeast",
  "University of Alaska Fairbanks",
  "University of Arizona",
  "University of Arkansas",
  "University of Arkansas - Fayetteville",
  "University of Arkansas - Monticello",
  "University of Arkansas at Little Rock",
  "University of Arkansas at Pine Bluff",
  "University of Arkansas Community College Batesville",
  "University of Arkansas for Medical Sciences",
  "University of Arkansas – Fort Smith",
  "University of Bridgeport",
  "University of California",
  "University of California Davis",
  "University of California Hastings College of the Law",
  "University of California Irvine",
  "University of California Los Angeles",
  "University of California Los Angeles Extension",
  "University of California Merced",
  "University of California Press",
  "University of California Riverside",
  "University of California San Diego",
  "University of California San Francisco",
  "University of California Santa Barbara",
  "University of California Santa Cruz",
  "University of California Santa Cruz Silicon Valley Extension",
  "University of California Washington Center",
  "University of Central Arkansas",
  "University of Central Florida",
  "University of Central Missouri",
  "University of Central Oklahoma",
  "University of Central Texas",
  "University of Charleston",
  "University of Chicago",
  "University of Cincinnati",
  "University of Cincinnati Blue Ash",
  "University of Cincinnati Clermont",
  "University of Colorado",
  "University of Colorado Boulder",
  "University of Colorado Colorado Springs",
  "University of Colorado Denver",
  "University of Connecticut",
  "University of Connecticut Health Center",
  "University of Connecticut, Stamford",
  "University of Connecticut, Waterbury",
  "University of Dallas",
  "University of Dayton",
  "University of Delaware",
  "University of Denver",
  "University of Detroit Mercy",
  "University of Dubuque",
  "University of Evansville",
  "University of Florida",
  "University of Georgia",
  "University of Guam",
  "University of Hartford",
  "University of Hawaii - West Oahu",
  "University of Hawaii at Hilo",
  "University of Hawaii at Manoa",
  "University of Houston Clear Lake",
  "University of Houston Downtown",
  "University of Houston, Victoria",
  "University of Houston-University Park",
  "University of Idaho",
  "University of Illinois",
  "University of Illinois at Chicago",
  "University of Illinois at Urbana-Champaign",
  "University of Illinois Springfield",
  "University of Indianapolis",
  "University of Iowa",
  "University of Kentucky",
  "University of La Verne",
  "University of Louisiana at Monroe",
  "University of Louisiana Lafayette",
  "University of Main at Augusta",
  "University of Maine",
  "University of Maine at Presque Isle",
  "University of Maine Fort Kent",
  "University of Mary Hardin-Baylor",
  "University of Mary in Bismarck",
  "University of Mary Washington",
  "University of Maryland",
  "University of Maryland - College Park",
  "University of Maryland Eastern Shore",
  "University of Maryland University College",
  "University of Massachusetts",
  "University of Massachusetts Amherst",
  "University of Massachusetts Boston",
  "University of Massachusetts Dartmouth",
  "University of Massachusetts Lowell",
  "University of Massachusetts Medical School",
  "University of Miami",
  "University of Michigan",
  "University of Michigan - Dearborn",
  "University of Michigan-Flint",
  "University of Minnesota",
  "University of Minnesota - Duluth",
  "University of Minnesota Crookston",
  "University of Minnesota Twin Cities",
  "University of Mississippi Medical Center",
  "University of Missouri - Columbia",
  "University of Missouri - Rolla",
  "University of Missouri Kansas City",
  "University of Missouri Saint Louis",
  "University of Missouri System",
  "University of Mobile",
  "University of Montana",
  "University of Montana Helena College",
  "University of Montana Western",
  "University of Montevallo",
  "University of Mount Union",
  "University of Nebraska",
  "University of Nebraska at Kearney",
  "University of Nebraska at Lincoln",
  "University of Nebraska Medical Center",
  "University of Nebraska Omaha",
  "University of Nevada Las Vegas",
  "University of Nevada Reno",
  "University of New England",
  "University of New Hampshire",
  "University of New Haven",
  "University of New Mexico, Gallup",
  "University of New Mexico, Los Alamos",
  "University of New Mexico, Taos",
  "University of New Mexico, Valencia County",
  "University of New Orleans",
  "University of North Alabama",
  "University of North Carolina",
  "University of North Carolina Asheville",
  "University of North Carolina at Chapel Hill",
  "University of North Carolina Charlotte",
  "University of North Carolina Greensboro",
  "University of North Carolina Pembroke",
  "University of North Carolina School of the Arts",
  "University of North Carolina Wilmington",
  "University of North Dakota",
  "University of North Florida",
  "University of North Georgia",
  "University of North Texas",
  "University of North Texas at Dallas",
  "University of North Texas Health Science Center",
  "University of Northern Colorado",
  "University of Northern Iowa",
  "University of Northwestern Ohio",
  "University of Northwestern Saint Paul",
  "University of Notre Dame",
  "University of Oregon",
  "University of Ottawa",
  "University of Pennsylvania",
  "University of Phoenix",
  "University of Pittsburgh",
  "University of Portland",
  "University of Providence",
  "University of Puerto Rico, Aguadilla",
  "University of Puerto Rico, Arecibo",
  "University of Puerto Rico, Bayamon",
  "University of Puerto Rico, Carolina",
  "University of Puerto Rico, Cayey",
  "University of Puerto Rico, Humacao",
  "University of Puerto Rico, Mayaguez",
  "University of Puerto Rico, Medical Sciences",
  "University of Puerto Rico, Ponce",
  "University of Puerto Rico, Rio Piedras",
  "University of Puerto Rico, Utuado",
  "University of Puget Sound",
  "University of Redlands",
  "University of Rhode Island",
  "University of Richmond",
  "University of Rio  Grande",
  "University of Rio Grande",
  "University of Rochester",
  "University of Saint Francis",
  "University of Saint Joseph Connecticut",
  "University of Saint Katherine",
  "University of Saint Mary",
  "University of Saint Thomas",
  "University of San Diego",
  "University of San Francisco",
  "University of Science and Arts of Oklahoma",
  "University of Sioux Falls",
  "University of South Alabama",
  "University of South Carolina",
  "University of South Carolina Aiken",
  "University of South Carolina Beaufort",
  "University of South Carolina Upstate",
  "University of South Dakota",
  "University of South Florida",
  "University of South Florida Saint Petersburg",
  "University of South Florida Sarasota-Manatee",
  "University of Southern California",
  "University of Southern Colorado",
  "University of Southern Indiana",
  "University of Southern Maine",
  "University of Southern Mississippi",
  "University of St. Thomas - Minnesota",
  "University of St. Thomas - Texas",
  "University of Tampa",
  "University of Tennessee Chattanooga",
  "University of Tennessee Health Science Center",
  "University of Tennessee Knoxville",
  "University of Tennessee Martin",
  "University of Tennessee Space Institute",
  "University of Texas Arlington",
  "University of Texas at Arlington",
  "University of Texas at Austin",
  "University of Texas at El Paso",
  "University of Texas at San Antonio",
  "University of Texas at Tyler",
  "University of Texas Brownsville",
  "University of Texas Dallas",
  "University of Texas Health Science Center Houston",
  "University of Texas Health Science Center San Antonio",
  "University of Texas Medical Branch at Galveston",
  "University of Texas of the Permian Basin",
  "University of Texas Pan American",
  "University of Texas Southwestern Medical Center",
  "University of Texas, Rio Grande Valley",
  "University of the Arts",
  "University of the Cumberlands",
  "University of the District of Columbia",
  "University of the Incarnate Word",
  "University of the Ozarks",
  "University of the Pacific",
  "University of the Rockies",
  "University of the Sciences in Philadelphia",
  "University of the South",
  "University of the Southwest",
  "University of the Virgin Islands",
  "University of the Virgin Islands-Albert A. Sheen",
  "University of the West",
  "University of Toledo",
  "University of Tulsa",
  "University of Utah",
  "University of Vermont",
  "University of Virginia",
  "University of Virginia’s College at Wise",
  "University of Washington",
  "University of Washington - Bothell",
  "University of Washington - Tacoma",
  "University of Washington Bothell",
  "University of West Alabama",
  "University of West Florida",
  "University of West Georgia",
  "University of Wisconsin - Parkside",
  "University of Wisconsin Colleges",
  "University of Wisconsin Eau Claire",
  "University of Wisconsin Extension",
  "University of Wisconsin Green Bay",
  "University of Wisconsin La Crosse",
  "University of Wisconsin Milwaukee",
  "University of Wisconsin Oshkosh",
  "University of Wisconsin Platteville",
  "University of Wisconsin River Falls",
  "University of Wisconsin Stevens Point",
  "University of Wisconsin Superior",
  "University of Wisconsin Whitewater",
  "University of Wisconsin, Madison",
  "University of Wisconsin, Stout",
  "University of Wyoming",
  "University System of Georgia",
  "University System of Maryland",
  "Universty of Houston",
  "Upper Iowa University",
  "Upstate  Medical University",
  "Urban College of Boston",
  "Urbana University",
  "Ursinus College",
  "Ursuline College",
  "Utah State University",
  "Utah Valley University",
  "Utica College",
  "Valdosta State University",
  "Valencia College",
  "Valley City State University",
  "Valley Forge Christian College",
  "Valley Grande Institute",
  "Valparaiso University",
  "Vance-Granville Community College",
  "Vanderbilt University",
  "Vanguard University",
  "Vanguard University of Southern California",
  "Vassar College",
  "Vaughn College of Aeronautics and Technology",
  "Ventura College",
  "Vermont Law School",
  "Vermont Technical College",
  "Vernon College",
  "Victor Valley College",
  "Victoria College",
  "Villanova University",
  "Vincennes University",
  "Virgina Highlands Community College",
  "Virginia Commonwealth University",
  "Virginia Institute of Marine Science",
  "Virginia International University",
  "Virginia State University",
  "Virginia Tech",
  "Virginia Union University",
  "Virginia University of Lynchburg",
  "Virginia Wesleyan College",
  "Virginia Wesleyan University",
  "Virginia Western Community College",
  "Virginia’s Community Colleges",
  "Viterbo University",
  "Volunteer State Community College",
  "Voorhees College",
  "Wabash College",
  "Wagner College",
  "Wake Forest University",
  "Wake Technical Community College",
  "Walden University",
  "Waldorf College",
  "Waldorf University",
  "Walla Walla Community College",
  "Walla Walla University",
  "Walsh University",
  "Warner Pacific College",
  "Warner Pacific University",
  "Warner University",
  "Warren Wilson College",
  "Wartburg College",
  "Washburn University",
  "Washburn University School of Law",
  "Washington Adventist University",
  "Washington and Jefferson  College",
  "Washington and Lee University",
  "Washington College",
  "Washington State University",
  "Washington University in Saint Louis",
  "Washtenaw Community College",
  "Waubonsee Community College",
  "Waukesha County Technical College",
  "Wayland Baptist University",
  "Wayne State",
  "Wayne State College",
  "Wayne State University",
  "Waynesburg University",
  "Weatherford College",
  "Webber International University",
  "Weber State University",
  "Webster University",
  "Wellesley College",
  "Wells College",
  "Wenatchee Valley College",
  "Wentworth Institute of Technology",
  "Wesley College",
  "Wesleyan University",
  "West  Chester University",
  "West Chester University of Pennsylvania",
  "West Coast Baptist College",
  "West Coast University",
  "West Hills College, Coalinga",
  "West Hills College, Lemoore",
  "West Hills Community College District",
  "West Liberty University",
  "West Los Angeles College",
  "West Texas A&M University",
  "West Valley College",
  "West Virginia Northern Community College",
  "West Virginia State University",
  "West Virginia University",
  "West Virginia University at Parkersburg",
  "West Virginia Wesleyan College",
  "Westchester Community College",
  "Western Carolina University",
  "Western Colorado University",
  "Western Connecticut State University",
  "Western Dakota Technical Institute",
  "Western Illinois University",
  "Western International University",
  "Western Kentucky University",
  "Western Michigan University",
  "Western Nevada College",
  "Western New England University",
  "Western New Mexico University",
  "Western Oklahoma State College",
  "Western Oregon University",
  "Western Piedmont Community College",
  "Western Reserve University",
  "Western State Colorado University",
  "Western Technical College",
  "Western Texas College",
  "Western University of Health Sciences",
  "Western Washington University",
  "Westfield State University",
  "Westmar University",
  "Westminster College",
  "Westminster College, Missouri",
  "Westmont College",
  "Westmoreland County Community College",
  "Wharton County Junior College",
  "Wheaton College",
  "Wheaton College, Norton",
  "Wheeling Jesuit University",
  "Wheeling University",
  "Wheelock College",
  "White Mountains Community College",
  "Whitman College",
  "Whittier College",
  "Whitworth University",
  "Wichita State University",
  "Widener University",
  "Wilberforce University",
  "Wiley College",
  "Wilkes University",
  "Willamette University",
  "William Carey University",
  "William Howard Taft University",
  "William Jessup University",
  "William Paterson University",
  "William Paterson University of New Jersey",
  "William Rainey Harper College",
  "William Woods University",
  "Williams Baptist College",
  "Williams College",
  "Williston State College",
  "Wilmington College",
  "Wilmington University",
  "Wilson College",
  "Wingate University",
  "Winona State University",
  "Winona State Unviversity",
  "Winston-Salem State University",
  "Winthrop University",
  "Wiregrass Georgia Technical College",
  "Wisconsin Indianhead Technical College",
  "Wisconsin Lutheran College",
  "Wisconsin Technical College System",
  "Wittenberg University",
  "Wofford College",
  "Woodbury University",
  "Woodland Community College",
  "Wor-Wic Community College",
  "Worcester Polytechnic Institute",
  "Worcester State University",
  "World Mission University",
  "Wright State University",
  "Xavier University",
  "Xavier University of Louisiana",
  "Yakima Valley College",
  "Yakima Valley Community College",
  "Yale University",
  "Yavapai College",
  "Yeshiva University",
  "York College of Pennsylvania",
  "York County Community College",
  "York Technical College",
  "Yosemite Community College District",
  "Youngstown State University",
  "Yuba Community College District",
];
export const yearsOfExpOptions = [
  "1 - 5 years",
  "6+ years",
  "11+ years",
  "15+ years",
];
export const ageRangeOptions = [
  "18 – 25 years",
  "26+ years",
  "31+ years",
  "41+ years",
  "51+ years",
];
export const academicLevelOptions = [
  "First Year",
  "Sophomore",
  "Junior",
  "Senior",
  "Graduate Students (Masters and Ph.D.)",
];
export const levelEducationOptions = [
  "None",
  "Diploma",
  "Bachelor’s degree",
  "Master’s degree",
  "Ph.D.",
];

export const pronounsOptions = [
  { label: "She/Her", value: "She/Her" },
  { label: "He/Him", value: "He/Him" },
  { label: "They/Them", value: "They/Them" },
  { label: "Prefer not to say", value: "Prefer not to say" },
  { label: "Others, please specify", value: "Others" },
];

export const profLicenseOptions = [
  { label: "None", value: "None" },
  {
    label: "Associated Risk Manager (ARM)",
    value: "Associated Risk Manager (ARM)",
  },
  {
    label: "Certified Associate Construction Manager (CACM)",
    value: "Certified Associate Construction Manager (CACM)",
  },
  {
    label: "Certified Associate in Project Management (CAPM)",
    value: "Certified Associate in Project Management (CAPM)",
  },
  {
    label: "Certified Construction Manager (CCM)",
    value: "Certified Construction Manager (CCM)",
  },
  {
    label: "Certified/Associate Safety Professional (CSP/ASP)",
    value: "Certified/Associate Safety Professional (CSP/ASP)",
  },
  {
    label: "Certified Healthcare Environmental Services Technician (CHEST)",
    value: "Certified Healthcare Environmental Services Technician (CHEST)",
  },
  {
    label: "Certified Health and Safety Technician (CHST)",
    value: "Certified Health and Safety Technician (CHST)",
  },
  { label: "General Contractor", value: "General Contractor" },
  {
    label: "LEED Accredited Professional (LEED AP)",
    value: "LEED Accredited Professional (LEED AP)",
  },
  {
    label: "LEED Green Associate",
    value: "LEED Green Associate",
  },
  { label: "Licensed Architect (AIA)", value: "Licensed Architect (AIA)" },
  { label: "OSHA 10", value: "OSHA 10" },
  { label: "OSHA 30", value: "OSHA 30" },
  {
    label: "Principles and Practice of Engineering (PE)",
    value: "Principles and Practice of Engineering (PE)",
  },
  { label: "Professional Engineer (PE)", value: "Professional Engineer (PE)" },
  { label: "Professional Builder (PB)", value: "Professional Builder (PB)" },
  {
    label: "Site Safety Training (SST) Certification",
    value: "Site Safety Training (SST) Certification",
  },
  {
    label: "Tradesman license",
    value: "Tradesman license",
  },
];
export const typeProjectOptions = [
  { label: "Amusement and Recreation", value: "Amusement and Recreation" },
  { label: "Commercial Construction", value: "Commercial Construction" },
  { label: "Communication", value: "Communication" },
  {
    label: "Conservation and Development",
    value: "Conservation and Development",
  },
  { label: "Education", value: "Education" },
  { label: "Geotechnical and Tunneling", value: "Geotechnical and Tunneling" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Highway and Street", value: "Highway and Street" },
  { label: "Lodging", value: "Lodging" },
  { label: "Manufacturing", value: "Manufacturing" },
  {
    label: "Mechanical, Electrical, and Plumbing",
    value: "Mechanical, Electrical, and Plumbing",
  },
  { label: "Offices", value: "Offices" },
  { label: "Oil and Gas Construction", value: "Oil and Gas Construction" },
  { label: "Power", value: "Power" },
  { label: "Public Safety", value: "Public Safety" },
  { label: "Religious Construction", value: "Religious Construction" },
  { label: "Residential Construction", value: "Residential Construction" },
  { label: "Sewage and Waste Disposal", value: "Sewage and Waste Disposal" },
  { label: "Special Trade Construction", value: "Special Trade Construction" },
  {
    label: "Transportation/Highway Construction",
    value: "Transportation/Highway Construction",
  },
  { label: "Water Supply", value: "Water Supply" },
];
export const deptOptions = [
  {
    label: "Architectural Construction Management",
    value: "Architectural Construction Management",
  },
  { label: "Architectural Engineering", value: "Architectural Engineering" },
  {
    label: "Architectural Engineering Technology",
    value: "Architectural Engineering Technology",
  },
  { label: "Architectural Science", value: "Architectural Science" },
  { label: "Architectural Studies", value: "Architectural Studies" },
  { label: "Architecture", value: "Architecture" },
  {
    label: "Architecture and Environmental Design",
    value: "Architecture and Environmental Design",
  },
  {
    label: "Building and Construction Technology",
    value: "Building and Construction Technology",
  },
  { label: "Building Construction", value: "Building Construction" },
  { label: "Built Environment", value: "Built Environment" },
  {
    label: "Civil and Environmental Engineering",
    value: "Civil and Environmental Engineering",
  },
  { label: "Civil Engineering", value: "Civil Engineering" },
  {
    label: "Civil Engineering Technology",
    value: "Civil Engineering Technology",
  },
  { label: "Construction Engineering", value: "Construction Engineering" },
  {
    label: "Construction Engineering and Management",
    value: "Construction Engineering and Management",
  },
  {
    label: "Construction Engineering Technology",
    value: "Construction Engineering Technology",
  },
  { label: "Construction Management", value: "Construction Management" },
  {
    label: "Construction Management Engineering Technology",
    value: "Construction Management Engineering Technology",
  },
  {
    label: "Construction Management Technology",
    value: "Construction Management Technology",
  },
  { label: "Construction Science", value: "Construction Science" },
  {
    label: "Construction Science and Management",
    value: "Construction Science and Management",
  },
  { label: "Construction Technology", value: "Construction Technology" },
  { label: "Environmental Engineering", value: "Environmental Engineering" },
  {
    label: "Environmental Health and Safety",
    value: "Environmental Health and Safety",
  },
  { label: "Geomatics", value: "Geomatics" },
  { label: "Landscape Architecture", value: "Landscape Architecture" },
  {
    label: "Mechanical Engineering",
    value: "Mechanical Engineering",
  },
  {
    label: "Real Estate and Construction Management",
    value: "Real Estate and Construction Management",
  },
];
export const typeOfFirmOptions = [
  {
    label: "Construction Advisory firm",
    value: "Construction Advisory firm",
  },
  { label: "Design-Build firm", value: "Design-Build firm" },
  { label: "Design firms", value: "Design firms" },
  {
    label: "Engineering Consulting",
    value: "Engineering Consulting",
  },
  {
    label: "Federal/Heavy Construction firm",
    value: "Federal/Heavy Construction firm",
  },
  { label: "General Contractor", value: "General Contractor" },
  {
    label: "Government Agency/Department",
    value: "Government Agency/Department",
  },
  {
    label: "Mechanical, Electrical and Plumbing (MEP) firm",
    value: "Mechanical, Electrical and Plumbing (MEP) firm",
  },
  {
    label: "Multifamily/Mixed Use Construction",
    value: "Multifamily/Mixed Use Construction",
  },
  {
    label: "Non-Residential Building Construction firm",
    value: "Non-Residential Building Construction firm",
  },
  {
    label: "Residential Building Construction firm",
    value: "Residential Building Construction firm",
  },
  {
    label: "Special Trade Contracting firm",
    value: "Special Trade Contracting firm",
  },
  {
    label: "Transportation and Highway Construction firm",
    value: "Transportation and Highway Construction firm",
  },
  {
    label: "University Department/Division",
    value: "University Department/Division",
  },
  { label: "Others, please specify", value: "Others" },
];
export const sizeOfFirmOptions = [
  { label: "Less than 10 employees", value: "Less than 10 employees" },
  { label: "10+ employees", value: "10+ employees" },
  { label: "20+ employees", value: "20+ employees" },
  { label: "50+ employees", value: "50+ employees" },
  { label: "100+ employees", value: "100+ employees" },
  { label: "250+ employees", value: "250+ employees" },
  { label: "500+ employees", value: "500+ employees" },
  { label: "1,000+ employees", value: "1,000+ employees" },
];

export const companySizeOptions = [
  { label: "Less than 10 employees", value: "Less than 10 employees" },
  { label: "10 - 19 employees", value: "10 - 19 employees" },
  { label: "20 - 49 employees", value: "20 - 49 employees" },
  { label: "50 - 99 employees", value: "50 - 99 employees" },
  { label: "100 - 249 employees", value: "100 - 249 employees" },
  { label: "250 - 499 employees", value: "250 - 499 employees" },
  { label: "500 - 999 employees", value: "500 - 999 employees" },
  { label: "1,000+ employees", value: "1,000+ employees" },
];

export const positionOptions = [
  { label: "Architect", value: "Architect" },
  { label: "Assistant Project Engineer", value: "Assistant Project Engineer" },
  { label: "Assistant Project Manager", value: "Assistant Project Manager" },
  {
    label: "Assistant Site Superintendent",
    value: "Assistant Site Superintendent",
  },
  { label: "Civil Engineer", value: "Civil Engineer" },
  {
    label: "Construction Data Analyst",
    value: "Construction Data Analyst",
  },
  { label: "Construction Manager", value: "Construction Manager" },
  { label: "Electrical Engineer", value: "Electrical Engineer" },
  { label: "Estimator", value: "Estimator" },
  { label: "Field/Project Engineer", value: "Field/Project Engineer" },
  {
    label: "Health and Safety Specialist",
    value: "Health and Safety Specialist",
  },
  { label: "Inspector", value: "Inspector" },
  { label: "Landscape Architect", value: "Landscape Architect" },
  {
    label: "Mechanical, Electrical and Plumbing (MEP) Specialist",
    value: "Mechanical, Electrical and Plumbing (MEP) Specialist",
  },
  { label: "Mechanical Engineer", value: "Mechanical Engineer" },
  { label: "Owner's Representative", value: "Owner's Representative" },
  { label: "President/CEO", value: "President/CEO" },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Sales Associate", value: "Sales Associate" },
  { label: "Sales Engineer", value: "Sales Engineer" },
  { label: "Senior Manager", value: "Senior Manager" },
  { label: "Site Manager", value: "Site Manager" },
  { label: "Structural Engineer", value: "Structural Engineer" },
  { label: "Superintendent", value: "Superintendent" },
  { label: "Surveyor", value: "Surveyor" },
  {
    label: "Talent Engagement Manager",
    value: "Talent Engagement Manager",
  },
  { label: "Tradesman", value: "Tradesman" },
  { label: "Vice President", value: "Vice President" },
  { label: "Others, please specify", value: "Others" },
];
export const insTypeOptions = [
  {
    label: "Predominantly White Institution",
    value: "Predominantly White Institution",
  },
  {
    label: "Historically Black Institution",
    value: "Historically Black Institution",
  },
  {
    label: "Hispanic Serving Institution",
    value: "Hispanic Serving Institution",
  },
  { label: "Others, please specify", value: "Others" },
];
export const instructorTitleOptions = [
  { label: "Professor", value: "Professor" },
  { label: "Adjunct Professor", value: "Adjunct Professor" },
  { label: "Associate Professor", value: "Associate Professor" },
  { label: "Assistant Professor", value: "Assistant Professor" },
  { label: "Professor of Practice", value: "Professor of Practice" },
  {
    label: "Assistant Professor of Practice",
    value: "Assistant Professor of Practice",
  },
  {
    label: "Senior Professor of Practice",
    value: "Senior Professor of Practice",
  },
  { label: "Lecturer", value: "Lecturer" },
  { label: "Instructor", value: "Instructor" },
  { label: "Others, please specify", value: "Others" },
];
export const practitionerTitleOptions = [
  { label: "Mr.", value: "Mr" },
  { label: "Ms.", value: "Ms" },
  { label: "Mrs.", value: "Mrs" },
  { label: "Miss", value: "Miss" },
  { label: "Dr.", value: "Dr." },
  { label: "Others, please specify", value: "Others" },
];

export const typeTechnologyOptions = [
  "3D Printing",
  "Augmented Reality",
  "BIM",
  "Blockchain",
  "Digital Twin",
  "Exoskeleton (Robotics)",
  "Global Positioning System",
  "Laser scanner",
  "RFID",
  "Smart Building Materials",
  "Unmanned Aerial Vehicle (Drone)",
  "Virtual Reality",
  "Wearable Technology (e.g., Smart PPE)",
];
export const subjectWillingToSpeakOnOptions = [
  { label: "Estimating", value: "Estimating" },
  { label: "Scheduling", value: "Scheduling" },
  { label: "Project Management", value: "Project Management" },
  { label: "Program Management", value: "Program Management" },
  { label: "HVAC", value: "HVAC" },
  { label: "Site Logistics", value: "Site Logistics" },
  { label: "Structural Analysis", value: "Structural Analysis" },
  { label: "Lean Construction", value: "Lean Construction" },
  {
    label: "Process Planning and Design",
    value: "Process Planning and Design",
  },
  { label: "Business Management", value: "Business Management" },
  { label: "Waste Management", value: "Waste Management" },
  { label: "Foundations", value: "Foundations" },
];

export const diversityOptions = [
  { label: "Very High", value: "Very High" },
  { label: "High", value: "High" },
  { label: "Moderate", value: "Moderate" },
  { label: "Slightly", value: "Slightly" },
  { label: "Not diverse and inclusive", value: "Not diverse and inclusive" },
];

export const locationOptions = [
  { label: "Midwest", value: "Midwest" },
  { label: "Northeast", value: "Northeast" },
  { label: "Southeast", value: "Southeast" },
  { label: "Southwest", value: "Southwest" },
  { label: "West", value: "West" },
];

export const UserFields = [
  { key: "email", label: "Email", sortable: true },
  { key: "firstName", label: "First Name", sortable: true },
  { key: "lastName", label: "Last Name", sortable: true },
  { key: "title", label: "Title" },
  {
    key: "nameOrg",
    label: "Name of Organization",
    sortable: true,
  },
  "status",
  {
    key: "roleId",
    label: "Role",
  },
  { key: "actions", label: "", width: 80 },
];

export const FeedbackFields = [
  { key: "user.firstName", label: "First Name", sortable: true },
  { key: "user.lastName", label: "Last Name", sortable: true },
  { key: "rating", label: "rating" },
  { key: "message", label: "Message", width: "25%" },
  { key: "actions", label: "", width: 80 },
];

export const ContactUsFields = [
  { key: "firstName", label: "First Name", sortable: true },
  { key: "lastName", label: "Last Name", sortable: true },
  { key: "email", label: "Email" },
  { key: "nameOrg", label: "Name of Organization" },
  { key: "message", label: "Message", width: "25%" },
  { key: "actions", label: "", width: 80 },
];

export const FAQFields = [
  "userQuestion",
  "answer",
  "display",
  { key: "actions", label: "", width: 80 },
];

export const DashboardAccessRequestFields = [
  { key: "user.firstName", label: "First Name", sortable: true },
  { key: "user.lastName", label: "Last Name", sortable: true },
  { key: "user.email", label: "Email" },
  { key: "user.nameOrg", label: "Name of Organization" },
  { key: "actions", label: "", width: 80 },
];

export const DashboardFeedbackFields = [
  { key: "user.firstName", label: "First Name", sortable: true },
  { key: "user.lastName", label: "Last Name", sortable: true },
  { key: "message", label: "Message", width: "25%" },
  { key: "actions", label: "", width: 80 },
];

export const OrganizationFields = [
  { key: "name", label: "Name", sortable: true },
  { key: "originalName", label: "Name on User Profile", sortable: true },
  { key: "display", label: "Display on Screen" },
  { key: "actions", label: "", width: 80 },
];

export const ClassFields = [
  "courseCode",
  "courseTitle",
  "semester",
  "year",
  "academicLevel",
  "studentDept",
  "classSize",
  "status",
  { key: "actions", label: "", width: 80 },
];

export const InstructorFields = [
  { key: "user.email", label: "Email" },
  { key: "user.firstName", label: "First Name" },
  { key: "user.lastName", label: "Last Name" },
  "department",
  { key: "user.nameOrg", label: "Institution" },
  { key: "user.title", label: "Title" },
  { key: "user.gender", label: "Gender" },
  { key: "user.ethnicity", label: "Racial Identity" },
  "ageRange",
  { key: "actions", label: "", width: 80 },
];

export const IndustryPractitionerFields = [
  { key: "user.email", label: "Email" },
  { key: "user.firstName", label: "First Name" },
  { key: "user.lastName", label: "Last Name" },
  { key: "user.nameOrg", label: "Organization" },
  "position",
  "yearsOfExp",
  "expertise",
  "location",
  { key: "actions", label: "", width: 80 },
];

export const CourseRequestFields = [
  "courseRequestType",
  "studentDept",
  "academicLevel",
  "insDeliveryType",
  "classSize",
  "courseCode",
  "courseTitle",
  { key: "user.email", label: "Instructor Email" },
  "status",
  { key: "actions", label: "", width: 80 },
];

export const ResourceRequestFields = [
  "type",
  "description",
  { key: "user.firstName", label: "Instructor First Name" },
  { key: "user.lastName", label: "Instructor Last Name" },
  { key: "user.email", label: "Instructor Email" },
  "status",
  { key: "actions", label: "", width: 80 },
];

export const AvailabilityFields = [
  "courseRequestType",
  "insDeliveryType",
  "academicLevel",
  "studentDept",
  "insState",
  "insName",
  { key: "actions", label: "", width: 80 },
];

export const UploadFields = [
  "type",
  "description",
  { key: "user.email", label: "User Email" },
  { key: "user.firstName", label: "User First Name" },
  { key: "user.lastName", label: "User Last Name" },
  { key: "status", sortable: true },
  "reasonDelete",
  { key: "actions", label: "", width: 80 },
];

export const DashboardLabels = {
  "Predominantly White Institution": "PWI",
  "Historically Black Institution": "HBI",
  "Hispanic Serving Institution": "HSI",
  Labs: "Labs",
  Male: "M",
  Female: "F",
  "Non-binary": "NB",
  Others: "Others",
};
