import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "rating-stars" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group text-align-center mt-4" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "@/services/user.service.js";

// Define props and emits

export default /*@__PURE__*/_defineComponent({
  __name: 'FeedbackForm',
  props: {
    currCourseId: {}
  },
  emits: ["fetchCourse"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

// State variables
let loading = ref(false);

// Validation schema
const courseFeedbackSchema = yup.object().shape({
  message: yup.string().required("Comments about the session is required!"),
  rating: yup
    .number()
    .required("Rating is required!")
    .min(1, "Please select a rating."),
  concern: yup.string().optional().nullable(),
});

// Save feedback
const saveCourseFeedback = (data) => {
  loading.value = true;
  data.courseRequestId = props.currCourseId;
  UserService.saveCourseFeedback(data).then(
    (response) => {
      if (response.status == "200") emits("fetchCourse");
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      console.error(error);
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: saveCourseFeedback,
    "validation-schema": _unref(courseFeedbackSchema)
  }, {
    default: _withCtx(({ values, meta, setFieldValue }) => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { for: "message" }, [
          _createTextVNode(" Comments about the session "),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), {
          name: "message",
          type: "text",
          class: "form-control"
        }),
        _createVNode(_unref(ErrorMessage), {
          name: "message",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "rating" }, [
          _createTextVNode(" Rating of the Course-Support Session"),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (star) => {
            return _createElementVNode("span", {
              key: star,
              class: _normalizeClass(["star", { selected: star <= values.rating }]),
              onClick: ($event: any) => (setFieldValue('rating', star))
            }, " ★ ", 10, _hoisted_4)
          }), 64))
        ]),
        _createVNode(_unref(ErrorMessage), {
          name: "rating",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { for: "concern" }, "Any feedback, comments, or concern to Admin.", -1)),
        _createVNode(_unref(Field), {
          name: "concern",
          type: "text",
          class: "form-control"
        }),
        _createVNode(_unref(ErrorMessage), {
          name: "concern",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading) || !meta.valid
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          _cache[3] || (_cache[3] = _createTextVNode(" Save Feedback "))
        ], 8, _hoisted_7)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})