<template>
  <div class="flex flex-column lg:flex-row gap-4 mt-3">
    <div class="align-center text-align-center flex-grow-0">
      <div>
        <h3>Categories</h3>
        <div
          class="d-inline-block mt-1 mx-1 p-1 px-2 cursor-pointer category-menu-item rounded-2"
          title="Rename"
          @click="renameMenuVisible = true"
        >
          <img src="@/assets/images/rename.png" class="category-menu-img" />
        </div>
        <div
          class="d-inline-block mt-1 mx-1 py-1 px-2 cursor-pointer category-menu-item rounded-2"
          title="Delete"
          @click="deleteCategory"
        >
          <img
            src="@/assets/images/delete.png"
            class="category-menu-img cursor-pointer"
          />
        </div>
      </div>
      <div class="mt-3">
        <div
          v-for="(category, index) in categories"
          v-bind:key="index"
          class="p-2 bg-bluegray-300 my-1 cursor-pointer rounded-3"
          :class="{ active: selectedCategory === index }"
          @click="selectedCategory = index"
        >
          {{ category.name }}
        </div>
        <div
          class="p-2 bg-bluegray-300 my-1 cursor-pointer rounded-3"
          @click="newCategoryMenuVisible = true"
        >
          <font-awesome-icon
            style="margin: auto"
            icon="fa-solid fa-plus"
            title="Add"
          />&nbsp;Add New
        </div>
      </div>
    </div>
    <div class="align-center text-align-center">
      <div class="flex flex-direction-row">
        <h3>Topics</h3>
        <div class="flex-grow-0 w-10rem text-end">
          <va-switch
            v-model="showAll"
            color="#5123a1"
            off-color="#ffd300"
            size="small"
          >
            <template #innerLabel>
              <div class="va-text-center">
                <div v-if="showAll">
                  <span> All </span>
                </div>
                <div v-else>
                  <span> Uncategorized </span>
                </div>
              </div>
            </template>
          </va-switch>
        </div>
      </div>
      <div class="mt-6 flex flex-direction-row flex-wrap">
        <div class="flex flex-wrap" v-if="showAll">
          <span
            v-for="topic in topics"
            v-bind:key="topic.id"
            class="p-1 bg-bluegray-200 m-1 rounded-2 topic-item"
          >
            <input
              :id="topic.id"
              type="checkbox"
              :name="topic.name"
              :checked="categories[selectedCategory]?.id === topic.categoryId"
              @click="changeCategory($event, topic)"
            />&nbsp;
            {{ topic.name }}
          </span>
        </div>
        <div class="flex flex-wrap" v-else>
          <span
            v-for="topic in topicsFiltered"
            v-bind:key="topic.id"
            class="p-1 bg-bluegray-200 m-1 rounded-2 topic-item"
          >
            <input
              :id="topic.id"
              type="checkbox"
              :name="topic.name"
              :checked="categories[selectedCategory]?.id === topic.categoryId"
              @click="changeCategory($event, topic)"
            />&nbsp;
            {{ topic.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="newCategoryMenuVisible"
    modal
    header="Create New Category"
    :style="{ width: '30vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '75vw' }"
  >
    <Form
      @submit="createNewCategory"
      :validation-schema="newCategorySchema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <Field
          name="name"
          type="text"
          class="form-control"
          placeholder="Category"
        />
        <ErrorMessage name="name" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Create</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="renameMenuVisible"
    modal
    header="Rename Category"
    :style="{ width: '30vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '75vw' }"
  >
    <Form
      @submit="updateCategory"
      :validation-schema="newCategorySchema"
      v-slot="{ meta }"
    >
      <b>Current Category </b>: <i>{{ categories[selectedCategory]?.name }} </i>
      <div class="form-group mt-2">
        <Field
          name="name"
          type="text"
          class="form-control"
          placeholder="New Name"
        />
        <ErrorMessage name="name" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Rename</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import { showToast } from "@/utils";
import AdminService from "@/services/admin.service.js";
import { Category, Topic } from "@/types";
import { useModal } from "vuestic-ui";

const { confirm } = useModal();

let categories = ref([] as Category[]);
let topics = ref([] as Topic[]);
let topicsFiltered = ref([] as Topic[]);

let selectedCategory = ref(0);
let showAll = ref(false);
let newCategoryMenuVisible = ref(false);
let renameMenuVisible = ref(false);
let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const props = defineProps<{
  tableName: string;
}>();

const newCategorySchema = yup.object().shape({
  name: yup
    .string()
    .required("Category Name is required!")
    .notOneOf(
      categories.value.map((el) => el.name),
      "Category already exists!"
    ),
});

const getCategories = (table) => {
  loading.value = true;
  AdminService.getCategories(table).then(
    (response) => {
      console.log(response.data);
      categories.value = response.data;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const createNewCategory = (data) => {
  loading.value = true;
  AdminService.createCategory(data, props.tableName).then(
    (response) => {
      showToast("New Category created successfully.");
      getCategories(props.tableName);
      newCategoryMenuVisible.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const updateCategory = (data) => {
  loading.value = true;
  AdminService.updateCategory(
    data,
    categories.value[selectedCategory.value]?.id
  ).then(
    (response) => {
      showToast("Category updated successfully.");
      getCategories(props.tableName);
      renameMenuVisible.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const deleteCategory = async () => {
  const cancelDeleteRequest = await confirm({
    message: "Are you sure you want to delete this category?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDeleteRequest) {
    loading.value = true;
    AdminService.deleteCategory(
      categories.value[selectedCategory.value]?.id
    ).then(
      (response) => {
        showToast("Category deleted successfully.");
        getCategories(props.tableName);
        getTopic(props.tableName);
        newCategoryMenuVisible.value = false;
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const getTopic = (table) => {
  loading.value = true;
  AdminService.getTopic(table).then(
    (response) => {
      topics.value = response.data;
      topicsFiltered.value = topics.value.filter((el) => el.categoryId == null);
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const changeCategory = (event, topic) => {
  loading.value = true;
  AdminService.updateTopic(
    topic.id,
    event.target.checked ? categories.value[selectedCategory.value]?.id : null
  ).then(
    (response) => {
      getTopic(props.tableName);
      showToast("Topic Category updated successfully.");
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCategories(props.tableName);
getTopic(props.tableName);

const formValues = {
  name: categories.value[selectedCategory.value]?.name,
};
</script>

<style scoped>
.active {
  font-weight: bolder;
  font-size: larger;
}

.topic-item {
  width: max-content;
  display: flex;
  flex-wrap: wrap;
  max-width: fit-content;
  padding: 0.25rem 0.25rem;
  align-items: center;
}

.category-menu-img {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.category-menu-item:hover {
  background: #acadb0;
}
</style>
