<template>
  <div class="content-card">
    <p>
      <b>Course-Support Type</b>: {{ course.courseRequestType }}
      <span
        title="A notification has been sent to the selected industry practitioner"
      >
        <font-awesome-icon
          icon="fa-solid fa-circle-check"
          style="color: #0bda51; font-size: large"
          v-if="
            course.status === '10' ||
            course.status === '20' ||
            course.status === '60'
          "
        />
      </span>
      <br />
      <b>Course Requested On</b>:
      {{ new Date(course.createdAt).toDateString() }}
    </p>
    <div>
      <div
        v-if="
          course &&
          course.course_request_x_practitioners &&
          course.course_request_x_practitioners.filter(
            (el) => el.status === '10' || el.status === '60'
          ).length > 0
        "
        class="font-bold font-larger text-align-center"
      >
        <font-awesome-icon
          icon="fa-solid fa-check"
          class="font-larger"
          style="color: #0bda51; font-size: xx-large"
        />
        Accepted
        <br />
        {{
          new Date(
            course.course_request_x_practitioners.filter(
              (el) => el.status === "10" || el.status === "60"
            )[0]?.selectedDate || ""
          ).toLocaleString()
        }}
      </div>

      <div
        v-else-if="
          course.course_request_x_practitioners.filter(
            (el) => el.status === '20'
          ).length > 0
        "
        class="font-bold font-larger text-align-center"
      >
        <font-awesome-icon
          icon="fa-solid fa-circle-exclamation"
          class="font-larger"
          style="color: #336; font-size: xx-large"
        />
        Response Pending
      </div>
    </div>
    <div class="button-container lg:ml-3">
      <div v-if="FeedStore.tab === 0" class="p-2">
        <button
          class="btn btn-danger bg-red-600 border-none"
          @click="(currCourse = course), (visible = true)"
          title="Delete"
        >
          <font-awesome-icon icon="fa-solid fa-trash" />
        </button>
      </div>
      <div
        v-if="
          FeedStore.tab === 1 && !course.feedbackExists && course.status == '60'
        "
        class="p-2"
      >
        <button
          class="btn btn-danger bg-red-500 border-none"
          @click="(currCourse = course), (feedbackVisible = true)"
          title="Give Feedback"
        >
          <img src="@/assets/images/feedback.png" class="sidebar-image" />
        </button>
      </div>
      <div class="p-2">
        <button
          class="btn btn-primary bg-bluegray-400 border-none"
          @click="editCourseRequest(course)"
          title="Edit & Submit"
        >
          <font-awesome-icon icon="fa-solid fa-pen-to-square" />
        </button>
      </div>
      <div
        v-if="
          course.status !== '10' &&
          course.status !== '20' &&
          course.status !== '60' &&
          type === 'Active'
        "
        class="p-2"
      >
        <button
          class="btn btn-primary bg-cyan-600 border-none"
          @click="updateCourseRequest(course)"
          title="Update"
        >
          <font-awesome-icon icon="fa-solid fa-rotate" />
        </button>
      </div>
      <div v-else class="p-2">
        <button
          class="btn btn-primary bg-green-400 border-none"
          @click="viewCourseRequest(course)"
          title="View Details"
        >
          <img src="@/assets/images/info.png" class="sidebar-image" />
        </button>
      </div>
      <div
        v-if="
          course.status == '10' ||
          course.status == '20' ||
          course.status == '60'
        "
        class="p-2 text-align-center flex flex-direction-column"
      >
        <button
          class="btn btn-primary"
          @click="viewSelectedRecommendations(course)"
        >
          View Selected Practitioners
        </button>
        <span style="font-style: italic; font-size: smaller">
          Practitioners' contact details available
        </span>
      </div>
      <div v-else-if="type === 'Active'" class="p-2">
        <button
          class="btn btn-primary collapsible"
          @click="viewRecommendations(course)"
        >
          View Recommendations
        </button>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    header="Delete Course-Support Request"
    :style="{ width: '40vw', padding: '20px', backgroundColor: '#fff' }"
  >
    <Form
      @submit="deleteCourseRequest"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonDelete"
          >Please provide a reason for deleting the course-support
          request</label
        >
        <Field name="reasonDelete" type="text" class="form-control" />
        <ErrorMessage name="reasonDelete" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Delete</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="feedbackVisible"
    modal
    header="Course-Support Feedback"
    :style="{ width: '50vw', padding: '20px', backgroundColor: '#fff' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <feedback-from
      :curr-course-id="currCourse.id"
      @fetchCourse="emitFetchCourse"
    />
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { showToast, showErrorToast } from "@/utils";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Dialog from "primevue/dialog";
import FeedbackFrom from "@/components/user/FeedbackForm.vue";
import InstructorService from "@/services/instructor.service.js";

import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { CourseRequest } from "@/types";

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const FeedStore = useFeedStore();
const RecommendationsStore = useRecommendationsStore();
const router = useRouter();

let currentUser = AuthStore.auth;
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let visible = ref(false);
let currCourse = ref({} as CourseRequest);
let feedbackVisible = ref(false);

// eslint-disable-next-line no-undef
const props = defineProps<{
  course: CourseRequest;
  type: string;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["fetchCourse"]);

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const deleteCourseRequest = (data) => {
  loading.value = true;
  data.courseId = currCourse.value.id;
  let courseStatus = currCourse.value.status;
  console.log(currCourse);
  InstructorService.deleteCourseRequest(data).then(
    (response) => {
      if (response.status == "200") emits("fetchCourse", "present");
      if (
        courseStatus == "10" ||
        courseStatus == "20" ||
        courseStatus == "60"
      ) {
        showToast(
          "Your course-support request has been deleted and a notification has been sent to the practitioner you earlier selected to inform them that you have canceled the request."
        );
      } else
        showToast("The course support request has been successfully deleted.");
      loading.value = false;
      visible.value = false;
    },
    (error) => {
      loading.value = false;
      visible.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const emitFetchCourse = () => {
  feedbackVisible.value = false;
  showToast("Your feedback has been successfully saved.");
  emits("fetchCourse", "past");
};

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-ins" });
};
const updateCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "update-course-request" });
};

const editCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "edit-course-request" });
};

const viewRecommendations = (course) => {
  CourseStore.addCourse(course);
  RecommendationsStore.resetSelectedPractitioner();
  router.push({ name: "view-recommendations" });
};
const viewSelectedRecommendations = (course) => {
  CourseStore.addCourse(course);
  RecommendationsStore.resetSelectedPractitioner();
  router.push({ name: "view-recommendations-selected" });
};
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 1.5em;
  padding: 18px;
  margin-right: 2rem;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-radius: 8px;
}
@media only screen and (max-width: 768px) {
  .content-card {
    margin-top: 1rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .content-card {
    margin-top: 0.5rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    flex-direction: column;
  }

  .button-container {
    justify-content: space-evenly;
  }
}
</style>
